import * as React from "react";
import { StyleSheet, ScrollView, View, Dimensions, ScaledSize, SafeAreaView, Pressable, Modal, ActivityIndicator, FlatList, Animated, Easing, VirtualizedList } from "react-native";
import { Text } from "../components/Themed";
import { kcData } from "../kcData";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { Button, SearchBar } from "react-native-elements";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { TabQuoteParamList } from "../types";
import RegListHelper, { RegListUpdateParam } from "../kcData/RegListHelper";
import { kcCommodityModel, kcModel_Enum } from "../kcModel";
import { kcExchangeCode } from "../kcModel/kcModel_Enum";
import { Ionicons, FontAwesome } from "@expo/vector-icons";
import { kcColor } from "../constants/Colors";
import { MaterialIcons } from "@expo/vector-icons";
import { TapGestureHandler } from "react-native-gesture-handler";
import { GetText } from "../Locales";
import { KcExcList } from "./KcExcList";

type CommodityItem = { Commodity: kcCommodityModel };
export type ExcListType = { Exchange: kcExchangeCode; Stocks: CommodityItem[] };

type IState = {
   SearchText: string;
   DisplayList: ExcListType[];
   DimensionsWindow: ScaledSize;
};

type IProp = {
   route: RouteProp<TabQuoteParamList, "TabQuoteScreen_Add">;
   navigation: StackNavigationProp<TabQuoteParamList, "TabQuoteScreen_Add">;
};

class QuoteScreen_Add extends React.Component<IProp, IState> {
   state: IState = {
      SearchText: "",
      DisplayList: [],
      DimensionsWindow: Dimensions.get("window"),
   };

   /* ------------------------------------------------------------- */
   // Member
   reg: string = "";
   ExchangeList: ExcListType[] = [];
   m_FailModal = React.createRef<AddFailModal>();

   /* ------------------------------------------------------------- */
   // HookFunc
   componentDidMount() {
      this.props.navigation.addListener("focus", this.OnComponent_Focused);
      this.props.navigation.addListener("blur", this.OnComponent_blur);
      Dimensions.addEventListener("change", this.onDimensionsChange);

      this.props.navigation.setOptions({
         //headerTitle: this.Header.bind(this, ""),
         headerTitle: this.Render_HeaderTitle.bind(this, ""),
         headerLeft: this.Render_HeaderLeft,
         headerTitleContainerStyle: {
            //marginLeft: 50,
            marginRight: 5,
            flex: 1,
         },
      });

      this.InitExchangeList();
   }

   componentWillUnmount() {
      this.props.navigation.removeListener("focus", this.OnComponent_Focused);
      this.props.navigation.removeListener("blur", this.OnComponent_blur);
      Dimensions.removeEventListener("change", this.onDimensionsChange);
      kcSetUnmount(this, true);
   }

   // shouldComponentUpdate(nextProps: IProp, nextState: IState) {
   //   return true;
   // }

   /* ------------------------------------------------------------- */

   // 整理成By各個交易所的List
   private InitExchangeList = () => {
      let CommodityList = kcData.GetCommodityList();
      if (!CommodityList || CommodityList.length === 0) {
         setTimeout(this.InitExchangeList, 500);
         return;
      }
      let ExcList: ExcListType[] = [];
      for (let i = 0; i < CommodityList.length; i++) {
         let mdCommodity = CommodityList[i];
         let ExcItem = ExcList.find((q) => q.Exchange === mdCommodity.ExchangeCode);
         if (!ExcItem) {
            ExcItem = { Exchange: mdCommodity.ExchangeCode, Stocks: [] };
            ExcList.push(ExcItem);
         }
         ExcItem.Stocks.push({ Commodity: mdCommodity });
      }
      ExcList.sort((a, b) => a.Exchange - b.Exchange);

      this.ExchangeList = ExcList;

      kcSetState(this, { DisplayList: this.ExchangeList });
   };

   private updateSearch = (_Text: string) => {
      this.props.navigation.setOptions({
         headerTitle: this.Render_HeaderTitle.bind(this, _Text),
      });
      kcSetState(this, { SearchText: _Text });
   };

   /* ------------------------------------------------------------- */
   // On
   private OnComponent_Focused = () => {};

   private OnComponent_blur = () => {};

   private onDimensionsChange = ({ window, screen }: { window: ScaledSize; screen: ScaledSize }) => {
      this.setState({ DimensionsWindow: window });
   };

   /* ------------------------------------------------------------- */
   //Render
   private Render_HeaderLeft = () => {
      return (
         <Button
            // style={{ width: 48, height: 48 }}
            icon={<Ionicons name="ios-chevron-back" size={24} color={kcColor("Icon")} />}
            iconContainerStyle={{ alignSelf: "center" }}
            buttonStyle={styles.button}
            onPress={() => {
               if (this.props.navigation.canGoBack()) this.props.navigation.goBack();
               else this.props.navigation.navigate("TabQuoteScreen");
            }}
         />
      );
   };

   private Render_HeaderTitle = (_SearchText: string) => {
      return (
         <View
            style={{
               justifyContent: "center",
               flex: 1,
               backgroundColor: "#00000000",
            }}
         >
            <SearchBar
               key="StockSearchBar"
               platform="default"
               placeholder={GetText("Search")}
               onChangeText={this.updateSearch.bind(this)}
               value={_SearchText}
               textBreakStrategy="highQuality"
               showSoftInputOnFocus={true}
               //showLoading={true}
               containerStyle={[
                  styles.BackGroundColor,
                  {
                     borderTopWidth: 0,
                     borderBottomWidth: 0,
                     //backgroundColor: "green",
                  },
               ]}
               // rightIconContainerStyle={{
               //   width: 30,
               //   height: 30,
               //   backgroundColor: "green",
               // }}
               // inputStyle={{
               //   height: 30,
               //   //backgroundColor: "#00FFFF"
               //   borderTopWidth: 1,
               //   borderTopColor: "green",
               // }}
               inputContainerStyle={{
                  // height: 30,
                  borderRadius: 13,
                  backgroundColor: kcColor("ListTitleBackground"),
               }}
               // style={{
               //   height: 30,
               //   backgroundColor: "#ffff00",
               // }}
               // searchIcon={<></>}
            />
         </View>
      );
   };

   render() {
      let { DisplayList, SearchText } = this.state;
      const EffectiveHeigh = this.state.DimensionsWindow.height - 60;
      return (
         <SafeAreaView
            style={{
               backgroundColor: kcColor("Background"),
               flexDirection: "column",
               height: EffectiveHeigh,
               paddingTop: 0,
               paddingBottom: 0,
            }}
         >
            <AddFailModal ref={this.m_FailModal} />
            <ScrollView
               style={{ backgroundColor: "#00000000", flex: 1 }}
               showsVerticalScrollIndicator={false} // 關閉垂直滾動條
            >
               {DisplayList.map((_Item, _idx) => (
                  <KcExcList
                     key={`${_idx}_${kcExchangeCode[_Item.Exchange]}`}
                     DataItem={_Item}
                     SearchText={SearchText}
                     ParentProp={this.props}
                     OnFail={(_szMsg) => {
                        this.m_FailModal?.current?.Show(_szMsg);
                     }}
                  />
               ))}

               <View style={{ backgroundColor: "#00000000", height: 60 }} />
            </ScrollView>
            <View style={{ backgroundColor: "#00000000", height: 40 }} />
         </SafeAreaView>
      );
   }
}

type IAddFailModalState = {
   Visable: boolean;
   loading: boolean;
   Message: string;
   nFixedUpdateUI: number;
};

type IAddFailModalProp = {
   Location?: { X: number | string; Y: number | string };
};

class AddFailModal extends React.Component<IAddFailModalProp, IAddFailModalState> {
   constructor(props: IAddFailModalProp) {
      super(props);
   }

   state: IAddFailModalState = {
      Visable: false,
      loading: false,
      Message: "",
      nFixedUpdateUI: 0,
   };

   componentDidMount() {
      kcSetUnmount(this, false);
   }
   componentWillUnmount() {
      kcSetUnmount(this, true);
   }
   // shouldComponentUpdate(nextProps: IAddFailModalProp, nextState: IAddFailModalState) {
   //   if (!ObjectExtensions.ObjectsEqual(nextState, this.state)) return true;
   //   if (!ObjectExtensions.ObjectsEqual(nextProps, this.props)) return true;
   //   return false;
   // }

   public Show = (_szMsg: string) => {
      kcSetState(this, {
         loading: false,
         Visable: true,
         Message: _szMsg,
      });
   };
   public Close = () => {
      kcSetState(this, {
         Visable: false,
      });
   };
   private FixedUpdateUI = () => {
      kcSetState(this, { nFixedUpdateUI: this.state.nFixedUpdateUI + 1 });
   };

   private OnBtnClick = () => {
      kcSetState(this, { loading: false, Visable: false });
   };

   private OnTapHandlerStateChange = (event: any) => {
      setTimeout(() => {
         kcSetState(this, { loading: false, Visable: false });
      }, 100);
   };

   render() {
      if (this.state.Visable == false) return <></>;
      return (
         // Web 才有作用
         <TapGestureHandler onEnded={this.OnTapHandlerStateChange}>
            <Modal animationType="fade" transparent={true} visible={true}>
               <View
                  style={{
                     flex: 1,
                     backgroundColor: "#000000BB",
                     justifyContent: "center", // 上下位置
                     alignItems: "center", // 左右位置
                  }}
               >
                  {this.state.loading && <ActivityIndicator size="large" />}
                  {!this.state.loading && (
                     <View
                        style={{
                           flexDirection: "column",
                           margin: 0,
                           alignItems: "center",
                           backgroundColor: "white",
                           borderRadius: 10,
                           width: 300,
                        }}
                     >
                        {/* <Text
                  selectable={false}
                  style={{
                    marginTop: 10,
                    fontSize: 20,
                    color: "#000000",
                  }}
                >
                  {this.state.Message}
                </Text> */}
                        <Text
                           selectable={false}
                           style={{
                              fontSize: 16,
                              color: "#000000",
                              textAlign: "left",
                              marginTop: 10,
                              marginLeft: 30,
                              marginRight: 30,
                           }}
                        >
                           {this.state.Message}
                        </Text>

                        <View
                           style={{
                              marginTop: 20,
                              marginBottom: 20,
                              flexDirection: "row",
                              backgroundColor: "#00000000",
                              //alignSelf: "stretch",
                              width: 150,
                           }}
                        >
                           <Button
                              containerStyle={{ flex: 1 }}
                              buttonStyle={{
                                 backgroundColor: "#4480EE",
                                 height: 30,
                                 marginLeft: 10,
                                 marginRight: 10,
                                 borderRadius: 10,
                              }}
                              titleStyle={{ fontSize: 14 }}
                              title="確定"
                              onPress={() => {
                                 kcSetState(this, { Visable: false });
                              }}
                           />
                        </View>
                     </View>
                  )}
               </View>
            </Modal>
         </TapGestureHandler>
      );
   }
}

const styles = StyleSheet.create({
   BackGroundColor: {
      backgroundColor: "#00000000",
   },

   button: {
      backgroundColor: "#00000000",
      borderColor: "red",
      borderWidth: 0,
      borderRadius: 15,
   },
});

export default QuoteScreen_Add;
