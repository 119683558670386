import { Moment } from "moment";
import { MomentExtensions } from "../kcExternal";
import { DayOfWeek } from "../kcExternal/MomentExtenstions";
import kcCommodityModel from "./kcCommodityModel";
import kcHistoryOHLCModel from "./kcHistoryOHLCModel";
import kcHistoryTickModel from "./kcHistoryTickModel";

import { DayKLineType, TradeTimeState } from "./kcModel_Enum";
import kcTickModel from "./kcTickModel";
import kcTradeTimeModel from "./kcTradeTimeModel";

export default class ModelJSExtensions {
  public static CreateNewKLine(
    _Time: Moment,
    _dOpenPrice: number
  ): kcHistoryOHLCModel {
    let mlRet = new kcHistoryOHLCModel({
      Time: _Time,
      OpenPrice: _dOpenPrice,
      HighPrice: _dOpenPrice,
      LowPrice: _dOpenPrice,
      Vol: 0,
    });
    return mlRet;
  }

  public static UpdateKLineByTick_ByHistoryTick(
    _KLine: kcHistoryOHLCModel,
    _Tick: kcHistoryTickModel
  ): kcHistoryOHLCModel {
    if (_KLine.HighPrice < _Tick.Price) _KLine.HighPrice = _Tick.Price;
    if (_KLine.LowPrice > _Tick.Price) _KLine.LowPrice = _Tick.Price;
    _KLine.ClosePrice = _Tick.Price;
    _KLine.Vol += _Tick.Vol;

    return _KLine;
  }
  public static UpdateKLineByTick_ByTick(
    _KLine: kcHistoryOHLCModel,
    _Tick: kcTickModel
  ): kcHistoryOHLCModel {
    let dPrice = _Tick.ClosePrice;
    if (_KLine.HighPrice < dPrice) _KLine.HighPrice = dPrice;
    if (_KLine.LowPrice > dPrice) _KLine.LowPrice = dPrice;
    _KLine.ClosePrice = dPrice;
    _KLine.Vol += _Tick.Vol;

    return _KLine;
  }
  public static UpdateKLineByOHLC_ByOHLC(
    _KLine: kcHistoryOHLCModel,
    _OHLC: kcHistoryOHLCModel
  ): kcHistoryOHLCModel {
    if (_KLine.HighPrice < _OHLC.HighPrice) _KLine.HighPrice = _OHLC.HighPrice;
    if (_KLine.LowPrice > _OHLC.LowPrice) _KLine.LowPrice = _OHLC.LowPrice;
    _KLine.ClosePrice = _OHLC.ClosePrice;
    _KLine.Vol += _OHLC.Vol;
    return _KLine;
  }

  public static ToMinuteTime(_t: Moment): Moment {
    return MomentExtensions.FloorToMinute(_t);
  }

  public static ToDayTime(
    _t: Moment,
    _aTradeTime?: kcTradeTimeModel[]
  ): Moment {
    let DateRet = MomentExtensions.FloorToDay(_t);
    let TickDiff = _t.valueOf() - DateRet.valueOf();
    let TotalMinutes = TickDiff / MomentExtensions.TicksPerMinute;

    if (!_aTradeTime) return DateRet;

    let sFirstOpen = this.GetFirstOpenTime(_aTradeTime);
    if (sFirstOpen >= 0) {
      if (TotalMinutes < sFirstOpen) DateRet = DateRet.add(-1, "d");
    } // 針對期交所雙盤機制的判斷 前一日下午盤
    else {
      sFirstOpen += 1440;
      if (TotalMinutes >= sFirstOpen) DateRet = DateRet.add(1, "d");
      while (
        DateRet.weekday() == DayOfWeek.Saturday ||
        DateRet.weekday() == DayOfWeek.Sunday
      )
        DateRet = DateRet.add(1, "d");
    }
    return DateRet;
  }
  public static ToDayTime_ByCommodity(
    _t: Moment,
    _mdCommodity: kcCommodityModel
  ): Moment {
    return this.ToDayTime(_t, _mdCommodity.TradeTime);
  }
  public static GetFloorDayFunc(_nBase: number): {
    fFloorDate: (_Value: Moment, _nBase?: number) => Moment;
    nFloorBase: number;
  } {
    let nFloorBase;
    let fncFloorDate: (_Value: Moment, _nBase?: number) => Moment;

    let _DayType = _nBase;
    switch (_DayType) {
      case DayKLineType.Day:
        nFloorBase = 1; // 寫死1日
        fncFloorDate = MomentExtensions.FloorToDay;
        break;
      case DayKLineType.Week:
        nFloorBase = DayOfWeek.Monday; // 寫死星期一
        fncFloorDate = MomentExtensions.FloorToDayOfWeak;
        break;
      case DayKLineType.Month:
        nFloorBase = 1;
        fncFloorDate = MomentExtensions.FloorToMonth;
        break;
      case DayKLineType.Season:
        nFloorBase = 3;
        fncFloorDate = MomentExtensions.FloorToMonth;
        break;
      case DayKLineType.HalfYear:
        nFloorBase = 6;
        fncFloorDate = MomentExtensions.FloorToMonth;
        break;
      case DayKLineType.Year:
        nFloorBase = 12;
        fncFloorDate = MomentExtensions.FloorToMonth;
        break;
      default:
        nFloorBase = _nBase;
        fncFloorDate = MomentExtensions.FloorToDay;
        break;
    }

    let Ret = { fFloorDate: fncFloorDate, nFloorBase: nFloorBase };

    return Ret;
  }
  public static DateToTradeTime(
    _Date: Moment,
    _nBase: number,
    _mdCommodity: kcCommodityModel
  ): { StartTime: Moment; EndTime: Moment } {
    let StartDate = MomentExtensions.FloorToDay(_Date);
    let EndDate = MomentExtensions.FloorToDay(_Date);
    let _DayType = _nBase;
    switch (_DayType) {
      case DayKLineType.Day:
        EndDate = EndDate.add(1, "days");
        break;
      case DayKLineType.Week:
        EndDate = EndDate.add(7, "days");
        break;
      case DayKLineType.Month:
        EndDate = EndDate.add(1, "months");
        break;
      case DayKLineType.Season:
        EndDate = EndDate.add(3, "months");
        break;
      case DayKLineType.HalfYear:
        EndDate = EndDate.add(6, "months");
        break;
      case DayKLineType.Year:
        EndDate = EndDate.add(1, "years");
        break;
      default:
        EndDate = EndDate.add(_nBase, "days");
        break;
    }
    EndDate = EndDate.add(-1, "days");

    let sOpenOff = 0,
      sCloseOff = 0;
    let GetTradeMinuteOff_AllDayRes =
      this.GetTradeMinuteOff_AllDay(_mdCommodity);
    let maTradeTimes = GetTradeMinuteOff_AllDayRes.TradeTimes;
    if (GetTradeMinuteOff_AllDayRes.Resault) {
      sOpenOff = maTradeTimes[0].MinuteOffset;
      sCloseOff = maTradeTimes[1].MinuteOffset;
    }

    let mdRet = {
      StartTime: StartDate.add(sOpenOff, "minutes"),
      EndTime: EndDate.add(sCloseOff, "minutes"),
    };
    return mdRet;
  }
  public static GetTradeMinuteOff_AllDay(_mdCommodity: kcCommodityModel): {
    TradeTimes: kcTradeTimeModel[];
    Resault: boolean;
  } {
    let mdOpen_First: kcTradeTimeModel | undefined = undefined;
    let mdClose_Last: kcTradeTimeModel | undefined = undefined;
    for (let mdA of _mdCommodity.TradeTime) {
      if (mdA.State == TradeTimeState.Open) {
        if (!mdOpen_First !== undefined) mdOpen_First = mdA;
      } else if (mdA.State == TradeTimeState.Close) mdClose_Last = mdA;
    }

    let _aTradeTime: kcTradeTimeModel[] = [];
    let bRet = false;
    if (mdOpen_First !== undefined && mdClose_Last !== undefined) {
      _aTradeTime.push(mdOpen_First);
      _aTradeTime.push(mdClose_Last);
      bRet = true;
    }

    let mdRet = { TradeTimes: _aTradeTime, Resault: bRet };
    return mdRet;
  }
  public static GetFirstOpenTime(_aTradeTimes: kcTradeTimeModel[]): number {
    let sRet = 0;
    if (_aTradeTimes && _aTradeTimes.length > 0) {
      for (let mdA of _aTradeTimes) {
        if (mdA.State == TradeTimeState.Open) {
          sRet = mdA.MinuteOffset;
          break;
        }
      }
    }
    return sRet;
  }
}
