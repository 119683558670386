import { ArrayExtensions } from "../kcExternal";
import Endpoint, {
  delActivated,
  delDeActivated,
  delOnMessage,
} from "./Endpoint";
import Message from "./Message";

export default class SocketBase {
  constructor() {}

  /* ------------------------------------------ */
  // Member
  protected endpoints: Endpoint[] = [];
  protected sendReady: any = undefined;

  /* ------------------------------------------ */
  // 未實作function
  protected xattachEndpoint(_Endpoint: Endpoint): void {}
  protected xendpointTerminated(
    _Endpoint: Endpoint,
    _bFirstDeActivated: boolean
  ): void {}
  protected xonMessage(_Endpoint: Endpoint, _Message: Message): void {}
  protected xhasOut(): boolean {
    return false;
  }
  protected xsend(message: Message): boolean {
    return false;
  }

  /* ------------------------------------------ */
  // On EndPoint Callback
  private onEndpointActivated: delActivated = (endpoint) => {
    this.xattachEndpoint(endpoint);
  };
  private onEndpointDeactivated: delDeActivated = (
    endpoint,
    _bFirstDeActivated
  ) => {
    this.xendpointTerminated(endpoint, _bFirstDeActivated);
  };
  private onMessage_Func: delOnMessage = (endpoint, msg) => {
    this.xonMessage(endpoint, msg);
  };

  /* ------------------------------------------ */
  // 公開function
  public connect = (address: string) => {
    let endpoint = this.endpoints.find((q) => q.address == address);
    if (!endpoint) {
      endpoint = new Endpoint(
        address,
        this.onEndpointActivated,
        this.onEndpointDeactivated,
        this.onMessage_Func
      );
      this.endpoints.push(endpoint);
    }
  };

  public disconnect = (address: string | undefined) => {
    if (this.endpoints) {
      if (address) {
        while (true) {
          let nIdx = this.endpoints.findIndex((q) => q.address == address); // 找到指定address的endpoint
          if (nIdx < 0) break;

          this.endpoints[nIdx].close();
          ArrayExtensions.RemoveAt(this.endpoints, nIdx, 1);
        }
      } else {
        this.endpoints.forEach((EndPoint) => EndPoint.close());
        this.endpoints.length = 0;
      }
    }
  };

  public isConnected = () => {
    for (let i = 0; i < this.endpoints.length; i++)
      if (!this.endpoints[i].getIsActive()) return false;

    return true;
  };

  public isConnecting = () => {
    for (let i = 0; i < this.endpoints.length; i++)
      if (!this.endpoints[i].getIsConnecting()) return false;

    return true;
  };

  public send(message: Message): boolean {
    return this.xsend(message);
  }

  public getHasOut() {
    return this.xhasOut();
  }
}
