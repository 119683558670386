import SocketBase from "./SocketBase";
import Endpoint from "./Endpoint";
import Message from "./Message";
import LoadBalancer from "./LoadBalancer";

export enum ConnectionState {
  Connected,
  DisConnected, // 第一次斷線
  ConnectionFail, // 連不上線
}
export type delOnDealerMessage = (Messages: Uint8Array[]) => void;
export type delOnConnection = (
  _State: ConnectionState,
  _Address: string
) => void;

export default class Dealer extends SocketBase {
  constructor(
    _fonMessage?: delOnDealerMessage,
    _fOnConnection?: delOnConnection
  ) {
    super();
    this.onMessage = _fonMessage;
    this.OnConnection = _fOnConnection;

    this.lb.writeActivated = () => {
      while (this.SnedMessageBuffer.length) {
        let Msg = this.SnedMessageBuffer.shift();
        if (Msg) this.send(Msg);
      }
      if (this.sendReady != null) this.sendReady(this);
    };
  }

  private lb: LoadBalancer = new LoadBalancer();
  private SnedMessageBuffer: Message[] = [];
  private onMessage?: delOnDealerMessage;
  private OnConnection?: delOnConnection;

  /* ------------------------------------------- */
  // Override function
  protected xattachEndpoint(_Endpoint: Endpoint) {
    this.lb.attach(_Endpoint);
    // 發送Connection Event
    this.OnConnection?.call(this, ConnectionState.Connected, _Endpoint.address);
  }
  protected xendpointTerminated(
    _Endpoint: Endpoint,
    _bFirstDeActivated: boolean
  ) {
    this.lb.terminated(_Endpoint);

    // 發送Connection Event
    let ctype = _bFirstDeActivated
      ? ConnectionState.DisConnected
      : ConnectionState.ConnectionFail;
    this.OnConnection?.call(this, ctype, _Endpoint.address);
  }
  protected xhasOut() {
    return this.lb.getHasOut();
  }
  protected xsend(message: Message): boolean {
    let Sended = this.lb.send(message);
    if (!Sended) this.SnedMessageBuffer.push(message);
    return Sended;
  }
  protected xonMessage(_Endpoint: Endpoint, _Message: Message) {
    if (this.onMessage != null) {
      let Msgs: Uint8Array[] = [];
      while (_Message.getSize() > 0)
        Msgs.push(new Uint8Array(_Message.popBuffer()));
      this.onMessage(Msgs);
    }
  }
}
