import { kcTECPCollection } from "..";
import { MathEx } from "../../kcExternal";
import { kcHistoryOHLCModel } from "../../kcModel";
import { SvgLinePath, TopInfo } from "../kcTECPCollection";
import BBandSetting from "../TECPSetting/BBandSetting";
import { XUnit } from "../XInfo";
import { YUnit } from "../YInfo";

type BBandValues = {
  XUnitValues: { Value: kcBBandModel; XUnit: XUnit }[];
  Max?: number;
  Min?: number;
};

export default class kcBBandData {
  constructor(_Owner: kcTECPCollection, _MaSetting: BBandSetting) {
    this.m_Owner = _Owner;
    this.m_DataSrc = _Owner.mlOHLC;
    this.m_Setting = _MaSetting;
    this.m_Datas.length = this.m_DataSrc.length;
    this.m_fGetValue = (_md) => _md.ClosePrice;
  }

  // Data
  private m_Owner: kcTECPCollection;
  private m_DataSrc: kcHistoryOHLCModel[];
  private m_fGetValue: (_md: kcHistoryOHLCModel) => number;
  private m_Datas: kcBBandModel[] = [];
  private m_Setting: BBandSetting;

  public get Setting() {
    return this.m_Setting;
  }
  public get Datas() {
    return this.m_Datas;
  }

  // Core Function
  public Value(Index: number) {
    if (Index < 0 || Index >= this.m_Datas.length) return undefined;

    if (!this.m_Datas[Index]) this.m_Datas[Index] = new kcBBandModel();

    if (!this.m_Datas[Index].HasValue) {
      // setTimeout(() => {
      let mdBBand = this.CountValueCore(Index);
      if (mdBBand != undefined) this.m_Datas[Index].SetValue(mdBBand);
      // }, 500);
    }
    return this.m_Datas[Index].HasValue ? this.m_Datas[Index] : undefined;
  }
  public Init() {
    this.m_Datas.length = 0;
    this.m_Datas.length = this.m_DataSrc.length;
  }
  public Update(_StartIdx: number, _EndIdx: number) {
    if (this.m_Datas.length != this.m_DataSrc.length)
      this.m_Datas.length = this.m_DataSrc.length;

    for (let i = _StartIdx; i <= _EndIdx; i++) {
      if (this.m_Datas[i]) this.m_Datas[i].SetUpdate();
    }
  }
  private CountValueCore(_Index: number): kcBBandModel | undefined {
    let mdBBand = new kcBBandModel();
    let dSDBase = this.m_Setting.SDBase;

    if (_Index >= this.m_Setting.MaBase - 1) {
      let aClosePrice: number[] = [];
      // 先算Ma
      let Total: number = 0;
      let DataNumber: number = 0;

      for (let i = 0; i < this.m_Setting.MaBase; i++) {
        let DataIdx = _Index - i;
        if (DataIdx < 0 || DataIdx >= this.m_DataSrc.length) continue;

        let dItem = this.m_fGetValue(this.m_DataSrc[DataIdx]);
        Total += dItem;
        aClosePrice.push(dItem);
        DataNumber++;
      }
      let dMa = Total / DataNumber;

      // 標準差
      let dSD: number = 0;
      for (let i = 0; i < aClosePrice.length; i++) {
        let dDif = aClosePrice[i] - dMa;
        dSD += dDif * dDif;
      }
      dSD = dSD / DataNumber;
      dSD = Math.sqrt(dSD);

      // 設值
      mdBBand.SetValue({
        Ma: dMa,
        MaPressure: dMa + dSD * dSDBase,
        MaStrut: dMa - dSD * dSDBase,
      });
    }

    return mdBBand;
  }

  // Update Setting Function

  public UpdateSetting(_MaSetting: BBandSetting) {
    let UpdateResault = this.m_Setting.SetSetting(_MaSetting);

    if (UpdateResault.DataChanged) {
      this.Init();
    }
  }

  // 輔助 Function
  public map<U>(
    callbackfn: (value: kcBBandModel, index: number, array: kcBBandModel[]) => U
  ): U[] {
    let aRet: U[] = [];
    for (let i = 0; i < this.m_Datas.length; i++) {
      let Item = this.Value(i);
      aRet.push(callbackfn(this.m_Datas[i], i, this.m_Datas));
    }
    return aRet;
  }

  // Draw Function
  public GetValues(_XUnits: XUnit[]): BBandValues | undefined {
    if (!this.m_Setting.MaEnable && !this.m_Setting.SDEnable) return undefined;

    let aValues: BBandValues["XUnitValues"] = [];
    let dMax: number | undefined = undefined;
    let dMin: number | undefined = undefined;

    for (let i = 0; i < _XUnits.length; i++) {
      let dValue = this.Value(_XUnits[i].nDataIndex);
      if (dValue && dValue.HasValue) {
        if (this.m_Setting.MaEnable) {
          dMax = MathEx.Max(dMax, dValue.Ma);
          dMin = MathEx.Min(dMin, dValue.Ma);
        }
        if (this.m_Setting.SDEnable) {
          if (dValue.MaPressure) {
            dMax = MathEx.Max(dMax, dValue.MaPressure);
            dMin = MathEx.Min(dMin, dValue.MaPressure);
          }
          if (dValue.MaStrut) {
            dMax = MathEx.Max(dMax, dValue.MaStrut);
            dMin = MathEx.Min(dMin, dValue.MaStrut);
          }
        }

        aValues.push({ Value: dValue, XUnit: _XUnits[i] });
      }
    }

    if (aValues.length == 0) return undefined;

    return {
      XUnitValues: aValues,
      Max: dMax,
      Min: dMin,
    };
  }
  public GetSVGPath(
    _BBandValues: BBandValues | undefined,
    _YUnit: YUnit
  ): SvgLinePath[] | undefined {
    if (
      !_BBandValues ||
      !_BBandValues.XUnitValues ||
      _BBandValues.XUnitValues.length === 0
    )
      return undefined;

    if (!this.m_Setting.MaEnable && !this.m_Setting.SDEnable) return undefined;

    let szPath_Ma = "";
    let szPath_MaPressure = "";
    let szPath_MaStrut = "";

    for (let i = 0; i < _BBandValues.XUnitValues.length; i++) {
      let mdBBand = _BBandValues.XUnitValues[i].Value;
      if (!mdBBand) continue;

      let XCoord = _BBandValues.XUnitValues[i].XUnit.fXMidPos;
      if (this.m_Setting.MaEnable && mdBBand.Ma) {
        let YCoord = _YUnit.ValueToCoord(mdBBand.Ma);
        szPath_Ma += `${i == 0 ? "M" : "L"} ${XCoord} ${YCoord}`;
      }
      if (this.m_Setting.SDEnable) {
        if (mdBBand.MaPressure) {
          let YCoord = _YUnit.ValueToCoord(mdBBand.MaPressure);
          szPath_MaPressure += `${i == 0 ? "M" : "L"} ${XCoord} ${YCoord}`;
        }
        if (mdBBand.MaStrut) {
          let YCoord = _YUnit.ValueToCoord(mdBBand.MaStrut);
          szPath_MaStrut += `${i == 0 ? "M" : "L"} ${XCoord} ${YCoord}`;
        }
      }
    }

    let Path_Ma: SvgLinePath = {
      PathColor: this.m_Setting.MaLineColor,
      PathWidth: this.m_Setting.MaLineWidth,
      SvgPath: szPath_Ma,
    };
    let Path_MaPressure: SvgLinePath = {
      PathColor: this.m_Setting.SDLineColor,
      PathWidth: this.m_Setting.SDLineWidth,
      SvgPath: szPath_MaPressure,
    };
    let Path_MaStrut: SvgLinePath = {
      PathColor: this.m_Setting.SDLineColor,
      PathWidth: this.m_Setting.SDLineWidth,
      SvgPath: szPath_MaStrut,
    };

    return [Path_Ma, Path_MaPressure, Path_MaStrut];
  }
  public GetTopInfo() {
    if (!this.m_Setting.MaEnable && !this.m_Setting.SDEnable) return undefined;

    let mdBBand = this.Value(this.m_Datas.length - 1);
    let mdPreBBand = this.Value(this.m_Datas.length - 2);
    if (!mdBBand) return undefined;

    let lTopInfos: TopInfo[] = [];
    let FloatNum = this.m_Owner.kcUnit?.Commodity?.FloatNum ?? 5;

    if (this.m_Setting.MaEnable) {
      let szMa = mdBBand.Ma ? MathEx.kcRound(mdBBand.Ma, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdBBand.Ma && mdPreBBand && mdPreBBand.Ma) {
        if (mdBBand.Ma < mdPreBBand.Ma)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdBBand.Ma > mdPreBBand.Ma)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `布林${this.m_Setting.MaBase}`,
        TextColor: this.m_Setting.MaLineColor,
        Value: `${szMa}`,
        // Color: szValueColor,
      });
    }
    if (this.m_Setting.SDEnable) {
      let szMaPressure = mdBBand.MaPressure
        ? MathEx.kcRound(mdBBand.MaPressure, FloatNum)
        : " ";
      let szMaStrut = mdBBand.MaStrut
        ? MathEx.kcRound(mdBBand.MaStrut, FloatNum)
        : " ";
      let szPressureColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色
      let szStrutColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdBBand.MaPressure && mdPreBBand && mdPreBBand.MaPressure) {
        if (mdBBand.MaPressure < mdPreBBand.MaPressure)
          szPressureColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdBBand.MaPressure > mdPreBBand.MaPressure)
          szPressureColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }
      if (mdBBand.MaStrut && mdPreBBand && mdPreBBand.MaStrut) {
        if (mdBBand.MaStrut < mdPreBBand.MaStrut)
          szStrutColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdBBand.MaStrut > mdPreBBand.MaStrut)
          szStrutColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }
      lTopInfos.push(
        {
          Text: `布林上限`,
          TextColor: this.m_Setting.SDLineColor,
          Value: `${szMaPressure}`,
          //   Color: szPressureColor,
        },
        {
          Text: `布林下限`,
          TextColor: this.m_Setting.SDLineColor,
          Value: `${szMaStrut}`,
          //   Color: szStrutColor,
        }
      );
    }

    return lTopInfos;
  }
  public AddTopInfo(_Collection: TopInfo[]) {
    let mdTop = this.GetTopInfo();
    if (mdTop && _Collection) _Collection.push(...mdTop);
    return mdTop;
  }
  public GetLeftInfo(_nDataIndex: number) {
    if (_nDataIndex < 0 || _nDataIndex >= this.m_Datas.length) return undefined;
    if (!this.m_Setting.MaEnable && !this.m_Setting.SDEnable) return undefined;

    let mdBBand = this.Value(_nDataIndex);
    let mdPreBBand = this.Value(_nDataIndex - 1);
    if (!mdBBand) return undefined;

    let lTopInfos: TopInfo[] = [];
    let FloatNum = this.m_Owner.kcUnit?.Commodity?.FloatNum ?? 5;

    if (this.m_Setting.MaEnable) {
      let szMa = mdBBand.Ma ? MathEx.kcRound(mdBBand.Ma, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdBBand.Ma && mdPreBBand && mdPreBBand.Ma) {
        if (mdBBand.Ma < mdPreBBand.Ma)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdBBand.Ma > mdPreBBand.Ma)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `布林${this.m_Setting.MaBase}`,
        TextColor: this.m_Setting.MaLineColor,
        Value: `${szMa}`,
        Color: szValueColor,
      });
    }
    if (this.m_Setting.SDEnable) {
      let szMaPressure = mdBBand.MaPressure
        ? MathEx.kcRound(mdBBand.MaPressure, FloatNum)
        : " ";
      let szMaStrut = mdBBand.MaStrut
        ? MathEx.kcRound(mdBBand.MaStrut, FloatNum)
        : " ";
      let szPressureColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色
      let szStrutColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdBBand.MaPressure && mdPreBBand && mdPreBBand.MaPressure) {
        if (mdBBand.MaPressure < mdPreBBand.MaPressure)
          szPressureColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdBBand.MaPressure > mdPreBBand.MaPressure)
          szPressureColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }
      if (mdBBand.MaStrut && mdPreBBand && mdPreBBand.MaStrut) {
        if (mdBBand.MaStrut < mdPreBBand.MaStrut)
          szStrutColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdBBand.MaStrut > mdPreBBand.MaStrut)
          szStrutColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }
      lTopInfos.push(
        {
          Text: `布林上限`,
          TextColor: this.m_Setting.SDLineColor,
          Value: `${szMaPressure}`,
          Color: szPressureColor,
        },
        {
          Text: `布林下限`,
          TextColor: this.m_Setting.SDLineColor,
          Value: `${szMaStrut}`,
          Color: szStrutColor,
        }
      );
    }

    return lTopInfos;
  }
  public AddLeftInfo(_nDataIndex: number, _Collection: TopInfo[]) {
    let mdLeft = this.GetLeftInfo(_nDataIndex);
    if (mdLeft && _Collection) _Collection.push(...mdLeft);
    return mdLeft;
  }
}

class kcBBandModel {
  constructor() {}

  private m_HasValue: boolean = false;

  private m_Ma?: number;
  private m_MaPressure?: number;
  private m_MaStrut?: number;

  public get HasValue() {
    return this.m_HasValue;
  }
  public get Ma() {
    return this.m_Ma;
  }
  public get MaPressure() {
    return this.m_MaPressure;
  }
  public get MaStrut() {
    return this.m_MaStrut;
  }

  public SetValue(
    _mdValue:
      | kcBBandModel
      | { Ma?: number; MaPressure?: number; MaStrut?: number }
  ) {
    if (_mdValue) {
      this.m_Ma = _mdValue.Ma;
      this.m_MaPressure = _mdValue.MaPressure;
      this.m_MaStrut = _mdValue.MaStrut;

      this.m_HasValue = true;
    }
  }

  public SetUpdate() {
    this.m_HasValue = false;
  }
}
