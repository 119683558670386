import {
  NavigationContainerRef,
  PartialRoute,
  Route,
  StackActions,
} from "@react-navigation/native";
import * as React from "react";

export const navigationRef: React.RefObject<NavigationContainerRef> =
  React.createRef();

export function navigate(name: any, params: any) {
  _WaitNavigate(name, params);
}
export function CurrentRoute() {
  return navigationRef.current?.getCurrentRoute();
}
export function RootState() {
  return navigationRef.current?.getRootState();
}
function GetCurrentRootScreen() {
  let CurrentRootState = RootState();
  if (CurrentRootState)
    return CurrentRootState.routes[CurrentRootState.index].name;
  else return undefined;
}

export function GoBack() {
  if (navigationRef.current && navigationRef.current.canGoBack())
    navigationRef.current.goBack();
  else return false;
}
export function GoRootScreen(params?: any) {
  _WaitNavigate("Root", params);
}
export function GoLoginScreen(params?: any) {
  _WaitNavigate("Login", params);
}
export function GotoTradeScreen(Params?: any, PopCurrent: boolean = true) {
  if (PopCurrent && navigationRef.current) {
    let CurrentRoute = navigationRef.current.getCurrentRoute();
    if (CurrentRoute) {
      let CurrentScreen = CurrentRoute.name;
      if (CurrentScreen.indexOf("TabTrade") != 0) {
        if (CurrentScreen !== "TabTECPScreen")
          navigationRef.current.dispatch(StackActions.popToTop());

        setTimeout(() => {
          GotoTradeScreen(Params, false);
        }, 10);
        return;
      }
    }
  }

  _WaitNavigate("Root", {
    screen: "TabTrade",
    params: { screen: "TabTradeScreen", params: Params },
  });
}

var _WaitNavigate = async (_name: any, params: any) => {
  if (navigationRef.current) {
    let CurrentRootScreen = GetCurrentRootScreen();
    if (CurrentRootScreen === _name) {
      // Root層同個Screen, 用navigate
      navigationRef.current.navigate(_name, params);
    } else {
      try {
        // Root層不同個Screen, 用reset
        navigationRef.current.reset({
          routes: [{ name: _name, params: params }],
          stale: true,
        });
      } catch {
        setTimeout(_WaitNavigate, 1000, _name, params);
      }
    }
    // let RootState = navigationRef.current.getRootState();
    // let RootKey = RootState.key;
    // let ScreenKey: string | undefined = undefined;
    // let State: PartialRoute<Route<string, object | undefined>>["state"] =
    //   undefined;
    // let nIdx = RootState.routes.findIndex((q) => q.name == _name);
    // if (nIdx >= 0) {
    //   ScreenKey = RootState.routes[nIdx].key;
    //   State = RootState.routes[nIdx].state as PartialRoute<
    //     Route<string, object | undefined>
    //   >["state"];
    // }

    // navigationRef.current.resetRoot({
    //   key: RootKey,
    //   index: 0,
    //   routeNames: RootState.routeNames,
    //   routes: [
    //     {
    //       name: _name,
    //       params: params,
    //       key: ScreenKey,
    //       state: State,
    //     },
    //   ],
    //   stale: true,
    //   type: "stack",
    // });
  } else setTimeout(_WaitNavigate, 1000, _name, params);
};
