import { eMaType } from "./TECPSettingDefine";

type MaDataSettingType = {
  MaType?: eMaType;
  MaDayBase?: number;
};
type MaDrawSettingType = {
  UpdateMaxMinLimit?: boolean;
  Enable?: boolean;
  LineColor?: string;
  LineWidth?: number;
};
export default class MaSetting {
  constructor(props: object) {
    Object.assign(this, { ...props });
  }

  MaType: eMaType = eMaType.SMa;
  MaDayBase: number = 1;
  UpdateMaxMinLimit: boolean = true;
  Enable: boolean = true;
  LineColor: string = "red";
  LineWidth: number = 1;

  public Clone(): MaSetting {
    return new MaSetting(this);
  }
  public SetGlobalSetting(_Setting: MaSetting) {
    Object.assign(this, _Setting);
  }

  public SetSetting(
    _Setting: (MaDataSettingType & MaDrawSettingType) | MaSetting
  ): {
    DataChanged: boolean;
    DrawChanged: boolean;
  } {
    let Reasult = { DataChanged: false, DrawChanged: false };

    if (_Setting) {
      if (_Setting.MaType !== undefined && _Setting.MaType !== this.MaType) {
        this.MaType = _Setting.MaType;
        Reasult.DataChanged = true;
      }
      if (
        _Setting.MaDayBase !== undefined &&
        _Setting.MaDayBase !== this.MaDayBase
      ) {
        this.MaDayBase = _Setting.MaDayBase;
        Reasult.DataChanged = true;
      }
      if (
        _Setting.UpdateMaxMinLimit !== undefined &&
        _Setting.UpdateMaxMinLimit !== this.UpdateMaxMinLimit
      ) {
        this.UpdateMaxMinLimit = _Setting.UpdateMaxMinLimit;
        Reasult.DrawChanged = true;
      }
      if (_Setting.Enable !== undefined && _Setting.Enable !== this.Enable) {
        this.Enable = _Setting.Enable;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.LineColor !== undefined &&
        _Setting.LineColor !== this.LineColor
      ) {
        this.LineColor = _Setting.LineColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.LineWidth !== undefined &&
        _Setting.LineWidth !== this.LineWidth
      ) {
        this.LineWidth = _Setting.LineWidth;
        Reasult.DrawChanged = true;
      }
    }

    return Reasult;
  }
  public ToDefault(_MaIdx: number) {
    switch (_MaIdx) {
      case 0:
        this.MaDayBase = 5;
        this.LineColor = "#FF8080FF";
        this.LineWidth = 1;
        break;
      case 1:
        this.MaDayBase = 10;
        this.LineColor = "#FF8000FF";
        this.LineWidth = 1;
        break;
      case 2:
        this.MaDayBase = 20;
        this.LineColor = "#FFFF64FF";
        this.LineWidth = 1;
        break;
      case 3:
        this.MaDayBase = 60;
        this.LineColor = "#5287E9FF";
        this.LineWidth = 1;
        break;
    }
  }
}
