export function AccountStringToMoney(_sz: string) {
  // 1234 (100 USD)
  let szRet = _sz.trim();
  let Start = szRet.indexOf("(");
  let End = szRet.indexOf("USD)");
  if (Start != -1 || End != -1) szRet = _sz.substring(Start + 1, End);
  let Money = parseFloat(szRet);
  return Money;
}

export function WaitSleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function StringSort(a: any, b: any) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

class kcUnmount {
  SetState(THIS: any, Param: any, fCallBack?: () => void) {
    if (THIS.__kcUnmount) return;
    THIS.setState(Param, fCallBack);
  }

  SetUnmount(THIS: any, b: boolean) {
    THIS.__kcUnmount = b;
  }

  IsUnmount(THIS: any) {
    return !!THIS.__kcUnmount;
  }
}
export const kcSetState = kcUnmount.prototype.SetState;
export const kcSetUnmount = kcUnmount.prototype.SetUnmount;
export const kcIsUnmount = kcUnmount.prototype.IsUnmount;
