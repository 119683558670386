import * as React from "react";
import {
  Dimensions,
  ScrollView,
  Pressable,
  Animated,
  ViewStyle,
  StyleProp,
} from "react-native";
import { View } from "./Themed";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { Ionicons } from "@expo/vector-icons";
import { kcColor } from "../constants/Colors";
import {
  HandlerStateChangeEvent,
  PanGestureHandler,
} from "react-native-gesture-handler";

const ShowScrollBarAlways = true;
const ScrollView_PaddingTop = 5;
const ScrollView_PaddingBottom = 5;
const MovingScrollMaxDiff = 5; // 拖動時AutoScroll, 一次可Scroll最大距離, 數字越大跳動越快
const DragIConPaddingRight = 20; // 拖動用的ICon的PaddingRight, 20是ScrollBar寬度
const StartDragDelay = 100; // delayLongPress
const DraggingAnimationDuration = 250; // 拖動動畫耗時(含被動交換位置)
const FocusAnimationDuration = 50; // Focus修改動畫耗時(陰影動畫)
const FocusItemShadowWidth = 20; // Focus項目的陰影大小
const ItemZIndex_Default = 10; // 預設Item的zIndex
const ItemZIndex_Focus = 99; // Focus時Item的zIndex

type IState<T> = {
  DataItems: DraggableListItem<T>[];
  FocusItem?: DraggableListItem<T>;
  ScrollViewWidth: number; // ScrollView寬
  DragChanged: boolean; // 有拖動變動過, 用來判斷要不要送onDataChanged出去
};
type IProp<T> = {
  Source: T[];
  ItemHeight: number;
  ItemWrapStyle?: StyleProp<ViewStyle>; // 可以設框線用
  renderItem: (Item: T, index: number) => React.ReactElement;
  onDataChanged: (Data: T[]) => void;
  onStartDrop: (Item: T) => void;
  onEndDrop: (Item: T) => void;
};

export default class KC_DraggableList<T> extends React.Component<
  IProp<T>,
  IState<T>
> {
  /*
    Move End 流程: 
    1. 依改動後的座標排序 -> setState(DataItems改過順序的) -> 每一項移動到該有的位置 -> 關閉Focus
    2. 關閉Focus的Event上來後, 用新state的DataItems重設Index與座標 -> 再一次setState -> 送DataChange的Event出去
    */
  constructor(props: any) {
    super(props);

    this.state.DataItems = this.CreateItems(this.props.Source);
    this.state.ScrollViewWidth = Dimensions.get("window").width;
  }

  state: IState<T> = {
    DataItems: [],
    ScrollViewWidth: 0,
    FocusItem: undefined,
    DragChanged: false,
  };

  m_bFocusHasMove: boolean = false;
  m_ScrollOuterHeight: number = 0; // ScrollView外層高度(可視範圍)
  m_nScrolledTransY: number = 0; // 紀錄拖動時總共Scroll過的值
  m_ScrollValue: number = 0;
  m_ScrollRef = React.createRef<ScrollView>();

  shouldComponentUpdate(_NextProp: IProp<T>, _NextState: IState<T>) {
    if (_NextProp.Source != this.props.Source) {
      const Datas = this.CreateItems(_NextProp.Source);
      _NextState.DataItems = Datas;

      return true;
    }
    return true;
  }
  componentWillUnmount() {
    kcSetUnmount(this, true);
  }

  /* --------------------------------------------------- */
  // 輔助Function
  private CreateItems = (_Source: T[]): DraggableListItem<T>[] => {
    let Items = _Source.map((Value, Index) => {
      let nLocY = this.IndexToPosY(Index);
      let mdItem = new DraggableListItem<T>({
        ParentList: this,
        Item: Value,
        IndexSrc: Index,
        LocationY: nLocY,
        onItemFocused: this.onItemFocused,
      });
      return mdItem;
    });
    return Items;
  };
  private IndexToPosY = (_Index: number) => {
    return this.props.ItemHeight * _Index;
  };
  private PosYToIndex = (_PosY: number) => {
    let nIndex = Math.floor(_PosY / this.props.ItemHeight);
    nIndex = Math.min(this.state.DataItems.length - 1, Math.max(0, nIndex));
    return nIndex;
  };
  public MaxPosY = () => {
    return (
      this.IndexToPosY(this.state.DataItems.length - 1) +
      this.props.ItemHeight / 2
    ); // 下面可以超出半個Row
    //return this.IndexToPosY(this.state.DataItems.length); // 下面可以超出一個Row
  };

  public MinPosY = () => {
    return this.IndexToPosY(0) - this.props.ItemHeight / 2; // 上面可以超出半個Row
    //return this.IndexToPosY(-1); // 上面可以超出一個Row
  };
  public MaxScrollablePosY = () => {
    return (
      this.IndexToPosY(this.state.DataItems.length) +
      ScrollView_PaddingTop +
      ScrollView_PaddingBottom +
      1 -
      this.m_ScrollOuterHeight
    );
  };

  /* --------------------------------------------------- */
  // On Function
  private onItemFocused = (
    _mdFocusedItem: DraggableListItem<T>,
    _bFocused: boolean
  ) => {
    let mdFocusedItem = _bFocused ? _mdFocusedItem : undefined;
    this.m_nScrolledTransY = 0; // SetState前改有問題的話, SetState後再改

    // 先修改state的FocusItem
    kcSetState(this, { FocusItem: mdFocusedItem }, () => {
      if (_bFocused) {
        //this.m_nScrolledTransY = 0; // SetState前改有問題的話, 在這邊改
        this.props.onStartDrop?.call(this, _mdFocusedItem.Item);
      } else {
        this.props.onEndDrop?.call(this, _mdFocusedItem.Item);

        if (this.state.DragChanged) {
          let ChangedDataSrc = this.state.DataItems.map((Item, Index) => {
            let nLocY = this.IndexToPosY(Index);
            Item.ReSet(Index, nLocY);
            return Item.Item;
          });
          // 先修改state的DragChanged, 然後才送Event出去
          kcSetState(this, { DragChanged: false }, () => {
            this.props.onDataChanged?.call(this, ChangedDataSrc);
          });
        }
      }
    });
  };

  private onMoveStart = (
    e: HandlerStateChangeEvent<Record<string, unknown>>
  ) => {
    if (!this.state.FocusItem) return;
    this.m_bFocusHasMove = true;
  };
  private onMoving = (_translationY: number) => {
    if (!this.state.FocusItem) return;

    let mdFocused = this.state.FocusItem;
    mdFocused.SetTranslateY_Animated(
      _translationY + this.m_nScrolledTransY,
      true
    ); // 這行會改動LocationYValue值, 調整Call的順序需要注意

    // 判斷是否超出View範圍, 並且Scroll
    this.DoScrollOnMoving();

    // ChangeingMove
    let TempIndex = this.PosYToIndex(
      mdFocused.LocationYValue + this.props.ItemHeight / 2
    );
    if (mdFocused.MovingTempIndex !== TempIndex) {
      for (let mdItem of this.state.DataItems) {
        if (mdItem == mdFocused) continue;
        if (mdItem.IndexSrc < mdFocused.IndexSrc) {
          let nIdx = mdItem.IndexSrc;
          if (mdItem.IndexSrc >= TempIndex) nIdx++;
          let SwapPos = this.IndexToPosY(nIdx);
          mdItem.MoveY_Animated(SwapPos);
        } else if (mdItem.IndexSrc > mdFocused.IndexSrc) {
          let nIdx = mdItem.IndexSrc;
          if (mdItem.IndexSrc <= TempIndex) nIdx--;
          let SwapPos = this.IndexToPosY(nIdx);
          mdItem.MoveY_Animated(SwapPos);
        }
      }
      mdFocused.MovingTempIndex = TempIndex;
    }
    this.m_bFocusHasMove = true;
  };
  private onMoveEnd = (
    e?: HandlerStateChangeEvent<Record<string, unknown>>
  ) => {
    if (!this.state.FocusItem) {
      this.onMoveCancel();
      return;
    }

    let mdFocused = this.state.FocusItem;

    if (e) {
      // PanGestureHandler進來的, 會比Pressabl先
      if (this.m_bFocusHasMove) {
        this.ChangeDataSource();
      }
      // 基本上不會有else
    } else {
      // Pressable進來的
      if (!this.m_bFocusHasMove) {
        // 代表點下去沒移動過, 直接關掉Focus
        mdFocused.SetFocus(false);
      }
      this.m_bFocusHasMove = false;
    }
  };
  private onMoveCancel = () => {
    // if (!this.state.FocusItem) return;
    // let mdFocused = this.state.FocusItem;

    this.state.FocusItem?.RestoreLocationY();

    // if (this.m_bFocusHasMove) {
    this.state.DataItems.forEach((_Item) => {
      _Item.RestoreLocationY();
    });
    // }
  };
  private onScroll = (_ScrollValue: number) => {
    let PreScrollValue = this.m_ScrollValue;
    this.m_ScrollValue = _ScrollValue;

    if (this.state.FocusItem) {
      // 代表是Moving改Scroll進來的
      let ScrollDiff = _ScrollValue - PreScrollValue;

      this.m_nScrolledTransY += ScrollDiff;
      let newTranslateY =
        this.state.FocusItem.TranslationY - this.m_nScrolledTransY + ScrollDiff;
      this.onMoving(newTranslateY);
    }
  };

  /* --------------------------------------------------- */
  // 移動相關輔助 Function
  private ChangeDataSource = () => {
    let NewData = [...this.state.DataItems];
    NewData.sort((a, b) => a.LocationYValue - b.LocationYValue);

    kcSetState(this, { DataItems: NewData, DragChanged: true }, () => {
      NewData.forEach((Item, Index) => {
        Item.MoveY_Animated(this.IndexToPosY(Index), () => {
          Item.SetFocus(false);
        });
      });
    });
  };
  private DoScrollOnMoving = () => {
    if (!this.state.FocusItem) return;
    let mdFocused = this.state.FocusItem;

    let ItemTop = mdFocused.LocationYValue; // Item的上座標
    let ItemBottom = mdFocused.LocationYValue + this.props.ItemHeight; // Item的下座標
    let ScrollViewTop = this.m_ScrollValue - ScrollView_PaddingTop; // ScrollView可視範圍的上座標
    let ScrollViewBottom =
      this.m_ScrollValue + this.m_ScrollOuterHeight - ScrollView_PaddingTop; // ScrollView可視範圍的下座標
    // ScrollViewBottom = Math.min(ScrollViewBottom, this.MaxDataPosY());

    let nOverDiff = 0;
    if (ItemBottom >= ScrollViewBottom) {
      // 需要向下Scroll
      let OverDiff = ItemBottom - ScrollViewBottom;
      nOverDiff = Math.min(MovingScrollMaxDiff, OverDiff);
    } else if (ItemTop <= ScrollViewTop) {
      // 需要向上Scroll
      let OverDiff = ItemTop - ScrollViewTop;
      nOverDiff = Math.max(-MovingScrollMaxDiff, OverDiff);
    }

    if (nOverDiff != 0) {
      let ScrollY = this.m_ScrollValue + nOverDiff;
      ScrollY = Math.max(0, Math.min(this.MaxScrollablePosY(), ScrollY));

      this.m_ScrollRef.current?.scrollTo({
        y: ScrollY,
        animated: false,
      });
    }
  };

  render() {
    let bShowScrollBar =
      ShowScrollBarAlways || this.state.FocusItem === undefined;

    return (
      <ScrollView
        ref={this.m_ScrollRef}
        scrollEnabled={bShowScrollBar}
        bounces={false}
        scrollEventThrottle={1}
        style={{
          flexDirection: "column",
          flex: 1,
          backgroundColor: "#00000000",
          paddingTop: ScrollView_PaddingTop,
          paddingBottom: ScrollView_PaddingBottom,
        }}
        onScroll={(e) => {
          this.onScroll(e.nativeEvent.contentOffset.y);
        }}
        onLayout={(e) => {
          this.m_ScrollOuterHeight = e.nativeEvent.layout.height;
          kcSetState(this, { ScrollViewWidth: e.nativeEvent.layout.width });
        }}
      >
        <View
          style={{
            //flex: 1,
            width: this.state.ScrollViewWidth,
            height: this.props.Source.length * this.props.ItemHeight + 1, // + ScrollView.BorderTopWidth + ScrollView.BorderBottomWidth
            backgroundColor: "#00000000",
            //flexWrap: "wrap",
            flexDirection: "column",
          }}
        >
          {this.state.DataItems.map((Item, Index) => {
            let Key = Item.Key;
            return (
              <Animated.View
                key={Key}
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  backgroundColor: kcColor("Background"),

                  position: "absolute",
                  left: 0,
                  top: Item.LocationY,

                  shadowColor: kcColor("ListIcon"),
                  shadowRadius: Item.ShadowRadius,
                  shadowOpacity: Item.ShadowOpacity,

                  zIndex: Item.zIndex,
                }}
              >
                <View
                  style={[
                    {
                      width: this.state.ScrollViewWidth,
                      height: this.props.ItemHeight, // 這邊不設高度的話, 框線等style會影響總高度
                      flex: 1,
                      flexDirection: "row",
                      backgroundColor: "#00000000",
                      alignItems: "center",
                    },
                    this.props.ItemWrapStyle,
                  ]}
                >
                  <View
                    style={[
                      {
                        flex: 1,
                        backgroundColor: "#00000000",
                        marginLeft: 10,
                        marginRight: 10,
                      },
                    ]}
                  >
                    {this.props.renderItem(Item.Item, Index)}
                  </View>

                  <PanGestureHandler
                    key={`Pan_${Key}`}
                    enabled={true}
                    onBegan={(e) => {
                      // 有可能會比PressIn還早進來這邊
                      //Item.SetFocus(true); // IOS App 會先onPressOut才進onBegan, 故這邊再一次SetFocus(true), 但直接打開會讓LongPress卡的Dealy失效 (點了可以直接移動)
                      this.onMoveStart(e);
                    }}
                    onEnded={(e) => {
                      this.onMoveEnd(e);
                    }}
                    onGestureEvent={(e) => {
                      this.onMoving(e.nativeEvent.translationY);
                    }}
                    onFailed={(e) => {
                      this.onMoveCancel();
                    }}
                  >
                    <Pressable
                      style={{
                        height: this.props.ItemHeight,
                        width: 40 + DragIConPaddingRight, // 40 + paddingRight
                        alignItems: "center",
                        justifyContent: "center",
                        paddingRight: DragIConPaddingRight,
                      }}
                      delayLongPress={StartDragDelay}
                      onLongPress={(e) => {
                        Item.SetFocus(true);
                      }}
                      onPressOut={(e) => {
                        if (this.state.FocusItem)
                          setTimeout(() => {
                            this.onMoveEnd();
                          }, 50);
                      }}
                    >
                      <Ionicons
                        name="ios-menu"
                        size={30}
                        color={kcColor("ListIcon")}
                      />
                    </Pressable>
                  </PanGestureHandler>
                </View>
              </Animated.View>
            );
          })}
        </View>
      </ScrollView>
    );
  }
}

type DraggableListItemPorps<T> = {
  ParentList: DraggableListItem<T>["m_ParentList"];
  Item: DraggableListItem<T>["m_Item"];
  IndexSrc: DraggableListItem<T>["m_IndexSrc"];
  LocationY?: number;
  onItemFocused?: DraggableListItem<T>["m_fOnItemFocesed"];
};

class DraggableListItem<T> {
  constructor(_Props: DraggableListItemPorps<T>) {
    this.m_ParentList = _Props.ParentList;
    this.m_Item = _Props.Item;
    this.m_IndexSrc = _Props.IndexSrc;
    this.m_szKey = this.GetKeyCore();
    this.m_bFocused = false;
    this.m_nOriLocationY = _Props.LocationY ?? 0;
    this.m_LocationY = new Animated.Value(this.m_nOriLocationY);
    this.m_FocusShadow = new Animated.ValueXY({ x: 0, y: 0 });
    this.m_zIndex = new Animated.Value(ItemZIndex_Default);
    this.m_fOnItemFocesed = _Props.onItemFocused;

    this.MovingTempIndex = this.m_IndexSrc;
  }

  private m_ParentList: KC_DraggableList<T>;
  private m_Item: T;
  private m_IndexSrc: number;
  private m_szKey: string;
  private m_bFocused: boolean;
  private m_LocationY: Animated.Value;
  private m_FocusShadow: Animated.ValueXY; // X:shadowRadius, Y:shadowOpacity
  private m_zIndex: Animated.Value;
  private m_nOriLocationY: number;
  private m_nTranslationY: number = 0;
  private m_fOnItemFocesed?: (
    _mdFocused: DraggableListItem<T>,
    _Focused: boolean
  ) => void;

  private GetKeyCore() {
    //return `${this.m_Item}-${this.m_IndexSrc}`;
    return `${this.m_IndexSrc}`;
  }
  public get Key() {
    return this.m_szKey;
  }
  public get Item() {
    return this.m_Item;
  }
  public get IndexSrc() {
    return this.m_IndexSrc;
  }
  public get LocationY() {
    return this.m_LocationY;
  }
  public get ShadowRadius() {
    return this.m_FocusShadow.x;
  }
  public get ShadowOpacity() {
    return this.m_FocusShadow.y;
  }
  public get zIndex() {
    return this.m_zIndex;
  }
  public get OriLocationY() {
    return this.m_nOriLocationY;
  }
  public get TranslationY() {
    return this.m_nTranslationY;
  }
  public get LocationYValue() {
    // 不考慮動畫移動中, 動畫後的LoactionY
    return this.m_nOriLocationY + this.m_nTranslationY;
  }
  public MovingTempIndex: number;

  public SetFocus(_bFocus: boolean): void {
    if (this.m_bFocused === _bFocus) return;
    this.m_bFocused = _bFocus;

    if (this.m_fOnItemFocesed) this.m_fOnItemFocesed(this, _bFocus);

    let nZIndex = _bFocus ? ItemZIndex_Focus : ItemZIndex_Default;
    let nshadowRadius = _bFocus ? FocusItemShadowWidth : 0;
    let shadowOpacity = _bFocus ? 1 : 0;

    this.m_zIndex.setValue(nZIndex);

    Animated.timing(this.m_FocusShadow, {
      toValue: { x: nshadowRadius, y: shadowOpacity },
      duration: FocusAnimationDuration,
      useNativeDriver: false,
    }).start();
  }
  public SetTranslateY_Animated(
    _nTranslationY: number,
    _IsMouseMove: boolean,
    _fFinished?: () => void
  ) {
    if (this.m_nTranslationY === _nTranslationY) {
      _fFinished?.call(this);
      return;
    }

    let nToY = this.m_nOriLocationY + _nTranslationY;
    nToY = Math.max(
      this.m_ParentList.MinPosY(),
      Math.min(this.m_ParentList.MaxPosY(), nToY)
    );

    if (_IsMouseMove)
      this.m_LocationY.setValue(this.m_nOriLocationY + this.m_nTranslationY);
    Animated.timing(this.m_LocationY, {
      toValue: nToY,
      duration: DraggingAnimationDuration,
      useNativeDriver: false,
    }).start(_fFinished);
    this.m_nTranslationY = nToY - this.m_nOriLocationY;
  }
  public MoveY_Animated(_nPosY: number, _fFinished?: () => void) {
    let nDiff = _nPosY - this.m_nOriLocationY;
    this.SetTranslateY_Animated(nDiff, false, _fFinished);
  }
  public RestoreLocationY() {
    this.SetTranslateY_Animated(0, false, () => {
      this.SetFocus(false);
    });
  }

  public ReSet(_IndexSrc: number, LocationY: number) {
    this.m_IndexSrc = _IndexSrc;
    this.m_szKey = this.GetKeyCore();
    this.m_nOriLocationY = LocationY;
    this.m_nTranslationY = 0;
  }
  public SetAnimation(_md: DraggableListItem<T>) {
    this.m_LocationY.setValue(
      DraggableListItem.GetAnimationValue(this.LocationY).value
    );
    this.m_FocusShadow.x.setValue(
      DraggableListItem.GetAnimationValue(this.m_FocusShadow.x).value
    );
    this.m_FocusShadow.y.setValue(
      DraggableListItem.GetAnimationValue(this.m_FocusShadow.y).value
    );
    this.m_zIndex.setValue(
      DraggableListItem.GetAnimationValue(this.m_zIndex).value
    );
  }

  private static GetAnimationValue(_AniValue: Animated.Value) {
    let Value = _AniValue as any;
    return {
      value: Value._value,
      startingValue: Value._startingValue,
      offset: Value._offset,
    };
  }
}
