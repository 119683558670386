import KLineSetting from "./KLineSetting";
import MaSetting from "./MaSetting";
import TECPGlobalSetting from "./TECPGlobalSetting";
import VolSetting from "./VolSetting";
import BBandSetting from "./BBandSetting";
import KDSetting from "./KDSetting";
import RSISetting from "./RSISetting";
import MACDSetting from "./MACDSetting";
import FinTechCloudSetting from "./FinTechCloudSetting";
import MACDFTSetting from "./MACDFTSetting";

export default class TECPSetting {
  constructor(props: object) {
    Object.assign(this, { ...props });
  }

  Global: TECPGlobalSetting = new TECPGlobalSetting({});
  KLine: KLineSetting = new KLineSetting({});
  KLine_Ma0: MaSetting = new MaSetting({});
  KLine_Ma1: MaSetting = new MaSetting({});
  KLine_Ma2: MaSetting = new MaSetting({});
  KLine_Ma3: MaSetting = new MaSetting({});
  BBand: BBandSetting = new BBandSetting({});
  Vol: VolSetting = new VolSetting({});
  KD: KDSetting = new KDSetting({});
  RSI: RSISetting = new RSISetting({});
  MACD: MACDSetting = new MACDSetting({});
  FinTechCloud: FinTechCloudSetting = new FinTechCloudSetting({});
  MACDFT: MACDFTSetting = new MACDFTSetting({});

  public Clone() {
    let mdClone = new TECPSetting({});

    mdClone.Global = this.Global.Clone();
    mdClone.KLine = this.KLine.Clone();
    mdClone.KLine_Ma0 = this.KLine_Ma0.Clone();
    mdClone.KLine_Ma1 = this.KLine_Ma1.Clone();
    mdClone.KLine_Ma2 = this.KLine_Ma2.Clone();
    mdClone.KLine_Ma3 = this.KLine_Ma3.Clone();
    mdClone.BBand = this.BBand.Clone();
    mdClone.Vol = this.Vol.Clone();
    mdClone.KD = this.KD.Clone();
    mdClone.RSI = this.RSI.Clone();
    mdClone.MACD = this.MACD.Clone();
    mdClone.FinTechCloud = this.FinTechCloud.Clone();
    mdClone.MACDFT = this.MACDFT.Clone();

    return mdClone;
  }
  public SetSetting(_TECPSetting: TECPSetting) {
    if (!_TECPSetting) return;

    this.Global.SetSetting(_TECPSetting.Global);
    this.KLine.SetSetting(_TECPSetting.KLine);
    this.KLine_Ma0.SetSetting(_TECPSetting.KLine_Ma0);
    this.KLine_Ma1.SetSetting(_TECPSetting.KLine_Ma1);
    this.KLine_Ma2.SetSetting(_TECPSetting.KLine_Ma2);
    this.KLine_Ma3.SetSetting(_TECPSetting.KLine_Ma3);
    this.BBand.SetSetting(_TECPSetting.BBand);
    this.Vol.SetSetting(_TECPSetting.Vol);
    this.KD.SetSetting(_TECPSetting.KD);
    this.RSI.SetSetting(_TECPSetting.RSI);
    this.MACD.SetSetting(_TECPSetting.MACD);
    this.FinTechCloud.SetSetting(_TECPSetting.FinTechCloud);
    this.MACDFT.SetSetting(_TECPSetting.MACDFT);
  }
}
