export default class MathEx {
  public static kcRound(_Value: number, _Round: number): number {
    const factor = 10 ** _Round;
    return Math.round(_Value * factor) / factor;
  }
  public static kcFloor(_Value: number, _Round: number): number {
    const factor = 10 ** _Round;
    return Math.round(Math.floor(_Value * factor)) / factor;
  }

  public static Max(...values: (number | undefined)[]): number | undefined {
    if (!values || values.length == 0) return undefined;

    let dMax: number | undefined = undefined;
    for (let i = 0; i < values.length; i++) {
      let dValue = values[i];

      if (typeof dValue !== "number") continue;

      if (dMax === undefined || dMax < dValue) dMax = dValue;
    }

    return dMax;
  }

  public static Min(...values: (number | undefined)[]): number | undefined {
    if (!values || values.length == 0) return undefined;

    let dMin: number | undefined = undefined;
    for (let i = 0; i < values.length; i++) {
      let dValue = values[i];

      if (typeof dValue !== "number") continue;

      if (dMin === undefined || dMin > dValue) dMin = dValue;
    }

    return dMin;
  }

  public static GetDecimalNumber(_Value: number): number {
    let nFloatNum = 0;
    let szValue = _Value.toString();
    let nDotIdx = szValue.indexOf(".");
    let neIdx = szValue.indexOf("e-");
    if (nDotIdx >= 0) {
      let szCut = szValue.substring(nDotIdx + 1);
      nFloatNum = szCut.length;
    } else if (neIdx >= 0) {
      let szCut = szValue.substring(neIdx + 2);
      nFloatNum = Number(szCut);
    }
    return nFloatNum;
  }
}
