import { Moment } from "moment";
import { kcAccountModel } from "../kcModel";
import { HistoryDataType, QuoteHistoryRequestMode } from "./InternalDefine";

export enum ResaultState {
  Ok = 0x01,
  Fail = 0x02,
}

export type TokenParam = {
  Token: string;
  RequestKey: number;
};
export type TokenResault = {
  Token: string;
  RequestKey: number;
};

// Trade_Login
export type LoginParam = {
  Account: string;
  PW: string;
  RequestKey: number;
};
export type LoginResault = {
  State: ResaultState;
  Message: string;
  Token: string;
  RequestKey: number;
  FailCode: number;
} & TokenResault;

// Trade_Account
export type GetAccountParam = {
  Token: string;
  RequestKey: number;
} & TokenParam;
export type GetAccountResault = {
  State: ResaultState;
  Message: string;
  Token: string;
  AccountInfo: kcAccountModel;
} & TokenResault;

// Quote_LastInfo
export type Quote_LastInfoParam = {
  StockCodes: string;
} & TokenParam;
export type Quote_LastInfoResault = {} & TokenResault;

// Quote_History
export type Quote_QuoteHistoryParam = {
  RequestKey: number;
  StockCode: string;
  HType: HistoryDataType;
  RequestMode: QuoteHistoryRequestMode;
  DayNumber?: undefined | number;
  StartTime?: undefined | string | Moment | Date;
  EndTime?: undefined | string | Moment | Date;
} & TokenParam;
export type Quote_QuoteHistoryResault = {
  State: ResaultState;
  Message: string;
  RequestKey: number;
  StockCode: string;
  HType: HistoryDataType;
  RequestMode: QuoteHistoryRequestMode;
  DayNumber: undefined | number;
  StartTime: undefined | string | Moment | Date;
  EndTime: undefined | string | Moment | Date;
} & TokenResault;
