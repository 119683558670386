import { I18n } from "i18n-js";
import { DisplayTextModel } from "./_Model";
import { ReLoadApp } from "../navigation/AutoReLoadApp";
import zhTW from "./zhTW";
import enUS from "./enUS";

const StorageKey = "Locale";

const LocaleKey_TW = "zh-TW";
const LocaleKey_en = "en";
export type Locales = typeof LocaleKey_TW | typeof LocaleKey_en;
export const LocaleKey = { TW: LocaleKey_TW, en: LocaleKey_en };

const i18n = new I18n({
  [LocaleKey_TW]: zhTW,
  [LocaleKey_en]: enUS,
});

export function SetLocale(szLocale: Locales, _bSave: boolean = true) {
  // 切換locale
  i18n.locale = szLocale;

  // 存檔
  if (_bSave) window.localStorage.setItem(StorageKey, szLocale);

  // 變更document的value
  // var Element = document.getElementsByTagName("html");
  // if (Element.length > 0) Element[0].setAttribute("lang", szLocale);
}

export function IsLocale(szLocale: Locales) {
  return i18n.locale === szLocale;
}

export function GetText(szKey: keyof DisplayTextModel) {
  return i18n.t(szKey);
}

export function ChangeLocale() {
  if (IsLocale("en")) SetLocale("zh-TW");
  else SetLocale("en");
  ReLoadApp();
}

/* ---------------------------------------------------- */
// 讀取 localStorage 先直接用window.localStorage
function InitLocale() {
  let szRead = window.localStorage.getItem(StorageKey);
  if (szRead !== null && Object.values(LocaleKey).indexOf(szRead) > -1)
    SetLocale(szRead as Locales, false);
  else SetLocale(LocaleKey_TW, true); // Default
}
/* ---------------------------------------------------- */

InitLocale();
export default i18n;
