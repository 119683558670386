import { kcColor } from "../../constants/Colors";

const Default_MACD_Base: number = 9;
const Default_EMA1_Base: number = 12;
const Default_EMA2_Base: number = 26;

type MACDDataSettingType = {
  MACD_Base?: number;
  EMA1_Base?: number;
  EMA2_Base?: number;
};
type MACDDrawSettingType = {
  Dif_Enable?: boolean;
  Dif_LineColor?: string;
  Dif_LineWidth?: number;

  MACD_Enable?: boolean;
  MACD_LineColor?: string;
  MACD_LineWidth?: number;

  Dif_MACD_UpColor?: string;
  Dif_MACD_DownColor?: string;
};
export default class MACDSetting {
  constructor(props: object) {
    Object.assign(this, { ...props });
  }

  MACD_Base: number = Default_MACD_Base;
  EMA1_Base: number = Default_EMA1_Base;
  EMA2_Base: number = Default_EMA2_Base;

  Dif_Enable: boolean = true;
  Dif_LineColor = "#FFA500FF";
  Dif_LineWidth: number = 1;

  MACD_Enable: boolean = true;
  MACD_LineColor = "#87CEEBFF";
  MACD_LineWidth: number = 1;

  Dif_MACD_UpColor = kcColor("Down");
  Dif_MACD_DownColor = "#E0E0E0FF";

  public get EnableAny() {
    return this.Dif_Enable || this.MACD_Enable;
  }
  public get EnableAll() {
    return this.Dif_Enable && this.MACD_Enable;
  }

  public Clone(): MACDSetting {
    return new MACDSetting(this);
  }
  public SetGlobalSetting(_Setting: MACDSetting) {
    Object.assign(this, _Setting);
  }

  public SetSetting(
    _Setting: (MACDDataSettingType & MACDDrawSettingType) | MACDSetting
  ): {
    DataChanged: boolean;
    DrawChanged: boolean;
  } {
    let Reasult = { DataChanged: false, DrawChanged: false };

    if (_Setting) {
      // DataChanged
      if (
        _Setting.MACD_Base !== undefined &&
        _Setting.MACD_Base !== this.MACD_Base
      ) {
        this.MACD_Base = _Setting.MACD_Base;
        Reasult.DataChanged = true;
      }
      if (
        _Setting.EMA1_Base !== undefined &&
        _Setting.EMA1_Base !== this.EMA1_Base
      ) {
        this.EMA1_Base = _Setting.EMA1_Base;
        Reasult.DataChanged = true;
      }
      if (
        _Setting.EMA2_Base !== undefined &&
        _Setting.EMA2_Base !== this.EMA2_Base
      ) {
        this.EMA2_Base = _Setting.EMA2_Base;
        Reasult.DataChanged = true;
      }

      // DrawChanged
      if (
        _Setting.Dif_Enable !== undefined &&
        _Setting.Dif_Enable !== this.Dif_Enable
      ) {
        this.Dif_Enable = _Setting.Dif_Enable;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.Dif_LineColor !== undefined &&
        _Setting.Dif_LineColor !== this.Dif_LineColor
      ) {
        this.Dif_LineColor = _Setting.Dif_LineColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.Dif_LineWidth !== undefined &&
        _Setting.Dif_LineWidth !== this.Dif_LineWidth
      ) {
        this.Dif_LineWidth = _Setting.Dif_LineWidth;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.MACD_Enable !== undefined &&
        _Setting.MACD_Enable !== this.MACD_Enable
      ) {
        this.MACD_Enable = _Setting.MACD_Enable;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.MACD_LineColor !== undefined &&
        _Setting.MACD_LineColor !== this.MACD_LineColor
      ) {
        this.MACD_LineColor = _Setting.MACD_LineColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.MACD_LineWidth !== undefined &&
        _Setting.MACD_LineWidth !== this.MACD_LineWidth
      ) {
        this.MACD_LineWidth = _Setting.MACD_LineWidth;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.Dif_MACD_UpColor !== undefined &&
        _Setting.Dif_MACD_UpColor !== this.Dif_MACD_UpColor
      ) {
        this.Dif_MACD_UpColor = _Setting.Dif_MACD_UpColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.Dif_MACD_DownColor !== undefined &&
        _Setting.Dif_MACD_DownColor !== this.Dif_MACD_DownColor
      ) {
        this.Dif_MACD_DownColor = _Setting.Dif_MACD_DownColor;
        Reasult.DrawChanged = true;
      }
    }

    return Reasult;
  }

  public ToDefault() {
    this.MACD_Base = Default_MACD_Base;
    this.EMA1_Base = Default_EMA1_Base;
    this.EMA2_Base = Default_EMA2_Base;
  }
}
