import { MathEx } from "../kcExternal";
import { PriceScaleExtensions } from "../kcModel/PriceScaleExtensions";
import cTECPDef from "../kcTECP/cTECPDef";

export class YCalculateModel {
  public nFixedHeight: number = 0;
  public nDynamicHeight: number = 0;
  constructor(_nHeight: number, _nMinuteHeight: number, _bFixed: boolean) {
    if (_bFixed) {
      this.nFixedHeight = _nHeight;
      this.nDynamicHeight = 0;
    } else {
      this.nFixedHeight = _nMinuteHeight;
      this.nDynamicHeight = _nHeight - _nMinuteHeight;
      if (this.nDynamicHeight < 0) this.nDynamicHeight = 0;
    }
  }

  public GetHeight(_dHeightRate: number) {
    return _dHeightRate * this.nDynamicHeight + this.nFixedHeight;
  }
  public static GetTotalFixedHeight(_ml: YCalculateModel[]) {
    let nRet = 0;
    _ml.forEach((md) => (nRet += md.nFixedHeight));
    return nRet;
  }
  public static GetTotalDynamicHeight(_ml: YCalculateModel[]) {
    let nRet = 0;
    _ml.forEach((md) => (nRet += md.nDynamicHeight));
    return nRet;
  }
  public static GetEachHeight(
    _ml: YCalculateModel[],
    _dHeightRate: number,
    _nTotalHeight: number
  ): number[] {
    let HeightRet: number[] = [_ml.length]; // 整數回傳值
    let dRemainder: number[] = [_ml.length]; // 小數部分的暫存
    let nHeightCount = 0; // 整數加總
    for (let i = 0; i < _ml.length; i++) {
      let dHeight = _ml[i].GetHeight(_dHeightRate);
      let nHeight = Math.floor(dHeight); // 整數部分
      HeightRet[i] = Math.floor(dHeight);
      dRemainder[i] = dHeight - nHeight; // 小數部分的暫存
      nHeightCount += nHeight;
    }

    let nHeightDif = _nTotalHeight - nHeightCount; // 多餘 需要補足的高度
    for (let nCount = 0; nCount < nHeightDif; nCount++) {
      let dMax = 0;
      let nMaxIdx = -1;
      for (
        let i = 0;
        i < dRemainder.length;
        i++ // 找小數部分最大的, 進位
      ) {
        if (dRemainder[i] > dMax) {
          dMax = dRemainder[i];
          nMaxIdx = i;
        }
      }
      if (nMaxIdx != -1) {
        dRemainder[nMaxIdx] = 0;
        HeightRet[nMaxIdx]++;
      } // 找不到適合放大的, 直接全部補在[0]
      else {
        HeightRet[0] += nHeightDif - nCount + 1;
        break;
      }
    }
    return HeightRet;
  }
}

export class YUnitBase {
  constructor() {}

  public StartY: number = 0;
  public EndY: number = 0;
  public High: number = 0;
  public FixedHeight: boolean = false;

  public SetYUnitBase_Height(_nHeight: number, _bFixedHeight: boolean) {
    let _nStartY = 0;
    let _nEndY = _nHeight + _nStartY - 1;
    this.SetYUnitBase_StartEnd(_nStartY, _nEndY, _bFixedHeight);
  }
  public SetYUnitBase_StartEnd(
    _nStartY: number,
    _nEndY: number,
    _bFixedHeight: boolean
  ) {
    this.StartY = _nStartY;
    this.EndY = _nEndY;
    this.High = _nEndY - _nStartY + 1;
    this.FixedHeight = _bFixedHeight;
    this.OnYValueChange();
  }
  public SetYUnitBase(_YUnitBase: YUnitBase) {
    this.StartY = _YUnitBase.StartY;
    this.EndY = _YUnitBase.EndY;
    this.High = _YUnitBase.High;
    this.FixedHeight = _YUnitBase.FixedHeight;
    this.OnYValueChange();
  }
  public ChangeHigh(_nHigh: number) {
    this.High = _nHigh;
    this.OnYValueChange();
  }
  public AddHigh(_nDif: number) {
    this.High += _nDif;
    this.OnYValueChange();
  }
  protected OnYValueChange() {}

  public ContainsPoint(X: number, Y: number) {
    return Y >= this.StartY && Y <= this.EndY;
  }
}

export class YUnit extends YUnitBase {
  constructor(_bKLineBuffer: boolean = false, _bIsReverse: boolean = false) {
    super();
    this.m_dStartValue = 0;
    this.m_dEndValue = 0;

    this.m_bKLineBuffer = _bKLineBuffer;
    this.m_bIsReverse = _bIsReverse;
  }

  private m_bEnableYShift: boolean = false;
  public get StartValue() {
    return this.m_dStartValue;
  }
  private m_dStartValue: number = 0; // 正常情況是小的
  public get EndValue() {
    return this.m_dEndValue;
  }
  private m_dEndValue: number = 0; // 正常情況是大的
  public get ValueSeted() {
    return this.m_bValueSeted;
  }
  private m_bValueSeted: boolean = false;
  private m_nEffectHigh: number = 0; // 有效高度 可畫圖高度
  private m_nEffectStartY: number = 0; // 有效StartY
  private m_dPixelValue: number = 0; // 一個Pixel 對應到多少價錢 (ex跳一次 就跳 0.02元)
  private m_nTopSpace: number = 0; // 上方留白區域
  private m_bKLineBuffer: boolean = false; // 暫時加在這
  private m_bIsReverse: boolean = false; // 暫時加在這
  private m_szYValueFormat: string = "f2";
  private m_dTickBase: number = 0.01;
  private m_nPixDif: number = 0;
  private m_bLockMaxMin: boolean = false;
  private m_nYTickBaseFloatNum: number = 2;

  public get nEffectStartY() {
    return this.m_nEffectStartY;
  }
  public get nEffectHigh() {
    return this.m_nEffectHigh;
  }
  public get nEffectEndY() {
    return this.m_nEffectStartY + this.m_nEffectHigh - 1;
  }
  public get dPixelValue() {
    return this.m_dPixelValue;
  }
  public get YLeftInfoPos() {
    return this.StartY + cTECPDef.FIXED_TOP_SPACE - 1;
  } // 跟TopArea相關
  public get YLeftInfoHeight() {
    return cTECPDef.LEFT_FONT_AREA;
  } // 跟TopArea相關
  public get YTickBase() {
    return this.m_dTickBase;
  }
  public get YValueFormat() {
    return this.m_szYValueFormat;
  }
  public get YTickBaseFloatNum() {
    return this.m_nYTickBaseFloatNum;
  }

  public ChangeYValue(
    _dStartValue: number,
    _dEndValue: number,
    _bIsReverse: boolean = false
  ) {
    _dStartValue = MathEx.kcRound(_dStartValue, 6); // 修正液位
    _dEndValue = MathEx.kcRound(_dEndValue, 6);

    if (
      (this.m_dStartValue == _dStartValue && this.m_dEndValue == _dEndValue) ||
      this.m_bLockMaxMin
    )
      return;

    this.m_dStartValue = _dStartValue;
    this.m_dEndValue = _dEndValue;
    if (Math.abs(this.m_dStartValue - this.m_dEndValue) < this.m_dTickBase) {
      let dTickBase = this.m_dTickBase;
      if (_bIsReverse) dTickBase = -dTickBase;
      this.m_dStartValue -= dTickBase;
      this.m_dEndValue += dTickBase;
    }
    this.m_bValueSeted = true;

    this.Init();
  }
  public SetTickBase(_dTickBase: number) {
    this.m_dTickBase = _dTickBase;
    let nFloatNum = MathEx.GetDecimalNumber(this.m_dTickBase);
    let szFormat = "f" + nFloatNum;
    this.m_szYValueFormat = szFormat;
    this.m_nYTickBaseFloatNum = nFloatNum;
  }
  protected OnYValueChange() {
    //override
    this.Init();
    super.OnYValueChange();
  }
  private Init() {
    if (!this.m_bValueSeted) return;
    /* ------------------------------------------------------------------------ */
    // Set BuffArea 上下流白高度
    let nBuffArea = cTECPDef.ELSE_TECP_BUFF_AREA;
    if (this.m_bKLineBuffer) nBuffArea = 14; //KLINE上下留空白 要畫最高最低價需要較大

    /* ------------------------------------------------------------------------ */
    // Set nTopArea 上方額外高度
    let nTopArea = cTECPDef.FIXED_TOP_SPACE + cTECPDef.LEFT_FONT_AREA;
    if (this.m_bKLineBuffer) nTopArea += cTECPDef.KLINE_BUFF_AREA;

    /* ------------------------------------------------------------------------ */
    // Set Effect Y
    this.m_nEffectStartY = this.StartY + nTopArea;
    this.m_nEffectHigh = this.High - nTopArea;

    //if (!m_mdMaxMin.HasLimit)
    //    return;

    /* ------------------------------------------------------------------------ */
    // Set dValueDif
    let dValueDif = this.m_dEndValue - this.m_dStartValue;
    //decimal dValueDif = m_mdMaxMin.MaxLimit.Value - m_mdMaxMin.MinLimit.Value;
    //if (dValueDif == 0)
    //    dValueDif = 1;

    /* ------------------------------------------------------------------------ */
    // Set DrawValue
    let nDrawValueHigh = this.High - 2 * nBuffArea - nTopArea;

    if (nDrawValueHigh <= 0) return;

    /* ------------------------------------------------------------------------ */
    // Set m_dPixelValue
    if (nDrawValueHigh > 0) this.m_dPixelValue = dValueDif / nDrawValueHigh;

    /* ------------------------------------------------------------------------ */
    // Set m_nTopSpace  這段意義不明?
    if (this.m_dPixelValue <= 0) return;
    let nMin_YCoord = Math.floor(dValueDif / this.m_dPixelValue); // 求出最小值Y的座標
    let nDynamicTop = (nDrawValueHigh - nMin_YCoord) / 2; // 高 - 固定留白 - 最小值Y的座標 / 2 平均分配給上下
    this.m_nTopSpace = nDynamicTop + nTopArea + nBuffArea;
  }

  public SetShiftPix(_nShiftOffset: number) {
    if (this.m_bEnableYShift) return;
    this.m_bLockMaxMin = true;
    this.m_nPixDif += _nShiftOffset;
  }
  public ResetShiftPix() {
    this.m_bLockMaxMin = false;
    this.m_nPixDif = 0;
  }
  public ValueToCoord(_Value: number) {
    if (this.m_dPixelValue == 0) return 0;

    if (_Value == undefined) return 0;

    let Dif = this.m_dEndValue - _Value;
    //decimal Dif;
    //if (m_bIsReverse)
    //    Dif = dValue - m_mdMaxMin.MinLimit.Value;
    //else
    //    Dif = m_mdMaxMin.MaxLimit.Value - dValue;

    // 差價 除 (一個pixel代表多少價) + 上方區域 + 起使作標--> Y座標
    let nCoord =
      Math.floor(Dif / this.m_dPixelValue) + this.m_nTopSpace + this.StartY;
    nCoord = nCoord + this.m_nPixDif;
    return nCoord;
  }
  public ValueToCoordHeight(_ValueRange: number) {
    if (this.m_dPixelValue == 0) return 0;

    return _ValueRange / this.m_dPixelValue;
  }
  public CoordToValue(_Coord: number) {
    _Coord = _Coord - this.StartY;
    _Coord = _Coord - this.m_nPixDif;

    return this.m_dEndValue - (_Coord - this.m_nTopSpace) * this.m_dPixelValue;
    //if (m_bIsReverse)
    //    return ((_Coord - m_nTopSpace) * m_dPixelValue) - m_mdMaxMin.MinLimit.Value;
    //else
    //    return m_mdMaxMin.MaxLimit.Value - ((_Coord - m_nTopSpace) * m_dPixelValue);
  }
  public CoordToFixedValue(_Coord: number) {
    let dOriValue = this.CoordToValue(_Coord);
    let dFixedValue;
    let dMod = dOriValue % this.m_dTickBase;
    if (dMod == 0) {
      dFixedValue = dOriValue;
    } else {
      if (dMod > this.m_dTickBase - dMod)
        dFixedValue = dOriValue + (this.m_dTickBase - dMod);
      else dFixedValue = dOriValue - dMod;
    }
    let fFixedCoord = this.ValueToCoord(dFixedValue); // 誤差關係, 統一再一次ValueToCoord
    return { dFixedValue, fFixedCoord };
  }
}

export default class YInfo {
  constructor() {}

  public get YUnits() {
    return this.m_mlYUnit;
  }
  public get Count() {
    return this.m_mlYUnit.length;
  }
  private m_mlYUnit: YUnit[] = [];
  private m_nPictureHeight: number = 0;

  public get PictureHeight() {
    return this.m_nPictureHeight;
  }

  public Init(_nPictureHeight: number, _bShowDeputyTECP: boolean) {
    this.ChangeHeight(_nPictureHeight, _bShowDeputyTECP);
  }
  public ChangeHeight(_nPictureHeight: number, _bShowDeputyTECP: boolean) {
    this.m_nPictureHeight = _nPictureHeight;
    this.UpdateYList(_bShowDeputyTECP);
    this.SetEachYUnitHeight();
  }
  public ChangeYList(_bShowDeputyTECP: boolean) {
    this.UpdateYList(_bShowDeputyTECP);
    this.SetEachYUnitHeight();
  }
  private SetEachYUnitHeight() {
    YInfo._SetEachHeight(
      this.m_mlYUnit,
      this.m_nPictureHeight,
      cTECPDef.AP_TOP_HEIGHT,
      cTECPDef.GRAY_LINE_HEIGH
    );
    this.OnYUnitChanged();
  }
  protected GetYCalculateList(): YCalculateModel[] {
    let nMinHeight = YInfo._GetMinHeight();
    let lEachY: YCalculateModel[] = [];
    this.m_mlYUnit.forEach((YBase) => {
      lEachY.push(
        new YCalculateModel(YBase.High, nMinHeight, YBase.FixedHeight)
      );
    });
    return lEachY;
  }
  private UpdateYList(_bShowDeputyTECP: boolean) {
    this.m_mlYUnit = [];
    let YUnit0 = new YUnit(true, false);
    YUnit0.SetYUnitBase_Height(300, false);
    this.m_mlYUnit.push(YUnit0);

    if (_bShowDeputyTECP) {
      let YUnit1 = new YUnit(false, false);
      YUnit1.SetYUnitBase_Height(100, false);
      this.m_mlYUnit.push(YUnit1);
    }

    let YUnit2 = new YUnit(false, false);
    YUnit2.SetYUnitBase_Height(20, true);
    this.m_mlYUnit.push(YUnit2);
  }
  private OnYUnitChanged() {
    // YUnitChanged?.Invoke();
  }

  public GetFocusYUnit(_PosY: number): YUnit | undefined {
    if (this.m_mlYUnit) {
      for (let mdYUnit of this.m_mlYUnit) {
        if (_PosY >= mdYUnit.StartY && _PosY <= mdYUnit.EndY) return mdYUnit;
      }
      return undefined;
    }
  }
  public GetFocusYUnitIndex(_PosY: number): number | undefined {
    let nIdx = -1;
    if (this.m_mlYUnit) {
      for (let i = 0; i < this.m_mlYUnit.length; i++) {
        let mdYUnit = this.m_mlYUnit[i];
        if (_PosY >= mdYUnit.StartY && _PosY <= mdYUnit.EndY) return i;
      }
    }
    return nIdx;
  }
  /* ----------------------------------------------------- */
  // static Func
  protected static _SetEachHeight(
    _lYBaseItems: YUnitBase[],
    _BMHeight: number,
    _nAP_TOP_HEIGHT: number,
    _nGRAY_LINE_HEIGH: number
  ) {
    // 用YUnitBase.High計算, 直接覆蓋原本的StartY,EndY,High
    let fLeftInfoFontH = cTECPDef.LEFT_FONT_AREA;
    let nMinHeight = fLeftInfoFontH + _nGRAY_LINE_HEIGH;

    let lEachY: YCalculateModel[] = [];
    _lYBaseItems.forEach((YBase) => {
      lEachY.push(
        new YCalculateModel(YBase.High, nMinHeight, YBase.FixedHeight)
      );
    });

    let nTotalFixedHeight = YCalculateModel.GetTotalFixedHeight(lEachY); // 全部的固定(最小)高度總合
    let nTotalDynamicHeight = YCalculateModel.GetTotalDynamicHeight(lEachY); // 動態部分高度總合

    let nDynamicBMHeight = _BMHeight - nTotalFixedHeight; // 畫面可分配的高度
    if (nDynamicBMHeight < 0)
      // 防止輸入過小
      nDynamicBMHeight = 0;

    let dDynamicRate = 0; // 可分配高度的比例
    if (nTotalDynamicHeight != 0)
      dDynamicRate = nDynamicBMHeight / nTotalDynamicHeight;

    // 分配每個YUnitBase的高度
    let aEachHeight = YCalculateModel.GetEachHeight(
      lEachY,
      dDynamicRate,
      _BMHeight
    );
    let nPosY = _nAP_TOP_HEIGHT;
    for (let i = 0; i < _lYBaseItems.length; i++) {
      let nYStart = nPosY;
      let nEndY = nYStart + aEachHeight[i] - 1;
      _lYBaseItems[i].SetYUnitBase_StartEnd(
        nYStart,
        nEndY,
        _lYBaseItems[i].FixedHeight
      );

      nPosY = nEndY + 1;
    }

    //PrintEachHeight(_lYBaseItems, _BMHeight);
  }
  protected static _GetMinHeight() {
    return cTECPDef.LEFT_FONT_AREA + cTECPDef.GRAY_LINE_HEIGH;
  }
}
