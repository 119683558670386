import {
  kcTickModel,
  kcLastInfoModel,
  kcCommodityModel,
  kcHistoryOHLCModel,
  kcDepthModel,
} from "../kcModel";
import kcHistoryCollection, {
  delOnHistoryChanged,
} from "./kcHistoryCollection";
import { HistoryDataType } from "../kcTransfer/InternalDefine";

export const MaxTickBufferSize = 100;
// export type HistoryCallback = (
//   _mdkcUnit: kcUnitModel,
//   _HistoryCollection: kcHistoryCollection
// ) => void;

export default class kcUnitModel {
  constructor(
    _StockCode: string,
    _Commodity?: kcCommodityModel,
    _LastInfo?: kcLastInfoModel
  ) {
    this.StockCode = _StockCode;
    this.Commodity = _Commodity;
    this.m_LastInfo = _LastInfo;

    this.m_HistoryTick = new kcHistoryCollection(this, HistoryDataType.Tick);
    this.m_HistoryMin = new kcHistoryCollection(this, HistoryDataType.Minute);
    this.m_HistoryDay = new kcHistoryCollection(this, HistoryDataType.Day);
  }

  StockCode: string;
  Commodity?: kcCommodityModel;
  private m_LastInfo?: kcLastInfoModel;
  private m_TickBuffer: kcTickModel[] = [];

  private m_HistoryTick;
  private m_HistoryMin;
  private m_HistoryDay;

  public get LastInfo_Pure() {
    return this.m_LastInfo;
  }

  public get LastInfo() {
    return this.m_LastInfo;
  }
  public get TickBuffer() {
    return this.m_TickBuffer;
  }

  public OnLastInfo(_mdLastInfo: kcLastInfoModel) {
    // Update Pure
    if (this.m_LastInfo) this.m_LastInfo.Update_ByLastInfo(_mdLastInfo);
    else this.m_LastInfo = _mdLastInfo;
  }

  public OnTick(_mdTick: kcTickModel) {
    // Update Pure
    this.m_LastInfo?.Update_ByTick(_mdTick);

    // TickBuffer, 留最新1000筆
    if (this.m_TickBuffer.length >= MaxTickBufferSize)
      this.m_TickBuffer.shift();
    this.m_TickBuffer.push(_mdTick);

    // Update History
    this.m_HistoryTick.Update(_mdTick);
    this.m_HistoryMin.Update(_mdTick);
    this.m_HistoryDay.Update(_mdTick);
  }

  public OnDepth(_mdDepth: kcDepthModel) {
    this.m_LastInfo?.Update_ByDepth(_mdDepth);
  }

  public GetAskBidSubString() {
    let BidString = "";
    let BidString_Left = "";
    let BidString_Right = "";
    let AskString = "";
    let AskString_Left = "";
    let AskString_Right = "";
    let OpenString = "";
    let OpenString_Left = "";
    let OpenString_Right = "";
    let PriceString = "";
    let PriceString_Left = "";
    let PriceString_Right = "";
    let IncString = "";
    let FloatNum = 0;

    if (!this.LastInfo || !this.Commodity) {
      return {
        BidString,
        BidString_Left,
        BidString_Right,
        AskString,
        AskString_Left,
        AskString_Right,
        OpenString,
        OpenString_Left,
        OpenString_Right,
        PriceString,
        PriceString_Left,
        PriceString_Right,
        IncString,
        FloatNum,
      };
    }

    let LastInfo = this.LastInfo;
    // let Split = this.Commodity.PriceScale[0].TickPrice.toString().split(".");
    // if (Split.length == 2) FloatNum = Split[1].length;
    FloatNum = this.Commodity.FloatNum;

    BidString = LastInfo.BidPrice1.toFixed(FloatNum).toString();
    BidString_Left = BidString.substring(0, BidString.length - 2);
    BidString_Right = BidString.substring(
      BidString.length - 2,
      BidString.length
    );

    AskString = LastInfo.AskPrice1.toFixed(FloatNum).toString();
    AskString_Left = AskString.substring(0, AskString.length - 2);
    AskString_Right = AskString.substring(
      AskString.length - 2,
      AskString.length
    );

    OpenString = LastInfo.OpenPrice.toFixed(FloatNum).toString();
    OpenString_Left = OpenString.substring(0, OpenString.length - 2);
    OpenString_Right = OpenString.substring(
      OpenString.length - 2,
      OpenString.length
    );

    PriceString = LastInfo.ClosePrice.toFixed(FloatNum).toString();
    PriceString_Left = PriceString.substring(0, PriceString.length - 2);
    PriceString_Right = PriceString.substring(
      PriceString.length - 2,
      PriceString.length
    );

    let Inc = LastInfo.ClosePrice - LastInfo.PreClosePrice;
    let IncRate =
      (LastInfo.ClosePrice - LastInfo.PreClosePrice) / LastInfo.PreClosePrice;
    let UpDown = Inc < 0 ? "▼" : "▲";
    let szInc = Math.abs(Inc).toFixed(FloatNum).toString();
    let szIncRate = Math.abs(IncRate).toFixed(2).toString() + "%";
    IncString = `${UpDown}${szInc} ${szIncRate}`;
    return {
      BidString,
      BidString_Left,
      BidString_Right,
      AskString,
      AskString_Left,
      AskString_Right,
      OpenString,
      OpenString_Left,
      OpenString_Right,
      PriceString,
      PriceString_Left,
      PriceString_Right,
      IncString,
      FloatNum,
    };
  }

  public Clear() {
    this.m_TickBuffer.length = 0;
    this.TickBuffer.length = 0;
    this.ClearHistory();
  }

  public GetHistory(
    _HType: HistoryDataType | "Tick" | "Minute" | "Day" | undefined
  ): kcHistoryCollection | undefined {
    let mdHistoryCollection: kcHistoryCollection | undefined = undefined;
    let bRet = true;
    if (_HType !== undefined) {
      if (_HType === HistoryDataType.Tick || _HType === "Tick") {
        mdHistoryCollection = this.m_HistoryTick;
      } else if (_HType === HistoryDataType.Minute || _HType === "Minute") {
        mdHistoryCollection = this.m_HistoryMin;
      } else if (_HType === HistoryDataType.Day || _HType === "Day") {
        mdHistoryCollection = this.m_HistoryDay;
      }
    }

    return mdHistoryCollection;
  }
  public OnHistory(_HType: HistoryDataType, _mlOHLC: kcHistoryOHLCModel[]) {
    switch (_HType) {
      case HistoryDataType.Tick:
        return this.m_HistoryTick.OnHistoryOHLC(_mlOHLC);
      case HistoryDataType.Minute:
        return this.m_HistoryMin.OnHistoryOHLC(_mlOHLC);
      case HistoryDataType.Day:
        return this.m_HistoryDay.OnHistoryOHLC(_mlOHLC);
    }
  }
  public ClearHistory() {
    this.m_HistoryTick.Clear();
    this.m_HistoryMin.Clear();
    this.m_HistoryDay.Clear();
  }
}
