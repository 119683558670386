import Endpoint from "./Endpoint";
import Message from "./Message";

export default class LoadBalancer {
  endpoints: Endpoint[] = [];
  current: number = 0;
  isActive: boolean = false;
  writeActivated: any = undefined;

  constructor() {}

  attach = (endpoint: Endpoint) => {
    this.endpoints.push(endpoint);

    if (!this.isActive) {
      this.isActive = true;
      if (this.writeActivated != undefined) this.writeActivated();
    }
  };

  terminated = (endpoint: Endpoint) => {
    var index = this.endpoints.indexOf(endpoint);

    if (this.current == this.endpoints.length - 1) {
      this.current = 0;
    }

    this.endpoints.splice(index, 1);
  };

  send = (message: Message) => {
    if (this.endpoints.length == 0) {
      this.isActive = false;
      return false;
    }

    this.endpoints[this.current].write(message);
    this.current = (this.current + 1) % this.endpoints.length;

    return true;
  };

  getHasOut = () => {
    //if (inprogress) return true; // 不知道inprogress是什麼

    return this.endpoints.length > 0;
  };
}
