import moment, { Moment } from "moment";

// export enum JWTLoginState {
//   None = 0x00,
//   Viewers = 0x01, // 觀摩密碼登入
//   Trader = 0x02, // 正規密碼登入
//   Admin = 0x04, // SuperAccount
// }
const _JWTLoginState = ["None", "User", "Admin"] as const;
export type JWTLoginState = typeof _JWTLoginState[number];

export default class JWTPayloadModel {
  constructor(props?: object) {
    if (props) Object.assign(this, { ...props });
  }

  iss: string = ""; // 發出者
  iat: number = 0; // 創造時間
  exp: number = 0; // 到期時間
  Account: string = "";
  State: JWTLoginState = "None";

  static IatTime = (md: JWTPayloadModel) => {
    return moment(md.iat * 1000);
  };
  static ExpTime = (md: JWTPayloadModel) => {
    return moment(md.exp * 1000);
  };

  static isJWTLoginState(_State: any) {
    return _JWTLoginState.includes(_State);
  }
  static Validate_State(_State: string): boolean {
    if (!JWTPayloadModel.isJWTLoginState(_State)) return false;
    if ((_State as JWTLoginState) === "None") return false;
    return true;
  }
}
