import * as React from "react";
import { StyleSheet, ScrollView, SafeAreaView, Platform } from "react-native";
import { Text, View } from "../components/Themed";
import { kcSetState, kcSetUnmount, StringConverter } from "../kcExternal";
import { Button } from "react-native-elements";
import { ClearStorage, GetAccount, LogOut } from "../kcTransfer/kcTrade";
import { BottomTabParamList, TabSettingParamList } from "../types";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import { CompositeNavigationProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { kcColorObj, kcColor } from "../constants/Colors";
import KC_SettingScreenAccInfo from "../components/KC_SettingScreenAccInfo";
import * as kcAutoReLoad from "../navigation/AutoReLoadApp";
import { Moment } from "moment";
import { ChangeLocale, GetText } from "../Locales";
import { MaterialIcons } from "@expo/vector-icons";

const DebugMode = false;

type IProp = {
  route: any;
  navigation: CompositeNavigationProp<
    StackNavigationProp<TabSettingParamList, "TabSettingScreen">,
    BottomTabNavigationProp<BottomTabParamList, "TabSetting">
  >;
  //navigation: BottomTabNavigationProp<BottomTabParamList>;
};
type IState = { FixedUpdate: boolean };

class SettingScreen extends React.Component<IProp, IState> {
  state: IState = { FixedUpdate: false };

  UpdateUI = () => {
    kcSetState(this, { FixedUpdate: !this.state.FixedUpdate });
  };

  componentDidMount() {
    this.props.navigation.setOptions({
      headerTitle: this.Render_HeaderTitle,
    });

    if (DebugMode) {
      kcAutoReLoad.SetCallback((Time) => {
        this.UpdateUI();
      });
    }
  }

  componentWillUnmount() {
    kcSetUnmount(this, true);
  }

  LogOut = () => {
    LogOut();
    setTimeout(() => {
      kcAutoReLoad.ReLoadApp();
    }, 100);
  };
  ClearStorage = () => {
    ClearStorage();
    setTimeout(() => {
      kcAutoReLoad.ReLoadApp();
    }, 100);
  };

  private TimeString = (_Time: Moment) => {
    // return _Time.format("YYYY/MM/DD HH:mm:ss.SSS");
    return _Time.format("HH:mm:ss.SSS");
  };

  private Render_HeaderTitle = () => {
    return (
      <View
        style={{
          backgroundColor: "#00000000",
          //flex: 1,
          width: 300,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            backgroundColor: "#00000000",
            color: kcColor("Title"),
            fontSize: 18,
          }}
          selectable={false}
        >
          {GetText("Screen_Account")}
        </Text>
      </View>
    );
  };

  render() {
    let bIsKCAdmin =
      GetAccount() === "kcAdmin9987" || GetAccount() === "ft2006003";

    return (
      <SafeAreaView
        style={{
          backgroundColor: kcColor("Background"),
          flex: 1,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <ScrollView style={{ backgroundColor: kcColor("Background") }}>
          <View
            style={{
              backgroundColor: "#00000000",
              //backgroundColor: "#ffffffff",
              alignSelf: "center",

              //justifyContent: "center",
              width: "100%",
              maxWidth: "600px",
              height: "calc( 100vh - 120px )",
            }}
          >
            <Button
              style={{ margin: 20, marginBottom: 0, marginTop: 15 }}
              buttonStyle={{
                backgroundColor: "#ffffff30",
                borderRadius: 7,
                height: "30px",
              }}
              onPress={ChangeLocale}
              title={
                <View
                  style={{
                    flexDirection: "row",
                    backgroundColor: "#00000000",
                    alignItems: "center",
                  }}
                >
                  <MaterialIcons
                    name="language"
                    size={20}
                    color={kcColor("Value")}
                  />
                  <Text
                    selectable={false}
                    style={{
                      borderRadius: 7,
                      paddingHorizontal: 3,
                      paddingVertical: 3,
                      backgroundColor: "#00000000",
                      color: kcColor("Value"),
                      fontSize: 16,
                    }}
                  >
                    {GetText("ChangeLocale")}
                  </Text>
                </View>
              }
            />
            <KC_SettingScreenAccInfo />

            <Button
              style={{ margin: 20, marginBottom: 0, marginTop: 15 }}
              buttonStyle={{
                backgroundColor: kcColor("Red"),
                borderRadius: 15,
              }}
              onPress={this.LogOut}
              title={GetText("Screen_Account_Logout")}
            />
            <Button
              style={{ margin: 20, marginBottom: 0, marginTop: 15 }}
              buttonStyle={{
                backgroundColor: kcColor("Blue"),
                borderRadius: 15,
              }}
              onPress={this.ClearStorage}
              title={GetText("Screen_Account_ClearData")}
            />
            {bIsKCAdmin && (
              <Button
                style={{ margin: 20, marginBottom: 0, marginTop: 15 }}
                buttonStyle={{
                  backgroundColor: kcColor("Blue"),
                  borderRadius: 15,
                }}
                title="OnLine"
                onPress={() => {
                  if (Platform.OS == "web") {
                    window.open(
                      //"https://localhost:44353/PostApi/OnLine",
                      "https://trader.seastock-fintech.com//PostApi/OnLine",
                      "_blank"
                    );
                  }
                }}
              />
            )}
            {DebugMode && (
              <>
                <View
                  style={{
                    margin: 20,
                    backgroundColor: kcColor("TabBackground"),
                    flex: 1,
                  }}
                >
                  <Text style={{ color: "white" }}>
                    {`Start: ${this.TimeString(kcAutoReLoad.AppStartTime)}`}
                  </Text>
                  <Text style={{ color: "white" }}>
                    {`Run  : ${this.TimeString(kcAutoReLoad.RunTime)}`}
                  </Text>
                  <Text
                    style={{ color: "white" }}
                  >{`MaxDiff  : ${kcAutoReLoad.RunTimeMaxDiff_s} s`}</Text>
                  <Text style={{ color: "white" }}>{`${this.TimeString(
                    kcAutoReLoad.MaxDiff_From
                  )} - ${this.TimeString(kcAutoReLoad.MaxDiff_To)}`}</Text>

                  <Button
                    style={{ margin: 20, marginBottom: 0, marginTop: 15 }}
                    buttonStyle={{
                      backgroundColor: kcColor("Blue"),
                      borderRadius: 15,
                    }}
                    onPress={() => {
                      kcAutoReLoad.ReLoadApp();
                    }}
                    title="重新整理"
                  />
                </View>
              </>
            )}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const Colorstyles = function (_ColName?: string, _BaseValue?: any) {
  switch (_ColName) {
    case "Title":
      return {
        color: "#ffffff",
      };
    case "BuySell":
      if (_BaseValue === "Buy")
        return {
          color: "#4480EE",
        };
      else
        return {
          color: "#DA5542",
        };
    case "Value":
      if (_BaseValue > 0)
        return {
          color: "#4480EE",
        };
      else if (_BaseValue < 0)
        return {
          color: "#DA5542",
        };
      else
        return {
          color: "#C3C3C3",
        };
  }
  return {
    color: "#707070",
  };
};

const styles = StyleSheet.create({
  Row_Flex_1: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#00000000",
  },
  Col_Flex_1: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#00000000",
  },
  TextSmall: {
    fontSize: 14,
  },
  TextNormal: {
    fontSize: 16,
    fontWeight: "bold",
  },
  TextBig: {
    fontSize: 24,
    fontWeight: "bold",
  },
});
export default SettingScreen;
