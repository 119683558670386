import moment from "moment";
import { MomentExtensions } from "../kcExternal";
import { TradeTimeState } from "./kcModel_Enum";

export default class kcTradeTimeModel {
  constructor(props: object) {
    Object.assign(this, { ...props });
  }

  /* ---------------------------Member--------------------------- */
  State: TradeTimeState = TradeTimeState.UnDef;
  MinuteOffset: number = 0;

  ToString() {
    let szRet = "";
    szRet += TradeTimeState[this.State] + ", ";
    szRet += this.MinuteOffset + ", ";
    return szRet;
  }

  static TimeString(_md: kcTradeTimeModel): string {
    if (_md === undefined || _md.MinuteOffset === undefined) return "";

    let MinuteOffset = _md.MinuteOffset;
    if (MinuteOffset < 0) MinuteOffset += 1440;
    if (MinuteOffset >= 1440) MinuteOffset -= 1440;

    let Hour = MinuteOffset / 60;
    let Minute = MinuteOffset % 60;

    let HH = Hour.toFixed();
    let mm = Minute.toFixed();

    if (HH.length == 1) HH = "0" + HH;
    if (mm.length == 1) mm = "0" + mm;

    let szRet = `${HH} : ${mm}`;

    return szRet;
  }

  static GetTradeNumber(_aTradeTimes: kcTradeTimeModel[]) {
    if (_aTradeTimes === undefined || _aTradeTimes.length == 0) return 0;

    let nNumber = 0;
    let bHasOpen = false;
    for (let i = 0; i < _aTradeTimes.length; i++) {
      let md = _aTradeTimes[i];
      if (
        md === undefined ||
        md.State === undefined ||
        md.MinuteOffset === undefined
      )
        continue;

      if (md.State === TradeTimeState.Open) bHasOpen = true;
      if (md.State === TradeTimeState.Close && bHasOpen) {
        nNumber++;
        bHasOpen = false;
      }
    }
    return nNumber;
  }
  static GetTradeSet(
    _aTradeTimes: kcTradeTimeModel[],
    _Idx: number
  ): { mdOpen: kcTradeTimeModel; mdClose: kcTradeTimeModel } | undefined {
    if (_aTradeTimes === undefined || _aTradeTimes.length == 0)
      return undefined;

    let nNumber = 0;
    let mdOpen: kcTradeTimeModel | undefined = undefined;
    let mdClose: kcTradeTimeModel | undefined = undefined;
    for (let i = 0; i < _aTradeTimes.length; i++) {
      let md = _aTradeTimes[i];
      if (
        md === undefined ||
        md.State === undefined ||
        md.MinuteOffset === undefined
      )
        continue;

      if (md.State === TradeTimeState.Open) mdOpen = md;
      if (md.State === TradeTimeState.Close && mdOpen !== undefined) {
        if (nNumber == _Idx) {
          mdClose = md;
          break;
        }
        nNumber++;
        mdOpen = undefined;
      }
    }

    if (mdOpen !== undefined && mdClose !== undefined) {
      return {
        mdOpen: new kcTradeTimeModel(mdOpen),
        mdClose: new kcTradeTimeModel(mdClose),
      };
    }
    return undefined;
  }
  static ToDisplayString(
    _Parem: { mdOpen: kcTradeTimeModel; mdClose: kcTradeTimeModel },
    _szTimeZone: string,
    _bLocalTime: boolean
  ) {
    if (
      _Parem === undefined ||
      _Parem.mdOpen === undefined ||
      _Parem.mdClose === undefined
    )
      return undefined;

    let tOpen = this.GetTime(
      _Parem.mdOpen.MinuteOffset,
      _szTimeZone,
      _bLocalTime
    );
    let tClose = this.GetTime(
      _Parem.mdClose.MinuteOffset,
      _szTimeZone,
      _bLocalTime
    );

    return `${this.HHmmString(tOpen)} - ${this.HHmmString(tClose)}`;
  }
  private static GetTime(
    _MinuteOffset: number,
    _szTimeZone: string,
    _bLocalTime: boolean
  ) {
    let Time = moment().tz(_szTimeZone);
    Time = MomentExtensions.FloorToDay(Time);
    Time = Time.add({ minutes: _MinuteOffset });
    if (_bLocalTime) Time = MomentExtensions.ToLocalTime(Time);

    return { HH: Time.hours(), mm: Time.minute() };
  }
  private static HHmmString(_Param: { HH: number; mm: number }) {
    let HH = _Param.HH.toFixed();
    let mm = _Param.mm.toFixed();

    if (HH.length == 1) HH = "0" + HH;
    if (mm.length == 1) mm = "0" + mm;

    return `${HH} : ${mm}`;
  }
  /* ---------------------------static Function--------------------------- */
}
