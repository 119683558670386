import moment, { Moment } from "moment";
import { MomentExtensions } from "../kcExternal";

export default class kcAccountModel {
  constructor(props: object) {
    Object.assign(this, { ...props });
    this.FixedTime();
  }

  IsConfirm: "未啟用" | "已啟用" = "未啟用";
  RegTime: Moment = moment(0); // 註冊日期
  Deadline: Moment = moment(0); // 到期日
  Account: string = ""; // 帳號
  Name: string = ""; // 姓名
  EMail: string = ""; // EMail

  private FixedTime() {
    this.RegTime = MomentExtensions.Parse(this.RegTime);
    this.Deadline = MomentExtensions.Parse(this.Deadline);
  }

  public Update(_mdAccount: kcAccountModel) {
    Object.assign(this, { ..._mdAccount });
    this.FixedTime();
  }
}
