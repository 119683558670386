import { DependencyList, useRef } from "react";

// export const useKcInit = (Init: () => void) => {
//    const InitRef = React.useRef(false);
//    if (InitRef.current) return;
//    InitRef.current = true;
//    Init();
// };

export const useKcEffect = (Func: () => void, deps: DependencyList) => {
   const preRef = useRef<DependencyList | undefined>(undefined);
   const predeps = preRef.current;
   if (IsDependencyUpdated(deps, predeps)) {
      Func();
      preRef.current = deps;
   }
};

function IsDependencyUpdated(now: DependencyList, prev?: DependencyList): boolean {
   if (prev == undefined) return true;
   if (prev.length !== now.length) return true;

   for (let i = 0; i < now.length; i++) {
      if (prev[i] !== now[i]) {
         return true;
      }
   }

   return false;
}
