import TECPSetting, { KLineSetting } from ".";
import { kcColor } from "../../constants/Colors";

export default class VolSetting {
  constructor(props: object) {
    Object.assign(this, { ...props });
  }

  // UpKColor: string = kcColor("Up");
  // DownKColor: string = kcColor("Down");
  // EqualKColor: string = kcColor("Yellow");
  public get UpKColor() {
    return kcColor("Up");
  }
  public set UpKColor(_Color: string) {}
  public get DownKColor() {
    return kcColor("Down");
  }
  public set DownKColor(_Color: string) {}
  public get EqualKColor() {
    return kcColor("Yellow");
  }
  public set EqualKColor(_Color: string) {}

  public Clone(): VolSetting {
    return new VolSetting(this);
  }
  public SetSetting(_Setting: VolSetting) {
    Object.assign(this, _Setting);
  }
}
