/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from "expo-linking";
import { LinkingOptions } from "@react-navigation/native";

const RouteConfig: LinkingOptions = {
  prefixes: [Linking.makeUrl("/")],
  config: {
    // initialRouteName: "Root",
    screens: {
      Login: "Login",
      Root: {
        initialRouteName: "TabQuote",
        screens: {
          TabQuote: {
            initialRouteName: "TabQuoteScreen",
            screens: {
              TabQuoteScreen: "Quote",
              TabQuoteScreen_Add: "CommodityAdd",
              TabQuoteScreen_Modify: "CommodityModify",
              TabQuoteScreen_Info: "CommodityInfo",
            },
          },
          TabTECP: {
            initialRouteName: "TabTECPScreen",
            screens: {
              TabTECPScreen: "TECP",
              TabTECPScreen_Info: "TECP_CommodityInfo",
              TabTECPScreen_Select: "TECP_Commodity",
              TabTECPScreen_Setting: "TECP_Setting",
              // TabTECPScreen_Setting_BBand: "TECP_Setting_BBand",
              TabTECPScreen_Setting_G_HLine: "TECP_Setting_G_HLine",
              // TabTECPScreen_Setting_KD: "TECP_Setting_KD",
              TabTECPScreen_Setting_KLine_Color: "TECP_Setting_KLine_Color",
              TabTECPScreen_Setting_KLine_Type: "TECP_Setting_KLine_Type",
              // TabTECPScreen_Setting_MA: "TECP_Setting_MA",
              // TabTECPScreen_Setting_MACD: "TECP_Setting_MACD",
              // TabTECPScreen_Setting_RSI: "TECP_Setting_RSI",
            },
          },
          TabSetting: {
            initialRouteName: "TabSettingScreen",
            screens: {
              TabSettingScreen: "Setting",
            },
          },
        },
      },
      NotFound: "*",
    },
  },
};

export default RouteConfig;
