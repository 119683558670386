import { IP_Quote_Dealer, IP_Quote_Subscriber, CommandType, CommodityChangeType, SystemSubscriberTopic_CommodityChange, SystemSubscriberTopic_LastInfoUpdate, SystemSubscriberTopic_ServerMessage, HistoryDataType, QuoteHistoryRequestMode, LdfApiAwakeURL, ConnectState } from "./InternalDefine";
import { Dealer, Message, Subscriber } from "../TSMQ";
import { kcTickModel, kcLastInfoModel, kcCommodityModel, kcHistoryTickModel, kcHistoryOHLCModel, kcDepthModel } from "../kcModel";
import * as kcTransModel from "./kcTransModel";
import { CompressionExtensions } from "../kcExternal";
import { ConnectionState, delOnConnection } from "../TSMQ/Dealer";
import { GoLoginScreen } from "../navigation/RootNavigation";
import { GetSessionToken, LogOut, TokenHandel } from "./kcTrade";

export type TickCallback = (_szStockCode: string, _mdTick: kcTickModel) => void;
export type DepthCallback = (_szStockCode: string, _mdDepth: kcDepthModel) => void;
export type LastInfoCallback = (_mlLastInfos: kcLastInfoModel[]) => void;
export type CommodityListCallback = (_mlCommodityList: kcCommodityModel[]) => void;
export type CommodityChangeCallback = (_mdCommodity: kcCommodityModel, _ChangeType: CommodityChangeType) => void;
export type HistoryCallback = (StockCode: string, HType: HistoryDataType, DayNumber: number, mlOHLC: kcHistoryOHLCModel[]) => void;

var SendingAwake: boolean = false;

var m_bDealerInited: boolean = false;
var m_bSubscriberInited: boolean = false;
var sQDealer: Dealer | undefined = undefined;
var sQSubscriber: Subscriber | undefined = undefined;
var dicCommodity: Map<string, kcCommodityModel> = new Map<string, kcCommodityModel>();

var OnTickCallback: TickCallback | undefined;
var OnDepthCallback: DepthCallback | undefined;
var OnLastInfoCallback: LastInfoCallback | undefined;
var OnCommodityListCallback: CommodityListCallback | undefined;
var OnCommodityChangeCallback: CommodityChangeCallback | undefined;
var OnHistoryCallback: HistoryCallback | undefined;
var OnQuoteReadyCallback: () => void | undefined;

//   "-----------------------------kcQuote In-----------------------------"

export var InitializeCallback = (_OnTickCallback?: TickCallback, _OnDepthCallback?: DepthCallback, _OnLastInfoCallback?: LastInfoCallback, _OnCommodityListCallback?: CommodityListCallback, _OnCommodityChangeCallback?: CommodityChangeCallback, _OnHistoryCallback?: HistoryCallback) => {
   OnTickCallback = _OnTickCallback;
   OnDepthCallback = _OnDepthCallback;
   OnLastInfoCallback = _OnLastInfoCallback;
   OnCommodityListCallback = _OnCommodityListCallback;
   OnCommodityChangeCallback = _OnCommodityChangeCallback;
   OnHistoryCallback = _OnHistoryCallback;
};

export var SetQuoteReadyCallback = (_OnQuoteReadyCallback: () => void | undefined) => {
   OnQuoteReadyCallback = _OnQuoteReadyCallback;
};

export var InitializeSocket = () => {
   InitializeDealer();
   if (!m_bSubscriberInited) InitializeSubscriber();
};

/* ------------------------------------------------------------------------- */
// Dealer
var InitializeDealer = () => {
   if (!m_bDealerInited) {
      if (sQDealer == undefined) sQDealer = new Dealer(On_Dealer_Receive, On_Dealer_Connection);

      sQDealer.connect(IP_Quote_Dealer);
      m_bDealerInited = true;
   }

   //Quote_Request_CommodityList();
};
var On_Dealer_Connection: delOnConnection = (_State, _Address) => {
   // 連線失敗, 嘗試喚醒後端
   if (_State === ConnectionState.ConnectionFail) {
      if (SendingAwake) return;
      SendingAwake = true;

      fetch(LdfApiAwakeURL).finally(() => {
         SendingAwake = false;
      });
   } else if (_State === ConnectionState.Connected) {
      Trade_OnConnection?.call(this, _State, _Address);
   } else if (_State === ConnectionState.DisConnected) {
      sQSubscriber?.disconnect(IP_Quote_Subscriber);
      m_bSubscriberInited = false;
   }
};

var On_Dealer_Receive = (msg: Uint8Array[]) => {
   if (msg.length < 2) return;

   let CTypeBuffer: Uint8Array | undefined = msg.shift(); // 回傳並移除陣列第一個元素
   if (CTypeBuffer == undefined) return;

   let dv = new DataView(CTypeBuffer.buffer);
   let nCType = dv.getUint32(0, true) as CommandType;

   switch (nCType) {
      case CommandType.Connection:
         return On_Dealer_Receive_Connection(msg);
      case CommandType.CommodityList:
         return On_Dealer_Receive_CommodityList(msg);
      case CommandType.LastInfo:
         return On_Dealer_Receive_LastInfo(msg);
      case CommandType.HistoryData:
         return On_Dealer_Receive_HistoryData(msg);
      case CommandType.Login:
         return OnLoginResault?.call(this, msg);
      case CommandType.Account:
         return OnGetAccountResault?.call(this, msg);
   }
};
var On_Dealer_Receive_Connection = (msg: Uint8Array[]) => {
   let CTypeBuffer: Uint8Array | undefined = msg.shift(); // 回傳並移除陣列第一個元素
   if (CTypeBuffer == undefined) return;

   let dv = new DataView(CTypeBuffer.buffer);
   let nCType = dv.getInt32(0, true) as ConnectState;

   switch (nCType) {
      case ConnectState.Initialize: {
         SendGetAccount?.call(this);
         break;
      }
      case ConnectState.KickOut: {
         setTimeout(() => {
            sQDealer?.disconnect(IP_Quote_Dealer);
            m_bDealerInited = false;
            sQSubscriber?.disconnect(IP_Quote_Subscriber);
            m_bSubscriberInited = false;
            LogOut();
         }, 100);
         break;
      }
   }
};
var On_Dealer_Receive_CommodityList = (msg: Uint8Array[]) => {
   if (!msg || msg.length < 2) return;
   let szJson = CompressionExtensions.Zlib_UnZip_toString(msg[0]);
   let mdResault: kcTransModel.TokenResault = JSON.parse(szJson);
   if (!mdResault) return;

   let bToken = TokenHandel(mdResault.Token); // 處理Token(更新狀態,存檔)
   let StateOK: boolean = bToken;

   if (StateOK) {
      let mlCommodity = kcCommodityModel.CreateList_FromJSon(msg[1]);
      if (!mlCommodity) return;

      mlCommodity.forEach((md) => {
         dicCommodity.set(md.StockCode, md);
      });

      if (OnCommodityListCallback) OnCommodityListCallback(mlCommodity);
      if (OnQuoteReadyCallback) OnQuoteReadyCallback();
   } else {
      GoLoginScreen();
   }
};
var On_Dealer_Receive_LastInfo = (msg: Uint8Array[]) => {
   if (!msg || msg.length < 2) return;
   let szJson = CompressionExtensions.Zlib_UnZip_toString(msg[0]);
   let mdResault: kcTransModel.TokenResault = JSON.parse(szJson);
   if (!mdResault) return;

   let bToken = TokenHandel(mdResault.Token); // 處理Token(更新狀態,存檔)
   let StateOK: boolean = bToken;

   if (StateOK) {
      let mlLastInfo = kcLastInfoModel.CreateList_FromJSon(msg[1]);
      if (!mlLastInfo) return;
      if (OnLastInfoCallback) OnLastInfoCallback(mlLastInfo);
   } else {
      GoLoginScreen();
   }
};
var On_Dealer_Receive_HistoryData = (msg: Uint8Array[]) => {
   if (!msg || msg.length < 1) return;
   let szJson = CompressionExtensions.Zlib_UnZip_toString(msg[0]);
   let mdResault: kcTransModel.Quote_QuoteHistoryResault = JSON.parse(szJson);

   if (!mdResault) return;

   let bToken = TokenHandel(mdResault.Token); // 處理Token(更新狀態,存檔)
   let StateOK: boolean = bToken;

   if (!StateOK) GoLoginScreen();

   let StockCode = mdResault.StockCode;
   let HType = mdResault.HType;
   let DayNumber = mdResault.DayNumber ? mdResault.DayNumber : -1;
   let mlOHLC: kcHistoryOHLCModel[] = [];

   let mdCommodity = dicCommodity.get(StockCode);
   if (!mdCommodity) {
      return;
   }

   let bState = false;
   if (mdResault.State === kcTransModel.ResaultState.Ok && msg.length > 1) {
      let DataBuffer = CompressionExtensions.Zlib_UnZip(msg[1]);

      if (mdResault.HType === HistoryDataType.Tick) {
         let mlTick = kcHistoryTickModel.FromListBuffer(DataBuffer, mdCommodity.TimeZone);
         mlOHLC = kcHistoryTickModel.ToOHLCList(mlTick);
         bState = true;
      } else if (mdResault.HType === HistoryDataType.Minute || mdResault.HType === HistoryDataType.Day) {
         mlOHLC = kcHistoryOHLCModel.FromListBuffer(DataBuffer, mdCommodity.TimeZone);
         bState = true;
      }
   }

   if (bState) {
      OnHistoryCallback?.call(this, StockCode, HType, DayNumber, mlOHLC);
      // 送出去
   } else {
      // Request錯誤訊息
   }
};

export var Quote_Request_CommodityList = () => {
   let SessionToken = GetSessionToken();
   if (!sQDealer || !SessionToken) return;

   let CType: CommandType = CommandType.CommodityList;
   let mdMsg: Message = new Message();

   let mdParma: kcTransModel.TokenParam = {
      Token: SessionToken,
      RequestKey: _GetRequestKey(),
   };

   let szJson = JSON.stringify(mdParma);
   let ZipBuffer = CompressionExtensions.Zlib_Zip_byString(szJson);

   mdMsg.addBuffer(_ToCommandTypeBuffer(CType));
   mdMsg.addBuffer(ZipBuffer);
   sQDealer.send(mdMsg);
};
export var Quote_Request_LastInfo = (StockCode?: string | string[]) => {
   let SessionToken = GetSessionToken();
   if (!sQDealer || !SessionToken) return;

   let CType: CommandType = CommandType.LastInfo;
   let szStockCodes: string = "";
   if (typeof StockCode === "undefined") szStockCodes = "*";
   else if (typeof StockCode === "string") szStockCodes = StockCode;
   else if (Array.isArray(StockCode)) {
      StockCode.forEach((szStockCode, Idx) => {
         if (Idx > 0) szStockCodes += "|";
         szStockCodes += szStockCode;
      });
   }

   let mdParma: kcTransModel.Quote_LastInfoParam = {
      Token: SessionToken,
      RequestKey: _GetRequestKey(),
      StockCodes: szStockCodes,
   };
   let szJson = JSON.stringify(mdParma);
   let ZipBuffer = CompressionExtensions.Zlib_Zip_byString(szJson);

   let mdMsg: Message = new Message();
   mdMsg.addBuffer(_ToCommandTypeBuffer(CType));
   mdMsg.addBuffer(ZipBuffer);
   sQDealer.send(mdMsg);
};
export var Quote_Request_History = (StockCode: string, HType: HistoryDataType, nDayNumber: number) => {
   let SessionToken = GetSessionToken();
   if (!sQDealer || !SessionToken) return;

   let CType: CommandType = CommandType.HistoryData;
   let mdMsg: Message = new Message();

   let mdParma: kcTransModel.Quote_QuoteHistoryParam = {
      Token: SessionToken,
      RequestKey: _GetRequestKey(),
      StockCode: StockCode,
      HType: HType,
      RequestMode: QuoteHistoryRequestMode.DayNumber,
      DayNumber: nDayNumber,
   };
   let szJson = JSON.stringify(mdParma);
   let ZipBuffer = CompressionExtensions.Zlib_Zip_byString(szJson);

   mdMsg.addBuffer(_ToCommandTypeBuffer(CType));
   mdMsg.addBuffer(ZipBuffer);
   sQDealer.send(mdMsg);
};
export var Quote_Request_History_ByRange = (StockCode: string, HType: HistoryDataType, ST: string, ET: string) => {
   let SessionToken = GetSessionToken();
   if (!sQDealer || !SessionToken) return;

   let CType: CommandType = CommandType.HistoryData;
   let mdMsg: Message = new Message();

   let mdParma: kcTransModel.Quote_QuoteHistoryParam = {
      Token: SessionToken,
      RequestKey: _GetRequestKey(),
      StockCode: StockCode,
      HType: HType,
      RequestMode: QuoteHistoryRequestMode.TimeRange,
      StartTime: ST,
      EndTime: ET,
   };
   let szJson = JSON.stringify(mdParma);
   let ZipBuffer = CompressionExtensions.Zlib_Zip_byString(szJson);

   mdMsg.addBuffer(_ToCommandTypeBuffer(CType));
   mdMsg.addBuffer(ZipBuffer);
   sQDealer.send(mdMsg);
};

var _ToCommandTypeBuffer = (_CType: CommandType): Uint8Array => {
   let Buf: Uint8Array = new Uint8Array(4);
   let dv = new DataView(Buf.buffer);
   dv.setUint32(0, _CType, true);
   return Buf;
};

/* ------------------------------------------------------------------------- */
// Subscriber
var InitializeSubscriber = () => {
   if (m_bSubscriberInited) return;

   if (sQSubscriber == undefined) sQSubscriber = new Subscriber(On_Subscriber_Receive);
   sQSubscriber.connect(IP_Quote_Subscriber);
   m_bSubscriberInited = true;

   // 訂閱系統資訊
   sQSubscriber.subscribe(SystemSubscriberTopic_CommodityChange);
   sQSubscriber.subscribe(SystemSubscriberTopic_LastInfoUpdate);
   sQSubscriber.subscribe(SystemSubscriberTopic_ServerMessage);
};
var On_Subscriber_Receive = (Topic: string, msg: Uint8Array[]) => {
   if (msg.length < 2) return;

   let CTypeBuffer: Uint8Array | undefined = msg.shift(); // 回傳並移除陣列第一個元素
   if (CTypeBuffer == undefined) return;

   let dv = new DataView(CTypeBuffer.buffer);
   let nCType = dv.getUint32(0, true) as CommandType;

   switch (nCType) {
      case CommandType.Tick:
         return On_Subscriber_On_Tick(Topic, msg);
      case CommandType.Best5:
         return On_Subscriber_On_Depth(Topic, msg);
      case CommandType.LastInfo:
         return On_Subscriber_On_LastInfo(Topic, msg);
      case CommandType.CommodityChange_Create:
         return On_Subscriber_On_CommodityCreate(Topic, msg);
      case CommandType.CommodityChange_Modify:
         return On_Subscriber_On_CommodityModify(Topic, msg);
      case CommandType.CommodityChange_Delete:
         return On_Subscriber_On_CommodityDelete(Topic, msg);
      default:
         return;
   }
};

var On_Subscriber_On_Tick = (Topic: string, msg: Uint8Array[]) => {
   let mdCommodity = dicCommodity.get(Topic);
   if (!mdCommodity) {
      return;
   }

   let mdTick = kcTickModel.CreateFromBuffer(msg[0], mdCommodity.TimeZone);
   if (mdTick && OnTickCallback) {
      OnTickCallback(Topic, mdTick);
   }
};
var On_Subscriber_On_Depth = (Topic: string, msg: Uint8Array[]) => {
   let mdCommodity = dicCommodity.get(Topic);
   if (!mdCommodity) {
      return;
   }

   let mdDepth = kcDepthModel.CreateFromBuffer(msg[0], mdCommodity.TimeZone);
   if (mdDepth && OnDepthCallback) {
      OnDepthCallback(Topic, mdDepth);
   }
};

var On_Subscriber_On_LastInfo = (Topic: string, msg: Uint8Array[]) => {
   let mlLastInfo = kcLastInfoModel.CreateList_FromJSon(msg[0]);
   if (OnLastInfoCallback) OnLastInfoCallback(mlLastInfo);
};
var On_Subscriber_On_CommodityCreate = (Topic: string, msg: Uint8Array[]) => {
   let mdCommodity = kcCommodityModel.Create_FromJSon(msg[0]);
   if (!mdCommodity) return;

   dicCommodity.set(mdCommodity.StockCode, mdCommodity);

   if (OnCommodityChangeCallback) OnCommodityChangeCallback(mdCommodity, CommodityChangeType.Create);
};
var On_Subscriber_On_CommodityModify = (Topic: string, msg: Uint8Array[]) => {
   let mdCommodity = kcCommodityModel.Create_FromJSon(msg[0]);
   if (!mdCommodity) return;

   dicCommodity.set(mdCommodity.StockCode, mdCommodity);

   if (OnCommodityChangeCallback) OnCommodityChangeCallback(mdCommodity, CommodityChangeType.Modify);
};
var On_Subscriber_On_CommodityDelete = (Topic: string, msg: Uint8Array[]) => {
   let mdCommodity = kcCommodityModel.Create_FromJSon(msg[0]);
   if (!mdCommodity) return;

   dicCommodity.delete(mdCommodity.StockCode);

   if (OnCommodityChangeCallback) OnCommodityChangeCallback(mdCommodity, CommodityChangeType.Delete);
};

var SubscriberTick = (Stocks?: string | string[]) => {
   if (sQSubscriber) {
      InitializeSubscriber();
      if (typeof Stocks === "undefined") sQSubscriber.subscribe("");
      else if (typeof Stocks === "string") sQSubscriber.subscribe(Stocks);
      else if (Array.isArray(Stocks)) Stocks.forEach((Stock: string) => sQSubscriber?.subscribe(Stock));
   }
};
var UnSubscriberTick = (Stocks?: string | string[]) => {
   if (sQSubscriber) {
      if (typeof Stocks === "undefined") sQSubscriber.unsubscribe("");
      else if (typeof Stocks === "string") sQSubscriber.unsubscribe(Stocks);
      else if (Array.isArray(Stocks)) Stocks.forEach((Stock: string) => sQSubscriber?.unsubscribe(Stock));
   }
};

// 訂閱Tick
export var Register = (Stocks?: string | string[]) => {
   if (sQDealer && sQSubscriber) {
      Quote_Request_LastInfo(Stocks);
      SubscriberTick(Stocks);
   } // 等到可以註冊時, 重新註冊
   else {
      setTimeout(Register, 1000, Stocks);
   }
};
// 反訂閱Tick
export var UnRegister = (Stocks?: string | string[]) => {
   UnSubscriberTick(Stocks);
};

var RequestKey = 20000;
var _GetRequestKey = () => {
   return RequestKey++;
};

/* -------------------------------------------------------------- */
// Loging Account 的Request搬到這邊來
type delOnMessage = (msg: Uint8Array[]) => void;
var Trade_OnConnection: delOnConnection | undefined = undefined;
var OnLoginResault: delOnMessage | undefined;
var OnGetAccountResault: delOnMessage | undefined;
var SendGetAccount: (() => void) | undefined = undefined;
export var SetAccountCallback = (_fTrade_OnConnection?: delOnConnection, _fOnLoginResault?: delOnMessage, _fOnGetAccountResault?: delOnMessage, _fSendGetAccount?: () => void) => {
   Trade_OnConnection = _fTrade_OnConnection;
   OnLoginResault = _fOnLoginResault;
   OnGetAccountResault = _fOnGetAccountResault;
   SendGetAccount = _fSendGetAccount;
};
export var Quote_SendRequest = (mdMsg: Message) => {
   InitializeDealer();

   if (!sQDealer) return;
   sQDealer.send(mdMsg);
};

InitializeSocket();
