import StringASCIIHelper from "./StringASCIIHelper";

export default class Message {
  frames: Uint8Array[] = [];
  constructor() {}

  getSize = () => {
    return this.frames.length;
  };

  // add string at the begining of the message
  prependString = (str: any) => {
    str = String(str);

    // one more byte is saved for the more byte
    var buffer = new Uint8Array(str.length);

    StringASCIIHelper.GetBytes(str, buffer);

    this.frames.splice(0, 0, buffer);
  };

  // add the string at the end of the message
  addString = (str: any) => {
    str = String(str);

    // one more byte is saved for the more byte
    var buffer = new Uint8Array(str.length);

    StringASCIIHelper.GetBytes(str, buffer);
    this.frames.push(buffer);
  };

  // pop a string from the begining of the message
  popString = () => {
    var frame = this.popBuffer();
    return StringASCIIHelper.GetString(frame);
  };

  popBuffer = () => {
    var frame = this.frames[0];
    this.frames.splice(0, 1);

    return frame;
  };

  // addd buffer at the end of the message
  addBuffer = (buffer: ArrayBuffer | Uint8Array) => {
    if (buffer instanceof ArrayBuffer) {
      this.frames.push(new Uint8Array(buffer));
    } else if (buffer instanceof Uint8Array) {
      this.frames.push(buffer);
    } else {
      // throw new 'unknown buffer type'();
    }
  };

  // return Uint8Array at location i
  getBuffer = (i: number) => {
    return this.frames[i];
  };
}
