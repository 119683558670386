import * as React from "react";
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native";
import { Text, View } from "../components/Themed";
import { MonoText, YaHeiText } from "../components/StyledText";

type IState = {};

type IProp = {
  Title: string;
  Value: any;
  style?: StyleProp<ViewStyle>;
  TitleStyles?: StyleProp<TextStyle>;
  ValueStyles?: StyleProp<TextStyle>;
};

class RowValue extends React.Component<IProp, IState> {
  _GetViewStyles = () => {
    if (this.props.style == undefined) return styles.View;
    else return this.props.style;
  };
  _GetTitleStyles = () => {
    if (this.props.TitleStyles == undefined) return styles.title;
    else return this.props.TitleStyles;
  };
  _GetValueStyles = () => {
    if (this.props.ValueStyles == undefined) return styles.Value;
    else return this.props.ValueStyles;
  };

  render() {
    return (
      <View
        style={[
          {
            flexDirection: "row",
            backgroundColor: "#00000000",
          },
          this._GetViewStyles(),
        ]}
      >
        <Text
          selectable={false}
          style={[{ backgroundColor: "#00000000" }, this._GetTitleStyles()]}
        >
          {this.props.Title}
        </Text>
        <Text
          selectable={false}
          style={[
            { flex: 1, backgroundColor: "#00000000" },
            this._GetValueStyles(),
          ]}
        >
          {this.props.Value}
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  View: {},
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#C3C3C3",
  },
  Value: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#C3C3C3",
    textAlign: "right",
  },
});

export default RowValue;
