import moment, { Moment } from "moment";
import { MomentExtensions } from "../kcExternal";
import { PriceInDepthType, PriceInDepthTypeArray } from "./kcModel_Enum";

export default class kcTickModel {
  constructor(props: object) {
    Object.assign(this, { ...props });

    this.Time = MomentExtensions.Parse(this.Time);
  }

  /* ---------------------------Member--------------------------- */
  Time: Moment = moment(0); // DateTime (8 bytes double OADate)
  ClosePrice: number = 0; // double (8 bytes double)
  Vol: number = 0; // ushort (2 bytes ushout)
  TotalVol: number = 0; // uint (4 bytes uint)
  AskPrice: number = 0; // double (8 bytes double)
  BidPrice: number = 0; // double (8 bytes double)
  Simulate: boolean = false; // 1 byte
  PriceInDepth: PriceInDepthType = "Unknow"; // 1 byte

  ToString() {
    let szRet = "";
    szRet += this.Time.utc().format("YYYY/MM/DD HH:mm:ss.SSS") + ", ";
    szRet += this.ClosePrice + ", ";
    szRet += this.Vol + ", ";
    szRet += this.TotalVol + ", ";
    return szRet;
  }

  /* ---------------------------static Function--------------------------- */
  static FixedBufferSize: number = 40;
  static CreateFromBuffer(
    bytes: Uint8Array,
    _TimeZone: string
  ): kcTickModel | undefined {
    if (bytes.length < kcTickModel.FixedBufferSize) return undefined;
    let dv = new DataView(bytes.buffer);

    let nIdx = 0;

    let dOADate: number = dv.getFloat64(nIdx, true);
    let tTime: Moment = MomentExtensions.FromOADate_WithTimeZone(
      dOADate,
      _TimeZone
    );
    nIdx += 8;

    let dClosePrice = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let sVol = dv.getUint16(nIdx, true);
    nIdx += 2;

    let sTotalVol = dv.getUint32(nIdx, true);
    nIdx += 4;

    let dAskPrice = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let dBidPrice = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let bSimulate = dv.getUint8(nIdx) != 0;
    nIdx += 1;

    let nPriceInDepthType = dv.getUint8(nIdx);
    nIdx += 1;
    let szPriceInDepthType = PriceInDepthTypeArray[nPriceInDepthType];

    let mdTick: kcTickModel = new kcTickModel({
      Time: tTime,
      ClosePrice: dClosePrice,
      Vol: sVol,
      TotalVol: sTotalVol,
      AskPrice: dAskPrice,
      BidPrice: dBidPrice,
      Simulate: bSimulate,
      PriceInDepth: szPriceInDepthType,
    });

    return mdTick;
  }
}
