import * as React from "react";
import { StyleSheet } from "react-native";
import { Text, View } from "./Themed";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { kcData } from "../kcData";
import RowValue from "../components/kcRowValue";
import { kcColor } from "../constants/Colors";
import { MaterialIcons } from "@expo/vector-icons";
import { GetAccountState } from "../kcTransfer/kcTrade";
import { kcAccountModel } from "../kcModel";
import { GetText } from "../Locales";

type IState = {
  nFixedRedraw: number; // 笨方式 用來強制繪圖
};

type IProp = {};

export default class KC_SettingScreenAccInfo extends React.Component<
  IProp,
  IState
> {
  state: IState = {
    nFixedRedraw: 0, // 笨方式 用來強制繪圖
  };

  /* ------------------------------------------------------------- */
  // Member
  nFixedRedraw: number = 0;
  PreCanUpdate: boolean = true;
  m_TradeAccount: kcAccountModel | undefined;

  /* ------------------------------------------------------------- */
  // HookFunc
  componentDidMount() {
    this.GetAccount_async();
  }

  componentWillUnmount() {
    kcSetUnmount(this, true);
  }

  private GetAccount_async = async () => {
    let mdAccount = kcData.AccountLDF();
    if (!mdAccount) {
      setTimeout(this.GetAccount_async, 100);
      return;
    }
    this.m_TradeAccount = mdAccount;
    this.FixedRedrawUI();
  };

  /* ------------------------------------------------------------- */
  private FixedRedrawUI = () => {
    this.nFixedRedraw = this.nFixedRedraw + 1;
    kcSetState(this, { nFixedRedraw: this.nFixedRedraw }); // 強制更動畫面
  };

  /* ------------------------------------------------------------- */
  render() {
    if (!this.m_TradeAccount)
      return <View style={{ height: 100, backgroundColor: "#123456" }}></View>;
    const md = this.m_TradeAccount;

    let szRegTime = md.RegTime.format("YYYY-MM-DD");
    let szDeadLineTime = md.Deadline.format("YYYY-MM-DD");
    let Account = md.Account;

    let Name = md.Name;
    let EMail = md.EMail;

    let AccountState = GetAccountState();
    let AccountStateText = "";
    let AccountStateColor = "";

    if (AccountState == "User") {
      AccountStateText = "Live";
      AccountStateColor = kcColor("Green");
    } else if (AccountState == "Admin") {
      AccountStateText = "Admin";
      AccountStateColor = kcColor("Yellow");
    } else if (AccountState == "None") {
      AccountStateText = "";
      AccountStateColor = "black";
    }

    return (
      <View
        style={{
          backgroundColor: kcColor("Background"),
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          borderColor: kcColor("Border"),
          borderWidth: 0,
          margin: 10,
          padding: 10,
          borderRadius: 15,
        }}
      >
        <MaterialIcons
          selectable={false}
          name="account-box"
          size={80}
          color={kcColor("Blue")}
        />
        <View style={styles.MainView}>
          <View
            style={{
              flex: 1,
              marginTop: 5,
              backgroundColor: "#00000000",
              flexDirection: "row-reverse",
            }}
          >
            <Text
              selectable={false}
              style={{
                borderRadius: 7,
                paddingHorizontal: 15,
                paddingVertical: 3,
                backgroundColor: AccountStateColor,
                color: "white",
              }}
            >
              {AccountStateText}
            </Text>
          </View>

          <RowValue
            Title={`${GetText("Screen_Account")} :`}
            Value={Account}
            style={{ marginTop: 5, backgroundColor: "#00000000" }}
            TitleStyles={styles.RowTitleStyles}
            ValueStyles={styles.RowValueStyles}
          ></RowValue>

          <RowValue
            Title={`${GetText("Screen_Account_Name")} :`}
            Value={Name}
            style={{ marginTop: 5, backgroundColor: "#00000000" }}
            TitleStyles={styles.RowTitleStyles}
            ValueStyles={styles.RowValueStyles}
          ></RowValue>

          {/* <RowValue
            Title="E-mail :"
            Value={EMail}
            style={{ marginTop: 5, backgroundColor: "#00000000" }}
            TitleStyles={styles.RowTitleStyles}
            ValueStyles={styles.RowValueStyles}
          ></RowValue> */}

          {/* <RowValue
            Title="註冊時間 :"
            Value={szRegTime}
            style={{ marginTop: 5, backgroundColor: "#00000000" }}
            TitleStyles={styles.RowTitleStyles}
            ValueStyles={styles.RowValueStyles}
          ></RowValue> */}

          <RowValue
            Title={`${GetText("Screen_Account_ExpiryDate")} :`}
            Value={szDeadLineTime}
            style={{ marginTop: 5, backgroundColor: "#00000000" }}
            TitleStyles={styles.RowTitleStyles}
            ValueStyles={styles.RowValueStyles}
          ></RowValue>

          {/* <RowValue
            Title="交易倍數 :"
            Value={0}
            style={{ marginTop: 5, backgroundColor: "#00000000" }}
            TitleStyles={styles.RowTitleStyles}
            ValueStyles={styles.RowValueStyles}
          ></RowValue> */}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  MainView: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    marginLeft: 10,
  },
  LeftView: {
    flex: 1,
    //paddingLeft: 32,
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  RightView: {
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  TextSmall: {
    fontSize: 14,
  },
  TextNormal: {
    fontSize: 14,
  },
  TextBig: {
    fontSize: 20,
    fontWeight: "bold",
  },

  RowTitleStyles: {
    color: kcColor("Title"),
    fontSize: 14,
  },
  RowValueStyles: {
    color: kcColor("Value"),
    fontSize: 14,
    textAlign: "right",
  },
});
