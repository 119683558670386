enum AnimationState {
  Non,
  Uping,
  Downing,
}
export default class kcAnimated {
  //private Control m_Parant;
  private m_DefaultValue: number = 0;
  private m_Value: number = 1;
  private m_AnimationTime: number = 500;
  private m_Timer?: NodeJS.Timer = undefined;
  private m_eState: AnimationState = AnimationState.Non;
  private m_LastUpdateTime: number = Date.now();
  private m_OnValueChange?: (_Value: number, _bEnd: boolean) => void =
    undefined;
  public get Value() {
    return this.m_Value;
  }
  public set Value(_value: number) {
    this.m_Value = Math.min(1, Math.max(0, _value));
  }

  constructor(_DefaultValue: 0 | 1 = 0, _AnimationTime: number = 500) {
    this.m_DefaultValue = _DefaultValue;
    this.m_Value = _DefaultValue;
    this.m_AnimationTime = _AnimationTime;
  }
  public SetCallback(
    _OnValueChange: (_Value: number, _bEnd: boolean) => void
  ): void {
    this.m_OnValueChange = _OnValueChange;
  }
  public RunUp(_AnimationTime: number | undefined = undefined): void {
    if (_AnimationTime) this.m_AnimationTime = _AnimationTime;

    if (this.m_eState == AnimationState.Non) {
      this.m_eState = AnimationState.Uping;
      this.m_LastUpdateTime = Date.now();
      this.m_Timer = setInterval(this.OnTimer_Tick, 10);
    } else this.m_eState = AnimationState.Uping;
  }
  public RunDown(_AnimationTime: number | undefined = undefined): void {
    if (_AnimationTime) this.m_AnimationTime = _AnimationTime;

    if (this.m_eState == AnimationState.Non) {
      this.m_eState = AnimationState.Downing;
      this.m_LastUpdateTime = Date.now();
      this.m_Timer = setInterval(this.OnTimer_Tick, 10);
    } else this.m_eState = AnimationState.Downing;
  }
  public Stop() {
    if (this.m_Timer) clearInterval(this.m_Timer);
    this.Value = this.m_DefaultValue;
    this.m_eState = AnimationState.Non;
  }
  private OnTimer_Tick = () => {
    let Now = Date.now();
    let TimeDif = Now - this.m_LastUpdateTime;
    this.m_LastUpdateTime = Now;

    let ValueDif = TimeDif / this.m_AnimationTime;
    if (this.m_eState == AnimationState.Uping) {
      this.m_Value = this.m_Value + ValueDif;
    }
    if (this.m_eState == AnimationState.Downing) {
      this.m_Value = this.m_Value - ValueDif;
    }

    let bEnd = false;
    if (this.m_Value >= 1) {
      bEnd = true;
      this.m_Value = 1;
      this.m_eState = AnimationState.Non;
      if (this.m_Timer) clearInterval(this.m_Timer);
    }
    if (this.m_Value <= 0) {
      bEnd = true;
      this.m_Value = 0;
      this.m_eState = AnimationState.Non;
      if (this.m_Timer) clearInterval(this.m_Timer);
    }

    if (this.m_OnValueChange) this.m_OnValueChange(this.m_Value, bEnd);
  };
  public static TranferValue(
    _NowValue: number,
    _StartValue: number,
    _EndValue: number
  ): number {
    return (_EndValue - _StartValue) * _NowValue + _StartValue;
  }
}
export class kcAnimatedOneTime {
  private m_StartValue: number = 0;
  private m_EndValue: number = 0;
  private m_AnimationTime: number = 500;
  private m_Deceleration: number = 0;

  private m_Velocity: number = 0;
  private m_Value: number = 1;
  private m_Timer?: NodeJS.Timer = undefined;
  private m_eState: AnimationState = AnimationState.Non;
  private m_StartTime: number = Date.now();
  private m_OnValueChange?: (_Value: number, _bEnd: boolean) => void =
    undefined;
  public get Value() {
    return this.m_Value;
  }
  constructor() {}
  public SetCallback(
    _OnValueChange: (_Value: number, _bEnd: boolean) => void
  ): void {
    this.m_OnValueChange = _OnValueChange;
  }
  public Start = (
    _StartValue: number,
    _EndValue: number,
    _AnimationTime: number,
    _Deceleration: number
  ) => {
    if (this.m_eState != AnimationState.Non) {
      this.Stop();
    }

    this.m_StartValue = _StartValue;
    this.m_EndValue = _EndValue;
    this.m_AnimationTime = _AnimationTime;
    this.m_Deceleration = _Deceleration;
    this.m_Velocity = this.GetDecayVelocity(
      _StartValue,
      _EndValue,
      _Deceleration,
      _AnimationTime
    );

    this.m_eState = AnimationState.Uping;
    this.m_StartTime = Date.now();
    if (this.m_Velocity == 0) {
      //console.log("Start _velocity = 0", this);
      if (this.m_OnValueChange) this.m_OnValueChange(this.m_StartValue, true);
      return;
    }

    this.m_Timer = setInterval(this.OnTimer_Tick, 10);
  };
  public Stop = () => {
    if (this.m_Timer) clearInterval(this.m_Timer);
    this.m_eState == AnimationState.Non;
  };

  private GetDecayVelocity(
    _StartValue: number,
    _EndValue: number,
    _Deceleration: number,
    _TimeDiff_ms: number
  ): number {
    let ValueDiff = _EndValue - _StartValue;
    let DecReverse = 1 - _Deceleration;
    let Exp = Math.exp(-DecReverse * _TimeDiff_ms);
    let Ret = (ValueDiff * DecReverse) / (1 - Exp);
    return Ret;
  }
  private GetDecayValue = (
    _StartValue: number,
    _velocity: number,
    _Deceleration: number,
    _TimeDiff_ms: number
  ) => {
    let DecReverse = 1 - _Deceleration;
    let Exp = Math.exp(-DecReverse * _TimeDiff_ms);
    let Ret = _StartValue + (_velocity / DecReverse) * (1 - Exp);
    return Ret;
  };
  private m_PreSendValue = 0;
  private OnTimer_Tick = () => {
    let Now = Date.now();
    let TimeDif = Now - this.m_StartTime;

    let Value = this.GetDecayValue(
      this.m_StartValue,
      this.m_Velocity,
      this.m_Deceleration,
      TimeDif
    );

    this.m_Value = Value;

    let bEnd = false;
    if (TimeDif >= this.m_AnimationTime) {
      bEnd = true;
      this.m_Value = this.m_EndValue;
      if (this.m_Timer) clearInterval(this.m_Timer);
    }

    if (this.m_OnValueChange) {
      let Diff = Math.abs(this.m_Value - this.m_PreSendValue);
      if (bEnd || Diff >= 0) {
        this.m_OnValueChange(this.m_Value, bEnd);
        this.m_PreSendValue = this.m_Value;
      }
    }
  };
}
export class kcAnimatedDecay {
  private m_fBaseValue: number = 0;
  private m_fromValue: number = 0;
  private m_velocity: number = 0;
  private m_deceleration: number = 0;
  private m_EndValue: number = 0;
  private m_Value: number = 1;
  private m_Timer?: NodeJS.Timer = undefined;
  private m_eState: AnimationState = AnimationState.Non;
  private m_StartTime: number = Date.now();
  private m_kcVelocity: kcVelocity = new kcVelocity();
  private m_OnValueChange?: (
    _BaseValue: number,
    _Value: number,
    _bEnd: boolean
  ) => void = undefined;
  public get Value() {
    return this.m_Value;
  }
  constructor() {}
  public SetCallback(
    _OnValueChange: (_BaseValue: number, _Value: number, _bEnd: boolean) => void
  ): void {
    this.m_OnValueChange = _OnValueChange;
  }
  public UpateAutoVelocity = (_VelocityValue: number) => {
    this.m_kcVelocity.Update(_VelocityValue);
  };
  public Start_AutoVelocity = (
    _fBaseValue: number,
    _fromValue: number,
    _deceleration: number,
    _reverse: boolean = false
  ) => {
    this.m_kcVelocity.Reverse = _reverse;
    this.Start(
      _fBaseValue,
      _fromValue,
      this.m_kcVelocity.Velocity,
      _deceleration
    );
    this.m_kcVelocity.ReSet();
  };
  public Start = (
    _fBaseValue: number,
    _fromValue: number,
    _velocity: number,
    _deceleration: number
  ) => {
    if (this.m_eState != AnimationState.Non) {
      this.Stop();
    }
    this.m_fBaseValue = _fBaseValue;
    this.m_fromValue = _fromValue;
    this.m_velocity = _velocity;
    this.m_deceleration = _deceleration;
    this.m_EndValue = this.GetDecayValue(
      _fromValue,
      _velocity,
      _deceleration,
      2000
    );
    this.m_eState = AnimationState.Uping;
    this.m_StartTime = Date.now();

    if (_velocity == 0) {
      //console.log("Start _velocity = 0", this);
      if (this.m_OnValueChange)
        this.m_OnValueChange(this.m_fBaseValue, this.m_fromValue, true);
      return;
    }

    this.m_Timer = setInterval(this.OnTimer_Tick, 10);
  };
  public Stop = () => {
    if (this.m_Timer) clearInterval(this.m_Timer);
    this.m_eState == AnimationState.Non;
  };
  private GetDecayValue = (
    _fromValue: number,
    _velocity: number,
    _deceleration: number,
    _TimeDiff_ms: number
  ) => {
    let Ret =
      _fromValue +
      (_velocity / (1 - _deceleration)) *
        (1 - Math.exp(-(1 - _deceleration) * _TimeDiff_ms));
    return Ret;
  };
  private OnTimer_Tick = () => {
    let Now = Date.now();
    let TimeDif = Now - this.m_StartTime;

    let Value = this.GetDecayValue(
      this.m_fromValue,
      this.m_velocity,
      this.m_deceleration,
      TimeDif
    );
    let bEnd = false;
    if (this.m_velocity > 0 && Value >= this.m_EndValue) {
      bEnd = true;
      Value = this.m_EndValue;
      if (this.m_Timer) clearInterval(this.m_Timer);
    }
    if (this.m_velocity < 0 && Value <= this.m_EndValue) {
      bEnd = true;
      Value = this.m_EndValue;
      if (this.m_Timer) clearInterval(this.m_Timer);
    }

    this.m_Value = Value;
    if (this.m_OnValueChange)
      this.m_OnValueChange(this.m_fBaseValue, this.m_Value, bEnd);
  };
}

export class kcVelocity {
  private m_Value?: number = undefined;
  private m_Time?: number = undefined;
  private m_TimeOut?: NodeJS.Timeout = undefined;
  private m_Velocity: number = 0;

  public Reverse: boolean = false;
  public get Velocity() {
    return this.Reverse ? -this.m_Velocity : this.m_Velocity;
  }

  public Update = (Value: number) => {
    if (!this.m_Value || !this.m_Time) {
      this.m_Value = Value;
      this.m_Time = Date.now();
      return;
    }
    let Now = Date.now();
    let ValueDif = Value - this.m_Value;
    let TimeDif = Now - this.m_Time; //
    if (TimeDif == 0) TimeDif = 1;
    this.m_Velocity = ValueDif / TimeDif;

    if (this.m_TimeOut) clearTimeout(this.m_TimeOut);
    this.m_TimeOut = setTimeout(() => {
      this.m_Velocity = 0;
    }, 50);
  };
  public ReSet = () => {
    this.m_Velocity = 0;
    this.m_Value = undefined;
    this.m_Time = undefined;
  };
}
