import { Device } from "../utils/kcGlobalSize";

export type kcGraphics = CanvasRenderingContext2D;

export type TextStyle = {
   Size?: number;
   Fill?: string;
   Font?: string;
   Stroke?: string;
   StrokeWidth?: number;
   HAlient?: "left" | "center" | "right";
   VAlient?: "top" | "middle" | "bottom";
};

export type Rectangle = {
   X: number;
   Y: number;
   Width: number;
   Height: number;
};

export type Point = {
   X: number;
   Y: number;
};

export type Circle = {
   X: number;
   Y: number;
   Radius: number;
};

export type Size = {
   Width: number;
   Height: number;
};

export type PathPoint = Point & {
   StartPoint?: boolean;
};

export type LinePath = {
   PathColor: string;
   PathWidth: number;
   Points: PathPoint[];
};

export type ArcTypeOption = {
   tl?: boolean;
   tr?: boolean;
   bl?: boolean;
   br?: boolean;
};

type tDrawImageParams1 = [dx: number, dy: number, _Opacity?: number];
type tDrawImageParams2 = [dx: number, dy: number, dw: number, dh: number, _Opacity?: number];
type tDrawImageParams3 = [sx: number, sy: number, sw: number, sh: number, dx: number, dy: number, dw: number, dh: number, _Opacity?: number];
type delDrawImageOverload = {
   (context: kcGraphics, image: CanvasImageSource, ...args: tDrawImageParams1): void;
   (context: kcGraphics, image: CanvasImageSource, ...args: tDrawImageParams2): void;
   (context: kcGraphics, image: CanvasImageSource, ...args: tDrawImageParams3): void;
};

const DefaultDashStyle = [5, 5];

const DrawLine = (_g: kcGraphics, _LineWidth: number, _Color: string, _Points: PathPoint[]) => {
   if (Device.PixelRatio_Enable) {
      _LineWidth *= Device.PixelRatio;
   }

   _g.beginPath();
   AddLinePath(_g, _Points);

   _g.strokeStyle = _Color;
   _g.lineWidth = _LineWidth;
   _g.stroke();
};

const DrawLine_N = (_g: kcGraphics, _Points: PathPoint[], ..._LineStyles: { Color: string; LineWidth: number; Dash?: number[] }[]) => {
   if (!Array.isArray(_LineStyles) || _LineStyles.length === 0) return;

   _g.beginPath();
   AddLinePath(_g, _Points);

   for (let style of _LineStyles) {
      _g.strokeStyle = style.Color;
      _g.lineWidth = style.LineWidth * Device.PixelRatio;
      if (style.Dash) _g.setLineDash(style.Dash.map((q) => q * Device.PixelRatio));

      _g.stroke();

      if (style.Dash) _g.setLineDash([]);
   }
};

const DrawDashLine = (_g: kcGraphics, _LineWidth: number, _Color: string, _Points: PathPoint[], _Dash: number[] = DefaultDashStyle) => {
   if (Device.PixelRatio_Enable) {
      _LineWidth *= Device.PixelRatio;
      _Dash = _Dash.map((q) => q * Device.PixelRatio);
   }

   _g.beginPath();
   AddLinePath(_g, _Points);

   _g.strokeStyle = _Color;
   _g.lineWidth = _LineWidth;
   _g.setLineDash(_Dash);
   _g.stroke();
   _g.setLineDash([]);
};

const DrawCircle = (_g: kcGraphics, _LineWidth: number, _Color: string, _Circles: Circle[]) => {
   if (Device.PixelRatio_Enable) {
      _LineWidth *= Device.PixelRatio;
   }

   _g.strokeStyle = _Color;
   _g.lineWidth = _LineWidth;

   _g.beginPath();
   AddCirclePath(_g, _Circles);
   _g.stroke();
};

const FillCircle = (_g: kcGraphics, _Color: string, _Circles: Circle[]) => {
   _g.strokeStyle = "#00000000";
   _g.lineWidth = 0;

   _g.fillStyle = _Color;

   _g.beginPath();
   AddCirclePath(_g, _Circles);
   _g.fill();
};

const DrawCircle_N = (_g: kcGraphics, _Circles: Circle[], ..._Draws: { Color: string; LineWidth?: number }[]) => {
   if (!Array.isArray(_Draws) || _Draws.length === 0) return;

   _g.beginPath();
   AddCirclePath(_g, _Circles);

   for (let oDraw of _Draws) {
      if (oDraw.LineWidth === undefined) {
         _g.fillStyle = oDraw.Color;
         _g.fill();
      } else {
         _g.strokeStyle = oDraw.Color;
         _g.lineWidth = oDraw.LineWidth * Device.PixelRatio;
         _g.stroke();
      }
   }
};

const DrawRect = (_g: kcGraphics, _LineWidth: number, _Color: string, x: number, y: number, width: number, height: number) => {
   // 禁用反鋸齒
   x += 0.5;
   y += 0.5;
   width -= 1;
   height -= 1;

   if (Device.PixelRatio_Enable) {
      _LineWidth *= Device.PixelRatio;
      x *= Device.PixelRatio;
      y *= Device.PixelRatio;
      width *= Device.PixelRatio;
      height *= Device.PixelRatio;
   }

   _g.strokeStyle = _Color;
   _g.lineWidth = _LineWidth;

   _g.strokeRect(x, y, width, height);
};

const FillRect = (_g: kcGraphics, _Color: string, x: number, y: number, width: number, height: number) => {
   if (Device.PixelRatio_Enable) {
      x *= Device.PixelRatio;
      y *= Device.PixelRatio;
      width *= Device.PixelRatio;
      height *= Device.PixelRatio;
   }

   _g.strokeStyle = "#00000000";
   _g.lineWidth = 0;

   _g.fillStyle = _Color;

   _g.fillRect(x, y, width, height);
};

const FillRoundedRect = (_g: kcGraphics, _fillColor: string, _x: number, _y: number, _width: number, _height: number, _radius: number, _arcType: ArcTypeOption = {}, _strokeColor?: string, _strokeWidth?: number) => {
   const defaultArcType: ArcTypeOption = {
      tl: false,
      tr: false,
      bl: false,
      br: false,
   };
   const arcType: ArcTypeOption = { ...defaultArcType, ..._arcType };

   if (Device.PixelRatio_Enable) {
      _x *= Device.PixelRatio;
      _y *= Device.PixelRatio;
      _width *= Device.PixelRatio;
      _height *= Device.PixelRatio;
      _radius *= Device.PixelRatio;
      if (_strokeWidth) _strokeWidth = Device.PixelRatio;
   }

   const maxRadius = Math.min(_width, _height) / 2;
   const radius = Math.min(_radius, maxRadius);

   _g.fillStyle = _fillColor;

   let bBorder = _strokeColor !== undefined && _strokeWidth !== undefined;
   if (bBorder) {
      _g.strokeStyle = _strokeColor!;
      _g.lineWidth = _strokeWidth!;
   } else {
      _g.strokeStyle = "#00000000";
      _g.lineWidth = 0;
   }

   _g.beginPath();
   _g.moveTo(_x + radius, _y);

   if (arcType && arcType.tl) {
      _g.quadraticCurveTo(_x, _y, _x, _y + radius);
   } else {
      _g.lineTo(_x, _y);
   }

   _g.lineTo(_x, _y + _height - radius);

   if (arcType && arcType.bl) {
      _g.quadraticCurveTo(_x, _y + _height, _x + radius, _y + _height);
   } else {
      _g.lineTo(_x, _y + _height);
   }

   _g.lineTo(_x + _width - radius, _y + _height);

   if (arcType && arcType.br) {
      _g.quadraticCurveTo(_x + _width, _y + _height, _x + _width, _y + _height - radius);
   } else {
      _g.lineTo(_x + _width, _y + _height);
   }

   _g.lineTo(_x + _width, _y + radius);

   if (arcType && arcType.tr) {
      _g.quadraticCurveTo(_x + _width, _y, _x + _width - radius, _y);
   } else {
      _g.lineTo(_x + _width, _y);
   }

   _g.lineTo(_x + radius, _y);

   if (arcType && arcType.tl) {
      _g.quadraticCurveTo(_x, _y, _x, _y + radius);
   } else {
      _g.lineTo(_x, _y);
   }

   _g.fill();
   if (bBorder) _g.stroke();
};

const AddRoundedRectPath = (_g: kcGraphics, _x: number, _y: number, _width: number, _height: number, _radius: number, _arcType: ArcTypeOption) => {
   if (Device.PixelRatio_Enable) {
      _x *= Device.PixelRatio;
      _y *= Device.PixelRatio;
      _width *= Device.PixelRatio;
      _height *= Device.PixelRatio;
      _radius *= Device.PixelRatio;
   }

   const maxRadius = Math.min(_width, _height) / 2;
   const radius = Math.min(_radius, maxRadius);

   _g.moveTo(_x + radius, _y);

   if (_arcType && _arcType.tl) {
      _g.quadraticCurveTo(_x, _y, _x, _y + radius);
   } else {
      _g.lineTo(_x, _y);
   }

   _g.lineTo(_x, _y + _height - radius);

   if (_arcType && _arcType.bl) {
      _g.quadraticCurveTo(_x, _y + _height, _x + radius, _y + _height);
   } else {
      _g.lineTo(_x, _y + _height);
   }

   _g.lineTo(_x + _width - radius, _y + _height);

   if (_arcType && _arcType.br) {
      _g.quadraticCurveTo(_x + _width, _y + _height, _x + _width, _y + _height - radius);
   } else {
      _g.lineTo(_x + _width, _y + _height);
   }

   _g.lineTo(_x + _width, _y + radius);

   if (_arcType && _arcType.tr) {
      _g.quadraticCurveTo(_x + _width, _y, _x + _width - radius, _y);
   } else {
      _g.lineTo(_x + _width, _y);
   }

   _g.lineTo(_x + radius, _y);

   if (_arcType && _arcType.tl) {
      _g.quadraticCurveTo(_x, _y, _x, _y + radius);
   } else {
      _g.lineTo(_x, _y);
   }
};

const DrawText = (_g: kcGraphics, _sz: string, _x: number, _y: number, _style: TextStyle, _Clip?: Rectangle) => {
   if (!_sz || _sz === "") return;
   if (_style) SetTextStyle(_g, _style);

   if (Device.PixelRatio_Enable) {
      _x *= Device.PixelRatio;
      _y *= Device.PixelRatio;
   }

   _g.fillText(_sz, _x, _y);
};

const DrawImage: delDrawImageOverload = (_g, _Image, ...args: tDrawImageParams1 | tDrawImageParams2 | tDrawImageParams3) => {
   const nInputLength = args.length;
   if (nInputLength === 2 || nInputLength === 3) {
      var [dx, dy, _Opacity] = args;
      if (Device.PixelRatio_Enable) {
         dx *= Device.PixelRatio;
         dy *= Device.PixelRatio;
      }

      const RestoreOpacity = _Opacity !== undefined ? _g.SetOpacity(_Opacity) : undefined;
      _g.drawImage(_Image, dx, dy);
      RestoreOpacity?.();
   } else if (nInputLength === 4 || nInputLength === 5) {
      var [dx, dy, dw, dh, _Opacity] = args;
      if (Device.PixelRatio_Enable) {
         dx *= Device.PixelRatio;
         dy *= Device.PixelRatio;
         dw *= Device.PixelRatio;
         dh *= Device.PixelRatio;
      }

      const RestoreOpacity = _Opacity !== undefined ? _g.SetOpacity(_Opacity) : undefined;
      _g.drawImage(_Image, dx, dy, dw, dh);
      RestoreOpacity?.();
   } else if (nInputLength === 8 || nInputLength === 9) {
      var [sx, sy, sw, sh, dx, dy, dw, dh, _Opacity] = args;
      if (Device.PixelRatio_Enable) {
         sx *= Device.PixelRatio;
         sy *= Device.PixelRatio;
         sw *= Device.PixelRatio;
         sh *= Device.PixelRatio;
         dx *= Device.PixelRatio;
         dy *= Device.PixelRatio;
         dw *= Device.PixelRatio;
         dh *= Device.PixelRatio;
      }

      const RestoreOpacity = _Opacity !== undefined ? _g.SetOpacity(_Opacity) : undefined;
      _g.drawImage(_Image, sx, sy, sw, sh, dx, dy, dw, dh);
      RestoreOpacity?.();
   }
};

const MeasureText = (_g: kcGraphics, _sz: string, _style?: TextStyle) => {
   let height = 0;

   if (_style) {
      let res = SetTextStyle(_g, _style);
      height = res?.FontHeight ?? 0;
   } else height = TryGetFontHeight(_g);

   let { width } = _g.measureText(_sz);

   if (Device.PixelRatio_Enable) {
      width /= Device.PixelRatio;
      height /= Device.PixelRatio;
   }

   let mdRet = { width, height };
   return mdRet;
};

const SetClip = (_g: kcGraphics, _Clip: Rectangle) => {
   if (Device.PixelRatio_Enable) {
      _Clip.X *= Device.PixelRatio;
      _Clip.Y *= Device.PixelRatio;
      _Clip.Width *= Device.PixelRatio;
      _Clip.Height *= Device.PixelRatio;
   }

   _g.save();

   _g.beginPath();
   _g.rect(_Clip.X, _Clip.Y, _Clip.Width, _Clip.Height);

   _g.clip(); // 繪製完後需要額外_g.restore()

   return { RestoreClip: _g.restore.bind(_g) };
};

/* ------------------------------------------------------------------ */
// 輔助function
const SetTextStyle = (_g: kcGraphics, _Style: TextStyle) => {
   if (!_g || !_Style) return;

   let { Size, Fill, Font, Stroke, StrokeWidth, HAlient, VAlient } = _Style;

   // Font處裡
   if (Size === undefined) Size = 12;
   if (!Font) Font = "Arial";

   // Alient處裡
   if (!HAlient) HAlient = "left";
   if (!VAlient) VAlient = "bottom";

   // 顏色處裡
   let bFill = !!Fill;
   if (!bFill) Fill = "#00000000";

   let bStroke = !!Stroke;
   if (bStroke) {
      if (!StrokeWidth) StrokeWidth = 1;
   } else {
      Stroke = "#00000000";
      StrokeWidth = 0;
   }

   if (Device.PixelRatio_Enable) {
      Size *= Device.PixelRatio;
      if (StrokeWidth) StrokeWidth *= Device.PixelRatio;
   }

   _g.font = `${Size}px ${Font}`;
   _g.textAlign = HAlient;
   _g.textBaseline = VAlient;
   _g.fillStyle = Fill!;
   _g.strokeStyle = Stroke!;
   _g.lineWidth = StrokeWidth;

   return { Fill: bFill, Stroke: bStroke, FontHeight: Size };
};

const TryGetFontHeight = (_g: kcGraphics) => {
   const { font } = _g;
   if (!font) return 0;

   let szValue = font.split(" ").find((q) => q.indexOf("pt") > 0 || q.indexOf("px") > 0);
   if (!szValue) return 0;

   // pt 計算
   let nCutIdx = szValue.indexOf("pt");
   if (nCutIdx) {
      let szSize = szValue.substring(nCutIdx).trim();
      let nSize = Number.parseInt(szSize);
      return Math.ceil(nSize * 1.33);
   }

   // px 計算
   nCutIdx = szValue.indexOf("px");
   if (nCutIdx) {
      let szSize = szValue.substring(nCutIdx).trim();
      return Number.parseInt(szSize);
   }

   return 0;
};

const AddLinePath = (_g: kcGraphics, _Points: PathPoint[]) => {
   let nLength = _Points.length;
   for (let i = 0; i < nLength; i++) {
      let p = _Points[i];
      let X = (p.X + 0.5) * Device.PixelRatio;
      let Y = (p.Y + 0.5) * Device.PixelRatio;

      if (p.StartPoint) _g.moveTo(X, Y);
      else _g.lineTo(X, Y);
   }
};

const AddCirclePath = (_g: kcGraphics, _Circles: Circle[]) => {
   let nLength = _Circles.length;
   for (let i = 0; i < nLength; i++) {
      let Cir = _Circles[i];
      let X = (Cir.X + 0.5) * Device.PixelRatio;
      let Y = (Cir.Y + 0.5) * Device.PixelRatio;
      let Radius = Cir.Radius * Device.PixelRatio;

      _g.moveTo(X + Radius, Y);
      _g.arc(X, Y, Radius, 0, Math.PI * 2);
   }
};

declare global {
   interface CanvasRenderingContext2D {
      SaveStackCount: number;
      save(): number;
      restore(): number;
      ReSet(): void; // 比clearRect慢
      SetOpacity(_value: number): () => void;
   }
}

CanvasRenderingContext2D.prototype.SaveStackCount = 0;

CanvasRenderingContext2D.prototype.ReSet = function () {
   if ((this as any).reset) (this as any).reset();
   else this.clearRect(0, 0, this.canvas.width, this.canvas.height);
};

CanvasRenderingContext2D.prototype.SetOpacity = function (_value: number) {
   const nOpacityOri = this.globalAlpha;
   this.globalAlpha = _value;
   const RestoreOpacity = () => {
      this.globalAlpha = nOpacityOri;
   };
   return RestoreOpacity;

   /*
   // 非常消耗效能
   this.filter = `opacity(${_value})`;
   */

   /*
   // save - restore
   this.save();
   this.globalAlpha = _value;
   return { RestoreOpacity: this.restore.bind(this) };
   */
};

const originalSave = CanvasRenderingContext2D.prototype.save;
CanvasRenderingContext2D.prototype.save = function () {
   this.SaveStackCount++;
   originalSave.call(this);
   return this.SaveStackCount;
};

const originalRestore = CanvasRenderingContext2D.prototype.restore;
CanvasRenderingContext2D.prototype.restore = function () {
   this.SaveStackCount--;
   originalRestore.call(this);
   return this.SaveStackCount;
};

export const kcGraphicsHelper = {
   DefaultDashStyle,
   DrawLine,
   DrawLine_N,
   DrawDashLine,
   DrawCircle,
   FillCircle,
   DrawCircle_N,
   DrawRect,
   FillRect,
   FillRoundedRect,
   AddRoundedRectPath,
   DrawText,
   DrawImage,
   MeasureText,
   SetClip,
};
