export default class ObjectExtenstions {
  static ObjectsEqual = (a: any, b: any, bDebug?: boolean) => {
    let ret = ObjectExtenstions._ObjectsEqual(a, b);
    if (bDebug) console.log("ObjectsEqual", ret, a, b);
    return ret;
  };

  static _ObjectsEqual = (a: any, b: any) => {
    if (a === undefined && b === undefined) return true;
    if (a === undefined && b != undefined) return false;
    if (a != undefined && b === undefined) return false;

    for (var prop in a) {
      if (a.hasOwnProperty(prop)) {
        if (b.hasOwnProperty(prop)) {
          if (Array.isArray(a[prop])) {
            if (ObjectExtenstions._ArrayEquals(a[prop], b[prop]) == false)
              return false;
          } else if (typeof a[prop] === "object") {
            if (!ObjectExtenstions._ObjectsEqual(a[prop], b[prop]))
              return false;
          } else {
            if (a[prop] !== b[prop]) return false;
          }
        } else {
          return false;
        }
      }
    }
    return true;
  };

  static _ArrayEquals = (a: any, b: any) => {
    let Ret =
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);

    return Ret;
  };
}
