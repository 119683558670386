import moment, { Moment } from "moment";
import { NativeModules } from "react-native";
import { MomentExtensions } from "../kcExternal";

const TimerInterval = 1000; // ms
const AutoReLoadMinTimeOut_ms = 1 * MomentExtensions.TicksPerMinute;

export var AppStartTime = moment();
export var RunTime: Moment = moment();
export var RunTimeMaxDiff_s: number = 0;
export var MaxDiff_From: Moment = moment();
export var MaxDiff_To: Moment = moment();
var OnAutoReLoad: (() => void) | undefined = undefined;
var OnRunTime: ((_Time: Moment) => void) | undefined = undefined;
var Timer: NodeJS.Timer | undefined = undefined;
var AutoReLoadTimeOut_ms: number = 0; // 0關閉AutoReLoad, 最小值為AutoReLoadMinTimeOut_ms

export var AppStart = () => {
  StopTimer();

  AppStartTime = moment();
  RunTime = moment();
  RunTimeMaxDiff_s = 0;
  MaxDiff_From = moment();
  MaxDiff_To = moment();

  Timer = setInterval(() => {
    let NowTime = moment();
    let diff_Tick = NowTime.valueOf() - RunTime.valueOf();
    let diff = diff_Tick / MomentExtensions.TicksPerSecond;

    if (diff > RunTimeMaxDiff_s) {
      RunTimeMaxDiff_s = diff;
      MaxDiff_From = RunTime;
      MaxDiff_To = NowTime;
    }
    RunTime = NowTime;
    OnRunTime?.call(this, RunTime);

    if (diff_Tick >= AutoReLoadTimeOut_ms) OnAutoReLoad?.call(this);
  }, TimerInterval);
};
export var StopTimer = () => {
  if (Timer) {
    clearInterval(Timer);
    Timer = undefined;
  }
};
export var SetAutoReLoad = (_TimeOut_s: number, _OnAutoReLoad?: () => void) => {
  // 0關閉AutoReLoad, 最小值為AutoReLoadMinTimeOut_ms
  if (_OnAutoReLoad) OnAutoReLoad = _OnAutoReLoad;

  AutoReLoadTimeOut_ms = _TimeOut_s * MomentExtensions.TicksPerSecond;
  if (AutoReLoadTimeOut_ms !== 0)
    AutoReLoadTimeOut_ms = Math.max(
      AutoReLoadTimeOut_ms,
      AutoReLoadMinTimeOut_ms
    );

  if (Timer === undefined) AppStart();
};
export var SetCallback = (_OnRunTime: (_Time: Moment) => void) => {
  OnRunTime = _OnRunTime;
};

export var ReLoadApp = () => {
  if (window.location) window.location.reload();
  // else DevSettings.reload()
  else NativeModules.DevSettings.reload();
};
