import AsyncStorage from "@react-native-async-storage/async-storage";

// https://react-native-async-storage.github.io/async-storage/docs/api

export type MultiStringValue = {
  Key: string;
  Value: string;
};

export type MultiObjectValue = {
  Key: string;
  Value: any;
};

export default class StorageHelper {
  /* ---------------------------------------------- */
  // Save
  static async SaveData(_szKey: string, _szData: string) {
    try {
      await AsyncStorage.setItem(_szKey, _szData);
    } catch (e) {
      console.log(`[StorageHelper] SaveData() catch: ${e}`);
    }
  }
  static async SaveDatas(Datas: MultiStringValue[]) {
    try {
      let keyValuePairs = Datas.map((_Item) => [_Item.Key, _Item.Value]);
      await AsyncStorage.multiSet(keyValuePairs);
    } catch (e) {
      console.log(`[StorageHelper] SaveDatas() catch: ${e}`);
    }
  }
  static async SaveObject(_szKey: string, _oData: any) {
    try {
      let jsonValue = JSON.stringify(_oData);
      await AsyncStorage.setItem(_szKey, jsonValue);
    } catch (e) {
      console.log(`[StorageHelper] SaveObject() catch: ${e}`);
    }
  }
  static async SaveObjects(Datas: MultiObjectValue[]) {
    try {
      let keyValuePairs = Datas.map((_Item) => [
        _Item.Key,
        JSON.stringify(_Item.Value),
      ]);
      await AsyncStorage.multiSet(keyValuePairs);
    } catch (e) {
      console.log(`[StorageHelper] SaveObjects() catch: ${e}`);
    }
  }

  /* ---------------------------------------------- */
  // Read
  static async ReadData(_szKey: string) {
    try {
      return await AsyncStorage.getItem(_szKey);
    } catch (e) {
      console.log(`[StorageHelper] ReadData() catch: ${e}`);
    }
  }
  static async ReadDatas(_szKeys: string[]) {
    let values;
    try {
      values = await AsyncStorage.multiGet(_szKeys);
    } catch (e) {
      console.log(`[StorageHelper] ReadDatas() catch: ${e}`);
    }
    return values;
  }

  static async ReadObject(_szKey: string) {
    try {
      const jsonValue = await AsyncStorage.getItem(_szKey);
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
      console.log(`[StorageHelper] ReadObject() catch: ${e}`);
    }
  }

  static async ReadObjects(_szKeys: string[]) {
    let values: MultiObjectValue[] = [];
    try {
      let ReadValues = await AsyncStorage.multiGet(_szKeys);
      values = ReadValues.map((KeyValuePare) => {
        let szValue = KeyValuePare[1];
        let mdParse: MultiObjectValue = {
          Key: KeyValuePare[0],
          Value: StorageHelper.TryJsonParse(szValue),
        };
        return mdParse;
      });
    } catch (e) {
      console.log(`[StorageHelper] ReadObjects() catch: ${e}`);
    }
    return values;
  }

  /* ---------------------------------------------- */
  // Other
  static async GetAllKeys() {
    let keys: string[] = [];
    try {
      keys = await AsyncStorage.getAllKeys();
    } catch (e) {
      console.log(`[StorageHelper] getAllKeys() catch: ${e}`);
    }
    return keys;
  }

  static async GetAllValues() {
    let values: MultiObjectValue[] = [];
    try {
      let keys: string[] = await AsyncStorage.getAllKeys();
      values = await StorageHelper.ReadObjects(keys);
    } catch (e) {
      console.log(`[StorageHelper] getAllKeys() catch: ${e}`);
    }
    return values;
  }

  static async RemoveValue(_szKey: string | string[]) {
    try {
      Array.isArray(_szKey)
        ? await AsyncStorage.multiRemove(_szKey)
        : await AsyncStorage.removeItem(_szKey);
    } catch (e) {
      console.log(`[StorageHelper] RemoveValue() catch: ${e}`);
    }
  }
  static async ClearAll() {
    try {
      await AsyncStorage.clear();
    } catch (e) {
      console.log(`[StorageHelper] ClearAll() catch: ${e}`);
    }
  }
  static async TryJsonParse(_szJson: string | null | undefined) {
    let RetItem = _szJson;
    if (_szJson) {
      try {
        RetItem = JSON.parse(_szJson);
      } catch (e) {}
    }
    return RetItem;
  }
}
