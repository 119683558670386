import * as React from "react";
import {
  Dimensions,
  Pressable,
  ScaledSize,
  ScrollView,
  StyleSheet,
} from "react-native";
import { View, Text } from "../../components/Themed";
import { Button } from "react-native-elements";
import { Ionicons } from "@expo/vector-icons";
import { kcColor } from "../../constants/Colors";
import { kcSetState, kcSetUnmount } from "../../kcExternal";
import { CompositeNavigationProp, RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { BottomTabParamList, TabTECPParamList } from "../../types";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import TECPSetting, { TECPSettingHelper } from "../../kcTECP/TECPSetting";
import { TECPSettingEditHelper } from "../../kcTECP";
import TECPSettingControl from "../../components/kcTECPSettingControl";

type IState = {
  bFixedRedraw: boolean; // 笨方式 用來強制繪圖
  bIsScreenFocus: boolean;
  loading: boolean;
  DimensionsWindow: ScaledSize;
};

type IProp = {
  route: RouteProp<TabTECPParamList, "TabTECPScreen_Setting_MACD">;
  navigation: CompositeNavigationProp<
    StackNavigationProp<TabTECPParamList, "TabTECPScreen_Setting_MACD">,
    BottomTabNavigationProp<BottomTabParamList, "TabTECP">
  >;
};

export default class TECPScreen_Setting_MACD extends React.Component<
  IProp,
  IState
> {
  constructor(props: any) {
    super(props);

    if (TECPSettingEditHelper.SettingBuffer)
      this.m_TECPSetting = TECPSettingEditHelper.SettingBuffer.Setting;
    else this.m_TECPSetting = TECPSettingHelper.CreateDefault();
  }

  state: IState = {
    loading: false,
    bFixedRedraw: false, // 笨方式 用來強制繪圖
    bIsScreenFocus: true,
    DimensionsWindow: Dimensions.get("window"),
  };

  m_TECPSetting: TECPSetting;
  m_ControlRef_EMA1 = React.createRef<TECPSettingControl>();
  m_ControlRef_EMA2 = React.createRef<TECPSettingControl>();
  m_ControlRef_MACD = React.createRef<TECPSettingControl>();

  componentDidMount() {
    this.props.navigation.addListener("focus", this.OnFocus);
    this.props.navigation.addListener("blur", this.OnBlur);
    Dimensions.addEventListener("change", this.OnDimensionsChange);

    this.props.navigation.setOptions({
      headerLeft: this.Render_HeaderLeft,
      headerRight: this.Render_HeaderRight,
      headerTitle: this.Render_HeaderTitle,
    });
  }

  componentWillUnmount() {
    this.props.navigation.removeListener("focus", this.OnFocus);
    this.props.navigation.removeListener("blur", this.OnBlur);
    Dimensions.removeEventListener("change", this.OnDimensionsChange);

    kcSetUnmount(this, true);
  }

  private FixedRedrawUI = () => {
    kcSetState(this, { bFixedRedraw: !this.state.bFixedRedraw }); // 強制更動畫面
  };
  private InitSetting = async () => {
    if (TECPSettingEditHelper.SettingBuffer) {
      let setting = await TECPSettingEditHelper.SettingBuffer.GetSettingAsync(
        true
      );
      this.RefreshSettingControl();
    }
  };
  private RestoreSetting = () => {
    TECPSettingEditHelper.SettingBuffer?.ReSet();
  };
  private SaveSetting = () => {
    TECPSettingEditHelper.SettingBuffer?.Save();
  };
  private ToDefaultSetting = () => {
    this.m_TECPSetting.MACD.ToDefault();
    this.RefreshSettingControl();
  };
  private RefreshSettingControl = () => {
    this.m_ControlRef_EMA1.current?.SetInputValue(
      this.m_TECPSetting.MACD.EMA1_Base
    );
    this.m_ControlRef_EMA2.current?.SetInputValue(
      this.m_TECPSetting.MACD.EMA2_Base
    );
    this.m_ControlRef_MACD.current?.SetInputValue(
      this.m_TECPSetting.MACD.MACD_Base
    );

    this.FixedRedrawUI();
  };
  private GoBack = () => {
    this.props.navigation.pop();
  };

  /*---------------------------------------------------------------------------- */
  private OnFocus = () => {
    this.InitSetting();
    kcSetState(this, { bIsScreenFocus: true });
  };
  private OnBlur = () => {
    kcSetState(this, { bIsScreenFocus: false });
  };
  private OnDimensionsChange = ({
    window,
    screen,
  }: {
    window: ScaledSize;
    screen: ScaledSize;
  }) => {
    this.setState({ DimensionsWindow: window });
  };
  private OnHeadLeftClick = () => {
    this.RestoreSetting();
    this.GoBack();
  };
  private OnHeadRightClick = () => {
    this.ToDefaultSetting();
  };
  private OnSettingChange = (
    _EMA1_Base?: number,
    _EMA2_Base?: number,
    _MACD_Base?: number
  ) => {
    if (_EMA1_Base !== undefined)
      this.m_TECPSetting.MACD.EMA1_Base = Math.floor(_EMA1_Base);
    if (_EMA2_Base !== undefined)
      this.m_TECPSetting.MACD.EMA2_Base = Math.floor(_EMA2_Base);
    if (_MACD_Base !== undefined)
      this.m_TECPSetting.MACD.MACD_Base = Math.floor(_MACD_Base);
    this.FixedRedrawUI();
  };
  private OnSaveClick = () => {
    this.SaveSetting();
    this.GoBack();
  };

  private Render_HeaderLeft = () => {
    return (
      <Button
        // style={{ width: 48, height: 48 }}
        icon={
          <Ionicons name="ios-chevron-back" size={24} color={kcColor("Icon")} />
        }
        iconContainerStyle={{ alignSelf: "center" }}
        buttonStyle={styles.Head_Left_ButtonStyle}
        onPress={this.OnHeadLeftClick}
      />
    );
  };

  private Render_HeaderRight = () => {
    return (
      <Pressable
        style={{
          flex: 1,
          justifyContent: "center",
          padding: 5,
        }}
        onPress={this.OnHeadRightClick}
      >
        <Text style={{ color: "white" }} selectable={false}>
          恢復預設值
        </Text>
      </Pressable>
    );
  };

  private Render_HeaderTitle = () => {
    return (
      <View
        style={{
          backgroundColor: "#00000000",
          //flex: 1,
          width: 300,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            backgroundColor: "#00000000",
            color: kcColor("Title"),
            fontSize: 18,
          }}
          selectable={false}
        >
          {"MACD"}
        </Text>
      </View>
    );
  };

  /*-------------------------------------------------*/
  private Render_SettingControls = () => {
    if (!TECPSettingEditHelper.SettingBuffer?.SettingReady) return <></>;
    return (
      <>
        <TECPSettingControl
          style={{ marginTop: 10, marginBottom: 5 }}
          ref={this.m_ControlRef_EMA1}
          Mode="NumberInput"
          Text="短週期"
          SubText="範圍: 2-999"
          OnTextClick="Default"
          InitInputValue={this.m_TECPSetting.MACD.EMA1_Base}
          InputValueProp={{ FloatNum: 0, Min: 2, Max: 999 }}
          OnInputValueChanged={(_Value) => this.OnSettingChange(_Value)}
        />
        <TECPSettingControl
          style={{ marginTop: 5, marginBottom: 5 }}
          ref={this.m_ControlRef_EMA2}
          Mode="NumberInput"
          Text="長週期"
          SubText="範圍: 2-999"
          OnTextClick="Default"
          InitInputValue={this.m_TECPSetting.MACD.EMA2_Base}
          InputValueProp={{ FloatNum: 0, Min: 2, Max: 999 }}
          OnInputValueChanged={(_Value) =>
            this.OnSettingChange(undefined, _Value)
          }
        />
        <TECPSettingControl
          style={{ marginTop: 5, marginBottom: 5 }}
          ref={this.m_ControlRef_MACD}
          Mode="NumberInput"
          Text="MACD"
          SubText="範圍: 2-999"
          OnTextClick="Default"
          InitInputValue={this.m_TECPSetting.MACD.MACD_Base}
          InputValueProp={{ FloatNum: 0, Min: 2, Max: 999 }}
          OnInputValueChanged={(_Value) =>
            this.OnSettingChange(undefined, undefined, _Value)
          }
        />
      </>
    );
  };
  render() {
    const EffectiveHeigh = this.state.DimensionsWindow.height - 60;
    return (
      <View
        style={{
          backgroundColor: kcColor("Background"),
          height: EffectiveHeigh,
        }}
      >
        <ScrollView style={{ flex: 1 }}>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              backgroundColor: kcColor("Background"),
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "column",
                backgroundColor: "#00000000",
              }}
            >
              {this.Render_SettingControls()}

              <Button
                style={{ margin: 20, marginBottom: 0, marginTop: 15 }}
                buttonStyle={{
                  backgroundColor: kcColor("Blue"),
                  borderRadius: 15,
                }}
                onPress={this.OnSaveClick}
                title="儲存"
              />
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  Head_Left_ButtonStyle: {
    backgroundColor: "#00000000",
    borderColor: "red",
    borderWidth: 0,
    borderRadius: 15,
  },
  button: {
    backgroundColor: "#00000000",
    borderColor: "red",
    borderWidth: 0,
    borderRadius: 15,
  },
});
