import { Moment } from "moment";
import { MathEx, MomentExtensions } from "../kcExternal";
import {
  kcCommodityModel,
  kcHistoryOHLCModel,
  ModelJSExtensions,
} from "../kcModel";
import { DayKLineType } from "../kcModel/kcModel_Enum";
import YInfo, { YUnit } from "../kcTECP/YInfo";
import { HistoryDataType } from "../kcTransfer/InternalDefine";
import { HistoryParams } from "./kcTECPCollection";
import XInfo from "./XInfo";

export default class kcDrawHelper {
  constructor() {}

  public static m_YValueStage: number[] = [
    0.00000001, 0.00000002, 0.00000005, 0.0000001, 0.0000002, 0.0000005,
    0.000001, 0.000002, 0.000005, 0.00001, 0.00002, 0.00005, 0.0001, 0.0002,
    0.0005, 0.001, 0.002, 0.005, 0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10,
    20, 25, 50, 100, 200, 500, 1000, 1500, 2000, 2500, 5000, 10000, 20000,
    25000, 50000, 100000, 200000, 250000, 500000, 1000000, 2000000, 2500000,
    5000000, 10000000, 20000000, 25000000, 50000000,
  ];

  public static GetDefaultHorizontaGridLineBase(_YUnit: YUnit): number[] {
    // 高度過小會跑很久 需要再修
    if (/*!_YUnit.mdMaxMin.HasLimit ||*/ _YUnit.dPixelValue == 0) return [];

    let dMinTickBase = _YUnit.YTickBase;
    let dMaxLimit = _YUnit.CoordToValue(_YUnit.nEffectStartY);
    let dMinLimit = _YUnit.CoordToValue(
      _YUnit.nEffectStartY + _YUnit.nEffectHigh - 1
    );
    let dMaxRange = _YUnit.nEffectHigh / 2;
    let dPixelValue = _YUnit.dPixelValue;
    let nHeight = _YUnit.nEffectHigh;

    // 設定期望數值
    let nRange; // 一格大小期望值
    if (_YUnit.nEffectHigh <= 120) {
      // 小格子
      //nRange = 35; // 期望值一格 20 ~ 30 px, 取中間值
      //nRange = _YUnit.nEffectHigh / 3;
      nRange = _YUnit.nEffectHigh / 2;
    } else {
      nRange = 60; // 期望值一格 60 px
    }

    let nSelectedIdx = -1;
    let dDifMinimum = Number.MAX_VALUE;
    let m_YValueStage = kcDrawHelper.m_YValueStage;
    for (let i = 0; i < m_YValueStage.length; i++) {
      if (m_YValueStage[i] < dMinTickBase) continue;
      let nStagePixel = m_YValueStage[i] / dPixelValue; // 一格 dStagePixel 個 px
      // 尋找最接近期望值的
      let dPixelDef = Math.abs(nStagePixel - nRange);
      if (nStagePixel > dMaxRange && nSelectedIdx > -1) break;
      if (dDifMinimum > dPixelDef) {
        dDifMinimum = dPixelDef;
        nSelectedIdx = i;
      } else break;
    }

    let dYBaseValue = m_YValueStage[nSelectedIdx];
    let dNeedAdd = dMinLimit % dYBaseValue;
    if (dNeedAdd < 0) dNeedAdd = dYBaseValue + dNeedAdd;
    let dYBottonValue = dMinLimit - dNeedAdd;

    let dlRet: number[] = [];
    let dValue = dYBottonValue;
    let count = 0;
    while (true) {
      if (count++ > 20) break; // 防止無限迴圈
      //if (dValue < dMinLimit)
      //    goto Next;
      //if (dValue > dMaxLimit)
      //    break;
      if (dValue <= _YUnit.StartValue) {
        dValue += dYBaseValue;
        continue;
      }

      dValue = MathEx.kcRound(dValue, 6); // 修正液位
      if (dValue >= _YUnit.EndValue) break;
      dlRet.push(dValue);
      dValue += dYBaseValue;
    }
    return dlRet;
  }

  /* -------------------------------------------------------------------------------------- */
  // 垂直時間線
  public static GetBasicInfo_Time(
    _TimeTECP: kcHistoryOHLCModel[],
    _XInfo: XInfo,
    _BasicIni: HistoryParams /* TimeZoneInfo _tz*/
  ): BasicTimeModel[] {
    //bool bUseTimeZone = _tz != null;
    let nMinRange = 100;
    let slRet: BasicTimeModel[] = [];

    if (!_TimeTECP) return slRet;

    let PreTime: Moment = MomentExtensions.MinValue;
    // if (!_TimeTECP.GetTime((int)_XInfo.ScreenDataIndex - 1, out PreTime))
    //     PreTime = DateTime.MinValue;
    let TimeStartIndex = Math.floor(_XInfo.ScreenDataIndex) - 1;
    if (TimeStartIndex >= 0 && TimeStartIndex < _TimeTECP.length) {
      PreTime = _TimeTECP[TimeStartIndex].Time;
    }

    let fPreXPos = -9999;
    let szPreDateTime = "";
    let szTimeFormat = "";
    for (let i = _XInfo.lXUnit.length - 1; i >= 0; i--) {
      let nDataIndex = _XInfo.lXUnit[i].nDataIndex;
      let fXMidPos = _XInfo.lXUnit[i].fXMidPos;
      let fXWidth = _XInfo.lXUnit[i].fXWidth;

      // if (!_TimeTECP.GetTime(nDataIndex, out TimeRet))
      //     continue;
      if (nDataIndex < 0 || nDataIndex >= _TimeTECP.length) continue;
      let TimeRet: Moment = _TimeTECP[nDataIndex].Time; // 回傳出去的Time

      let TimeLoc = _TimeTECP[nDataIndex].TimeLocal; // 用來換日判斷的Time
      // if (bUseTimeZone)
      //     TimeLoc = TimeZoneHelper.ToLocalTime(TimeRet, _tz);

      // 先判距離
      if (fXMidPos - fPreXPos < nMinRange) {
        PreTime = TimeRet;
        continue;
      }
      // 位置防呆
      if (fXMidPos > _XInfo.TECPWidth) {
        PreTime = TimeRet;
        continue;
      }
      let CheckTimeResault = this.CheckTimeLineValue(
        PreTime,
        TimeRet,
        _BasicIni
      );
      szTimeFormat = CheckTimeResault.szFormat;
      // if (!CheckTimeLineValue(PreTime, TimeLoc, _BasicIni, out szTimeFormat))
      if (!CheckTimeResault.Resault) {
        PreTime = TimeRet;
        continue;
      }

      let szDatetimeValue = TimeLoc.format(szTimeFormat);

      if (slRet.length == 0 || szPreDateTime != szDatetimeValue) {
        slRet.push(
          new BasicTimeModel(nDataIndex, fXMidPos, TimeLoc, szTimeFormat)
        );
        fPreXPos = fXMidPos;
        szPreDateTime = szDatetimeValue;
      }

      PreTime = TimeRet;
    }
    return slRet;
  }
  private static CheckTimeLineValue(
    _PreTime: Moment,
    _NowTime: Moment,
    _BasicIni: HistoryParams
  ): { Resault: boolean; szFormat: string } {
    let bRet = false;
    let _szFormat = "";
    switch (_BasicIni.HistoryType) {
      case HistoryDataType.Tick:
        if (_NowTime.minutes() == 0 || _NowTime.minutes() == 30) {
          _szFormat = "HH:mm";
          if (_PreTime.format(_szFormat) != _NowTime.format(_szFormat))
            bRet = true;
        }
        break;
      case HistoryDataType.Minute:
        if (_NowTime.minutes() == 0 || _NowTime.minutes() == 30) {
          _szFormat = "HH:mm";
          bRet = true;
        }
        break;
      case HistoryDataType.Day:
        switch (_BasicIni.HistoryBase) {
          case DayKLineType.Day: // 日線
            if (_PreTime.year() != _NowTime.year()) {
              // 換年顯示
              _szFormat = "YYYY/MM";
              bRet = true;
            } else if (_PreTime.month() != _NowTime.month()) {
              // 換月顯示
              _szFormat = "MM";
              bRet = true;
            }
            break;
          case DayKLineType.Week: // 週線
            if (_PreTime.year() != _NowTime.year()) {
              // 換年顯示
              _szFormat = "YYYY";
              bRet = true;
            }
            break;
          case DayKLineType.Month: // 月線
            if (_PreTime.year() != _NowTime.year()) {
              // 換年顯示
              _szFormat = "YYYY";
              bRet = true;
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    return { Resault: bRet, szFormat: _szFormat };
  }

  /* -------------------------------------------------------------------------------------- */
  // 換日線
  public static GetDailyLines(
    _TimeTECP: kcHistoryOHLCModel[],
    _XInfo: XInfo,
    _BasicIni: HistoryParams,
    _mdCommodity: kcCommodityModel | undefined
  ): BasicTimeModel[] {
    let nMinRange = 1;
    let slRet: BasicTimeModel[] = [];

    if (_XInfo.lXUnit.length == 0) return slRet;

    let sMinuteOffset = 0; // 日開盤時間
    if (_mdCommodity) {
      sMinuteOffset = ModelJSExtensions.GetFirstOpenTime(
        _mdCommodity.TradeTime
      );
      // if (TradeTimeHelper.FindFirstTradeItem(_mdCommodity.TradeTime, TradeTimeState.Open, out kcTradeTimeModel _mdFirstOpen))
      //     sMinuteOffset = _mdFirstOpen.MinuteOffset;
      if (sMinuteOffset < 0)
        // 防呆, 在這邊判斷用正值
        sMinuteOffset += 1440;
    }

    let NextDisplayTime = MomentExtensions.MinValue;
    let PreTime = MomentExtensions.MinValue;
    let TimeStartIndex = Math.floor(_XInfo.ScreenDataIndex) - 1;
    if (TimeStartIndex >= 0 && TimeStartIndex < _TimeTECP.length)
      NextDisplayTime = this.GetNextDisplayTime(
        PreTime,
        _BasicIni,
        sMinuteOffset
      );
    // if (_TimeTECP.GetTime((int)_XInfo.ScreenDataIndex - 1, out DateTime PreTime))
    //     NextDisplayTime = GetNextDisplayTime(PreTime, _BasicIni, sMinuteOffset);
    let szTimeFormat = this.GetDailyTimeFormat(_BasicIni);

    let fPreXPos = -9999;
    for (let i = _XInfo.lXUnit.length - 1; i >= 0; i--) {
      let nDataIndex = _XInfo.lXUnit[i].nDataIndex;
      let fXMidPos = _XInfo.lXUnit[i].fXMidPos;

      if (nDataIndex < 0 || nDataIndex >= _TimeTECP.length) continue;
      let NowTime = _TimeTECP[nDataIndex].Time;

      // 全Data的第1跟不顯示
      if (NextDisplayTime.isSame(MomentExtensions.MinValue)) {
        NextDisplayTime = this.GetNextDisplayTime(
          NowTime,
          _BasicIni,
          sMinuteOffset
        );
        continue;
      }

      // 先判距離
      if (fXMidPos - fPreXPos < nMinRange) {
        NextDisplayTime = this.GetNextDisplayTime(
          NowTime,
          _BasicIni,
          sMinuteOffset
        );
        continue;
      }

      // 位置防呆
      if (fXMidPos > _XInfo.TECPWidth) {
        NextDisplayTime = this.GetNextDisplayTime(
          NowTime,
          _BasicIni,
          sMinuteOffset
        );
        continue;
      }

      if (NowTime < NextDisplayTime) continue;

      slRet.push(
        new BasicTimeModel(
          nDataIndex,
          fXMidPos,
          MomentExtensions.ToLocalTime(NowTime),
          szTimeFormat
        )
      );
      fPreXPos = fXMidPos;

      NextDisplayTime = this.GetNextDisplayTime(
        NowTime,
        _BasicIni,
        sMinuteOffset
      );
    }
    return slRet;
  }
  private static GetNextDisplayTime(
    _NowTime: Moment,
    _BasicIni: HistoryParams,
    _sOpenMinuteOffset: number
  ): Moment {
    if (_sOpenMinuteOffset < 0) _sOpenMinuteOffset += 1440;

    let tRet: Moment;
    switch (_BasicIni.HistoryType) {
      case HistoryDataType.Tick:
      case HistoryDataType.Minute:
      default:
        {
          tRet = MomentExtensions.FloorToDay(_NowTime).add(
            _sOpenMinuteOffset,
            "minutes"
          );
          if (_NowTime >= tRet) tRet = tRet.add(1, "days");
        }
        break;

      case HistoryDataType.Day:
        {
          if (_BasicIni.HistoryBase < DayKLineType.Week) {
            // 日線, 換月顯示
            tRet = MomentExtensions.FloorToMonth(_NowTime).add(1, "months");
          } // 其他, 換年顯示
          else {
            tRet = MomentExtensions.FloorToYear(_NowTime).add(1, "years");
          }
        }
        break;
    }
    return tRet;
  }
  private static GetDailyTimeFormat(_BasicIni: HistoryParams): string {
    let szRet = "";
    switch (_BasicIni.HistoryType) {
      case HistoryDataType.Tick:
      case HistoryDataType.Minute:
      default:
        szRet = "YYYY/MM/dd";
        break;

      case HistoryDataType.Day:
        if (_BasicIni.HistoryBase < DayKLineType.Week)
          // 日線, 換月顯示
          szRet = "YYYY/MM";
        // 其他, 換年顯示
        else szRet = "YYYY";
        break;
    }
    return szRet;
  }
}

export class BasicTimeModel {
  constructor(
    _DataIndex: number,
    _XMidPos: number,
    _Time: Moment,
    _TimeFormat: string
  ) {
    this.DataIndex = _DataIndex;
    this.XMidPos = _XMidPos;
    this.Time = _Time;
    this.TimeFormat = _TimeFormat;
  }
  public DataIndex: number;
  public XMidPos: number;
  public Time: Moment;
  public TimeFormat: string;
}
export type Rectangle = {
  X: number;
  Y: number;
  Width: number;
  Height: number;
};
export type Point = {
  X: number;
  Y: number;
};
export type Size = {
  Width: number;
  Height: number;
};
