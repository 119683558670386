import StringConverter from "./StringConverter";

const pako = require("pako");

export default class CompressionExtensions {
  static Zlib_Zip(Buffer: Uint8Array): Uint8Array {
    let ZipBuffer = pako.deflate(Buffer);
    return ZipBuffer;
  }
  static Zlib_Zip_byString(_szData: string): Uint8Array {
    let ZipBuffer = pako.deflate(StringConverter.UTF8_GetBytes(_szData));
    return ZipBuffer;
  }
  static Zlib_UnZip(_ZipBuffer: Uint8Array): Uint8Array {
    let Buffer = pako.inflate(_ZipBuffer);
    return Buffer;
  }
  static Zlib_UnZip_toString(_ZipBuffer: Uint8Array): string {
    let Buffer = pako.inflate(_ZipBuffer);
    return StringConverter.UTF8_GetString(Buffer);
  }
}
