import { kcUnitModel } from ".";
import { kcHistoryOHLCModel, kcTickModel, ModelJSExtensions } from "../kcModel";
import { HistoryDataType } from "../kcTransfer/InternalDefine";
import { Quote_Request_History } from "../kcTransfer/kcQuote";

const ReqDayNum_Day = 10000;
const ReqDayNum_Min = 50;
const ReqDayNum_Tick = 2;
const MaxReqDataNum = 20000;

enum ReadyState {
   None = 0,
   Calling = 1,
   Ready = 2,
}

export type HistoryChangedType = "Init" | "Update" | "Clear";
export type delOnHistoryChanged = (HistoryCollection: kcHistoryCollection, ChangedType: HistoryChangedType, Data?: kcTickModel | kcHistoryOHLCModel[]) => void;

export default class kcHistoryCollection {
   constructor(_mdUnit: kcUnitModel, _HType: HistoryDataType) {
      this.m_kcUnit = _mdUnit;
      this.m_HType = _HType;
   }

   private m_fOnHistoryChanged?: delOnHistoryChanged = undefined;
   private m_bReadyState: ReadyState = ReadyState.None;
   private m_kcUnit: kcUnitModel;
   private m_HType: HistoryDataType;
   private m_mlPureHistory: kcHistoryOHLCModel[] = []; // Pure List 不加點差

   public get HistoryReady(): boolean {
      return this.m_bReadyState === ReadyState.Ready;
   }
   public get kcUnit(): kcUnitModel {
      return this.m_kcUnit;
   }
   public get HType(): HistoryDataType {
      return this.m_HType;
   }
   public get DataCount(): number {
      return this.m_mlPureHistory.length;
   }

   public GetPureOHLC() {
      return this.m_mlPureHistory;
   }

   public Get(_fOnHistoryChanged?: delOnHistoryChanged) {
      if (_fOnHistoryChanged) this.m_fOnHistoryChanged = _fOnHistoryChanged;

      if (this.m_bReadyState === ReadyState.Ready) {
         this.SendCallback("Init", this.m_mlPureHistory);
      } else if (this.m_bReadyState === ReadyState.None) {
         let DayNumber: number = 0;
         switch (this.m_HType) {
            case HistoryDataType.Tick:
               DayNumber = ReqDayNum_Tick;
               break;
            case HistoryDataType.Minute:
               DayNumber = ReqDayNum_Min;
               break;
            case HistoryDataType.Day:
               DayNumber = ReqDayNum_Day;
               break;
         }
         this.m_bReadyState = ReadyState.Calling;
         Quote_Request_History(this.m_kcUnit.StockCode, this.m_HType, DayNumber);
      }
   }
   Clear() {
      this.m_bReadyState = ReadyState.None;
      this.m_mlPureHistory.length = 0;

      this.SendCallback("Clear");
      this.m_fOnHistoryChanged = undefined;
   }
   public OnHistoryOHLC(_mlOHLC: kcHistoryOHLCModel[]): void {
      if (this.m_bReadyState !== ReadyState.Calling) return;

      if (_mlOHLC.length > MaxReqDataNum) {
         _mlOHLC.splice(0, _mlOHLC.length - MaxReqDataNum);
      }
      this.m_mlPureHistory = _mlOHLC;
      this.m_bReadyState = ReadyState.Ready;

      this.SendCallback("Init", this.m_mlPureHistory);
   }

   public Update(_mdTick: kcTickModel): boolean {
      if (this.m_bReadyState !== ReadyState.Ready) return false;

      let bUpdated = false;
      switch (this.m_HType) {
         case HistoryDataType.Tick:
            bUpdated = this.UpdateTick(_mdTick);
            break;
         case HistoryDataType.Minute:
            bUpdated = this.UpdateMinute(_mdTick);
            break;
         case HistoryDataType.Day:
            bUpdated = this.UpdateDay(_mdTick);
            break;
      }
      if (bUpdated) this.SendCallback("Update", _mdTick);
      return bUpdated;
   }

   private UpdateTick(_mdTick: kcTickModel): boolean {
      let dPrice = _mdTick.ClosePrice;

      let md: kcHistoryOHLCModel = new kcHistoryOHLCModel({
         Time: _mdTick.Time.clone(),
         OpenPrice: dPrice,
         HighPrice: dPrice,
         LowPrice: dPrice,
         ClosePrice: dPrice,
         Vol: _mdTick.Vol,
      });

      this._Add(md);

      return true;
   }
   private UpdateMinute(_mdTick: kcTickModel): boolean {
      let dPrice = _mdTick.ClosePrice;
      let t = _mdTick.Time;
      t = ModelJSExtensions.ToMinuteTime(t);

      if (this.m_mlPureHistory.length == 0 || !t.isSame(this.m_mlPureHistory[this.m_mlPureHistory.length - 1].Time)) {
         let md = ModelJSExtensions.CreateNewKLine(t, dPrice);
         ModelJSExtensions.UpdateKLineByTick_ByTick(md, _mdTick);
         this._Add(md);
      } else {
         let md = this.m_mlPureHistory[this.m_mlPureHistory.length - 1];
         ModelJSExtensions.UpdateKLineByTick_ByTick(md, _mdTick);
      }

      return true;
   }
   private UpdateDay(_mdTick: kcTickModel): boolean {
      let dPrice = _mdTick.ClosePrice;
      let t = _mdTick.Time;
      t = ModelJSExtensions.ToDayTime(t, this.m_kcUnit.Commodity?.TradeTime);

      if (this.m_mlPureHistory.length == 0 || !t.isSame(this.m_mlPureHistory[this.m_mlPureHistory.length - 1].Time)) {
         let md = ModelJSExtensions.CreateNewKLine(t, dPrice);
         ModelJSExtensions.UpdateKLineByTick_ByTick(md, _mdTick);
         this._Add(md);
      } else {
         let md = this.m_mlPureHistory[this.m_mlPureHistory.length - 1];
         ModelJSExtensions.UpdateKLineByTick_ByTick(md, _mdTick);
      }

      return true;
   }

   private _Add(_HistoryModel: kcHistoryOHLCModel): void {
      let Sort: boolean = false;

      let mdPre: kcHistoryOHLCModel | undefined = undefined;
      if (this.m_mlPureHistory.length != 0) mdPre = this.m_mlPureHistory[this.m_mlPureHistory.length - 1];

      this.m_mlPureHistory.push(_HistoryModel);

      if (mdPre != null && mdPre.Time > _HistoryModel.Time) Sort = true;

      if (Sort) this.ResortData();
   }
   private ResortData(): void {
      this.m_mlPureHistory = this.m_mlPureHistory.sort((x, y) => {
         return x.Time.valueOf() - y.Time.valueOf();
      });
   }
   private SendCallback(_ChangedType: HistoryChangedType, _Data?: kcTickModel | kcHistoryOHLCModel[]): void {
      this.m_fOnHistoryChanged?.call(this, this, _ChangedType, _Data);
   }
}
