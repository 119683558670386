import { StyleProp, ViewStyle } from "react-native";

export const HL_Left_Top: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "row",
   justifyContent: "flex-start",
   alignItems: "flex-start",
};

export const HL_Left_Center: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "row",
   justifyContent: "flex-start",
   alignItems: "center",
};

export const HL_Left_Bottom: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "row",
   justifyContent: "flex-start",
   alignItems: "flex-end",
};

/*----------------------------------------------------*/
export const HL_Center_Top: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "row",
   justifyContent: "center",
   alignItems: "flex-start",
};

export const HL_Center_Center: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "row",
   justifyContent: "center",
   alignItems: "center",
};

export const HL_Center_Bottom: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "row",
   justifyContent: "center",
   alignItems: "flex-end",
};

/*----------------------------------------------------*/
export const HL_Right_Top: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "row",
   justifyContent: "flex-end",
   alignItems: "flex-start",
};

export const HL_Right_Center: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "row",
   justifyContent: "flex-end",
   alignItems: "center",
};

export const HL_Right_Bottom: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "row",
   justifyContent: "flex-end",
   alignItems: "flex-end",
};

/*-------------------------------------------------------------------------------*/
/* 垂直 */
export const VL_Top_Left: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "column",
   justifyContent: "flex-start",
   alignItems: "flex-start",
};

export const VL_Top_Center: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "column",
   justifyContent: "flex-start",
   alignItems: "center",
};

export const VL_Top_Right: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "column",
   justifyContent: "flex-start",
   alignItems: "flex-end",
};

/*----------------------------------------------------*/
export const VL_Center_Left: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "column",
   justifyContent: "center",
   alignItems: "flex-start",
};

export const VL_Center_Center: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "column",
   justifyContent: "center",
   alignItems: "center",
};

export const VL_Center_Right: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "column",
   justifyContent: "center",
   alignItems: "flex-end",
};

/*----------------------------------------------------*/
export const VL_Bottom_Left: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "column",
   justifyContent: "flex-end",
   alignItems: "flex-start",
};

export const VL_Bottom_Center: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "column",
   justifyContent: "flex-end",
   alignItems: "center",
};

export const VL_Bottom_Right: StyleProp<ViewStyle> = {
   display: "flex",
   flexDirection: "column",
   justifyContent: "flex-end",
   alignItems: "flex-end",
};
