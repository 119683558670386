import { GetText } from "../../Locales";

export enum KLineDispalyType {
  NormalKLine = 0, // 一般K線
  //kcKLine = 1,
  USKLine = 1, // 美國線
  CloseKLine = 2, // 折線(收盤線)
}

export enum eMaType {
  SMa = 0,
  EMa = 1,
  WMa = 2,
}

export const KLineDispalyTypeToString = (
  _KLineDispalyType: KLineDispalyType
) => {
  switch (_KLineDispalyType) {
    case KLineDispalyType.NormalKLine:
      return GetText("Screen_Setting_KLineType_CandlesTick");
    case KLineDispalyType.USKLine:
      return GetText("Screen_Setting_KLineType_USLine");
    case KLineDispalyType.CloseKLine:
      return GetText("Screen_Setting_KLineType_CloseLine");
    default:
      break;
  }
  return "未定義";
};
