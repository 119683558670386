import * as React from "react";
import {
  StyleSheet,
  ScrollView,
  SafeAreaView,
  Platform,
  LayoutChangeEvent,
} from "react-native";
import { GetText } from "../Locales";
import { Text, View } from "../components/Themed";
import { kcData, kcUnitModel } from "../kcData";
import { Button } from "react-native-elements";
import { EnumHelpers, kcSetState, kcSetUnmount } from "../kcExternal";
import { Ionicons } from "@expo/vector-icons";
import { connectActionSheet } from "@expo/react-native-action-sheet";
import { kcCommodityModel } from "../kcModel";
import { CompositeNavigationProp } from "@react-navigation/native";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import {
  BottomTabParamList,
  RootStackParamList,
  TabQuoteParamList,
} from "../types";
import { StackNavigationProp } from "@react-navigation/stack";
import KC_QuoteScreenRow from "../components/KC_QuoteScreenRow";
import RegListHelper, { RegListUpdateParam } from "../kcData/RegListHelper";
import { kcColorObj, kcColor } from "../constants/Colors";
import { ReLoadApp } from "../navigation/AutoReLoadApp";

enum ActionSheetItems {
  圖表,
  商品規格,
  取消,
}

type IState = {
  mlStockCode: string[];
  bIsScreenFocus: boolean;
  bShowBest5: boolean;
};

type IProp = {
  route: any;
  navigation: CompositeNavigationProp<
    StackNavigationProp<TabQuoteParamList, "TabQuoteScreen">,
    CompositeNavigationProp<
      BottomTabNavigationProp<BottomTabParamList, "TabQuote">,
      StackNavigationProp<RootStackParamList>
    >
  >;
  showActionSheetWithOptions: any;
};

class QuoteTable extends React.Component<IProp, IState> {
  state: IState = {
    mlStockCode: [],
    bIsScreenFocus: true,
    bShowBest5: false,
  };

  /* ------------------------------------------------------------- */
  // HookFunc

  componentDidMount() {
    RegListHelper.RegListUpdateEvent_Add(this.OnRegListChanged);

    this.props.navigation.addListener("focus", () => {
      kcSetState(this, { bIsScreenFocus: true });
    });
    this.props.navigation.addListener("blur", () => {
      kcSetState(this, { bIsScreenFocus: false });
    });

    this.props.navigation.setOptions({
      headerLeft: this.Render_HeaderLeft,
      headerRight: this.Render_HeaderRight,
      headerTitle: this.Render_HeaderTitle,
    });

    const RegList = RegListHelper.GetRegList();
    this.ChangeStockList(RegList);

    // setTimeout(() => {
    //   ReLoadApp();
    // }, 3000);
  }

  componentWillUnmount() {
    RegListHelper.RegListUpdateEvent_Remove(this.OnRegListChanged);
    kcSetUnmount(this, true);
  }

  /* ------------------------------------------------------------- */

  private navigationToAdd = () => {
    this.props.navigation.navigate("TabQuoteScreen_Add");
  };

  private navigationToModify = () => {
    this.props.navigation.navigate("TabQuoteScreen_Modify");
  };

  private ChangeStockList = (_ml: string[]) => {
    // if (_ml.length > 15) _ml = _ml.slice(0, 15);
    kcSetState(this, { mlStockCode: _ml });
  };

  private OpenActionSheet = (_mdCommodity?: kcCommodityModel) => {
    if (!_mdCommodity) return;

    // let aOptions = EnumHelpers.getNames(ActionSheetItems);
    let aOptions = [
      GetText("Screen_TECP"), // 圖表
      GetText("Screen_Commodity"), // 商品規格
      GetText("Cancel"), // 取消
    ];
    if (Platform.OS === "web") {
      aOptions.push("");
    }

    this.props.showActionSheetWithOptions(
      {
        options: aOptions, //  ["圖表", "詳細情況", "取消"],
        //destructiveButtonIndex: [0, 1],
        cancelButtonIndex: ActionSheetItems.取消,
        userInterfaceStyle: "dark",
        title: _mdCommodity.StockCode,
        message: _mdCommodity.LocaleStockName(),
        showSeparators: true,
      },
      (buttonIndex: ActionSheetItems) => {
        switch (buttonIndex) {
          case ActionSheetItems.圖表:
            this.props.navigation.navigate("TabTECP", {
              screen: "TabTECPScreen",
              params: { StockCode: _mdCommodity.StockCode },
            });
            break;
          case ActionSheetItems.商品規格:
            this.props.navigation.navigate("TabQuoteScreen_Info", {
              StockCode: _mdCommodity.StockCode,
            });
            break;
        }
      }
    );
  };

  /* ------------------------------------------------------------- */
  // On

  private OnRegListChanged = (_Param: RegListUpdateParam) => {
    this.ChangeStockList(_Param.RegList);
  };

  private OnQuoteScreenRowPress = (
    _mdUnit: kcUnitModel,
    _bIsLongPress: boolean
  ) => {
    this.OpenActionSheet(_mdUnit.Commodity);
  };

  private onLayout = (e: LayoutChangeEvent) => {
    let nWidth = e.nativeEvent.layout.width;
    let nHeight = e.nativeEvent.layout.height;
    let ShowBest5 = nWidth > 520;
    if (ShowBest5 != this.state.bShowBest5)
      kcSetState(this, { bShowBest5: ShowBest5 });
  };

  /* ------------------------------------------------------------- */
  // Render

  private Render_HeaderTitle = () => {
    return (
      <View
        style={{
          backgroundColor: "#00000000",
          //flex: 1,
          width: 300,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            backgroundColor: "#00000000",
            color: kcColor("Title"),
            fontSize: 18,
          }}
          selectable={false}
        >
          {GetText("Screen_Quote")}
        </Text>
      </View>
    );
  };

  private Render_HeaderRight = () => {
    return (
      <Button
        icon={
          <Ionicons
            selectable={false}
            name="ios-add"
            size={28}
            color={kcColor("Icon")}
          />
        }
        buttonStyle={styles.button}
        onPress={this.navigationToAdd}
      />
    );
  };

  private Render_HeaderLeft = () => {
    return (
      <Button
        icon={
          <Ionicons
            selectable={false}
            name="ios-pencil"
            size={24}
            color={kcColor("Icon")}
          />
        }
        iconContainerStyle={{ alignSelf: "center" }}
        buttonStyle={styles.button}
        onPress={this.navigationToModify}
      />
    );
  };

  render() {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: kcColor("Background"),
          paddingTop: 0,
          paddingBottom: 0,
        }}
        onLayout={this.onLayout}
      >
        <ScrollView
          style={{
            backgroundColor: kcColor("Background"),
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          {this.state.mlStockCode.map((StockCode) => {
            return (
              <KC_QuoteScreenRow
                bShowBest5={this.state.bShowBest5}
                StockCode={StockCode}
                key={"QuoteRow" + StockCode}
                delCanUpdate={() => this.state.bIsScreenFocus}
                OnPress={this.OnQuoteScreenRowPress}
              />
            );
          })}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: "#00000000",
    borderColor: "#00000000",
    borderWidth: 0,
    borderRadius: 15,
  },
});

const ConnectedApp = connectActionSheet(QuoteTable);
export default ConnectedApp;
