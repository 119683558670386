import moment, { Moment } from "moment";
import { MomentExtensions } from "../kcExternal";

// kcMinDepthModel
export default class kcDepthModel {
  constructor(props: object) {
    Object.assign(this, { ...props });

    this.Time = MomentExtensions.Parse(this.Time);
  }

  /* ---------------------------Member--------------------------- */
  Time: Moment = moment(0); // DateTime (8 bytes double OADate)
  AskPrice1: number = 0; // 8 bytes
  AskVolume1: number = 0; // 2 bytes
  BidPrice1: number = 0; // 8 bytes
  BidVolume1: number = 0; // 2 bytes

  ToString() {
    let szRet = "";
    szRet += this.Time.utc().format("YYYY/MM/DD HH:mm:ss.SSS") + ", ";
    szRet += this.AskPrice1 + ", ";
    szRet += this.AskVolume1 + ", ";
    szRet += this.BidPrice1 + ", ";
    szRet += this.BidVolume1 + ", ";
    return szRet;
  }

  /* ---------------------------static Function--------------------------- */
  static FixedBufferSize: number = 28;
  static CreateFromBuffer(
    bytes: Uint8Array,
    _TimeZone: string
  ): kcDepthModel | undefined {
    if (bytes.length < kcDepthModel.FixedBufferSize) return undefined;
    let dv = new DataView(bytes.buffer);

    let nIdx = 0;

    let dOADate: number = dv.getFloat64(nIdx, true);
    let tTime: Moment = MomentExtensions.FromOADate_WithTimeZone(
      dOADate,
      _TimeZone
    );
    nIdx += 8;

    let dAskPrice1 = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let dAskVolume1 = dv.getUint16(nIdx, true);
    nIdx += 2;

    let dBidPrice1 = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let dBidVolume1 = dv.getUint16(nIdx, true);
    nIdx += 2;

    let mdTick: kcDepthModel = new kcDepthModel({
      Time: tTime,
      AskPrice1: dAskPrice1,
      AskVolume1: dAskVolume1,
      BidPrice1: dBidPrice1,
      BidVolume1: dBidVolume1,
    });

    return mdTick;
  }
}
