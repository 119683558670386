import { DisplayTextModel } from "./_Model";

const enUS: DisplayTextModel = {
  Cancel: "Cancel", // 取消
  Save: "Save", // 儲存
  Search: "Search", // 搜尋
  Login: "log in", // 登入 log in
  ChangeLocale: "繁體中文", // 變更語言

  Screen_Login_Account: "Account", // 帳號 Account
  Screen_Login_Password: "Password", // 密碼 Password
  Screen_Login_ShowPassword: "show password", // 顯示密碼 show password
  Screen_Login_RememberPassword: "remember password", // 記住密碼 remember password

  Screen_Quote: "Quote", // 報價 Quote
  Screen_Edit: "Edit", // 編輯 Edit
  Screen_Favorite: "Favorite", // 自選商品 favorite
  Screen_TECP: "Chart", // 圖表 Chart

  Quote_High: "High", // 最高 High
  Quote_Low: "Low", // 最低 Low
  Quote_Bid: "Bid", // 買進 Bid
  Quote_Ask: "Ask", // 賣出 Ask
  Quote_Vol: "Vol", // 單量 Vol

  TECP_KLine: "KLine", // K線圖 KLine
  TECP_KLine_Date: "Date", // 日期 Date
  TECP_KLine_Time: "Time", // 時間 Time
  TECP_KLine_Open: "Open", // 開盤 Open
  TECP_KLine_High: "High", // 最高 High
  TECP_KLine_Low: "Low", // 最低 Low
  TECP_KLine_Close: "Close", // 收盤 Close
  TECP_KLineSub_FinTech_Fast: "Fast", // 快線 Fast
  TECP_KLineSub_FinTech_Slow: "Slow", // 慢線 Slow
  TECP_KLineSub_FinTech_Scale: "Scale", // 升降刻度 Scale(Graduation)
  TECP_KLineSub_FinTech_Pressure: "Pressure", // 壓力 Pressure
  TECP_KLineSub_FinTech_Support: "Support", // 支撐 Support

  TECP_FinTech: "FinTech", // 觔斗雲 FinTech
  TECP_FinTech_Trend: "Trend", // 多空動向 Trend

  Screen_Account: "Account", // 帳號
  Screen_Account_Name: "User Name", // 姓名
  Screen_Account_ExpiryDate: "Expiration date", // 使用期限 expiration date
  Screen_Account_Logout: "log out", // 登出 log out
  Screen_Account_ClearData: "clear local data", // 清除本機資料 clear local data

  Screen_Commodity: "Commodity", // 商品規格
  Screen_Commodity_Contract: "contract specification", // 合約規格 contract specification
  Screen_Commodity_Exchange: "Exchange", // 交易所 exchange
  Screen_Commodity_Category: "Commodity Category", // 類別 Commodity Category
  Screen_Commodity_DecimalPlaces: "Decimal places", // 小數點位數 decimal places
  Screen_Commodity_TickSize: "TickSize", // 最小跳動點數 TickSize
  Screen_Commodity_ValuePerPoint: "Pip value", // 每點價值 Pip value
  Screen_Commodity_TradeTime: "Trading session", // 交易時段 trading session

  Screen_Setting: "technical indicators setting", // 指標設定 technical indicators setting
  Screen_Setting_ChartSetting: "Chart setting", // 圖表設定 Chart setting
  Screen_Setting_KLineColor: "KLine color", // K線顏色 KLine color
  Screen_Setting_KLineColor_RedUp: "red up. ", // 紅漲 red up.
  Screen_Setting_KLineColor_GreenDown: "green down. ", // 綠跌 green down.
  Screen_Setting_KLineColor_GreenUp: "green up. ", // 綠漲 green up.
  Screen_Setting_KLineColor_RedDown: "red down. ", // 紅跌 red down.
  Screen_Setting_KLineColor_RUGD: "red up.  green down. ", // 紅漲綠跌 red up. green down.
  Screen_Setting_KLineColor_GURD: "green up.  red down. ", // 綠漲紅跌 green up. red down.
  Screen_Setting_KLineType: "KLine type", // 圖表類型 chart type
  Screen_Setting_KLineType_CandlesTick: "Candlestick Chart", // 蠟燭圖 Candlestick Chart
  Screen_Setting_KLineType_USLine: "Bar Chart", // 美國線 Bar Chart
  Screen_Setting_KLineType_CloseLine: "Line Chart", // 折線 Line Chart
  Screen_Setting_AuxiliaryLine: "Auxiliary line", // 輔助線 Auxiliary line
  Screen_Setting_AuxiliaryLine_Daily: "daily line", // 換日線 daily line
  Screen_Setting_AuxiliaryLine_Price: "price line", // 現價線 price line
  Screen_Setting_AuxiliaryLine_None: "no auxiliary line", // 不顯示輔助線 no auxiliary line
  Screen_Setting_ShowSubChart: "Show sub-chart", // 顯示副圖 Show sub-chart

  LoginMessage_OK: "Login suceed", // 登入成功 Login suceed
  LoginMessage_01: "data error", // 資料錯誤 data error
  LoginMessage_02: "The server is busy, please try again later", // 伺服器忙碌中請稍後再試 The server is busy, please try again later
  LoginMessage_03: "Wrong account or password", // 不存在的帳號或是錯誤的密碼 Wrong account or password
  LoginMessage_04: "This account has not been activated", // 此帳號尚未啟用 This account has not been activated
  LoginMessage_05: "This account has expired", // 此帳號使用期限已過期 This account has expired
  LoginMessage_06: "Error", // 錯誤 Error
};

export default enUS;
