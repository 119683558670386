// import { ImageSourcePropType } from "react-native";
import { kcTECPCollection } from "..";
import { MathEx } from "../../kcExternal";
import { kcHistoryOHLCModel } from "../../kcModel";
import { GetText } from "../../Locales";
import { Rectangle, Point } from "../kcDrawHelper";
import { LinePath, PathPoint, SvgLinePath, SvgRectanglePath, SvgStringPath, TopInfo } from "../kcTECPCollection";
import FinTechCloudSetting from "../TECPSetting/FinTechCloudSetting";
import { XUnit } from "../XInfo";
import { YUnit } from "../YInfo";
import { kcColor_IsRedUp } from "../../constants/Colors";

// type ImageType =ImageSourcePropType & NodeRequire;
type ImageType = HTMLImageElement;
type delOnHint = (state: { State: FinTechTradeType; Image: ImageType; AnimatedValue: number }) => void;

const HintDisplayDuration = 5000;
const LeftFinTechDataCount = 12; // 升降刻度(半圓形5格那個)參數
const ForCastNum = 69; // IniDefine
const MaNum = 44; // IniDefine
const LinerMaNum = 5; // IniDefine
const LeadingFastMaNum = 15; // IniDefine
const XX = 7; // IniDefine 支撐壓力線
const YY = 14; // IniDefine 支撐壓力線
const ZZ = 17; // IniDefine 支撐壓力線

const m_fBallSize = 19;
const m_fCloudSize = 25;
const m_fSmallBallSize = 7;

const CreateImage = (_nWidth: number, _nHeight: number, _src: string) => {
   const Img = new Image(_nWidth, _nHeight);
   Img.src = _src;
   return Img;
};

// const m_bmRedSmillBall: ImageSourcePropType & NodeRequire = require("../../../assets/images/Mini_BallBuy.png"); // 紅小球
// const m_bmGreenSmillBall: ImageSourcePropType & NodeRequire = require("../../../assets/images/Mini_BallSell.png"); // 綠小球
// const m_bmRedCloud: ImageSourcePropType & NodeRequire = require("../../../assets/images/CloudR.png"); // 紅雲
// const m_bmGreenCloud: ImageSourcePropType & NodeRequire = require("../../../assets/images/CloudG.png"); // 綠雲
// const m_bmRedBall: ImageSourcePropType & NodeRequire = require("../../../assets/images/GemR.png"); // 紅球
// const m_bmGreenBall: ImageSourcePropType & NodeRequire = require("../../../assets/images/GemG.png"); // 綠球

// const m_Big_Cloud_Up_Red: ImageSourcePropType & NodeRequire = require("../../../assets/images/Big_Cloud_Up_Red.png"); // 雲 紅漲
// const m_Big_Cloud_Up_Green: ImageSourcePropType & NodeRequire = require("../../../assets/images/Big_Cloud_Up_Green.bmp"); // 雲 綠漲
// const m_Big_Cloud_Down_Red: ImageSourcePropType & NodeRequire = require("../../../assets/images/Big_Cloud_Down_Red.bmp"); // 雲 紅跌
// const m_Big_Cloud_Down_Green: ImageSourcePropType & NodeRequire = require("../../../assets/images/Big_Cloud_Down_Green.png"); // 雲 綠跌
// const m_Big_BallR: ImageSourcePropType & NodeRequire = require("../../../assets/images/Big_BallR.png"); // 火球紅
// const m_Big_BallG: ImageSourcePropType & NodeRequire = require("../../../assets/images/Big_BallG.png"); // 火球綠

// export const Image_Cloud_Base: ImageSourcePropType & NodeRequire = require("../../../assets/images/Cloud_Base.png");
// export const Image_Cloud_watch1: ImageSourcePropType & NodeRequire = require("../../../assets/images/cloud_watch1.png");
// export const Image_Cloud_watch2: ImageSourcePropType & NodeRequire = require("../../../assets/images/cloud_watch2.png");
// export const Image_Cloud_watch3: ImageSourcePropType & NodeRequire = require("../../../assets/images/cloud_watch3.png");
// export const Image_Cloud_watch4: ImageSourcePropType & NodeRequire = require("../../../assets/images/cloud_watch4.png");
// export const Image_Cloud_watch5: ImageSourcePropType & NodeRequire = require("../../../assets/images/cloud_watch5.png");

const m_bmRedSmillBall = CreateImage(7, 7, require("../../../assets/images/Mini_BallBuy.png")); // 紅小球
const m_bmGreenSmillBall = CreateImage(7, 7, require("../../../assets/images/Mini_BallSell.png")); // 綠小球
const m_bmRedCloud = CreateImage(25, 25, require("../../../assets/images/CloudR.png")); // 紅雲
const m_bmGreenCloud = CreateImage(25, 25, require("../../../assets/images/CloudG.png")); // 綠雲
const m_bmRedBall = CreateImage(18, 19, require("../../../assets/images/GemR.png")); // 紅球
const m_bmGreenBall = CreateImage(18, 19, require("../../../assets/images/GemG.png")); // 綠球

const m_Big_Cloud_Up_Red = CreateImage(134, 134, require("../../../assets/images/Big_Cloud_Up_Red.png")); // 雲 紅漲
const m_Big_Cloud_Up_Green = CreateImage(134, 134, require("../../../assets/images/Big_Cloud_Up_Green.bmp")); // 雲 綠漲
const m_Big_Cloud_Down_Red = CreateImage(134, 134, require("../../../assets/images/Big_Cloud_Down_Red.bmp")); // 雲 紅跌
const m_Big_Cloud_Down_Green = CreateImage(134, 99999, require("../../../assets/images/Big_Cloud_Down_Green.png")); // 雲 綠跌
const m_Big_BallR = CreateImage(134, 134, require("../../../assets/images/Big_BallR.png")); // 火球紅
const m_Big_BallG = CreateImage(134, 134, require("../../../assets/images/Big_BallG.png")); // 火球綠

const ImageSize_Cloud = 180;
export const Image_Cloud_Base = CreateImage(ImageSize_Cloud, ImageSize_Cloud, require("../../../assets/images/Cloud_Base.png"));
export const Image_Cloud_watch1 = CreateImage(ImageSize_Cloud, ImageSize_Cloud, require("../../../assets/images/cloud_watch1.png"));
export const Image_Cloud_watch2 = CreateImage(ImageSize_Cloud, ImageSize_Cloud, require("../../../assets/images/cloud_watch2.png"));
export const Image_Cloud_watch3 = CreateImage(ImageSize_Cloud, ImageSize_Cloud, require("../../../assets/images/cloud_watch3.png"));
export const Image_Cloud_watch4 = CreateImage(ImageSize_Cloud, ImageSize_Cloud, require("../../../assets/images/cloud_watch4.png"));
export const Image_Cloud_watch5 = CreateImage(ImageSize_Cloud, ImageSize_Cloud, require("../../../assets/images/cloud_watch5.png"));

export const GetImage_Cloud_watch = (_Idx: number) => {
   switch (_Idx) {
      case 1:
         return Image_Cloud_watch1;
      case 2:
         return Image_Cloud_watch2;
      case 3:
         return Image_Cloud_watch3;
      case 4:
         return Image_Cloud_watch4;
      case 5:
         return Image_Cloud_watch5;
   }
   return undefined;
};

export type FinTechCloudValues = {
   XUnitValues: { Value: FinTechCloudModel; XUnit: XUnit }[];
   Max?: number;
   Min?: number;
};

export default class FinTechCloudData {
   constructor(_Owner: kcTECPCollection, _MaSetting: FinTechCloudSetting) {
      this.m_Owner = _Owner;
      this.m_DataSrc = _Owner.mlOHLC;
      this.m_Setting = _MaSetting;
      this.m_Datas.length = this.m_DataSrc.length;
      this.m_ValueFlowNum = _Owner?.kcUnit?.Commodity?.FloatNum ?? 2;

      // this.m_Animated.addListener((state) => {
      //   this.SendHint(state.value);
      // });
   }

   // Data
   private m_Owner: kcTECPCollection;
   private m_DataSrc: kcHistoryOHLCModel[];
   private m_Datas: FinTechCloudModel[] = [];
   private m_Setting: FinTechCloudSetting;
   private m_ValueFlowNum;

   private m_HintIdx = -1;
   private m_HintState = FinTechTradeType.None;
   // private m_HintImage: (ImageSourcePropType & NodeRequire) | undefined = undefined;
   private m_HintAnimatedValue = 0;
   private m_HintTimeout: NodeJS.Timeout | undefined = undefined;
   // private m_Animated = new Animated.Value(0);
   private m_fOnHint: delOnHint | undefined = undefined;

   public get Setting() {
      return this.m_Setting;
   }
   public get Datas() {
      return this.m_Datas;
   }

   public get HintState() {
      if (this.m_HintAnimatedValue == 0 || this.m_HintState === FinTechTradeType.None) return undefined;

      let bIsRedUp = kcColor_IsRedUp(); // 紅漲綠跌
      let HintImage: ImageType;
      if (this.m_HintState == FinTechTradeType.LongIn) HintImage = bIsRedUp ? m_Big_Cloud_Up_Red : m_Big_Cloud_Up_Green;
      else if (this.m_HintState == FinTechTradeType.LongOut) HintImage = bIsRedUp ? m_Big_BallG : m_Big_BallR;
      else if (this.m_HintState == FinTechTradeType.ShortIn) HintImage = bIsRedUp ? m_Big_Cloud_Down_Green : m_Big_Cloud_Down_Red;
      else if (this.m_HintState == FinTechTradeType.ShortOut) HintImage = bIsRedUp ? m_Big_BallR : m_Big_BallG;

      return {
         State: this.m_HintState,
         Image: HintImage!,
         AnimatedValue: this.m_HintAnimatedValue,
      };
   }

   public OnHintCallback(_fCallback?: delOnHint) {
      this.m_fOnHint = _fCallback;
   }

   // Core Function
   public Value(Index: number) {
      if (Index < 0 || Index >= this.m_Datas.length) return undefined;

      if (!this.m_Datas[Index]) this.m_Datas[Index] = new FinTechCloudModel();

      if (!this.m_Datas[Index].HasValue) {
         /* --------------------------------------- */
         // 因為是延續的資料, 向前檢查計算
         let PreIdx: number | undefined = undefined;
         for (let i = Index - 1; i >= 0; i--) {
            if (!this.m_Datas[i] || !this.m_Datas[i].HasValue) PreIdx = i;
            else break;
         }

         if (PreIdx != undefined) {
            for (let i = PreIdx; i < Index; i++) {
               if (!this.m_Datas[i]) this.m_Datas[i] = new FinTechCloudModel();
               let mdPreMACD = this.CountValueCore(i);
               if (mdPreMACD != undefined) this.m_Datas[i].SetValue(mdPreMACD);
            }
         }
         /* --------------------------------------- */

         // setTimeout(() => {
         let mdBBand = this.CountValueCore(Index);
         if (mdBBand != undefined) this.m_Datas[Index].SetValue(mdBBand);
         // }, 500);
      }
      return this.m_Datas[Index].HasValue ? this.m_Datas[Index] : undefined;
   }
   public Init() {
      this.m_Datas.length = 0;
      this.m_Datas.length = this.m_DataSrc.length;
      this.m_InitCnt = 0;
   }
   private m_InitCnt = 0;
   public Update(_StartIdx: number, _EndIdx: number) {
      if (this.m_Datas.length != this.m_DataSrc.length) this.m_Datas.length = this.m_DataSrc.length;

      if (this.m_InitCnt < 3) {
         this.m_InitCnt++;
         for (let i = 0; i < this.m_DataSrc.length; i++) {
            if (this.m_Datas[i]) this.m_Datas[i].SetUpdate();
         }
      } else {
         for (let i = _StartIdx; i <= _EndIdx; i++) {
            if (this.m_Datas[i]) this.m_Datas[i].SetUpdate();
         }
      }
   }
   private CountValueCore(_Index: number): FinTechCloudModel | undefined {
      if (_Index < 0 || _Index >= this.m_Datas.length) return undefined;

      let mdRet = new FinTechCloudModel();

      // 延續進場狀態
      let PreType = FinTechTradeType.None;
      if (_Index - 1 >= 0 && _Index - 1 < this.m_Datas.length) {
         PreType = this.m_Datas[_Index - 1].NowType;
         mdRet.NowType = this.m_Datas[_Index - 1].NowType;
         mdRet.InPrice = this.m_Datas[_Index - 1].InPrice;
         mdRet.Signal = FinTechTradeType.None;
      }

      this.UpdateLinearClose_Func(mdRet, _Index);
      this.UpdateMaClose_Func(mdRet, _Index); // 每次都要更新

      if (mdRet.LinearClose === undefined || mdRet.MaClose === undefined) {
         return mdRet;
      }

      mdRet.MID = 0.809 * mdRet.LinearClose + 0.191 * mdRet.MaClose;
      let dl: number[] = [];
      for (let j = 0; j < LinerMaNum; j++) {
         let idx = _Index - j;
         if (idx < 0) break;

         let mdIdx = this.m_Datas[idx];
         if (mdIdx.MID !== undefined) dl.push(mdIdx.MID);
      }
      if (dl.length == 0) return mdRet;

      let Ma = 0;
      dl.forEach((v) => (Ma += v));
      Ma = Ma / dl.length;

      mdRet.LeadingSpanB = Ma;
      let PreIndex = _Index - 22;
      if (PreIndex < 0) return mdRet;
      let mdPre = this.m_Datas[PreIndex];

      if (mdPre.LeadingSpanB === undefined) return mdRet;

      let dMIDAF = mdPre.LeadingSpanB;
      mdRet.LeadingSpanA = 1.618 * mdRet.LeadingSpanB - 0.618 * dMIDAF;
      mdRet.LeadingFast = 3.382 * mdRet.LeadingSpanB - 2.382 * dMIDAF;

      let dFast: number[] = [];
      for (let j = 0; j < LeadingFastMaNum; j++) {
         let idx = _Index - j;
         if (idx < 0) break;

         let mdIdx = this.m_Datas[idx];
         if (mdIdx.LeadingFast !== undefined) dFast.push(mdIdx.LeadingFast);
      }
      if (dFast.length == 0) return mdRet;

      let FastMa = 0;
      dFast.forEach((v) => (FastMa += v));
      FastMa = FastMa / dFast.length;

      if (mdRet.LeadingSpanA !== undefined && mdRet.LeadingSpanB !== undefined) this.UpdateSignal_Func(mdRet, _Index, mdRet.LeadingSpanA, mdRet.LeadingSpanB, FastMa);

      this.UpdatePressure_Func(mdRet, _Index, PreType, dMIDAF);
      this.UpdateHint(mdRet, _Index);
      mdRet.HasValue = true;
      return mdRet;
   }
   private UpdateLinearClose_Func(_md: FinTechCloudModel, _nIdx: number) {
      // 用之前的資料 不包含此次 更新自己
      let LinearClose = 0;
      let ml: LinearData[] = [];
      for (let i = _nIdx - ForCastNum + 1; i <= _nIdx; i++) {
         if (i < 0 || i >= this.m_DataSrc.length) continue;
         let mdKLine = this.m_DataSrc[i];

         let md: LinearData = { X: i, Y: mdKLine.ClosePrice };
         ml.push(md);
      }
      if (ml.length < 2) return;
      LinearClose = FinTechCloudData.LinearRegression(ml, _nIdx);
      _md.LinearClose = LinearClose;
   }
   private UpdateMaClose_Func(_md: FinTechCloudModel, _nIdx: number) {
      // 用之前的資料 包含此次 更新自己
      let dl: number[] = [];
      for (let i = _nIdx - MaNum + 1; i <= _nIdx; i++) {
         if (i < 0 || i >= this.m_DataSrc.length) continue;

         let mdKLine = this.m_DataSrc[i];

         dl.push(mdKLine.ClosePrice);
      }
      if (dl.length == 0) return;

      let Ma = 0;
      dl.forEach((v) => {
         Ma += v;
      });

      Ma = Ma / dl.length;
      _md.MaClose = Ma;
   }
   private UpdateSignal_Func(_md: FinTechCloudModel, _nIdx: number, _dValueA: number, _dValueB: number, _dFastMA: number) {
      let NowType: FinTechTradeType = _md.NowType; // Update時會先直接用前一個狀態做現在的延續
      let PreSignal: FinTechTradeType = this.Datas[_nIdx - 1].Signal; // 前一筆的訊號

      let mdKLine = this.m_DataSrc[_nIdx];

      let dAvgClosePrice = mdKLine.ClosePrice;
      let dHighPrice3 = mdKLine.HighPrice;
      let dLowPrice3 = mdKLine.LowPrice;

      if (_nIdx - 1 >= 0 && _nIdx - 1 < this.m_DataSrc.length) {
         let mdKLinePre = this.m_DataSrc[_nIdx - 1];
         dAvgClosePrice = (dAvgClosePrice + mdKLinePre.ClosePrice) / 2;
         if (dHighPrice3 < mdKLinePre.HighPrice) dHighPrice3 = mdKLinePre.HighPrice;
         if (dLowPrice3 > mdKLinePre.LowPrice) dLowPrice3 = mdKLinePre.LowPrice;
      }

      if (_nIdx + 1 >= 0 && _nIdx + 1 < this.m_DataSrc.length) {
         let mdKLineNext = this.m_DataSrc[_nIdx + 1];
         if (dHighPrice3 < mdKLineNext.HighPrice) dHighPrice3 = mdKLineNext.HighPrice;
         if (dLowPrice3 > mdKLineNext.LowPrice) dLowPrice3 = mdKLineNext.LowPrice;
      }

      let dMax: number;
      let dMin: number;
      if (_dValueA > _dValueB) {
         dMax = _dValueA;
         dMin = _dValueB;
      } else {
         dMax = _dValueB;
         dMin = _dValueA;
      }

      if (dAvgClosePrice > dMax && _md.NowType != FinTechTradeType.LongIn) {
         // if (
         //   _nIdx == this.m_DataSrc.length - 1 &&
         //   _md.NowType != FinTechTradeType.LongIn
         // ) {
         _md.Signal = FinTechTradeType.LongIn;
         _md.NowType = FinTechTradeType.LongIn;
         _md.SignalValue = dLowPrice3;
         _md.SignalClosePrice = mdKLine.ClosePrice;
         _md.InPrice = dAvgClosePrice;
      } else if (dAvgClosePrice < dMin && _md.NowType != FinTechTradeType.ShortIn) {
         _md.Signal = FinTechTradeType.ShortIn;
         _md.NowType = FinTechTradeType.ShortIn;
         _md.SignalValue = dHighPrice3;
         _md.SignalClosePrice = mdKLine.ClosePrice;
         _md.InPrice = dAvgClosePrice;
      } else {
         switch (NowType) {
            case FinTechTradeType.LongIn:
               if (_dValueA > _dValueB && dAvgClosePrice < _dFastMA && _md.InPrice !== undefined && _md.InPrice < dAvgClosePrice && PreSignal != FinTechTradeType.LongIn) {
                  _md.Signal = FinTechTradeType.LongOut;
                  _md.SignalValue = dHighPrice3;
                  _md.SignalClosePrice = mdKLine.ClosePrice;

                  _md.InPrice = undefined;
               }
               break;
            case FinTechTradeType.ShortIn:
               if (_dValueA < _dValueB && dAvgClosePrice > _dFastMA && _md.InPrice !== undefined && _md.InPrice > dAvgClosePrice && PreSignal != FinTechTradeType.ShortIn) {
                  _md.Signal = FinTechTradeType.ShortOut;
                  _md.SignalValue = dLowPrice3;
                  _md.SignalClosePrice = mdKLine.ClosePrice;
                  _md.InPrice = undefined;
               }
               break;
            default:
               return;
         }
      }
   }
   private UpdatePressure_Func(
      _md: FinTechCloudModel,
      _nIdx: number,
      _PreType: FinTechTradeType,
      _dMIDAF: number // 支撐壓力線 (MIDAF:前22日的MIDD(LeadingSpanB))
   ) {
      if (_nIdx < 0 || _nIdx >= this.m_DataSrc.length) return;
      let mdKLineNow = this.m_DataSrc[_nIdx];

      let HIGH2 = mdKLineNow.ClosePrice > mdKLineNow.OpenPrice ? mdKLineNow.ClosePrice : mdKLineNow.OpenPrice; // HIGH2=IF(CLOSE>OPEN,CLOSE,OPEN);
      let LOW2 = mdKLineNow.ClosePrice > mdKLineNow.OpenPrice ? mdKLineNow.OpenPrice : mdKLineNow.ClosePrice; // LOW2=IF(CLOSE>OPEN,OPEN,CLOSE);
      let HIGH1 = mdKLineNow.HighPrice; // HIGH1=HIGH;
      let LOW1 = mdKLineNow.LowPrice; // LOW1=LOW;

      let dPreHighEMA = 0,
         dPreLowEMA = 0,
         dPreLA1 = 0,
         dPreLB1 = 0,
         dPreSSL = 0,
         dPreBBL = 0;
      let mdPreFinTech: FinTechCloudModel | undefined = undefined;
      if (_nIdx - 1 >= 0) {
         mdPreFinTech = this.Datas[_nIdx - 1];
         if (mdPreFinTech.HighEMA !== undefined) dPreHighEMA = mdPreFinTech.HighEMA;
         if (mdPreFinTech.LowEMA !== undefined) dPreLowEMA = mdPreFinTech.LowEMA;
         if (mdPreFinTech.LA1 !== undefined) dPreLA1 = mdPreFinTech.LA1;
         if (mdPreFinTech.LB1 !== undefined) dPreLB1 = mdPreFinTech.LB1;
         if (mdPreFinTech.SSL !== undefined) dPreSSL = mdPreFinTech.SSL;
         if (mdPreFinTech.BBL !== undefined) dPreBBL = mdPreFinTech.BBL;
      }

      let dHighEMA = this.EMA(dPreHighEMA, HIGH1, XX); // EMA(HIGH1,XX)
      let dLowEMA = this.EMA(dPreLowEMA, LOW1, XX); // EMA(LOW1,XX)
      let mdHLMA = this.GetHighLowMA(_nIdx, YY);
      let dHighMA = mdHLMA._dHighMA,
         dLowMA = mdHLMA._dLowMA; // AVR(HIGH1,YY); && AVR(LOW1,YY);

      let LA1 = 2 * dHighEMA - dHighMA; // LA1=2*EMA(HIGH1,XX)-AVR(HIGH1,YY);
      let LB1 = 2 * dLowEMA - dLowMA; // LB1=2*EMA(LOW1,XX)-AVR(LOW1,YY);
      let LA = dPreLA1 == 0 ? LA1 : (LA1 + dPreLA1) / 2; // LA=AVR(LA1,2);
      let LB = dPreLB1 == 0 ? LB1 : (LB1 + dPreLB1) / 2; // LB=AVR(LB1,2);
      let SSL = LA,
         BBL = LB;
      for (let i = _nIdx - ZZ + 1; i < _nIdx; i++) {
         if (i >= 0 && i < this.m_Datas.length) {
            let mdFinTechGet = this.m_Datas[i];
            if (mdFinTechGet.LA !== undefined && SSL < mdFinTechGet.LA)
               // SSL=HHV(LA,ZZ);
               SSL = mdFinTechGet.LA;
            if (mdFinTechGet.LB !== undefined && BBL > mdFinTechGet.LB)
               // BBL=LLV(LB,ZZ);
               BBL = mdFinTechGet.LB;
         }
      }

      // PZN>0 前有買單  PZN<0 前有賣單
      // UPPER = IF(SSL < MIDAF AND SSL > LA AND SSL == REF(SSL, 1)  AND PZN > 0, 1, 0);//壓力成立為1
      let bUPPER = SSL < _dMIDAF && SSL > LA && SSL == dPreSSL && _PreType == FinTechTradeType.LongIn;
      // LOWER=IF(BBL>MIDAF AND BBL<LB AND BBL==REF(BBL,1)  AND PZN<0 ,1,0);//支撐成立為1
      let bLOWER = BBL > _dMIDAF && BBL < LB && BBL == dPreBBL && _PreType == FinTechTradeType.ShortIn;

      //HID = IF(顯示訊號 > 0 and aaa == 1 and UPPER > 0 and REF(UPPER, 1) < 1 AND SSL > HIGH, 1, 0);
      let bHID = bUPPER && !mdPreFinTech?.UPPER && SSL > mdKLineNow.HighPrice;
      //LID = IF(顯示訊號 > 0 and aaa == 1 and LOWER > 0 and REF(LOWER, 1) < 1 AND BBL < LOW, 1, 0);
      let bLID = bLOWER && !mdPreFinTech?.LOWER && BBL < mdKLineNow.LowPrice;

      // Assign
      _md.HighEMA = dHighEMA;
      _md.LowEMA = dLowEMA;
      _md.LA1 = LA1;
      _md.LB1 = LB1;
      _md.LA = LA;
      _md.LB = LB;
      _md.SSL = SSL;
      _md.BBL = BBL;
      _md.UPPER = bUPPER;
      _md.LOWER = bLOWER;
      _md.HID = bHID;
      _md.LID = bLID;
   }
   private EMA(_dPreEMA: number, _dValue: number, _nBase: number): number {
      return (_dPreEMA * (_nBase - 1) + _dValue * 2) / (_nBase + 1);
   }
   private GetHighLowMA(_nIdx: number, _nBase: number): { _dHighMA: number; _dLowMA: number } {
      let mdRet = { _dHighMA: 0, _dLowMA: 0 };

      let lHigh: number[] = [];
      let lLow: number[] = [];
      for (let i = _nIdx - _nBase + 1; i <= _nIdx; i++) {
         if (i >= this.m_DataSrc.length) break;
         if (i < 0) continue;

         let mdKLine = this.m_DataSrc[i];

         lHigh.push(mdKLine.HighPrice);
         lLow.push(mdKLine.LowPrice);
      }
      if (lHigh.length > 0) {
         let dSum = 0;
         lHigh.forEach((v) => {
            dSum += v;
         });
         mdRet._dHighMA = dSum / lHigh.length;
      }
      if (lLow.length > 0) {
         let dSum = 0;
         lLow.forEach((v) => {
            dSum += v;
         });
         mdRet._dLowMA = dSum / lLow.length;
      }
      return mdRet;
   }
   private UpdateHint(_md: FinTechCloudModel, _nIdx: number) {
      if (this.Datas.length <= 0) return;
      let nPreCroseState = 0;
      if (_nIdx > 1) {
         let mdPre = this.Datas[_nIdx - 1];
         if (mdPre.LeadingSpanA !== undefined && mdPre.LeadingSpanB !== undefined) {
            if (mdPre.LeadingSpanA < mdPre.LeadingSpanB) nPreCroseState = -1;
            else if (mdPre.LeadingSpanA > mdPre.LeadingSpanB) nPreCroseState = 1;
         }
      }

      // 進場提示
      if (_md.Signal == FinTechTradeType.LongIn || _md.Signal == FinTechTradeType.ShortIn) {
         if (_nIdx == this.m_Datas.length - 1) {
            //this.m_PlayHint_FTType = _md.Signal;
            this.StartHint(_nIdx, _md.Signal);
         }
      }

      // 出場提示
      if (_md.Signal == FinTechTradeType.LongOut || _md.Signal == FinTechTradeType.ShortOut) {
         if (_nIdx == this.m_Datas.length - 1) {
            //this.m_PlayHint_FTType = _md.Signal;
            this.StartHint(_nIdx, _md.Signal);
         }
      }

      // 交叉提示
      if (_md.LeadingSpanA !== undefined && _md.LeadingSpanB !== undefined) {
         let nNowCroseState = 0;
         if (_md.LeadingSpanA < _md.LeadingSpanB) nNowCroseState = -1;
         else if (_md.LeadingSpanA > _md.LeadingSpanB) nNowCroseState = 1;

         if (
            (nNowCroseState == 0 && (nPreCroseState == -1 || nPreCroseState == 1)) || // 現在一樣(自己交錯)
            (nNowCroseState == 1 && nPreCroseState == -1) ||
            (nNowCroseState == -1 && nPreCroseState == 1)
         ) {
            if (_nIdx == this.m_Datas.length - 1) {
               //this.m_PlayHint_FTType = FinTechTradeType.None;
               this.StartHint(_nIdx, FinTechTradeType.None);
            }
         }
      }
   }
   private StartHint(_Index: number, _Type: FinTechTradeType) {
      this.m_HintIdx = _Index;
      this.m_HintState = _Type;

      if (_Type === FinTechTradeType.None) {
         return;
      }

      if (this.m_HintTimeout !== undefined) clearTimeout(this.m_HintTimeout);

      this.SendHint(1);
      this.m_HintTimeout = setTimeout(() => {
         this.SendHint(0);
      }, HintDisplayDuration);

      // this.m_Animated.setValue(1);
      // Animated.timing(this.m_Animated, {
      //   toValue: 0,
      //   duration: HintDisplayDuration,
      //   useNativeDriver: false,
      // }).start();
   }
   private SendHint(AnimatedValue: number) {
      if (this.m_HintState === FinTechTradeType.None) return;

      this.m_HintAnimatedValue = AnimatedValue;

      let bIsRedUp = kcColor_IsRedUp(); // 紅漲綠跌

      let HintImage: ImageType;
      if (this.m_HintState == FinTechTradeType.LongIn) HintImage = bIsRedUp ? m_Big_Cloud_Up_Red : m_Big_Cloud_Up_Green;
      else if (this.m_HintState == FinTechTradeType.LongOut) HintImage = bIsRedUp ? m_Big_BallG : m_Big_BallR;
      else if (this.m_HintState == FinTechTradeType.ShortIn) HintImage = bIsRedUp ? m_Big_Cloud_Down_Green : m_Big_Cloud_Down_Red;
      else if (this.m_HintState == FinTechTradeType.ShortOut) HintImage = bIsRedUp ? m_Big_BallR : m_Big_BallG;

      this.m_fOnHint?.call(this, {
         State: this.m_HintState,
         Image: HintImage!,
         AnimatedValue: AnimatedValue,
      });
   }

   private static LinearRegression(parray: LinearData[], _CalX: number = -1): number {
      let YRet: number = -1;
      if (parray.length < 2) return YRet;

      let averagex: number = 0;
      let averagey: number = 0;
      parray.forEach((p) => {
         averagex += p.X;
         averagey += p.Y;
      });

      averagex /= parray.length;
      averagey /= parray.length;
      let numerator = 0;
      let denominator = 0;
      parray.forEach((p) => {
         numerator += (p.X - averagex) * (p.Y - averagey);
         denominator += (p.X - averagex) * (p.X - averagex);
      });

      let RCB = numerator / denominator; //回歸係數b（Regression Coefficient）
      let RCA = averagey - RCB * averagex; //回歸係數a
      // Fun = y = RCA + (RCB)X

      if (_CalX < 0) _CalX = parray[parray.length - 1].X + 1;

      YRet = RCA + RCB * _CalX;

      return YRet;
   }
   // Update Setting Function

   public UpdateSetting(_MaSetting: FinTechCloudSetting) {
      let UpdateResault = this.m_Setting.SetSetting(_MaSetting);

      if (UpdateResault.DataChanged) {
         this.Init();
      }
   }

   // 輔助 Function
   public map<U>(callbackfn: (value: FinTechCloudModel, index: number, array: FinTechCloudModel[]) => U): U[] {
      let aRet: U[] = [];
      for (let i = 0; i < this.m_Datas.length; i++) {
         let Item = this.Value(i);
         aRet.push(callbackfn(this.m_Datas[i], i, this.m_Datas));
      }
      return aRet;
   }
   private CompareTo(_Value1: number, _Value2: number): number {
      if (_Value1 < _Value2) return -1;
      else if (_Value1 > _Value2) return 1;
      else return 0;
   }
   // Draw Function
   public GetValues(_XUnits: XUnit[]): FinTechCloudValues | undefined {
      let aValues: FinTechCloudValues["XUnitValues"] = [];
      let dMax: number | undefined = undefined;
      let dMin: number | undefined = undefined;

      for (let i = 0; i < _XUnits.length; i++) {
         let dValue = this.Value(_XUnits[i].nDataIndex);
         if (this.m_Setting.SpanAreaEnable && dValue && dValue.HasValue) {
            if (dValue.LeadingSpanA) {
               dMax = MathEx.Max(dMax, dValue.LeadingSpanA);
               dMin = MathEx.Min(dMin, dValue.LeadingSpanA);
            }
            if (dValue.LeadingSpanB) {
               dMax = MathEx.Max(dMax, dValue.LeadingSpanB);
               dMin = MathEx.Min(dMin, dValue.LeadingSpanB);
            }
            if (dValue.SignalValue) {
               dMax = MathEx.Max(dMax, dValue.SignalValue);
               dMin = MathEx.Min(dMin, dValue.SignalValue);
            }
            aValues.push({ Value: dValue, XUnit: _XUnits[i] });
         }
      }

      if (aValues.length == 0) return undefined;

      return {
         XUnitValues: aValues,
         Max: dMax,
         Min: dMin,
      };
   }
   public GetPath(_Values: FinTechCloudValues | undefined, _YUnit: YUnit): FinTechCloudPaths | undefined {
      if (!_Values || !_Values.XUnitValues || _Values.XUnitValues.length === 0) return undefined;

      const bIsRedUp = kcColor_IsRedUp(); // 紅漲綠跌

      let Path_FastUpper: PathPoint[] = []; // 快線雲帶
      let Path_SlowUpper: PathPoint[] = []; // 慢線雲帶
      let Path_Fast: PathPoint[] = []; // 快線
      let Path_Fast2: PathPoint[] = []; // 快線2
      let Path_Slow: PathPoint[] = []; // 慢線
      let Path_Slow2: PathPoint[] = []; // 慢線2
      let Path_RedSmillBall: Rectangle[] = []; // 紅小球
      let Path_GreenSmillBall: Rectangle[] = []; // 綠小球

      let Path_RedCloud: Rectangle[] = []; // 紅雲
      let Path_GreenCloud: Rectangle[] = []; // 綠雲
      let Path_RedBall: Rectangle[] = []; // 紅球
      let Path_GreenBall: Rectangle[] = []; // 綠球
      let Path_RedString: SvgStringPath[] = []; // 紅字
      let Path_RedString_Color = bIsRedUp ? "#FF2020FF" : "#20FF20FF"; // 紅字Color
      let Path_GreenString: SvgStringPath[] = []; // 綠字
      let Path_GreenString_Color = bIsRedUp ? "#20FF20FF" : "#FF2020FF"; // 綠字Color

      const PressureLine_Color = this.m_Setting.PressureLine_Color; // 壓力 波浪 & 方塊 顏色
      const SupportLine_Color = this.m_Setting.SupportLine_Color; // 支撐 波浪 & 方塊 顏色
      const PressureString_Color = this.m_Setting.PressureString_Color; // 壓力 波浪 & 方塊 顏色
      const SupportString_Color = this.m_Setting.SupportString_Color; // 支撐 波浪 & 方塊 顏色
      let Path_Pressure: Rectangle[] = []; // 壓力線
      let Path_PressureLine: PathPoint[] = []; // 壓力線 波浪
      let Path_PressureValueStr: SvgStringPath[] = []; // 壓力線 文字
      let PressureBuffer = new PressureRect();
      let Path_Support: Rectangle[] = []; // 支撐線
      let Path_SupportLine: PathPoint[] = []; // 支撐線 波浪
      let Path_SupportValueStr: SvgStringPath[] = []; // 支撐線 文字
      let SupportBuffer = new PressureRect();

      let nPreYState = 0;
      let pPreFast: Point | undefined = undefined;
      let pPreSlow: Point | undefined = undefined;
      let lpFastBuffer: Point[] = [];
      let lpSlowBuffer: Point[] = [];

      for (let i = 0; i < _Values.XUnitValues.length; i++) {
         let xUnit = _Values.XUnitValues[i].XUnit;
         let nDataIndex = xUnit.nDataIndex;
         let fXMidPos = xUnit.fXMidPos;
         let fXWidth = xUnit.fXWidth;
         let fXLeftPos = xUnit.fXLeftPos;

         if (nDataIndex < 0 || nDataIndex >= this.m_Datas.length) continue;

         let md = this.m_Datas[nDataIndex];

         // 雲帶
         if (this.m_Setting.SpanAreaEnable) {
            let PFast = { X: 0, Y: 0 },
               PSlow = { X: 0, Y: 0 };
            if (md.LeadingSpanA !== undefined && md.LeadingSpanB !== undefined) {
               let YCoordA = _YUnit.ValueToCoord(md.LeadingSpanA);
               PFast = { X: fXMidPos, Y: YCoordA };

               let YCoordB = _YUnit.ValueToCoord(md.LeadingSpanB);
               PSlow = { X: fXMidPos, Y: YCoordB };

               let nNowYState = -this.CompareTo(PFast.Y, PSlow.Y);

               if (nNowYState == 0) {
                  // 現在一樣(自己交錯)
                  if (nPreYState == 0) {
                  } else if (nPreYState == -1) {
                     if (lpFastBuffer.length > 0) {
                        lpFastBuffer.push(PFast);
                        this.AddLine_Clear(Path_Fast2, lpFastBuffer);
                     }
                     if (lpSlowBuffer.length > 0) {
                        lpSlowBuffer.push(PSlow);
                        this.AddLine_Clear(Path_Slow2, lpSlowBuffer);
                     }
                     Path_GreenSmillBall.push({
                        X: fXMidPos - m_fSmallBallSize / 2,
                        Y: (PFast.Y + PSlow.Y) / 2 - m_fSmallBallSize / 2,
                        Width: m_fSmallBallSize,
                        Height: m_fSmallBallSize,
                     });
                  } else {
                     if (lpFastBuffer.length > 0) {
                        lpFastBuffer.push(PFast);
                        this.AddLine_Clear(Path_Fast, lpFastBuffer);
                     }
                     if (lpSlowBuffer.length > 0) {
                        lpSlowBuffer.push(PSlow);
                        this.AddLine_Clear(Path_Slow, lpSlowBuffer);
                     }
                     Path_RedSmillBall.push({
                        X: fXMidPos - m_fSmallBallSize / 2,
                        Y: (PFast.Y + PSlow.Y) / 2 - m_fSmallBallSize / 2,
                        Width: m_fSmallBallSize,
                        Height: m_fSmallBallSize,
                     });
                  }
                  lpFastBuffer.push(PFast);
                  lpSlowBuffer.push(PSlow);
               } else if (nNowYState == 1) {
                  this.AddLine_Clear(Path_FastUpper, [PFast, PSlow]);
                  if (nPreYState == -1) {
                     // 直接交錯
                     if (lpFastBuffer.length > 0) {
                        lpFastBuffer.push(PFast);
                        this.AddLine_Clear(Path_Fast2, lpFastBuffer);
                     }
                     if (lpSlowBuffer.length > 0) {
                        lpSlowBuffer.push(PSlow);
                        this.AddLine_Clear(Path_Slow2, lpSlowBuffer);
                     }
                     if (pPreFast !== undefined && pPreSlow !== undefined)
                        Path_GreenSmillBall.push({
                           X: (pPreFast.X + pPreSlow.X) / 2 - m_fSmallBallSize / 2,
                           Y: (pPreFast.Y + pPreSlow.Y) / 2 - m_fSmallBallSize / 2,
                           Width: m_fSmallBallSize,
                           Height: m_fSmallBallSize,
                        });
                  } else if (nPreYState == 0) {
                  } else {
                  }
                  lpFastBuffer.push(PFast);
                  lpSlowBuffer.push(PSlow);
               } else if (nNowYState == -1) {
                  this.AddLine_Clear(Path_SlowUpper, [PFast, PSlow]);
                  if (nPreYState == 1) {
                     if (lpFastBuffer.length > 0) {
                        lpFastBuffer.push(PFast);
                        this.AddLine_Clear(Path_Fast, lpFastBuffer);
                     }
                     if (lpSlowBuffer.length > 0) {
                        lpSlowBuffer.push(PSlow);
                        this.AddLine_Clear(Path_Slow, lpSlowBuffer);
                     }
                     if (pPreFast !== undefined && pPreSlow !== undefined)
                        Path_RedSmillBall.push({
                           X: (pPreFast.X + pPreSlow.X) / 2 - m_fSmallBallSize / 2,
                           Y: (pPreFast.Y + pPreSlow.Y) / 2 - m_fSmallBallSize / 2,
                           Width: m_fSmallBallSize,
                           Height: m_fSmallBallSize,
                        });
                  } else if (nPreYState == 0) {
                  } else {
                  }
                  lpFastBuffer.push(PFast);
                  lpSlowBuffer.push(PSlow);
               }

               pPreFast = PFast;
               pPreSlow = PSlow;
               nPreYState = nNowYState;
            }
         }
         // 進出場訊號
         if (this.m_Setting.ShowSignal && md.SignalValue) {
            let bHasSignal = true;
            let Path_Img: Rectangle[] | undefined = undefined;
            let Path_Str: SvgStringPath[] | undefined = undefined;
            let fImgSize = 0;
            let bAtUpper = false;
            let szStringColor = "";
            switch (md.Signal) {
               case FinTechTradeType.LongIn: {
                  Path_Img = Path_RedCloud;
                  Path_Str = Path_RedString;
                  szStringColor = Path_RedString_Color;
                  fImgSize = m_fCloudSize;
                  bAtUpper = false;
                  break;
               }
               case FinTechTradeType.LongOut: {
                  Path_Img = Path_GreenBall;
                  Path_Str = Path_GreenString;
                  szStringColor = Path_GreenString_Color;
                  fImgSize = m_fBallSize;
                  bAtUpper = true;
                  break;
               }
               case FinTechTradeType.ShortIn: {
                  Path_Img = Path_GreenCloud;
                  Path_Str = Path_GreenString;
                  szStringColor = Path_GreenString_Color;
                  fImgSize = m_fCloudSize;
                  bAtUpper = true;
                  break;
               }
               case FinTechTradeType.ShortOut: {
                  Path_Img = Path_RedBall;
                  Path_Str = Path_RedString;
                  szStringColor = Path_RedString_Color;
                  fImgSize = m_fBallSize;
                  bAtUpper = false;
                  break;
               }
            }
            if (bHasSignal) {
               let fXPosDet = fXMidPos;
               let YCoord = _YUnit.ValueToCoord(md.SignalValue);
               let RectImg = {
                  X: fXPosDet - fImgSize / 2,
                  Y: YCoord,
                  Width: fImgSize,
                  Height: fImgSize,
               };
               let pStr: Point = { X: 0, Y: 0 };
               let szAlignment: "Near" | "Center" | "Far" = "Near";
               let szLineAlignment: "Near" | "Center" | "Far" = "Near";
               if (bAtUpper) {
                  szAlignment = "Near";
                  szLineAlignment = "Far";
                  RectImg.Y += -fImgSize - 5;
                  pStr = { X: fXPosDet, Y: RectImg.Y };
               } else {
                  szAlignment = "Near";
                  szLineAlignment = "Near";
                  RectImg.Y += 5;
                  pStr = { X: fXPosDet, Y: RectImg.Y + RectImg.Height };
               }
               Path_Img?.push(RectImg);
               if (md.SignalClosePrice !== undefined) {
                  let szSignal = md.SignalClosePrice.toFixed(_YUnit.YTickBaseFloatNum);
                  Path_Str?.push({
                     Color: szStringColor,
                     Value: szSignal,
                     X: pStr.X,
                     Y: pStr.Y,
                     dX: 0,
                     dY: 0,
                     Alignment: szAlignment,
                     LineAlignment: szLineAlignment,
                  });
               }
            }
         }
         // 壓力
         if (this.m_Setting.ShowPressureLine) {
            if (md.UPPER && md.SSL) {
               let fYS = _YUnit.ValueToCoord(md.SSL);
               let fYE = _YUnit.ValueToCoord(md.SSL * 0.99996);
               let rectf: Rectangle = {
                  X: fXLeftPos,
                  Y: fYS,
                  Width: fXWidth,
                  Height: fYE - fYS,
               };

               if (rectf.Height <= 0) rectf.Height = 1;
               if (!PressureBuffer.Expand(i, rectf)) {
                  Path_Pressure.push({ ...PressureBuffer.Rect });
                  PressureBuffer.StartBuffer(i, rectf);
               }
               this.AddWavePath(Path_PressureLine, { X: fXMidPos, Y: fYS }, false);
               //Path_PressureStr.AddString("︴", m_PressureFont, new PointF(fXMidPos + 3, fYS + 3), m_SignalFormat);

               if (md.HID) {
                  let szValue = md.SSL.toFixed(_YUnit.YTickBaseFloatNum);
                  Path_PressureValueStr.push({
                     Color: PressureString_Color,
                     Value: szValue,
                     X: fXMidPos,
                     Y: fYS - 18,
                     dX: 0,
                     dY: 0,
                     Alignment: "Near",
                     LineAlignment: "Far",
                  });
               }
            }
            if (md.LOWER && md.BBL) {
               let fYS = _YUnit.ValueToCoord(md.BBL * 1.00004);
               let fYE = _YUnit.ValueToCoord(md.BBL);
               //RectangleF rectf = RectangleF.FromLTRB(fXLeftPos, fYS, fXLeftPos + nPressureWidth, fYE);
               let rectf: Rectangle = {
                  X: fXLeftPos,
                  Y: fYS,
                  Width: fXWidth,
                  Height: fYE - fYS,
               };
               if (rectf.Height <= 0) rectf.Height = 1;
               //Path_Support.AddRectangle(rectf);
               if (!SupportBuffer.Expand(i, rectf)) {
                  Path_Support.push({ ...SupportBuffer.Rect });
                  SupportBuffer.StartBuffer(i, rectf);
               }

               this.AddWavePath(Path_SupportLine, { X: fXMidPos, Y: fYE }, true);
               if (md.LID) {
                  let szValue = md.BBL.toFixed(_YUnit.YTickBaseFloatNum);
                  Path_SupportValueStr.push({
                     Color: SupportString_Color,
                     Value: szValue,
                     X: fXMidPos,
                     Y: fYE + 15,
                     dX: 0,
                     dY: 0,
                     Alignment: "Near",
                     LineAlignment: "Near",
                  });
               }
            }
         }
      }

      if (nPreYState == -1) {
         this.AddLine_Clear(Path_Fast2, lpFastBuffer);
         this.AddLine_Clear(Path_Slow2, lpSlowBuffer);
      } else if (nPreYState == 1) {
         this.AddLine_Clear(Path_Fast, lpFastBuffer);
         this.AddLine_Clear(Path_Slow, lpSlowBuffer);
      }

      if (!PressureBuffer.bEmpty) Path_Pressure.push({ ...PressureBuffer.Rect });
      if (!SupportBuffer.bEmpty) Path_Support.push({ ...SupportBuffer.Rect });

      let BackLines: LinePath[] = [];
      BackLines.push({
         PathColor: this.m_Setting.AUpperArea_Color,
         PathWidth: this.m_Setting.AUpperArea_LineWidth,
         Points: Path_FastUpper,
      });

      BackLines.push({
         PathColor: this.m_Setting.BUpperArea_Color,
         PathWidth: this.m_Setting.BUpperArea_LineWidth,
         Points: Path_SlowUpper,
      });

      BackLines.push({
         PathColor: this.m_Setting.LeadingSpanA_Color,
         PathWidth: this.m_Setting.LeadingSpanA_LineWidth,
         Points: Path_Fast,
      });

      BackLines.push({
         PathColor: this.m_Setting.LeadingSpanA_Color2,
         PathWidth: this.m_Setting.LeadingSpanA_LineWidth,
         Points: Path_Fast2,
      });

      BackLines.push({
         PathColor: this.m_Setting.LeadingSpanB_Color,
         PathWidth: this.m_Setting.LeadingSpanB_LineWidth,
         Points: Path_Slow,
      });
      BackLines.push({
         PathColor: this.m_Setting.LeadingSpanB_Color2,
         PathWidth: this.m_Setting.LeadingSpanB_LineWidth,
         Points: Path_Slow2,
      });

      let ForeLines: LinePath[] = [];
      ForeLines.push({ PathColor: PressureLine_Color, PathWidth: 1, Points: Path_PressureLine }); // 壓力 波浪線
      ForeLines.push({ PathColor: SupportLine_Color, PathWidth: 1, Points: Path_SupportLine }); // 壓力 波浪線

      let Images: ImagePath[] = [];
      Images.push({ Image: bIsRedUp ? m_bmRedSmillBall : m_bmGreenSmillBall, Paths: Path_RedSmillBall });
      Images.push({ Image: bIsRedUp ? m_bmGreenSmillBall : m_bmRedSmillBall, Paths: Path_GreenSmillBall });
      Images.push({ Image: bIsRedUp ? m_bmRedCloud : m_bmGreenCloud, Paths: Path_RedCloud });
      Images.push({ Image: bIsRedUp ? m_bmGreenCloud : m_bmRedCloud, Paths: Path_GreenCloud });
      Images.push({ Image: bIsRedUp ? m_bmRedBall : m_bmGreenBall, Paths: Path_RedBall });
      Images.push({ Image: bIsRedUp ? m_bmGreenBall : m_bmRedBall, Paths: Path_GreenBall });

      let Rects: SvgRectanglePath[] = [];
      Rects.push({ Color: PressureLine_Color, Paths: Path_Pressure }); // 壓力 方塊
      Rects.push({ Color: SupportLine_Color, Paths: Path_Support }); // 壓力 方塊

      let strings: SvgStringPath[] = [...Path_RedString, ...Path_GreenString, ...Path_PressureValueStr, ...Path_SupportValueStr];

      let mdRet: FinTechCloudPaths = {
         BackLines: BackLines,
         ForeLines: ForeLines,
         Images: Images,
         Strings: strings,
         Rects: Rects,
      };

      return mdRet;
   }
   public GetSVGPath(_Values: FinTechCloudValues | undefined, _YUnit: YUnit): FinTechCloudSvgPaths | undefined {
      if (!_Values || !_Values.XUnitValues || _Values.XUnitValues.length === 0) return undefined;

      let Path_FastUpper: string = ""; // 快線雲帶
      let Path_SlowUpper: string = ""; // 慢線雲帶
      let Path_Fast: string = ""; // 快線
      let Path_Fast2: string = ""; // 快線2
      let Path_Slow: string = ""; // 慢線
      let Path_Slow2: string = ""; // 慢線2
      let Path_RedSmillBall: Rectangle[] = []; // 紅小球
      let Path_GreenSmillBall: Rectangle[] = []; // 綠小球

      let Path_RedCloud: Rectangle[] = []; // 紅雲
      let Path_GreenCloud: Rectangle[] = []; // 綠雲
      let Path_RedBall: Rectangle[] = []; // 紅球
      let Path_GreenBall: Rectangle[] = []; // 綠球
      let Path_RedString: SvgStringPath[] = []; // 紅字
      let Path_RedString_Color = "#FF2020FF"; // 紅字Color
      let Path_GreenString: SvgStringPath[] = []; // 綠字
      let Path_GreenString_Color = "#20FF20FF"; // 綠字Color

      let Path_Pressure: Rectangle[] = []; // 壓力線
      let Path_PressureLine: string = ""; // 壓力線 波浪
      let Path_PressureValueStr: SvgStringPath[] = []; // 壓力線 文字
      let PressureBuffer = new PressureRect();
      let Path_Support: Rectangle[] = []; // 支撐線
      let Path_SupportLine: string = ""; // 支撐線 波浪
      let Path_SupportValueStr: SvgStringPath[] = []; // 支撐線 文字
      let SupportBuffer = new PressureRect();

      let nPreYState = 0;
      let pPreFast: Point | undefined = undefined;
      let pPreSlow: Point | undefined = undefined;
      let lpFastBuffer: Point[] = [];
      let lpSlowBuffer: Point[] = [];

      for (let i = 0; i < _Values.XUnitValues.length; i++) {
         let xUnit = _Values.XUnitValues[i].XUnit;
         let nDataIndex = xUnit.nDataIndex;
         let fXMidPos = xUnit.fXMidPos;
         let fXWidth = xUnit.fXWidth;
         let fXLeftPos = xUnit.fXLeftPos;

         if (nDataIndex < 0 || nDataIndex >= this.m_Datas.length) continue;

         let md = this.m_Datas[nDataIndex];

         // 雲帶
         if (this.m_Setting.SpanAreaEnable) {
            let PFast = { X: 0, Y: 0 },
               PSlow = { X: 0, Y: 0 };
            if (md.LeadingSpanA !== undefined && md.LeadingSpanB !== undefined) {
               let YCoordA = _YUnit.ValueToCoord(md.LeadingSpanA);
               PFast = { X: fXMidPos, Y: YCoordA };

               let YCoordB = _YUnit.ValueToCoord(md.LeadingSpanB);
               PSlow = { X: fXMidPos, Y: YCoordB };

               let nNowYState = -this.CompareTo(PFast.Y, PSlow.Y);

               if (nNowYState == 0) {
                  // 現在一樣(自己交錯)
                  if (nPreYState == 0) {
                  } else if (nPreYState == -1) {
                     if (lpFastBuffer.length > 0) {
                        lpFastBuffer.push(PFast);
                        Path_Fast2 = this.AddSvgLine_Clear(Path_Fast2, lpFastBuffer);
                     }
                     if (lpSlowBuffer.length > 0) {
                        lpSlowBuffer.push(PSlow);
                        Path_Slow2 = this.AddSvgLine_Clear(Path_Slow2, lpSlowBuffer);
                     }
                     Path_GreenSmillBall.push({
                        X: fXMidPos - m_fSmallBallSize / 2,
                        Y: (PFast.Y + PSlow.Y) / 2 - m_fSmallBallSize / 2,
                        Width: m_fSmallBallSize,
                        Height: m_fSmallBallSize,
                     });
                  } else {
                     if (lpFastBuffer.length > 0) {
                        lpFastBuffer.push(PFast);
                        Path_Fast = this.AddSvgLine_Clear(Path_Fast, lpFastBuffer);
                     }
                     if (lpSlowBuffer.length > 0) {
                        lpSlowBuffer.push(PSlow);
                        Path_Slow = this.AddSvgLine_Clear(Path_Slow, lpSlowBuffer);
                     }
                     Path_RedSmillBall.push({
                        X: fXMidPos - m_fSmallBallSize / 2,
                        Y: (PFast.Y + PSlow.Y) / 2 - m_fSmallBallSize / 2,
                        Width: m_fSmallBallSize,
                        Height: m_fSmallBallSize,
                     });
                  }
                  lpFastBuffer.push(PFast);
                  lpSlowBuffer.push(PSlow);
               } else if (nNowYState == 1) {
                  Path_FastUpper = this.AddSvgLine_Clear(Path_FastUpper, [PFast, PSlow]);
                  if (nPreYState == -1) {
                     // 直接交錯
                     if (lpFastBuffer.length > 0) {
                        lpFastBuffer.push(PFast);
                        Path_Fast2 = this.AddSvgLine_Clear(Path_Fast2, lpFastBuffer);
                     }
                     if (lpSlowBuffer.length > 0) {
                        lpSlowBuffer.push(PSlow);
                        Path_Slow2 = this.AddSvgLine_Clear(Path_Slow2, lpSlowBuffer);
                     }
                     if (pPreFast !== undefined && pPreSlow !== undefined)
                        Path_GreenSmillBall.push({
                           X: (pPreFast.X + pPreSlow.X) / 2 - m_fSmallBallSize / 2,
                           Y: (pPreFast.Y + pPreSlow.Y) / 2 - m_fSmallBallSize / 2,
                           Width: m_fSmallBallSize,
                           Height: m_fSmallBallSize,
                        });
                  } else if (nPreYState == 0) {
                  } else {
                  }
                  lpFastBuffer.push(PFast);
                  lpSlowBuffer.push(PSlow);
               } else if (nNowYState == -1) {
                  Path_SlowUpper = this.AddSvgLine_Clear(Path_SlowUpper, [PFast, PSlow]);
                  if (nPreYState == 1) {
                     if (lpFastBuffer.length > 0) {
                        lpFastBuffer.push(PFast);
                        Path_Fast = this.AddSvgLine_Clear(Path_Fast, lpFastBuffer);
                     }
                     if (lpSlowBuffer.length > 0) {
                        lpSlowBuffer.push(PSlow);
                        Path_Slow = this.AddSvgLine_Clear(Path_Slow, lpSlowBuffer);
                     }
                     if (pPreFast !== undefined && pPreSlow !== undefined)
                        Path_RedSmillBall.push({
                           X: (pPreFast.X + pPreSlow.X) / 2 - m_fSmallBallSize / 2,
                           Y: (pPreFast.Y + pPreSlow.Y) / 2 - m_fSmallBallSize / 2,
                           Width: m_fSmallBallSize,
                           Height: m_fSmallBallSize,
                        });
                  } else if (nPreYState == 0) {
                  } else {
                  }
                  lpFastBuffer.push(PFast);
                  lpSlowBuffer.push(PSlow);
               }

               pPreFast = PFast;
               pPreSlow = PSlow;
               nPreYState = nNowYState;
            }
         }
         // 進出場訊號
         if (this.m_Setting.ShowSignal && md.SignalValue) {
            let bHasSignal = true;
            let Path_Img: Rectangle[] | undefined = undefined;
            let Path_Str: SvgStringPath[] | undefined = undefined;
            let fImgSize = 0;
            let bAtUpper = false;
            let szStringColor = "";
            switch (md.Signal) {
               case FinTechTradeType.LongIn: {
                  Path_Img = Path_RedCloud;
                  Path_Str = Path_RedString;
                  szStringColor = Path_RedString_Color;
                  fImgSize = m_fCloudSize;
                  bAtUpper = false;
                  break;
               }
               case FinTechTradeType.LongOut: {
                  Path_Img = Path_GreenBall;
                  Path_Str = Path_GreenString;
                  szStringColor = Path_GreenString_Color;
                  fImgSize = m_fBallSize;
                  bAtUpper = true;
                  break;
               }
               case FinTechTradeType.ShortIn: {
                  Path_Img = Path_GreenCloud;
                  Path_Str = Path_GreenString;
                  szStringColor = Path_GreenString_Color;
                  fImgSize = m_fCloudSize;
                  bAtUpper = true;
                  break;
               }
               case FinTechTradeType.ShortOut: {
                  Path_Img = Path_RedBall;
                  Path_Str = Path_RedString;
                  szStringColor = Path_RedString_Color;
                  fImgSize = m_fBallSize;
                  bAtUpper = false;
                  break;
               }
            }
            if (bHasSignal) {
               let fXPosDet = fXMidPos;
               let YCoord = _YUnit.ValueToCoord(md.SignalValue);
               let RectImg = {
                  X: fXPosDet - fImgSize / 2,
                  Y: YCoord,
                  Width: fImgSize,
                  Height: fImgSize,
               };
               let pStr: Point = { X: 0, Y: 0 };
               let szAlignment: "Near" | "Center" | "Far" = "Near";
               let szLineAlignment: "Near" | "Center" | "Far" = "Near";
               if (bAtUpper) {
                  szAlignment = "Near";
                  szLineAlignment = "Far";
                  RectImg.Y += -fImgSize - 5;
                  pStr = { X: fXPosDet, Y: RectImg.Y };
               } else {
                  szAlignment = "Near";
                  szLineAlignment = "Near";
                  RectImg.Y += 5;
                  pStr = { X: fXPosDet, Y: RectImg.Y + RectImg.Height };
               }
               Path_Img?.push(RectImg);
               if (md.SignalClosePrice !== undefined) {
                  let szSignal = md.SignalClosePrice.toFixed(_YUnit.YTickBaseFloatNum);
                  Path_Str?.push({
                     Color: szStringColor,
                     Value: szSignal,
                     X: pStr.X,
                     Y: pStr.Y,
                     dX: 0,
                     dY: 0,
                     Alignment: szAlignment,
                     LineAlignment: szLineAlignment,
                  });
               }
            }
         }
         // 壓力
         if (this.m_Setting.ShowPressureLine) {
            if (md.UPPER && md.SSL) {
               let fYS = _YUnit.ValueToCoord(md.SSL);
               let fYE = _YUnit.ValueToCoord(md.SSL * 0.99996);
               let rectf: Rectangle = {
                  X: fXLeftPos,
                  Y: fYS,
                  Width: fXWidth,
                  Height: fYE - fYS,
               };

               if (rectf.Height <= 0) rectf.Height = 1;
               if (!PressureBuffer.Expand(i, rectf)) {
                  Path_Pressure.push({ ...PressureBuffer.Rect });
                  PressureBuffer.StartBuffer(i, rectf);
               }
               Path_PressureLine = this.AddSvgWavePath(Path_PressureLine, { X: fXMidPos, Y: fYS }, false);
               //Path_PressureStr.AddString("︴", m_PressureFont, new PointF(fXMidPos + 3, fYS + 3), m_SignalFormat);

               if (md.HID) {
                  let szValue = md.SSL.toFixed(_YUnit.YTickBaseFloatNum);
                  Path_PressureValueStr.push({
                     Color: this.m_Setting.PressureString_Color,
                     Value: szValue,
                     X: fXMidPos,
                     Y: fYS - 18,
                     dX: 0,
                     dY: 0,
                     Alignment: "Near",
                     LineAlignment: "Far",
                  });
               }
            }
            if (md.LOWER && md.BBL) {
               let fYS = _YUnit.ValueToCoord(md.BBL * 1.00004);
               let fYE = _YUnit.ValueToCoord(md.BBL);
               //RectangleF rectf = RectangleF.FromLTRB(fXLeftPos, fYS, fXLeftPos + nPressureWidth, fYE);
               let rectf: Rectangle = {
                  X: fXLeftPos,
                  Y: fYS,
                  Width: fXWidth,
                  Height: fYE - fYS,
               };
               if (rectf.Height <= 0) rectf.Height = 1;
               //Path_Support.AddRectangle(rectf);
               if (!SupportBuffer.Expand(i, rectf)) {
                  Path_Support.push({ ...SupportBuffer.Rect });
                  SupportBuffer.StartBuffer(i, rectf);
               }

               Path_SupportLine = this.AddSvgWavePath(Path_SupportLine, { X: fXMidPos, Y: fYE }, true);
               if (md.LID) {
                  let szValue = md.BBL.toFixed(_YUnit.YTickBaseFloatNum);
                  Path_SupportValueStr.push({
                     Color: this.m_Setting.SupportString_Color,
                     Value: szValue,
                     X: fXMidPos,
                     Y: fYE + 15,
                     dX: 0,
                     dY: 0,
                     Alignment: "Near",
                     LineAlignment: "Near",
                  });
               }
            }
         }
      }

      if (nPreYState == -1) {
         Path_Fast2 = this.AddSvgLine_Clear(Path_Fast2, lpFastBuffer);
         Path_Slow2 = this.AddSvgLine_Clear(Path_Slow2, lpSlowBuffer);
      } else if (nPreYState == 1) {
         Path_Fast = this.AddSvgLine_Clear(Path_Fast, lpFastBuffer);
         Path_Slow = this.AddSvgLine_Clear(Path_Slow, lpSlowBuffer);
      }

      if (!PressureBuffer.bEmpty) Path_Pressure.push({ ...PressureBuffer.Rect });
      if (!SupportBuffer.bEmpty) Path_Support.push({ ...SupportBuffer.Rect });

      let BackLines: SvgLinePath[] = [];
      BackLines.push({
         PathColor: this.m_Setting.AUpperArea_Color,
         PathWidth: this.m_Setting.AUpperArea_LineWidth,
         SvgPath: Path_FastUpper,
      });

      BackLines.push({
         PathColor: this.m_Setting.BUpperArea_Color,
         PathWidth: this.m_Setting.BUpperArea_LineWidth,
         SvgPath: Path_SlowUpper,
      });

      BackLines.push({
         PathColor: this.m_Setting.LeadingSpanA_Color,
         PathWidth: this.m_Setting.LeadingSpanA_LineWidth,
         SvgPath: Path_Fast,
      });

      BackLines.push({
         PathColor: this.m_Setting.LeadingSpanA_Color2,
         PathWidth: this.m_Setting.LeadingSpanA_LineWidth,
         SvgPath: Path_Fast2,
      });

      BackLines.push({
         PathColor: this.m_Setting.LeadingSpanB_Color,
         PathWidth: this.m_Setting.LeadingSpanB_LineWidth,
         SvgPath: Path_Slow,
      });
      BackLines.push({
         PathColor: this.m_Setting.LeadingSpanB_Color2,
         PathWidth: this.m_Setting.LeadingSpanB_LineWidth,
         SvgPath: Path_Slow2,
      });

      let ForeLines: SvgLinePath[] = [];
      ForeLines.push({
         PathColor: this.m_Setting.PressureLine_Color,
         PathWidth: 1,
         SvgPath: Path_PressureLine,
      });
      ForeLines.push({
         PathColor: this.m_Setting.SupportLine_Color,
         PathWidth: 1,
         SvgPath: Path_SupportLine,
      });

      let Images: ImagePath[] = [];
      Images.push({
         Image: m_bmRedSmillBall,
         Paths: Path_RedSmillBall,
      });
      Images.push({
         Image: m_bmGreenSmillBall,
         Paths: Path_GreenSmillBall,
      });
      Images.push({
         Image: m_bmRedCloud,
         Paths: Path_RedCloud,
      });
      Images.push({
         Image: m_bmGreenCloud,
         Paths: Path_GreenCloud,
      });
      Images.push({
         Image: m_bmRedBall,
         Paths: Path_RedBall,
      });
      Images.push({
         Image: m_bmGreenBall,
         Paths: Path_GreenBall,
      });

      let Rects: SvgRectanglePath[] = [];
      Rects.push({
         Color: this.m_Setting.PressureLine_Color,
         Paths: Path_Pressure,
      });
      Rects.push({
         Color: this.m_Setting.SupportLine_Color,
         Paths: Path_Support,
      });

      let strings: SvgStringPath[] = [...Path_RedString, ...Path_GreenString, ...Path_PressureValueStr, ...Path_SupportValueStr];

      let mdRet: FinTechCloudSvgPaths = {
         BackLines: BackLines,
         ForeLines: ForeLines,
         Images: Images,
         Strings: strings,
         Rects: Rects,
      };

      return mdRet;
   }
   private AddLine_Clear(_Path: PathPoint[], _Points: Point[]): void {
      let szRet = _Path;
      for (let i = 0; i < _Points.length; i++) {
         _Path.push({ X: _Points[i].X, Y: _Points[i].Y, StartPoint: i == 0 });
      }
      _Points.length = 0;
   }
   private AddSvgLine_Clear(_Path: string, _Points: Point[]): string {
      let szRet = _Path;
      _Points = _Points.reverse();
      for (let i = 0; i < _Points.length; i++) {
         if (i == 0) szRet += `M${_Points[i].X} ${_Points[i].Y}`;
         else {
            let xDiff = MathEx.kcRound(_Points[i].X - _Points[i - 1].X, 1);
            let yDiff = MathEx.kcRound(_Points[i].Y - _Points[i - 1].Y, 1);
            szRet += `l${xDiff} ${yDiff}`;
         }
      }
      _Points.length = 0;
      return szRet;
   }
   private AddLine_Clear2(_Path: string, _Points: Point[]): string {
      let szRet = _Path;
      for (let i = 0; i < _Points.length; i++) szRet += `${i == 0 ? "M" : "L"} ${_Points[i].X} ${_Points[i].Y}`;
      _Points.length = 0;
      return szRet;
   }
   private AddWavePath(_Path: PathPoint[], _Point: Point, _bDown: boolean): void {
      let WaveCount = 5;
      let WaveW = 2;
      let WaveH = 3;
      if (!_bDown) WaveH = -WaveH;
      let PointY = _Point.Y;

      _Path.push({ X: _Point.X, Y: PointY, StartPoint: true });

      for (let i = 0; i < WaveCount; i++) {
         let PointX = i % 2 == 0 ? _Point.X - WaveW : _Point.X + WaveW;
         PointY += i == 0 ? WaveH / 2 : WaveH;
         _Path.push({ X: PointX, Y: PointY, StartPoint: false });
      }

      PointY += WaveH / 2;
      _Path.push({ X: _Point.X, Y: PointY, StartPoint: false });
   }
   private AddSvgWavePath(_Path: string, _Point: Point, _bDown: boolean): string {
      let WaveCount = 4;
      let WaveW = 3;
      let WaveH = 3;
      if (!_bDown) WaveH = -WaveH;
      let PointY = _Point.Y;

      let szRet = _Path;
      szRet += `M ${_Point.X + WaveW / 2} ${PointY}`;
      for (let i = 0; i < WaveCount; i++) {
         let XDiff = i % 2 == 0 ? -WaveW : WaveW;
         szRet += `l ${XDiff} ${WaveH}`;
      }

      return szRet;
   }
   private AddSvgWavePath2(_Path: string, _Point: Point, _bDown: boolean): string {
      let WaveCount = 5;
      let WaveW = 2;
      let WaveH = 3;
      if (!_bDown) WaveH = -WaveH;
      let PointY = _Point.Y;

      let szRet = _Path;
      szRet += `M ${_Point.X} ${PointY}`;
      for (let i = 0; i < WaveCount; i++) {
         let PointX = i % 2 == 0 ? _Point.X - WaveW : _Point.X + WaveW;
         PointY += i == 0 ? WaveH / 2 : WaveH;
         szRet += `L ${PointX} ${PointY}`;
      }

      PointY += WaveH / 2;
      szRet += `L ${_Point.X} ${PointY}`;

      return szRet;
   }
   public GetTopInfo() {
      let mdNow = this.Value(this.m_Datas.length - 1);
      let mdPre = this.Value(this.m_Datas.length - 2);
      if (!mdNow) return undefined;

      let lTopInfos: TopInfo[] = [];
      let FloatNum = this.m_Owner.kcUnit?.Commodity?.FloatNum ?? 5;

      if (this.m_Setting.SpanAreaEnable) {
         let szLeadA = mdNow.LeadingSpanA ? MathEx.kcRound(mdNow.LeadingSpanA, FloatNum) : " ";
         let szLeadB = mdNow.LeadingSpanB ? MathEx.kcRound(mdNow.LeadingSpanB, FloatNum) : " ";
         let UpDownTick = this.GetLeftFinTech(this.m_Datas.length - 1);
         let szValueColorA = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色
         let szValueColorB = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

         if (mdPre) {
            if (mdNow.LeadingSpanA && mdPre.LeadingSpanA) {
               if (mdNow.LeadingSpanA < mdPre.LeadingSpanA) szValueColorA = this.m_Owner.Setting.KLine.DownKColor;
               // 下跌 KLine下跌色
               else if (mdNow.LeadingSpanA > mdPre.LeadingSpanA) szValueColorA = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
            }

            if (mdNow.LeadingSpanB && mdPre.LeadingSpanB) {
               if (mdNow.LeadingSpanB < mdPre.LeadingSpanB) szValueColorB = this.m_Owner.Setting.KLine.DownKColor;
               // 下跌 KLine下跌色
               else if (mdNow.LeadingSpanB > mdPre.LeadingSpanB) szValueColorB = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
            }
         }

         lTopInfos.push({
            Text: GetText("TECP_KLineSub_FinTech_Fast"),
            TextColor: this.m_Setting.LeadingSpanA_Color,
            Value: `${szLeadA}`,
            //Color: szValueColorA,
         });
         lTopInfos.push({
            Text: GetText("TECP_KLineSub_FinTech_Slow"),
            TextColor: this.m_Setting.LeadingSpanB_Color,
            Value: `${szLeadB}`,
            //Color: szValueColorB,
         });
         lTopInfos.push({
            Text: GetText("TECP_KLineSub_FinTech_Scale"),
            TextColor: "#FFE780FF",
            Value: `${UpDownTick}`,
            //Color: szValueColorB,
         });
      }
      if (this.m_Setting.ShowPressureLine) {
         if (mdNow.UPPER && mdNow.SSL) {
            lTopInfos.push({
               Text: GetText("TECP_KLineSub_FinTech_Pressure"),
               TextColor: this.m_Setting.PressureLine_Color,
               Value: `${mdNow.SSL.toFixed(this.m_ValueFlowNum)}`,
               //Color: this.m_Owner.Setting.KLine.EqualKColor,
            });
         }
         if (mdNow.LOWER && mdNow.BBL) {
            lTopInfos.push({
               Text: GetText("TECP_KLineSub_FinTech_Support"),
               TextColor: this.m_Setting.SupportLine_Color,
               Value: `${mdNow.BBL.toFixed(this.m_ValueFlowNum)}`,
               //Color: this.m_Owner.Setting.KLine.EqualKColor,
            });
         }
      }

      return lTopInfos;
   }
   public AddTopInfo(_Collection: TopInfo[]) {
      let mdTop = this.GetTopInfo();
      if (mdTop && _Collection) _Collection.push(...mdTop);
      return mdTop;
   }
   public GetLeftInfo(_nDataIndex: number) {
      if (_nDataIndex < 0 || _nDataIndex >= this.m_Datas.length) return undefined;

      let mdNow = this.Value(_nDataIndex);
      let mdPre = this.Value(_nDataIndex - 1);
      if (!mdNow) return undefined;

      let lTopInfos: TopInfo[] = [];
      let FloatNum = this.m_Owner.kcUnit?.Commodity?.FloatNum ?? 5;

      if (this.m_Setting.SpanAreaEnable) {
         let szLeadA = mdNow.LeadingSpanA ? MathEx.kcRound(mdNow.LeadingSpanA, FloatNum) : " ";
         let szLeadB = mdNow.LeadingSpanB ? MathEx.kcRound(mdNow.LeadingSpanB, FloatNum) : " ";
         let szValueColorA = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色
         let szValueColorB = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

         if (mdPre) {
            if (mdNow.LeadingSpanA && mdPre.LeadingSpanA) {
               if (mdNow.LeadingSpanA < mdPre.LeadingSpanA) szValueColorA = this.m_Owner.Setting.KLine.DownKColor;
               // 下跌 KLine下跌色
               else if (mdNow.LeadingSpanA > mdPre.LeadingSpanA) szValueColorA = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
            }

            if (mdNow.LeadingSpanB && mdPre.LeadingSpanB) {
               if (mdNow.LeadingSpanB < mdPre.LeadingSpanB) szValueColorB = this.m_Owner.Setting.KLine.DownKColor;
               // 下跌 KLine下跌色
               else if (mdNow.LeadingSpanB > mdPre.LeadingSpanB) szValueColorB = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
            }
         }

         lTopInfos.push({
            Text: GetText("TECP_KLineSub_FinTech_Fast"),
            TextColor: this.m_Setting.LeadingSpanA_Color,
            Value: `${szLeadA}`,
            Color: szValueColorA,
         });
         lTopInfos.push({
            Text: GetText("TECP_KLineSub_FinTech_Slow"),
            TextColor: this.m_Setting.LeadingSpanB_Color,
            Value: `${szLeadB}`,
            Color: szValueColorB,
         });
      }
      if (this.m_Setting.ShowPressureLine) {
         if (mdNow.UPPER) {
            lTopInfos.push({
               Text: GetText("TECP_KLineSub_FinTech_Pressure"),
               TextColor: this.m_Setting.PressureLine_Color,
               Value: `${mdNow.SSL?.toFixed(this.m_ValueFlowNum)}`,
               Color: this.m_Owner.Setting.KLine.EqualKColor,
            });
         }
         if (mdNow.LOWER) {
            lTopInfos.push({
               Text: GetText("TECP_KLineSub_FinTech_Support"),
               TextColor: this.m_Setting.SupportLine_Color,
               Value: `${mdNow.BBL?.toFixed(this.m_ValueFlowNum)}`,
               Color: this.m_Owner.Setting.KLine.EqualKColor,
            });
         }
      }

      return lTopInfos;
   }
   public AddLeftInfo(_nDataIndex: number, _Collection: TopInfo[]) {
      let mdLeft = this.GetLeftInfo(_nDataIndex);
      if (mdLeft && _Collection) _Collection.push(...mdLeft);
      return mdLeft;
   }

   public GetLeftFinTech(_nDataIndex: number): number {
      let dMaxClose: number | undefined = undefined;
      let dMinClose: number | undefined = undefined;
      let dNowClose: number | undefined = undefined;

      for (let i = 0; i < LeftFinTechDataCount; i++) {
         let nIndex = _nDataIndex - i;
         let mdKLine = this.m_Owner.GetOHLCEx(nIndex);
         if (mdKLine) {
            if (dMaxClose === undefined || dMaxClose < mdKLine.ClosePrice) dMaxClose = mdKLine.ClosePrice;
            if (dMinClose === undefined || dMinClose > mdKLine.ClosePrice) dMinClose = mdKLine.ClosePrice;
            if (i == 0) dNowClose = mdKLine.ClosePrice;
         }
      }

      let nRet = -1;

      if (dNowClose !== undefined && dMaxClose !== undefined && dMinClose !== undefined) {
         if (dNowClose > dMaxClose * 0.8 + dMinClose * 0.2) nRet = 0;
         else if (dNowClose > dMaxClose * 0.6 + dMinClose * 0.4) nRet = 1;
         else if (dNowClose > dMaxClose * 0.4 + dMinClose * 0.6) nRet = 2;
         else if (dNowClose > dMaxClose * 0.2 + dMinClose * 0.8) nRet = 3;
         else nRet = 4;
      }
      return nRet + 1;
   }
}
enum FinTechTradeType {
   None,
   LongIn,
   LongOut,
   ShortIn,
   ShortOut,
}
enum HintDisplayMode {
   None = 0x00,
   FirstDispaly = 0x01,
   LastData = 0x02,
}
class FinTechCloudModel {
   constructor() {}

   private m_HasValue: boolean = false;

   LeadingFast?: number; // 更快
   LeadingSpanA?: number; // 先行帶A
   LeadingSpanB?: number; // 先行帶B

   LinearClose?: number; // 收盤價線性回歸
   MaClose?: number; // 基本MA
   MID?: number; // LinearClose MaClose組合而來

   NowType: FinTechTradeType = FinTechTradeType.None; // 進場狀態
   Signal: FinTechTradeType = FinTechTradeType.None; // 當前訊號
   SignalValue?: number; // 訊號位置
   SignalClosePrice?: number; // 訊號收盤價
   InPrice?: number; // 進場價錢

   HighEMA?: number; // 最高價EMA
   LowEMA?: number; // 最低價EMA
   LA1?: number;
   LB1?: number;
   LA?: number; // LA1的2日平均
   LB?: number; // LB1的2日平均
   SSL?: number; // LA的N日最大值
   BBL?: number; // LB的N日最小值
   UPPER: boolean = false; // 壓力訊號
   LOWER: boolean = false; // 支撐訊號
   HID: boolean = false; // 壓力訊號文字 UPPER String
   LID: boolean = false; // 支撐訊號文字 LOWER String

   public get HasValue() {
      return this.m_HasValue;
   }
   public set HasValue(_Value: boolean) {
      this.m_HasValue = _Value;
   }

   public SetValue(_mdValue: FinTechCloudModel | FinTechCloudModelProps) {
      if (_mdValue) {
         this.LeadingFast = _mdValue.LeadingFast;
         this.LeadingSpanA = _mdValue.LeadingSpanA;
         this.LeadingSpanB = _mdValue.LeadingSpanB;
         this.LinearClose = _mdValue.LinearClose;
         this.MaClose = _mdValue.MaClose;
         this.MID = _mdValue.MID;
         if (_mdValue.NowType !== undefined) this.NowType = _mdValue.NowType;
         if (_mdValue.Signal !== undefined) this.Signal = _mdValue.Signal;
         this.SignalValue = _mdValue.SignalValue;
         this.SignalClosePrice = _mdValue.SignalClosePrice;
         this.InPrice = _mdValue.InPrice;
         this.HighEMA = _mdValue.HighEMA;
         this.LowEMA = _mdValue.LowEMA;
         this.LA1 = _mdValue.LA1;
         this.LB1 = _mdValue.LB1;
         this.LA = _mdValue.LA;
         this.LB = _mdValue.LB;
         this.SSL = _mdValue.SSL;
         this.BBL = _mdValue.BBL;
         if (_mdValue.UPPER !== undefined) this.UPPER = _mdValue.UPPER;
         if (_mdValue.LOWER !== undefined) this.LOWER = _mdValue.LOWER;
         if (_mdValue.HID !== undefined) this.HID = _mdValue.HID;
         if (_mdValue.LID !== undefined) this.LID = _mdValue.LID;

         this.m_HasValue = true;
      }
   }

   public SetUpdate() {
      this.m_HasValue = false;
   }
}

type FinTechCloudModelProps = {
   LeadingFast?: number; // 更快
   LeadingSpanA?: number; // 先行帶A
   LeadingSpanB?: number; // 先行帶B

   LinearClose?: number; // 收盤價線性回歸
   MaClose?: number; // 基本MA
   MID?: number; // LinearClose MaClose組合而來

   NowType?: FinTechTradeType; // 進場狀態
   Signal?: FinTechTradeType; // 當前訊號
   SignalValue?: number; // 訊號位置
   SignalClosePrice?: number; // 訊號收盤價
   InPrice?: number; // 進場價錢

   HighEMA?: number; // 最高價EMA
   LowEMA?: number; // 最低價EMA
   LA1?: number;
   LB1?: number;
   LA?: number; // LA1的2日平均
   LB?: number; // LB1的2日平均
   SSL?: number; // LA的N日最大值
   BBL?: number; // LB的N日最小值
   UPPER?: boolean; // 壓力訊號
   LOWER?: boolean; // 支撐訊號
   HID?: boolean; // 壓力訊號文字 UPPER String
   LID?: boolean; // 支撐訊號文字 LOWER String
};
type LinearData = {
   X: number;
   Y: number;
};
class PressureRect {
   public bEmpty: boolean = true;
   public nIndex: number = -1;
   public Rect: Rectangle = { X: 0, Y: 0, Width: 0, Height: 0 };

   public Expand(_nIndex: number, _Rect: Rectangle): boolean {
      if (this.bEmpty) {
         this.StartBuffer(_nIndex, _Rect);
         return true;
      }
      if (_nIndex != this.nIndex + 1 && _nIndex != this.nIndex - 1) return false;
      if (this.Rect.Y != _Rect.Y || this.Rect.Y + this.Rect.Height != _Rect.Y + _Rect.Height) return false;

      this.nIndex = _nIndex;
      let fLeft = this.Rect.X;
      let fRight = this.Rect.X + this.Rect.Width;
      if (fLeft > _Rect.X) fLeft = _Rect.X;
      if (fRight < _Rect.X + _Rect.Width) fRight = _Rect.X + _Rect.Width;

      this.Rect.X = fLeft;
      this.Rect.Width = MathEx.kcRound(fRight - fLeft, 1);
      return true;
   }
   public StartBuffer(_nIndex: number, _Rect: Rectangle): void {
      this.bEmpty = false;
      this.nIndex = _nIndex;
      this.Rect = { ..._Rect };
   }
}

export type ImagePath = {
   Image: ImageType;
   Paths: Rectangle[];
};

export type FinTechCloudPaths = {
   BackLines: LinePath[];
   ForeLines: LinePath[];
   Images: ImagePath[];
   Strings: SvgStringPath[];
   Rects: SvgRectanglePath[];
};

export type FinTechCloudSvgPaths = {
   BackLines: SvgLinePath[];
   ForeLines: SvgLinePath[];
   Images: ImagePath[];
   Strings: SvgStringPath[];
   Rects: SvgRectanglePath[];
};
