export default class kcPriceScaleModel {
  constructor(props: object) {
    Object.assign(this, { ...props });
  }

  /* ---------------------------Member--------------------------- */
  MinPrice: number = 0;
  MaxPrice: number = 0;
  TickPrice: number = 0;

  ToString() {
    let szRet = "";
    szRet += this.MinPrice + ", ";
    szRet += this.MaxPrice + ", ";
    szRet += this.TickPrice;
    return szRet;
  }

  /* ---------------------------static Function--------------------------- */
}
