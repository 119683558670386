// import { isMobile, isDesktop, isConsole } from "react-device-detect";

// declare type delGlobalSizeChange = () => void;
// const OnGlobalSizeChange_Event: delGlobalSizeChange[] = [];

// var bIsAddEventListener = false;
// const Size = { Width: 0, Height: 0 };
export const Device = { /*IsDesktop: false, IsHorizontal: false,*/ PixelRatio: window.devicePixelRatio, PixelRatio_Enable: window.devicePixelRatio !== 1 };
//export const Device = { /*IsDesktop: false, IsHorizontal: false,*/ PixelRatio: 1, PixelRatio_Enable: false };

// function Init() {
//    fOnResize();
//    if (!bIsAddEventListener) {
//       window.addEventListener("resize", fOnResize);
//       bIsAddEventListener = true;
//    }
// }

// function Dispose() {
//    window.removeEventListener("resize", fOnResize);
//    bIsAddEventListener = false;
// }

// function fOnResize() {
//    Size.Width = document.documentElement.clientWidth;
//    Size.Height = document.documentElement.clientHeight;
//    Device.IsDesktop = isDesktop;
//    Device.IsHorizontal = fIsHorizontal();
//    Device.PixelRatio = window.devicePixelRatio;
//    Device.PixelRatio_Enable = window.devicePixelRatio !== 1;

//    Send Event
//    for (let i = 0; i < OnGlobalSizeChange_Event.length; i++) {
//       OnGlobalSizeChange_Event[i]();
//    }
// }

// export function GlobalSizeEvent_Add(_Callback: delGlobalSizeChange) {
//    if (_Callback) OnGlobalSizeChange_Event.push(_Callback);
// }

// export function GlobalSizeEvent_Remove(_Callback: delGlobalSizeChange) {
//    if (_Callback) {
//       let nIdx = OnGlobalSizeChange_Event.indexOf(_Callback);
//       if (nIdx >= 0) OnGlobalSizeChange_Event.splice(nIdx, 1);
//    }
// }

// function fIsHorizontal() {
//    // 嘗試了很多次了 不能用window.orientation 來判斷
//    // 會有滿滿的問題

//    if (Device.IsDesktop) return false;
//    if (Size.Width > Size.Height && Size.Height < 768) return true;
//    return false;
// }

const kcGlobalSize = {
   // Size: Size,
   // Init: Init,
   // Dispose: Dispose,
   Device: Device,
};

export default kcGlobalSize;
