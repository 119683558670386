type BBandDataSettingType = {};
type BBandDrawSettingType = {
  UpdateMaxMinLimit?: boolean;

  SpanAreaEnable?: boolean;
  ShowSignal?: boolean;
  ShowPressureLine?: boolean;

  AUpperArea_Color?: string;
  AUpperArea_LineWidth?: number;

  BUpperArea_Color?: string;
  BUpperArea_LineWidth?: number;

  LeadingSpanA_Color?: string;
  LeadingSpanA_Color2?: string;
  LeadingSpanA_LineWidth?: number;

  LeadingSpanB_Color?: string;
  LeadingSpanB_Color2?: string;
  LeadingSpanB_LineWidth?: number;

  PressureLine_Color?: string;
  PressureLine_Width?: number;
  PressureString_Color?: string;

  SupportLine_Color?: string;
  SupportLine_Width?: number;
  SupportString_Color?: string;
};
export default class FinTechCloudSetting {
  constructor(props: object) {
    Object.assign(this, { ...props });
  }

  UpdateMaxMinLimit: boolean = true;

  SpanAreaEnable: boolean = true; // 雲帶開關
  ShowSignal: boolean = true; // 顯示進出場訊號開關
  ShowPressureLine: boolean = true; // 壓力支撐開關

  AUpperArea_Color = "#572500"; // 雲帶 快線在上
  AUpperArea_LineWidth: number = 5; // 雲帶 快線在上

  BUpperArea_Color = "#00008E"; // 雲帶 慢線在上
  BUpperArea_LineWidth: number = 5; // 雲帶 慢線在上

  LeadingSpanA_Color = "#FFA466"; // 快線
  LeadingSpanA_Color2 = "#0073D0"; // 快線2 // 0x009BD0
  LeadingSpanA_LineWidth: number = 3; // 快線

  LeadingSpanB_Color = "#FF8000"; // 慢線
  LeadingSpanB_Color2 = "#249CFF"; // 慢線2
  LeadingSpanB_LineWidth: number = 3; // 慢線

  PressureLine_Color = "#00FFFF"; // 壓力線
  PressureLine_Width: number = 6; // 壓力線
  PressureString_Color = "#0080C0"; // 壓力線文字顏色

  SupportLine_Color = "#FF80FF"; // 支撐線
  SupportLine_Width: number = 6; // 支撐線
  SupportString_Color = "#A800A8"; // 支撐線文字顏色

  public Clone(): FinTechCloudSetting {
    return new FinTechCloudSetting(this);
  }
  public SetGlobalSetting(_Setting: FinTechCloudSetting) {
    Object.assign(this, _Setting);
  }

  public SetSetting(_Setting: (BBandDataSettingType & BBandDrawSettingType) | FinTechCloudSetting): {
    DataChanged: boolean;
    DrawChanged: boolean;
  } {
    let Reasult = { DataChanged: false, DrawChanged: false };

    if (_Setting) {
      // DataChanged

      // DrawChanged
      if (_Setting.UpdateMaxMinLimit !== undefined && _Setting.UpdateMaxMinLimit !== this.UpdateMaxMinLimit) {
        this.UpdateMaxMinLimit = _Setting.UpdateMaxMinLimit;
        Reasult.DrawChanged = true;
      }

      if (_Setting.SpanAreaEnable !== undefined && _Setting.SpanAreaEnable !== this.SpanAreaEnable) {
        this.SpanAreaEnable = _Setting.SpanAreaEnable;
        Reasult.DrawChanged = true;
      }
      if (_Setting.ShowSignal !== undefined && _Setting.ShowSignal !== this.ShowSignal) {
        this.ShowSignal = _Setting.ShowSignal;
        Reasult.DrawChanged = true;
      }

      if (_Setting.ShowPressureLine !== undefined && _Setting.ShowPressureLine !== this.ShowPressureLine) {
        this.ShowPressureLine = _Setting.ShowPressureLine;
        Reasult.DrawChanged = true;
      }

      if (_Setting.AUpperArea_Color !== undefined && _Setting.AUpperArea_Color !== this.AUpperArea_Color) {
        this.AUpperArea_Color = _Setting.AUpperArea_Color;
        Reasult.DrawChanged = true;
      }
      if (_Setting.AUpperArea_LineWidth !== undefined && _Setting.AUpperArea_LineWidth !== this.AUpperArea_LineWidth) {
        this.AUpperArea_LineWidth = _Setting.AUpperArea_LineWidth;
        Reasult.DrawChanged = true;
      }
      if (_Setting.BUpperArea_Color !== undefined && _Setting.BUpperArea_Color !== this.BUpperArea_Color) {
        this.BUpperArea_Color = _Setting.BUpperArea_Color;
        Reasult.DrawChanged = true;
      }
      if (_Setting.BUpperArea_LineWidth !== undefined && _Setting.BUpperArea_LineWidth !== this.BUpperArea_LineWidth) {
        this.BUpperArea_LineWidth = _Setting.BUpperArea_LineWidth;
        Reasult.DrawChanged = true;
      }
      if (_Setting.LeadingSpanA_Color !== undefined && _Setting.LeadingSpanA_Color !== this.LeadingSpanA_Color) {
        this.LeadingSpanA_Color = _Setting.LeadingSpanA_Color;
        Reasult.DrawChanged = true;
      }
      if (_Setting.LeadingSpanA_Color2 !== undefined && _Setting.LeadingSpanA_Color2 !== this.LeadingSpanA_Color2) {
        this.LeadingSpanA_Color2 = _Setting.LeadingSpanA_Color2;
        Reasult.DrawChanged = true;
      }
      if (_Setting.LeadingSpanA_LineWidth !== undefined && _Setting.LeadingSpanA_LineWidth !== this.LeadingSpanA_LineWidth) {
        this.LeadingSpanA_LineWidth = _Setting.LeadingSpanA_LineWidth;
        Reasult.DrawChanged = true;
      }

      if (_Setting.LeadingSpanB_Color !== undefined && _Setting.LeadingSpanB_Color !== this.LeadingSpanB_Color) {
        this.LeadingSpanB_Color = _Setting.LeadingSpanB_Color;
        Reasult.DrawChanged = true;
      }
      if (_Setting.LeadingSpanB_Color2 !== undefined && _Setting.LeadingSpanB_Color2 !== this.LeadingSpanB_Color2) {
        this.LeadingSpanB_Color2 = _Setting.LeadingSpanB_Color2;
        Reasult.DrawChanged = true;
      }
      if (_Setting.LeadingSpanB_LineWidth !== undefined && _Setting.LeadingSpanB_LineWidth !== this.LeadingSpanB_LineWidth) {
        this.LeadingSpanB_LineWidth = _Setting.LeadingSpanB_LineWidth;
        Reasult.DrawChanged = true;
      }

      if (_Setting.PressureLine_Color !== undefined && _Setting.PressureLine_Color !== this.PressureLine_Color) {
        this.PressureLine_Color = _Setting.PressureLine_Color;
        Reasult.DrawChanged = true;
      }
      if (_Setting.PressureLine_Width !== undefined && _Setting.PressureLine_Width !== this.PressureLine_Width) {
        this.PressureLine_Width = _Setting.PressureLine_Width;
        Reasult.DrawChanged = true;
      }
      if (_Setting.PressureString_Color !== undefined && _Setting.PressureString_Color !== this.PressureString_Color) {
        this.PressureString_Color = _Setting.PressureString_Color;
        Reasult.DrawChanged = true;
      }

      if (_Setting.SupportLine_Color !== undefined && _Setting.SupportLine_Color !== this.SupportLine_Color) {
        this.SupportLine_Color = _Setting.SupportLine_Color;
        Reasult.DrawChanged = true;
      }
      if (_Setting.SupportLine_Width !== undefined && _Setting.SupportLine_Width !== this.SupportLine_Width) {
        this.SupportLine_Width = _Setting.SupportLine_Width;
        Reasult.DrawChanged = true;
      }
      if (_Setting.SupportString_Color !== undefined && _Setting.SupportString_Color !== this.SupportString_Color) {
        this.SupportString_Color = _Setting.SupportString_Color;
        Reasult.DrawChanged = true;
      }
    }

    return Reasult;
  }
  public ToDefault() {}
}
