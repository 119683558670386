import * as React from "react";
import {
  StyleSheet,
  ScrollView,
  StyleProp,
  TextStyle,
  ScaledSize,
  Dimensions,
  SafeAreaView,
} from "react-native";
import { Text, View } from "../components/Themed";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { Button } from "react-native-elements";
import { BottomTabParamList, TabTECPParamList } from "../types";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import { CompositeNavigationProp, RouteProp } from "@react-navigation/native";
import {
  StackHeaderTitleProps,
  StackNavigationProp,
} from "@react-navigation/stack";
import { kcColor } from "../constants/Colors";
import { Ionicons } from "@expo/vector-icons";
import { TECPSettingEditHelper } from "../kcTECP";
import TECPSetting, { TECPSettingHelper } from "../kcTECP/TECPSetting";
import TECPSettingControl from "../components/kcTECPSettingControl";
import { GetText } from "../Locales";

type IProp = {
  route: RouteProp<TabTECPParamList, "TabTECPScreen_Setting">;
  navigation: CompositeNavigationProp<
    StackNavigationProp<TabTECPParamList, "TabTECPScreen_Setting">,
    BottomTabNavigationProp<BottomTabParamList, "TabTECP">
  >;
};
type IState = {
  bIsScreenFocus: boolean;
  FixedUpdate: boolean;
  DimensionsWindow: ScaledSize;
};

class TECPSettingScreen extends React.Component<IProp, IState> {
  constructor(props: any) {
    super(props);

    if (TECPSettingEditHelper.SettingBuffer)
      this.m_TECPSetting = TECPSettingEditHelper.SettingBuffer.Setting;
    else this.m_TECPSetting = TECPSettingHelper.CreateDefault();
  }

  state: IState = {
    bIsScreenFocus: false,
    FixedUpdate: false,
    DimensionsWindow: Dimensions.get("window"),
  };

  m_TECPSetting: TECPSetting;

  componentDidMount() {
    this.props.navigation.addListener("focus", this.OnFocus);
    this.props.navigation.addListener("blur", this.OnBlur);
    Dimensions.addEventListener("change", this.OnDimensionsChange);

    this.props.navigation.setOptions({
      headerLeft: this.Render_HeaderLeft,
      headerRight: this.Render_HeaderRight,
      headerTitle: this.Render_HeaderTitle,
    });
  }

  componentWillUnmount() {
    this.props.navigation.removeListener("focus", this.OnFocus);
    this.props.navigation.removeListener("blur", this.OnBlur);
    Dimensions.removeEventListener("change", this.OnDimensionsChange);
    kcSetUnmount(this, true);
  }

  private FixedRedrawUI = () => {
    this.setState({ FixedUpdate: !this.state.FixedUpdate });
  };
  private InitSetting = async () => {
    if (TECPSettingEditHelper.SettingBuffer) {
      let setting = await TECPSettingEditHelper.SettingBuffer.GetSettingAsync(
        true
      );
      this.FixedRedrawUI();
    }
  };
  private GoBack = () => {
    this.props.navigation.pop();
  };

  /*---------------------------------------------------------------------------- */
  private OnFocus = () => {
    this.InitSetting();
    kcSetState(this, { bIsScreenFocus: true });
  };

  private OnBlur = () => {
    kcSetState(this, { bIsScreenFocus: false });
  };

  private OnDimensionsChange = ({
    window,
    screen,
  }: {
    window: ScaledSize;
    screen: ScaledSize;
  }) => {
    this.setState({ DimensionsWindow: window });
  };
  private OnHeadLeftClick = () => {
    this.GoBack();
  };
  private OnHeadRightClick = () => {};

  /* -------------------------------------------------------------------- */
  // SubPage
  // private Goto_Setting_BBand = () => {
  //   this.props.navigation.push("TabTECPScreen_Setting_BBand");
  // };
  private Goto_Setting_G_HLine = () => {
    this.props.navigation.push("TabTECPScreen_Setting_G_HLine");
  };
  // private Goto_Setting_KD = () => {
  //   this.props.navigation.push("TabTECPScreen_Setting_KD");
  // };
  private Goto_Setting_KLine_Color = () => {
    this.props.navigation.push("TabTECPScreen_Setting_KLine_Color");
  };
  private Goto_Setting_KLine_Type = () => {
    this.props.navigation.push("TabTECPScreen_Setting_KLine_Type");
  };
  // private Goto_Setting_MA = () => {
  //   this.props.navigation.push("TabTECPScreen_Setting_MA");
  // };
  // private Goto_Setting_MACD = () => {
  //   this.props.navigation.push("TabTECPScreen_Setting_MACD");
  // };
  // private Goto_Setting_RSI = () => {
  //   this.props.navigation.push("TabTECPScreen_Setting_RSI");
  // };
  // private GetSettingString_BBand = () => {
  //   return `(${this.m_TECPSetting.BBand.MaBase},${this.m_TECPSetting.BBand.SDBase})`;
  // };
  private GetSettingString_G_HLine = () => {
    let Texts: string[] = [];

    if (this.m_TECPSetting.Global.EnableDailyVGridLine)
      Texts.push(GetText("Screen_Setting_AuxiliaryLine_Daily"));
    if (this.m_TECPSetting.Global.EnableNowPriceHLine)
      Texts.push(GetText("Screen_Setting_AuxiliaryLine_Price"));

    if (Texts.length === 0)
      Texts.push(GetText("Screen_Setting_AuxiliaryLine_None"));

    return Texts.join(", ");
  };
  // private GetSettingString_KD = () => {
  //   return `${this.m_TECPSetting.KD.KDBase}`;
  // };
  private GetSettingString_KLine_Color = () => {
    return this.m_TECPSetting.KLine.UpDownStyleToString();
  };
  private GetSettingString_KLine_Type = () => {
    return `${this.m_TECPSetting.KLine.DispalyTypeToString()}`;
  };
  // private GetSettingString_MA = () => {
  //   return `(${this.m_TECPSetting.KLine_Ma0.MaDayBase},${this.m_TECPSetting.KLine_Ma1.MaDayBase},${this.m_TECPSetting.KLine_Ma2.MaDayBase},${this.m_TECPSetting.KLine_Ma3.MaDayBase})`;
  // };
  // private GetSettingString_MACD = () => {
  //   return `(${this.m_TECPSetting.MACD.EMA1_Base},${this.m_TECPSetting.MACD.EMA2_Base},${this.m_TECPSetting.MACD.MACD_Base})`;
  // };
  // private GetSettingString_RSI = () => {
  //   return `(${this.m_TECPSetting.RSI.RSI1_Base},${this.m_TECPSetting.RSI.RSI2_Base})`;
  // };

  /* -------------------------------------------------------------------- */
  // Render
  private Render_HeaderLeft = () => {
    return (
      <Button
        // style={{ width: 48, height: 48 }}
        icon={
          <Ionicons name="ios-chevron-back" size={24} color={kcColor("Icon")} />
        }
        iconContainerStyle={{ alignSelf: "center" }}
        buttonStyle={styles.Head_Left_ButtonStyle}
        onPress={this.GoBack}
      />
    );
  };

  private Render_HeaderRight = () => {
    return <></>;
  };

  private Render_HeaderTitle = (props: StackHeaderTitleProps) => {
    return (
      <View
        style={{
          backgroundColor: "#00000000",
          //flex: 1,
          width: 300,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            backgroundColor: "#00000000",
            color: kcColor("Title"),
            fontSize: 18,
          }}
          selectable={false}
        >
          {GetText("Screen_Setting")}
        </Text>
      </View>
    );
  };

  render() {
    const EffectiveHeigh = this.state.DimensionsWindow.height - 60;
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: kcColor("Background"),
          height: EffectiveHeigh,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <ScrollView
          style={{
            backgroundColor: "#00000000",
            flex: 1,
          }}
        >
          <SplitTextControl children={GetText("Screen_Setting_ChartSetting")} />
          <TECPSettingControl
            style={[styles.Border_Bottom, styles.Border_TopRadius]}
            Mode="ICon_Forward"
            OnTextClick="Default"
            OnClick={this.Goto_Setting_KLine_Color}
            Text={GetText("Screen_Setting_KLineColor")}
            RightText={this.GetSettingString_KLine_Color()}
          />
          <TECPSettingControl
            style={[styles.Border_Bottom]}
            Mode="ICon_Forward"
            OnTextClick="Default"
            OnClick={this.Goto_Setting_KLine_Type}
            Text={GetText("Screen_Setting_KLineType")}
            RightText={this.GetSettingString_KLine_Type()}
          />
          <TECPSettingControl
            style={[styles.Border_Bottom]}
            Mode="ICon_Forward"
            OnTextClick="Default"
            OnClick={this.Goto_Setting_G_HLine}
            Text={GetText("Screen_Setting_AuxiliaryLine")}
            RightText={this.GetSettingString_G_HLine()}
          />
          <TECPSettingControl
            style={[styles.Border_Bottom, styles.Border_BottomRadius]}
            RightStyle={{ margin: 8 }}
            Mode="Switch"
            Text={GetText("Screen_Setting_ShowSubChart")}
            OnTextClick="Default"
            InitCheckValue={this.m_TECPSetting.Global.ShowDeputyTECP}
            OnCheckedChanged={(_Value) => {
              this.m_TECPSetting.Global.ShowDeputyTECP = _Value;
              TECPSettingEditHelper.SettingBuffer?.Save();
            }}
          />

          {/* <SplitTextControl children="主圖指標設定" /> */}
          {/* <TECPSettingControl
            style={[styles.Border_Bottom, styles.Border_TopRadius]}
            Mode="ICon_Forward"
            OnTextClick="Default"
            OnClick={this.Goto_Setting_MA}
            Text="移動平均線"
            RightText={this.GetSettingString_MA()}
          /> */}
          {/* <TECPSettingControl
            style={[styles.Border_Bottom, styles.Border_BottomRadius]}
            Mode="ICon_Forward"
            OnTextClick="Default"
            OnClick={this.Goto_Setting_BBand}
            Text="布林通道"
            RightText={this.GetSettingString_BBand()}
          /> */}
          {/* <SplitTextControl children="副圖指標設定" /> */}
          {/* <TECPSettingControl
            style={[styles.Border_Bottom, styles.Border_TopRadius]}
            Mode="ICon_Forward"
            OnTextClick="Default"
            OnClick={this.Goto_Setting_KD}
            Text="KD"
            RightText={this.GetSettingString_KD()}
          /> */}
          {/* <TECPSettingControl
            style={[styles.Border_Bottom]}
            Mode="ICon_Forward"
            OnTextClick="Default"
            OnClick={this.Goto_Setting_RSI}
            Text="RSI"
            RightText={this.GetSettingString_RSI()}
          /> */}
          {/* <TECPSettingControl
            style={[styles.Border_Bottom, styles.Border_BottomRadius]}
            Mode="ICon_Forward"
            OnTextClick="Default"
            OnClick={this.Goto_Setting_MACD}
            Text="MACD"
            RightText={this.GetSettingString_MACD()}
          /> */}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const BorderRadius = 10;
const styles = StyleSheet.create({
  Head_Left_ButtonStyle: {
    backgroundColor: "#00000000",
    borderColor: "red",
    borderWidth: 0,
    borderRadius: 15,
  },
  ItemSplit: {
    fontSize: 14,
    marginTop: 15,
    marginLeft: 25,
    marginBottom: 3,
    color: kcColor("Value"),
  },

  CheckBox_View: {
    flexDirection: "row",
    backgroundColor: "#000000",
    alignItems: "center",
    maxHeight: 50,
    marginBottom: -10,
  },
  CheckBox_Text: {
    marginLeft: -15,
    color: "#AAAAAA",
  },
  input: {
    flex: 1,
    borderWidth: 0,
    color: "#ffffff",
    paddingHorizontal: 10,
  },
  Border_TopRadius: {
    borderTopLeftRadius: BorderRadius,
    borderTopRightRadius: BorderRadius,
  },
  Border_BottomRadius: {
    borderBottomLeftRadius: BorderRadius,
    borderBottomRightRadius: BorderRadius,
  },
  Border_Bottom: { borderColor: kcColor("Border"), borderBottomWidth: 1 },
});
export default TECPSettingScreen;

type ISplitProp = { style?: StyleProp<TextStyle> };
type ISplitState = { FixedUpdate: boolean };
class SplitTextControl extends React.Component<ISplitProp, ISplitState> {
  constructor(_Prop: ISplitProp) {
    super(_Prop);
  }
  render() {
    return (
      <Text
        style={[styles.ItemSplit, this.props.style]}
        selectable={false}
        children={this.props.children}
      />
    );
  }
}
