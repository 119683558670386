import { kcTECPCollection } from "..";
import { MathEx } from "../../kcExternal";
import { kcHistoryOHLCModel } from "../../kcModel";
import { LinePath, PathPoint, SvgLinePath, TopInfo } from "../kcTECPCollection";
import KDSetting from "../TECPSetting/KDSetting";
import { XUnit } from "../XInfo";
import { YUnit } from "../YInfo";

type KDValues = {
  XUnitValues: { Value: kcKDModel; XUnit: XUnit }[];
  Max?: number;
  Min?: number;
};

export default class kcKDData {
  constructor(_Owner: kcTECPCollection, _KDSetting: KDSetting) {
    this.m_Owner = _Owner;
    this.m_DataSrc = _Owner.mlOHLC;
    this.m_Setting = _KDSetting;
    this.m_Datas.length = this.m_DataSrc.length;
  }

  // Data
  private m_Owner: kcTECPCollection;
  private m_DataSrc: kcHistoryOHLCModel[];
  private m_Datas: kcKDModel[] = [];
  private m_Setting: KDSetting;

  public get Setting() {
    return this.m_Setting;
  }
  public get Datas() {
    return this.m_Datas;
  }

  // Core Function
  public Value(Index: number) {
    if (Index < 0 || Index >= this.m_Datas.length) return undefined;

    if (!this.m_Datas[Index]) this.m_Datas[Index] = new kcKDModel();

    if (!this.m_Datas[Index].HasValue) {
      // setTimeout(() => {

      /* --------------------------------------- */
      // 因為是延續的資料, 向前檢查計算
      let PreIdx: number | undefined = undefined;
      for (let i = Index - 1; i >= 0; i--) {
        if (!this.m_Datas[Index] || !this.m_Datas[Index].HasValue) PreIdx = i;
        else break;
      }
      if (PreIdx != undefined) {
        for (let i = PreIdx; i < Index; i++) {
          if (!this.m_Datas[i]) this.m_Datas[i] = new kcKDModel();
          let mdPreMACD = this.CountValueCore(i);
          if (mdPreMACD != undefined) this.m_Datas[i].SetValue(mdPreMACD);
        }
      }
      /* --------------------------------------- */

      let mdKD = this.CountValueCore(Index);
      if (mdKD != undefined) this.m_Datas[Index].SetValue(mdKD);
      // }, 500);
    }
    return this.m_Datas[Index].HasValue ? this.m_Datas[Index] : undefined;
  }
  public Init() {
    this.m_Datas.length = 0;
    this.m_Datas.length = this.m_DataSrc.length;
  }
  public Update(_StartIdx: number, _EndIdx: number) {
    if (this.m_Datas.length != this.m_DataSrc.length)
      this.m_Datas.length = this.m_DataSrc.length;

    for (let i = _StartIdx; i <= this.m_DataSrc.length; i++) {
      if (this.m_Datas[i]) this.m_Datas[i].SetUpdate();
    }
  }
  private CountValueCore(_Index: number): kcKDModel | undefined {
    let mdKD = new kcKDModel();
    let Base = this.m_Setting.KDBase;
    let dRSVWeight = this.m_Setting.RSVWeight;
    let dKWeight = this.m_Setting.KWeight;

    // DefaultValue
    mdKD.SetValue({ RSV: 50, K: 50, D: 50, J: 50 });
    mdKD.SetUpdate();
    if (_Index < Base) return mdKD;

    let dMax: number | undefined = undefined;
    let dMin: number | undefined = undefined;
    let EndP: number | undefined = undefined;
    for (let j = 0; j < Base; j++) {
      let nDataIdx = _Index - j;
      if (nDataIdx < 0 || nDataIdx >= this.m_DataSrc.length) continue;
      let mdOHLC = this.m_DataSrc[nDataIdx];

      if (dMax === undefined || dMax < mdOHLC.HighPrice)
        dMax = mdOHLC.HighPrice;

      if (dMin === undefined || dMin > mdOHLC.LowPrice) dMin = mdOHLC.LowPrice;

      if (j === 0) EndP = mdOHLC.ClosePrice;
    }

    if (EndP === undefined || dMax === undefined || dMin === undefined)
      return mdKD;

    let mdPreKD = this.Value(_Index - 1);
    if (!mdPreKD || !mdPreKD.K || !mdPreKD.D) return mdKD;

    let dRSV;
    if (dMax - dMin == 0)
      // 防除0
      dRSV = 100;
    else dRSV = (100 * (EndP - dMin)) / (dMax - dMin);

    let dK = (mdPreKD.K * (dRSVWeight - 1)) / dRSVWeight + dRSV / dRSVWeight;
    let dD = (mdPreKD.D * (dKWeight - 1)) / dKWeight + dK / dKWeight;
    let dJ = dD * 3 - dK * 2;

    mdKD.SetValue({ RSV: dRSV, K: dK, D: dD, J: dJ });
    return mdKD;
  }

  // Update Setting Function

  public UpdateSetting(_KDSetting: KDSetting) {
    let UpdateResault = this.m_Setting.SetSetting(_KDSetting);

    if (UpdateResault.DataChanged) {
      this.Init();
    }
  }

  // 輔助 Function
  public map<U>(
    callbackfn: (value: kcKDModel, index: number, array: kcKDModel[]) => U
  ): U[] {
    let aRet: U[] = [];
    for (let i = 0; i < this.m_Datas.length; i++) {
      let Item = this.Value(i);
      aRet.push(callbackfn(this.m_Datas[i], i, this.m_Datas));
    }
    return aRet;
  }

  // Draw Function
  public GetValues(_XUnits: XUnit[]): KDValues | undefined {
    if (!this.m_Setting.EnableAny) return undefined;

    let aValues: KDValues["XUnitValues"] = [];
    let dMax: number = 100;
    let dMin: number = 0;
    for (let i = 0; i < _XUnits.length; i++) {
      let dValue = this.Value(_XUnits[i].nDataIndex);
      if (dValue && dValue.HasValue)
        aValues.push({ Value: dValue, XUnit: _XUnits[i] });
    }

    if (aValues.length == 0) return undefined;

    return {
      XUnitValues: aValues,
      Max: dMax,
      Min: dMin,
    };
  }
  public GetPath(
    _KDValues: KDValues | undefined,
    _YUnit: YUnit
  ): LinePath[] | undefined {
    if (
      !_KDValues ||
      !_KDValues.XUnitValues ||
      _KDValues.XUnitValues.length === 0
    )
      return undefined;

    if (!this.m_Setting.EnableAny) return undefined;

    let szPath_RSV: PathPoint[] = [];
    let szPath_K: PathPoint[] = [];
    let szPath_D: PathPoint[] = [];
    let szPath_J: PathPoint[] = [];

    for (let i = 0; i < _KDValues.XUnitValues.length; i++) {
      let mdKD = _KDValues.XUnitValues[i].Value;
      if (!mdKD) continue;

      let XCoord = _KDValues.XUnitValues[i].XUnit.fXMidPos;
      if (this.m_Setting.RSV_Enable && mdKD.RSV) {
        let YCoord = _YUnit.ValueToCoord(mdKD.RSV);
        szPath_RSV.push({ X: XCoord, Y: YCoord });
      }
      if (this.m_Setting.K_Enable && mdKD.K) {
        let YCoord = _YUnit.ValueToCoord(mdKD.K);
        szPath_K.push({ X: XCoord, Y: YCoord });
      }
      if (this.m_Setting.D_Enable && mdKD.D) {
        let YCoord = _YUnit.ValueToCoord(mdKD.D);
        szPath_D.push({ X: XCoord, Y: YCoord });
      }
      if (this.m_Setting.J_Enable && mdKD.J) {
        let YCoord = _YUnit.ValueToCoord(mdKD.J);
        szPath_J.push({ X: XCoord, Y: YCoord });
      }
    }

    let aPaths: LinePath[] = [];
    if (this.m_Setting.RSV_Enable) {
      aPaths.push({
        PathColor: this.m_Setting.RSV_LineColor,
        PathWidth: this.m_Setting.RSV_LineWidth,
        Points: szPath_RSV,
      });
    }
    if (this.m_Setting.K_Enable) {
      aPaths.push({
        PathColor: this.m_Setting.K_LineColor,
        PathWidth: this.m_Setting.K_LineWidth,
        Points: szPath_K,
      });
    }
    if (this.m_Setting.D_Enable) {
      aPaths.push({
        PathColor: this.m_Setting.D_LineColor,
        PathWidth: this.m_Setting.D_LineWidth,
        Points: szPath_D,
      });
    }
    if (this.m_Setting.J_Enable) {
      aPaths.push({
        PathColor: this.m_Setting.J_LineColor,
        PathWidth: this.m_Setting.J_LineWidth,
        Points: szPath_J,
      });
    }
    return aPaths;
  }
  public GetSVGPath(
    _KDValues: KDValues | undefined,
    _YUnit: YUnit
  ): SvgLinePath[] | undefined {
    if (
      !_KDValues ||
      !_KDValues.XUnitValues ||
      _KDValues.XUnitValues.length === 0
    )
      return undefined;

    if (!this.m_Setting.EnableAny) return undefined;

    let szPath_RSV = "";
    let szPath_K = "";
    let szPath_D = "";
    let szPath_J = "";

    for (let i = 0; i < _KDValues.XUnitValues.length; i++) {
      let mdKD = _KDValues.XUnitValues[i].Value;
      if (!mdKD) continue;

      let XCoord = _KDValues.XUnitValues[i].XUnit.fXMidPos;
      if (this.m_Setting.RSV_Enable && mdKD.RSV) {
        let YCoord = _YUnit.ValueToCoord(mdKD.RSV);
        szPath_RSV += `${i == 0 ? "M" : "L"} ${XCoord} ${YCoord}`;
      }
      if (this.m_Setting.K_Enable && mdKD.K) {
        let YCoord = _YUnit.ValueToCoord(mdKD.K);
        szPath_K += `${i == 0 ? "M" : "L"} ${XCoord} ${YCoord}`;
      }
      if (this.m_Setting.D_Enable && mdKD.D) {
        let YCoord = _YUnit.ValueToCoord(mdKD.D);
        szPath_D += `${i == 0 ? "M" : "L"} ${XCoord} ${YCoord}`;
      }
      if (this.m_Setting.J_Enable && mdKD.J) {
        let YCoord = _YUnit.ValueToCoord(mdKD.J);
        szPath_J += `${i == 0 ? "M" : "L"} ${XCoord} ${YCoord}`;
      }
    }

    let aPaths: SvgLinePath[] = [];
    if (this.m_Setting.RSV_Enable) {
      aPaths.push({
        PathColor: this.m_Setting.RSV_LineColor,
        PathWidth: this.m_Setting.RSV_LineWidth,
        SvgPath: szPath_RSV,
      });
    }
    if (this.m_Setting.K_Enable) {
      aPaths.push({
        PathColor: this.m_Setting.K_LineColor,
        PathWidth: this.m_Setting.K_LineWidth,
        SvgPath: szPath_K,
      });
    }
    if (this.m_Setting.D_Enable) {
      aPaths.push({
        PathColor: this.m_Setting.D_LineColor,
        PathWidth: this.m_Setting.D_LineWidth,
        SvgPath: szPath_D,
      });
    }
    if (this.m_Setting.J_Enable) {
      aPaths.push({
        PathColor: this.m_Setting.J_LineColor,
        PathWidth: this.m_Setting.J_LineWidth,
        SvgPath: szPath_J,
      });
    }
    return aPaths;
  }
  public GetTopInfo() {
    if (!this.m_Setting.EnableAny) return undefined;

    let mdKD = this.Value(this.m_Datas.length - 1);
    let mdPreKD = this.Value(this.m_Datas.length - 2);
    if (!mdKD) return undefined;

    let lTopInfos: TopInfo[] = [];
    let FloatNum = this.m_Owner.kcUnit?.Commodity?.FloatNum ?? 5;

    // RSV
    if (this.m_Setting.RSV_Enable) {
      let szRSV = mdKD.RSV ? MathEx.kcRound(mdKD.RSV, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdKD.RSV && mdPreKD && mdPreKD.RSV) {
        if (mdKD.RSV < mdPreKD.RSV)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdKD.RSV > mdPreKD.RSV)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `RSV`,
        TextColor: this.m_Setting.RSV_LineColor,
        Value: `${szRSV}`,
        // Color: szValueColor,
      });
    }

    // K
    if (this.m_Setting.K_Enable) {
      let szK = mdKD.K ? MathEx.kcRound(mdKD.K, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdKD.K && mdPreKD && mdPreKD.K) {
        if (mdKD.K < mdPreKD.K)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdKD.K > mdPreKD.K)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `K${this.m_Setting.KDBase}`,
        TextColor: this.m_Setting.K_LineColor,
        Value: `${szK}`,
        // Color: szValueColor,
      });
    }

    // D
    if (this.m_Setting.D_Enable) {
      let szD = mdKD.D ? MathEx.kcRound(mdKD.D, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdKD.D && mdPreKD && mdPreKD.D) {
        if (mdKD.D < mdPreKD.D)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdKD.D > mdPreKD.D)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `D${this.m_Setting.KDBase}`,
        TextColor: this.m_Setting.D_LineColor,
        Value: `${szD}`,
        // Color: szValueColor,
      });
    }

    // J
    if (this.m_Setting.J_Enable) {
      let szJ = mdKD.J ? MathEx.kcRound(mdKD.J, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdKD.J && mdPreKD && mdPreKD.J) {
        if (mdKD.J < mdPreKD.J)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdKD.J > mdPreKD.J)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `J${this.m_Setting.KDBase}`,
        TextColor: this.m_Setting.J_LineColor,
        Value: `${szJ}`,
        // Color: szValueColor,
      });
    }

    return lTopInfos;
  }
  public AddTopInfo(_Collection: TopInfo[]) {
    let mdTop = this.GetTopInfo();
    if (mdTop && _Collection) _Collection.push(...mdTop);
    return mdTop;
  }
  public GetLeftInfo(_nDataIndex: number) {
    if (_nDataIndex < 0 || _nDataIndex >= this.m_Datas.length) return undefined;
    if (!this.m_Setting.EnableAny) return undefined;

    let mdKD = this.Value(_nDataIndex);
    let mdPreKD = this.Value(_nDataIndex - 1);
    if (!mdKD) return undefined;

    let lTopInfos: TopInfo[] = [];
    let FloatNum = this.m_Owner.kcUnit?.Commodity?.FloatNum ?? 5;

    // RSV
    if (this.m_Setting.RSV_Enable) {
      let szRSV = mdKD.RSV ? MathEx.kcRound(mdKD.RSV, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdKD.RSV && mdPreKD && mdPreKD.RSV) {
        if (mdKD.RSV < mdPreKD.RSV)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdKD.RSV > mdPreKD.RSV)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `RSV`,
        TextColor: this.m_Setting.RSV_LineColor,
        Value: `${szRSV}`,
        Color: szValueColor,
      });
    }

    // K
    if (this.m_Setting.K_Enable) {
      let szK = mdKD.K ? MathEx.kcRound(mdKD.K, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdKD.K && mdPreKD && mdPreKD.K) {
        if (mdKD.K < mdPreKD.K)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdKD.K > mdPreKD.K)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `K${this.m_Setting.KDBase}`,
        TextColor: this.m_Setting.K_LineColor,
        Value: `${szK}`,
        Color: szValueColor,
      });
    }

    // D
    if (this.m_Setting.D_Enable) {
      let szD = mdKD.D ? MathEx.kcRound(mdKD.D, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdKD.D && mdPreKD && mdPreKD.D) {
        if (mdKD.D < mdPreKD.D)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdKD.D > mdPreKD.D)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `D${this.m_Setting.KDBase}`,
        TextColor: this.m_Setting.D_LineColor,
        Value: `${szD}`,
        Color: szValueColor,
      });
    }

    // J
    if (this.m_Setting.J_Enable) {
      let szJ = mdKD.J ? MathEx.kcRound(mdKD.J, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdKD.J && mdPreKD && mdPreKD.J) {
        if (mdKD.J < mdPreKD.J)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdKD.J > mdPreKD.J)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `J${this.m_Setting.KDBase}`,
        TextColor: this.m_Setting.J_LineColor,
        Value: `${szJ}`,
        Color: szValueColor,
      });
    }

    return lTopInfos;
  }
  public AddLeftInfo(_nDataIndex: number, _Collection: TopInfo[]) {
    let mdLeft = this.GetLeftInfo(_nDataIndex);
    if (mdLeft && _Collection) _Collection.push(...mdLeft);
    return mdLeft;
  }
}

class kcKDModel {
  constructor() {}

  private m_HasValue: boolean = false;

  private m_RSV?: number;
  private m_K?: number;
  private m_D?: number;
  private m_J?: number;

  public get HasValue() {
    return this.m_HasValue;
  }
  public get RSV() {
    return this.m_RSV;
  }
  public get K() {
    return this.m_K;
  }
  public get D() {
    return this.m_D;
  }
  public get J() {
    return this.m_J;
  }

  public SetValue(
    _mdValue: kcKDModel | { RSV?: number; K?: number; D?: number; J?: number }
  ) {
    if (_mdValue) {
      this.m_RSV = _mdValue.RSV;
      this.m_K = _mdValue.K;
      this.m_D = _mdValue.D;
      this.m_J = _mdValue.J;

      this.m_HasValue = true;
    }
  }

  public SetUpdate() {
    this.m_HasValue = false;
  }
}
