import React from "react";
import { Text, View } from "../components/Themed";
import {
  StyleSheet,
  Pressable,
  StyleProp,
  TextInput,
  TextStyle,
  ViewStyle,
} from "react-native";
import { MathEx } from "../kcExternal";
import { Ionicons } from "@expo/vector-icons";
import { kcColor } from "../constants/Colors";
import { CheckBox, Switch } from "react-native-elements";

type IstProp = {
  Mode: "None" | "ICon_Forward" | "Switch" | "Check" | "NumberInput";
  TextAreaStyle?: StyleProp<ViewStyle>;
  Text: string | React.ReactNode;
  TextStyle?: StyleProp<TextStyle>;
  SubText?: string | React.ReactNode;
  SubTextStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  RightStyle?: StyleProp<ViewStyle>;
  RightText?: string | React.ReactNode;
  RightTextStyle?: StyleProp<TextStyle>;
  OnClick?: () => void; // ICon Click時會進來
  OnTextClick?: "None" | "Default" | (() => void); // CallbackFunc在ICon模式時才會進
  InitCheckValue?: boolean; // "Switch" | "Check"
  OnCheckedChanged?: (_Check: boolean) => void; // "Switch" | "Check"
  InitInputValue?: number;
  InputValueProp?: { FloatNum?: number; Max?: number; Min?: number };
  OnInputValueChanged?: (_Value: number) => void;
};

type IstState = { FixedUpdate: boolean };

class TECPSettingControl extends React.Component<IstProp, IstState> {
  constructor(_Prop: IstProp) {
    super(_Prop);

    if (!this.props.OnClick && this.props.Mode === "ICon_Forward")
      this.m_bRightClickable = false;

    this.m_bTextClickable =
      this.m_bRightClickable && this.props.OnTextClick
        ? this.props.OnTextClick !== "None"
        : false;

    // Switch
    this.m_bCheckValue =
      this.props.InitCheckValue !== undefined
        ? this.props.InitCheckValue
        : false;

    // Input
    if (this.props.InputValueProp) {
      this.m_InputLimit = { ...this.props.InputValueProp };
      if (
        this.props.InputValueProp.FloatNum &&
        this.props.InputValueProp.FloatNum > 0
      )
        this.m_FloatNum = Math.floor(this.props.InputValueProp.FloatNum);
    } else
      this.m_InputLimit = {
        Max: undefined,
        Min: undefined,
      };

    this.m_dInputValue = this.CheckInputMaxMin(
      this.props.InitInputValue !== undefined ? this.props.InitInputValue : 0
    );

    this.m_szInputString = this.m_dInputValue.toString();
  }

  state: IstState = { FixedUpdate: false };
  private m_TextInputRef = React.createRef<TextInput>();
  private m_bTextClickable: boolean;
  private m_bRightClickable: boolean = true;

  // Switch
  private m_bCheckValue: boolean;

  // Input
  private m_FloatNum: number = 0;
  private m_InputLimit: { Max?: number; Min?: number }; // 最大最小值
  private m_dInputValue: number; // 輸入數值
  private m_szInputString: string; // 輸入數值字串(Float時要可以有.)

  public get Mode() {
    return this.props.Mode;
  }
  public get Checked() {
    return this.m_bCheckValue;
  }
  public set Checked(_Value: boolean) {
    this.m_bCheckValue = _Value;
    this.FixedReDrawUI();
  }
  public get InputValue() {
    this.OnTextInputBlur(this.m_szInputString);
    return this.m_dInputValue;
  }
  public set InputValue(_Value: number) {
    this.m_dInputValue = MathEx.kcFloor(_Value, this.m_FloatNum);
    this.m_szInputString = this.m_dInputValue.toFixed(this.m_FloatNum);
    this.FixedReDrawUI();
  }

  public SetInputValue = (_Value: number) => {
    this.InputValue = _Value;
  };
  public SetCheckedValue = (_Value: boolean) => {
    this.Checked = _Value;
  };
  public Refreash = () => {
    this.FixedReDrawUI();
  };

  private FixedReDrawUI = () => {
    this.setState({ FixedUpdate: !this.state.FixedUpdate });
  };
  private CheckInputMaxMin = (_Value: number) => {
    if (this.m_InputLimit.Max !== undefined)
      _Value = Math.min(_Value, this.m_InputLimit.Max);
    if (this.m_InputLimit.Min !== undefined)
      _Value = Math.max(_Value, this.m_InputLimit.Min);

    return _Value;
  };

  /* ----------------------------------------------- */
  // On
  private OnTextClick = () => {
    if (!this.props.OnTextClick || this.props.OnTextClick === "None") return;

    if (this.Mode === "NumberInput") this.m_TextInputRef.current?.focus();
    else if (this.Mode === "Switch" || this.Mode === "Check")
      this.OnRightClick();
    else if (this.props.OnTextClick === "Default") this.OnRightClick();
    else this.props.OnTextClick();
  };
  private OnRightClick = () => {
    if (this.Mode === "NumberInput") this.m_TextInputRef.current?.focus();
    else if (this.Mode === "Switch" || this.Mode === "Check")
      this.OnCheckChanged(!this.m_bCheckValue);
    else if (this.Mode === "ICon_Forward") {
      this.props.OnClick?.call(this);
    }
  };
  private OnCheckChanged = (_Checked: boolean) => {
    this.m_bCheckValue = _Checked;
    this.FixedReDrawUI();
    this.props.OnCheckedChanged?.call(this, this.m_bCheckValue);
  };
  private OnTextInputChanged = (_Text: string) => {
    if (this.Mode !== "NumberInput") return;

    if (_Text !== "-") {
      if (_Text === ".") _Text = "0.";

      let dInput: number = Number(_Text);

      // 檢查輸入是否合理
      if (Number.isNaN(dInput)) return;
      if (this.m_FloatNum === 0 && !Number.isSafeInteger(dInput)) return;

      // 檢查輸入長度
      let szFixed = MathEx.kcFloor(dInput, this.m_FloatNum).toFixed(
        this.m_FloatNum
      );
      if (_Text.length > szFixed.length) _Text = szFixed;
    }

    this.m_szInputString = _Text;
    this.FixedReDrawUI();
  };
  private OnTextInputBlur = (_Text: string) => {
    if (this.Mode !== "NumberInput") return;

    let dInput: number = Number(_Text);
    if (Number.isNaN(dInput)) dInput = 0;

    // 檢查最大最小直
    if (this.m_InputLimit.Max !== undefined) {
      if (
        (this.m_InputLimit.Max >= 0 && dInput >= this.m_InputLimit.Max) ||
        (this.m_InputLimit.Max < 0 && dInput > this.m_InputLimit.Max)
      ) {
        dInput = this.m_InputLimit.Max;
      }
    }
    if (this.m_InputLimit.Min !== undefined) {
      if (
        (this.m_InputLimit.Min >= 0 && dInput < this.m_InputLimit.Min) ||
        (this.m_InputLimit.Min < 0 && dInput <= this.m_InputLimit.Min)
      ) {
        dInput = this.m_InputLimit.Min;
      }
    }
    let bValueChanged = this.m_dInputValue !== dInput;
    this.m_dInputValue = dInput;
    this.m_szInputString = `${dInput}`;

    if (bValueChanged)
      this.props.OnInputValueChanged?.call(this, this.m_dInputValue);

    this.FixedReDrawUI();
  };

  /* ----------------------------------------------- */
  // Render
  private Render_Text = () => {
    return (
      <>
        <Text
          style={[styles_ListItem.TextStyle, this.props.TextStyle]}
          selectable={false}
        >
          {this.props.Text}
        </Text>
        {this.props.SubText && (
          <Text
            style={[styles_ListItem.SubTextStyle, this.props.SubTextStyle]}
            selectable={false}
          >
            {this.props.SubText}
          </Text>
        )}
      </>
    );
  };
  private Render_Right = () => {
    return (
      <>
        {this.props.RightText && this.Render_Right_Text()}
        {this.props.Mode === "ICon_Forward" && this.Render_Right_Icon()}
        {this.props.Mode === "Switch" && this.Render_Right_Switch()}
        {this.props.Mode === "Check" && this.Render_Right_CheckBox()}
        {this.props.Mode === "NumberInput" && this.Render_Right_TextInput()}
      </>
    );
  };
  private Render_Right_Text = () => {
    return (
      <>
        {this.props.RightText && (
          <Text
            style={[styles_ListItem.RightTextStyle, this.props.RightTextStyle]}
            selectable={false}
          >
            {this.props.RightText}
          </Text>
        )}
      </>
    );
  };
  private Render_Right_Icon = () => {
    return (
      <Ionicons
        selectable={false}
        name="ios-chevron-forward"
        size={24}
        color={kcColor("Icon")}
      />
    );
  };
  private Render_Right_Switch = () => {
    return (
      <Switch
        //color="green" // true時開關的顏色(ture滑軌預設顏色)
        //thumbColor="red" // false時開關的顏色
        //trackColor={{ false: "yellow", true: "blue" }} // 滑軌顏色
        //ios_backgroundColor="red"
        onValueChange={this.OnCheckChanged}
        value={this.m_bCheckValue}
      />
    );
  };
  private Render_Right_CheckBox = () => {
    return (
      <CheckBox
        containerStyle={{
          margin: 0,
          padding: 0,
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 3,
        }}
        checkedIcon="dot-circle-o"
        uncheckedIcon="circle-o"
        checked={this.m_bCheckValue}
        onPress={() => {
          this.OnCheckChanged(!this.m_bCheckValue);
        }}
      />
    );
  };
  private Render_Right_TextInput = () => {
    return (
      <TextInput
        ref={this.m_TextInputRef}
        style={styles_ListItem.TextInput}
        onChangeText={this.OnTextInputChanged}
        value={this.m_szInputString}
        keyboardType="numeric"
        onBlur={(e) => {
          this.OnTextInputBlur(this.m_szInputString);
        }}
      />
    );
  };

  render() {
    let bRight = this.props.Mode && this.props.Mode !== "None";
    return (
      <View style={[styles_ListItem.Container, this.props.style]}>
        {/* Text區域 */}
        {this.m_bTextClickable && (
          <Pressable
            style={[styles_ListItem.TextArea, this.props.TextAreaStyle]}
            onPress={this.OnTextClick}
          >
            {this.Render_Text()}
          </Pressable>
        )}
        {!this.m_bTextClickable && (
          <View style={[styles_ListItem.TextArea, this.props.TextAreaStyle]}>
            {this.Render_Text()}
          </View>
        )}

        {/* 右邊區域 */}
        {bRight && this.m_bRightClickable && (
          <Pressable
            style={[styles_ListItem.RightArea, this.props.RightStyle]}
            onPress={this.OnRightClick}
          >
            {this.Render_Right()}
          </Pressable>
        )}
        {bRight && !this.m_bRightClickable && (
          <View style={[styles_ListItem.RightArea, this.props.RightStyle]}>
            {this.Render_Right()}
          </View>
        )}
      </View>
    );
  }
}

const styles_ListItem = StyleSheet.create({
  Container: {
    // 整個主元件
    backgroundColor: kcColor("ListValueBackground"),
    flexDirection: "row",
    flex: 1,
    minHeight: 50,
  },
  TextArea: {
    // Text區域(中間)
    flexDirection: "column",
    backgroundColor: "#00000000",
    flex: 1,
    flexWrap: "nowrap",
    marginLeft: 25,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  TextStyle: {
    // Text 本體
    color: kcColor("Title"),
    fontWeight: "400",
    fontSize: 16,
  },
  SubTextStyle: {
    // SubText 本體
    color: kcColor("SubTitle"),
    fontSize: 10,
  },
  RightArea: {
    // 右邊區域
    backgroundColor: "#00000000",
    minWidth: 50,
    //width: 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 10,
    paddingLeft: 10,
  },
  RightTextStyle: {
    // RightText本體
    color: kcColor("SubTitle"),
    fontSize: 10,
    marginRight: 5,
  },
  TextInput: {
    // Textbox本體
    borderWidth: 1,
    padding: 5,
    color: kcColor("Icon"),
    textAlign: "center",
    width: 80,
    borderRadius: 6,
    borderColor: kcColor("Border"),
    backgroundColor: "#3B3B3B",
    // borderColor: "#2C2C2C",
    // backgroundColor: kcColor("Border"),
  },
});

export default TECPSettingControl;
