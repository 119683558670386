const Default_RSI1_Base: number = 6;
const Default_RSI2_Base: number = 12;

type RSIDataSettingType = {
  RSI1_Base?: number;
  RSI2_Base?: number;
};
type RSIDrawSettingType = {
  RSI1_Enable?: boolean;
  RSI1_LineColor?: string;
  RSI1_LineWidth?: number;

  RSI2_Enable?: boolean;
  RSI2_LineColor?: string;
  RSI2_LineWidth?: number;
};
export default class RSISetting {
  constructor(props: object) {
    Object.assign(this, { ...props });
  }

  RSI1_Base: number = Default_RSI1_Base;
  RSI1_Enable: boolean = true;
  RSI1_LineColor = "#87CEEBFF";
  RSI1_LineWidth: number = 1;

  RSI2_Base: number = Default_RSI2_Base;
  RSI2_Enable: boolean = true;
  RSI2_LineColor = "#FFA500FF";
  RSI2_LineWidth: number = 1;

  public get EnableAny() {
    return this.RSI1_Enable || this.RSI2_Enable;
  }
  public get EnableAll() {
    return this.RSI1_Enable && this.RSI2_Enable;
  }

  public Clone(): RSISetting {
    return new RSISetting(this);
  }
  public SetGlobalSetting(_Setting: RSISetting) {
    Object.assign(this, _Setting);
  }

  public SetSetting(
    _Setting: (RSIDataSettingType & RSIDrawSettingType) | RSISetting
  ): {
    DataChanged: boolean;
    DrawChanged: boolean;
  } {
    let Reasult = { DataChanged: false, DrawChanged: false };

    if (_Setting) {
      // DataChanged
      if (
        _Setting.RSI1_Base !== undefined &&
        _Setting.RSI1_Base !== this.RSI1_Base
      ) {
        this.RSI1_Base = _Setting.RSI1_Base;
        Reasult.DataChanged = true;
      }
      if (
        _Setting.RSI2_Base !== undefined &&
        _Setting.RSI2_Base !== this.RSI2_Base
      ) {
        this.RSI2_Base = _Setting.RSI2_Base;
        Reasult.DataChanged = true;
      }

      // DrawChanged
      if (
        _Setting.RSI1_Base !== undefined &&
        _Setting.RSI1_Base !== this.RSI1_Base
      ) {
        this.RSI1_Base = _Setting.RSI1_Base;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.RSI1_LineColor !== undefined &&
        _Setting.RSI1_LineColor !== this.RSI1_LineColor
      ) {
        this.RSI1_LineColor = _Setting.RSI1_LineColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.RSI1_LineWidth !== undefined &&
        _Setting.RSI1_LineWidth !== this.RSI1_LineWidth
      ) {
        this.RSI1_LineWidth = _Setting.RSI1_LineWidth;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.RSI2_Enable !== undefined &&
        _Setting.RSI2_Enable !== this.RSI2_Enable
      ) {
        this.RSI2_Enable = _Setting.RSI2_Enable;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.RSI2_LineColor !== undefined &&
        _Setting.RSI2_LineColor !== this.RSI2_LineColor
      ) {
        this.RSI2_LineColor = _Setting.RSI2_LineColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.RSI2_LineWidth !== undefined &&
        _Setting.RSI2_LineWidth !== this.RSI2_LineWidth
      ) {
        this.RSI2_LineWidth = _Setting.RSI2_LineWidth;
        Reasult.DrawChanged = true;
      }
    }

    return Reasult;
  }

  public ToDefault() {
    this.RSI1_Base = Default_RSI1_Base;
    this.RSI2_Base = Default_RSI2_Base;
  }
}
