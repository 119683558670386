import moment from "moment";
import { Moment } from "moment";
import { MomentExtensions } from "../kcExternal";

export default class TradeTimeRuleModel {
  public IsExpand: boolean = false;
  public OpenTimeOffset: number = 0;
  public CloseTimeOffset: number = 0;
  public DayOffset: number = 0;

  public RangeCompare_Offset(CompareOffset: number): number {
    if (CompareOffset < this.OpenTimeOffset) return -1;
    if (CompareOffset > this.CloseTimeOffset) return 1;
    return 0;
  }
  public RangeCompare_Time(_BaseTime: Moment, _Time: Moment): number {
    let dMinuteDif =
      (_Time.valueOf() - _BaseTime.valueOf()) / MomentExtensions.TicksPerMinute;
    return this.RangeCompare_Offset(dMinuteDif);
  }
  public Offset(_nOffset: number): TradeTimeRuleModel {
    let md = new TradeTimeRuleModel();
    md.IsExpand = this.IsExpand;
    md.OpenTimeOffset = this.OpenTimeOffset + _nOffset;
    md.CloseTimeOffset = this.CloseTimeOffset + _nOffset;

    return md;
  }
  public static CreateRules(
    _Open: number[],
    _Close: number[]
  ): TradeTimeRuleModel[] {
    let ml: TradeTimeRuleModel[] = [];
    for (let i = 0; i < _Open.length; i++) {
      if (i >= _Close.length) break;
      let mdAdd = new TradeTimeRuleModel();
      mdAdd.IsExpand = false;
      mdAdd.OpenTimeOffset = _Open[i];
      mdAdd.CloseTimeOffset = _Close[i];
      ml.push(mdAdd);
    }
    return ml;
  }
  public static Expand(
    _Source: TradeTimeRuleModel[],
    _nExpandOffset: number
  ): TradeTimeRuleModel[] {
    if (_Source.length == 0) return _Source;

    let mdExpandBef = _Source[_Source.length - 1].Offset(-_nExpandOffset);
    let mdExpandAft = _Source[0].Offset(_nExpandOffset);

    mdExpandBef.IsExpand = true;
    mdExpandBef.DayOffset = -1;
    mdExpandAft.IsExpand = true;
    mdExpandAft.DayOffset = 1;

    // 盡量做到涵蓋一整日(0-1440), 若已涵蓋的話則不需要再擴充
    if (_Source[0].OpenTimeOffset > 0) _Source.splice(0, 0, mdExpandBef);
    if (_Source[_Source.length - 1].CloseTimeOffset < _nExpandOffset)
      _Source.push(mdExpandAft);

    return _Source;
  }
  public static CreateExpandRules(
    _Open: number[],
    _Close: number[],
    _nExpandOffset: number
  ): TradeTimeRuleModel[] {
    return this.Expand(this.CreateRules(_Open, _Close), _nExpandOffset);
  }
}

export class TradeTimeResault {
  public Resault: boolean = false;
  public RuleIndex: number = -1;
  public OpenTime: Moment = moment(0);
  public CloseTime: Moment = moment(0);
  public IsInTradeTime: boolean = false;

  public SetReasult(
    _OpenTime: Moment,
    _CloseTime: Moment,
    _bIsInTradeTime: boolean
  ): void {
    this.OpenTime = _OpenTime;
    this.CloseTime = _CloseTime;
    this.IsInTradeTime = _bIsInTradeTime;
  }
}
