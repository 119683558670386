const Default_MaBase = 20;
const Default_SDBase = 2;
type BBandDataSettingType = {
  MaBase?: number;
  SDBase?: number;
};
type BBandDrawSettingType = {
  UpdateMaxMinLimit?: boolean;

  MaEnable?: boolean;
  MaLineColor?: string;
  MaLineWidth?: number;

  SDEnable?: boolean;
  SDLineColor?: string;
  SDLineWidth?: number;
};
export default class BBandSetting {
  constructor(props: object) {
    Object.assign(this, { ...props });
  }

  MaBase: number = Default_MaBase;
  SDBase: number = Default_SDBase;
  UpdateMaxMinLimit: boolean = true;

  MaEnable: boolean = true;
  MaLineColor = "#FF0000FF";
  MaLineWidth: number = 1;

  SDEnable: boolean = true;
  SDLineColor = "#0080FFFF";
  SDLineWidth: number = 1;

  public Clone(): BBandSetting {
    return new BBandSetting(this);
  }
  public SetGlobalSetting(_Setting: BBandSetting) {
    Object.assign(this, _Setting);
  }

  public SetSetting(
    _Setting: (BBandDataSettingType & BBandDrawSettingType) | BBandSetting
  ): {
    DataChanged: boolean;
    DrawChanged: boolean;
  } {
    let Reasult = { DataChanged: false, DrawChanged: false };

    if (_Setting) {
      // DataChanged
      if (_Setting.MaBase !== undefined && _Setting.MaBase !== this.MaBase) {
        this.MaBase = _Setting.MaBase;
        Reasult.DataChanged = true;
      }
      if (_Setting.SDBase !== undefined && _Setting.SDBase !== this.SDBase) {
        this.SDBase = _Setting.SDBase;
        Reasult.DataChanged = true;
      }

      // DrawChanged
      if (
        _Setting.UpdateMaxMinLimit !== undefined &&
        _Setting.UpdateMaxMinLimit !== this.UpdateMaxMinLimit
      ) {
        this.UpdateMaxMinLimit = _Setting.UpdateMaxMinLimit;
        Reasult.DrawChanged = true;
      }

      if (
        _Setting.MaEnable !== undefined &&
        _Setting.MaEnable !== this.MaEnable
      ) {
        this.MaEnable = _Setting.MaEnable;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.MaLineColor !== undefined &&
        _Setting.MaLineColor !== this.MaLineColor
      ) {
        this.MaLineColor = _Setting.MaLineColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.MaLineWidth !== undefined &&
        _Setting.MaLineWidth !== this.MaLineWidth
      ) {
        this.MaLineWidth = _Setting.MaLineWidth;
        Reasult.DrawChanged = true;
      }

      if (
        _Setting.SDEnable !== undefined &&
        _Setting.SDEnable !== this.SDEnable
      ) {
        this.SDEnable = _Setting.SDEnable;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.SDLineColor !== undefined &&
        _Setting.SDLineColor !== this.SDLineColor
      ) {
        this.SDLineColor = _Setting.SDLineColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.SDLineWidth !== undefined &&
        _Setting.SDLineWidth !== this.SDLineWidth
      ) {
        this.SDLineWidth = _Setting.SDLineWidth;
        Reasult.DrawChanged = true;
      }
    }

    return Reasult;
  }
  public ToDefault() {
    this.MaBase = Default_MaBase;
    this.SDBase = Default_SDBase;
  }
}
