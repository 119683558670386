import React from "react";
import { G, Path } from "react-native-svg";

// Each nameValuePair can be:
// * Name: <Svg />; or
// * Name: { svg: <Svg />, viewBox: '0 0 50 50' }

export default {
  Cross: {
    svg: (
      <Path d="M50,39.1c-3.9,0-7.1-1-7.1-2.2V2.2C42.9,1,46.1,0,50,0c3.9,0,7.1,1,7.1,2.2V37C57.1,38.2,53.9,39.1,50,39.1z M50,100 c-3.9,0-7.1-1-7.1-2.2V63c0-1.2,3.2-2.2,7.1-2.2c3.9,0,7.1,1,7.1,2.2v34.8C57.1,99,53.9,100,50,100z M37,57.1H2.2 C1,57.1,0,53.9,0,50s1-7.1,2.2-7.1H37c1.2,0,2.2,3.2,2.2,7.1C39.1,53.9,38.2,57.1,37,57.1z M97.8,57.1H63c-1.2,0-2.2-3.2-2.2-7.1 c0-3.9,1-7.1,2.2-7.1h34.8c1.2,0,2.2,3.2,2.2,7.1S99,57.1,97.8,57.1z M50,42.9c3.9,0,7.1,3.2,7.1,7.1s-3.2,7.1-7.1,7.1 s-7.1-3.2-7.1-7.1S46.1,42.9,50,42.9z" />
    ),
    viewBox: "0 0 100 100",
  },
  CrossPre: {
    svg: (
      //<Path d="M50,39.1c-2.4,0-4.3-1-4.3-2.2V2.2C45.7,1,47.6,0,50,0c2.4,0,4.3,1,4.3,2.2V37C54.3,38.2,52.4,39.1,50,39.1z M50,100 c-2.4,0-4.3-1-4.3-2.2V63c0-1.2,1.9-2.2,4.3-2.2c2.4,0,4.3,1,4.3,2.2v34.8C54.3,99,52.4,100,50,100z M37,54.3H2.2 C1,54.3,0,52.4,0,50s1-4.3,2.2-4.3H37c1.2,0,2.2,1.9,2.2,4.3C39.1,52.4,38.2,54.3,37,54.3z M97.8,54.3H63c-1.2,0-2.2-1.9-2.2-4.3 c0-2.4,1-4.3,2.2-4.3h34.8c1.2,0,2.2,1.9,2.2,4.3S99,54.3,97.8,54.3z M50,45.7c2.4,0,4.3,1.9,4.3,4.3s-1.9,4.3-4.3,4.3 c-2.4,0-4.3-1.9-4.3-4.3S47.6,45.7,50,45.7z" />
      <Path d="M500,391.3c-12,0-21.7-9.7-21.7-21.7V21.7C478.3,9.7,488,0,500,0c12,0,21.7,9.7,21.7,21.7v347.8 C521.7,381.6,512,391.3,500,391.3z M500,1000c-12,0-21.7-9.7-21.7-21.7V630.4c0-12,9.7-21.7,21.7-21.7c12,0,21.7,9.7,21.7,21.7 v347.8C521.7,990.3,512,1000,500,1000z M369.6,521.7H21.7C9.7,521.7,0,512,0,500s9.7-21.7,21.7-21.7h347.8c12,0,21.7,9.7,21.7,21.7 C391.3,512,381.6,521.7,369.6,521.7z M978.3,521.7H630.4c-12,0-21.7-9.7-21.7-21.7c0-12,9.7-21.7,21.7-21.7h347.8 c12,0,21.7,9.7,21.7,21.7S990.3,521.7,978.3,521.7z M500,478.3c12,0,21.7,9.7,21.7,21.7c0,12-9.7,21.7-21.7,21.7 c-12,0-21.7-9.7-21.7-21.7C478.3,488,488,478.3,500,478.3z" />
    ),
    viewBox: "0 0 1000 1000",
  },
};
