import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { StyleSheet, ScrollView, View, Dimensions, ScaledSize, SafeAreaView, Pressable, Modal, ActivityIndicator, FlatList, Animated, Easing, VirtualizedList } from "react-native";
import { Text } from "../components/Themed";
import { Ionicons, FontAwesome, MaterialIcons } from "@expo/vector-icons";
import { kcExchangeCode } from "../kcModel/kcModel_Enum";
import { kcCommodityModel, kcModel_Enum } from "../kcModel";
import { useKcEffect } from "../kcExternal/kcUseEffect";
import { RegListHelper } from "../kcData";
import { kcColor } from "../constants/Colors";
import { HL_Left_Center, VL_Center_Left } from "../kcExternal/kcAlignHelper";
import { StackNavigationProp } from "@react-navigation/stack";
import { TabTECPParamList } from "../types";
import { GetText } from "../Locales";
import { FixedSizeList, ListChildComponentProps } from "react-window";

export type CommodityItem = { Commodity: kcCommodityModel };
export type ExcListType = { Exchange: kcExchangeCode; Stocks: CommodityItem[] };
export type SubItemType = { StockCode: string; StockName: string };

class cMember {
   AllFilterItmes: SubItemType[] = [];
   RegList: string[] = [];
   TotalWidth: number = 0;
   TotalHeight: number = 0;
   MaxListHeight: number = 0;
   AnimatedControler = new Animated.Value(0);
   AnimatedItemsHeigh: number = 0;
   AnimatedItemsOpacity: number = 0;
   AnimatedIconRotate: string = "0deg";
}

interface IProps {
   IsSelect?: boolean;
   IsFavorite?: boolean;
   DataItem: ExcListType;
   SearchText: string;
   Expanded?: boolean;
   ParentProp: any;
   OnFail?: (_szFailMsg: string) => void;
}

const EachItemHeigh = 50;

export const KcExcList: React.FC<IProps> = (props) => {
   const { DataItem, SearchText, Expanded = false, OnFail, ParentProp, IsSelect = false, IsFavorite = false } = props;
   const [ShowList, setShowList] = useState(false);

   const FixedRender = React.useReducer(() => ({}), {})[1];
   const MemberRef = useRef<cMember>(new cMember());
   const m_ = MemberRef.current;

   const UpdateDisplayItems = () => {
      const { Stocks } = DataItem;
      const filteredItems = Stocks.filter((md) => {
         const stockCodeMatch = md.Commodity.StockCode.toLowerCase().includes(SearchText.toLowerCase());
         const stockNameMatch = md.Commodity.LocaleStockName().toLowerCase().includes(SearchText.toLowerCase());
         return stockCodeMatch || stockNameMatch;
      });

      m_.AllFilterItmes = filteredItems.map((md) => {
         return { StockCode: md.Commodity.StockCode, StockName: md.Commodity.LocaleStockName() };
      });
   };

   const SwitchShowList = () => {
      let Show = !ShowList;
      Animated.timing(m_.AnimatedControler, {
         toValue: Show ? 100 : 0,
         duration: 300,
         useNativeDriver: false,
      }).start();

      setShowList(Show);
   };

   const UpdateSize = (_w: number, _h: number) => {
      m_.TotalWidth = _w;
      m_.TotalHeight = _h;
      m_.MaxListHeight = _h * 0.7;
   };

   const OnSizeChange = ({ window, screen }: { window: ScaledSize; screen: ScaledSize }) => {
      UpdateSize(window.width, window.height);
      FixedRender();
   };

   const UpdateAnimatedValue = (value: number) => {
      const AllItemsHeigh = m_.AllFilterItmes.length * EachItemHeigh;
      const ItemsHeigh = Math.min(AllItemsHeigh, m_.MaxListHeight);
      m_.AnimatedItemsHeigh = (value * ItemsHeigh) / 100;
      m_.AnimatedItemsOpacity = value / 100;
      m_.AnimatedIconRotate = `${(value * -180) / 100}deg`;
   };

   const OnAnimatedValueChange = (state: { value: number }) => {
      const { value } = state;
      UpdateAnimatedValue(value);
      FixedRender();
   };

   useEffect(() => {
      Dimensions.addEventListener("change", OnSizeChange);
      m_.AnimatedControler.addListener(OnAnimatedValueChange);

      return () => {
         Dimensions.removeEventListener("change", OnSizeChange);
         m_.AnimatedControler.removeAllListeners();
      };
   }, []);

   useKcEffect(() => {
      let Windows = Dimensions.get("window");
      UpdateSize(Windows.width, Windows.height);
      m_.RegList = RegListHelper.GetRegList();
      if (Expanded) SwitchShowList();
   }, []);

   useKcEffect(() => {
      UpdateDisplayItems();
      if (ShowList) {
         UpdateAnimatedValue(100);
      } else {
         UpdateAnimatedValue(0);
      }
   }, [SearchText]);

   const RenderList = () => {
      const FixedRender = React.useReducer(() => ({}), {})[1];
      const ChangeRegStock = (_szStockCode: string, _bAdd: boolean) => {
         if (_bAdd) {
            let bUpdated = RegListHelper.AppendRegListItem(_szStockCode);
            if (bUpdated == "false_OverSize") {
               OnFail?.call(this, "自選商品數量已達上限");
            }
         } else {
            RegListHelper.RemoveRegListItem(_szStockCode);
         }
         m_.RegList = RegListHelper.GetRegList();
         FixedRender();
      };

      const ChangeSelectCommodity = (_szStockCode: string) => {
         if (ParentProp.route.name == "TabTECPScreen_Select") {
            let Navigation = ParentProp.navigation as StackNavigationProp<TabTECPParamList, "TabTECPScreen_Select">;
            Navigation.navigate("TabTECPScreen", { StockCode: _szStockCode });
         }
      };

      const ToCommodityInfoPage = (_szStockCode: string) => {
         let NowScreen = ParentProp.route.name;

         if (NowScreen == "TabTECPScreen_Select") {
            let Navigation = ParentProp.navigation as StackNavigationProp<TabTECPParamList, "TabTECPScreen_Select">;
            Navigation.push("TabTECPScreen_Info", { StockCode: _szStockCode });
         }
         if (NowScreen == "TabQuoteScreen_Add") {
            ParentProp.navigation.navigate("TabQuoteScreen_Info", { StockCode: _szStockCode });
         }
      };

      const renderItem = (props: ListChildComponentProps) => {
         const { index, style, data } = props;
         let item: SubItemType = data[index];
         const { StockCode, StockName } = item;
         let ItemRegisted = m_.RegList.includes(StockCode);

         return (
            <div key={index} style={{ ...style, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", backgroundColor: kcColor("ListValueBackground"), borderColor: kcColor("Border"), borderWidth: "1px", borderStyle: "solid", boxSizing: "border-box", paddingLeft: "10px", paddingRight: "10px" }}>
               <FontAwesome selectable={false} name={ItemRegisted ? "star" : "star-o"} size={24} color={ItemRegisted ? kcColor("Yellow") : kcColor("ListIcon")} onPress={() => ChangeRegStock(StockCode, !ItemRegisted)} />
               <View style={[VL_Center_Left, { flex: 1, marginLeft: "10px" }]}>
                  <Text selectable={false} style={{ color: kcColor("Title"), fontSize: 16 }} children={StockCode} />
                  <Text selectable={false} style={{ color: kcColor("SubTitle"), fontSize: 14 }} children={StockName} />
               </View>
               <Ionicons selectable={false} name="information-circle-sharp" size={28} color={kcColor("Blue")} onPress={() => ToCommodityInfoPage(StockCode)} />
            </div>
         );
      };

      const renderItemSelect = (props: ListChildComponentProps) => {
         const { index, style, data } = props;
         let item: SubItemType = data[index];
         const { StockCode, StockName } = item;
         return (
            <div key={index} style={{ ...style, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", backgroundColor: kcColor("ListValueBackground"), borderColor: kcColor("Border"), borderWidth: "1px", borderStyle: "solid", boxSizing: "border-box", paddingLeft: "10px", paddingRight: "10px" }}>
               <Ionicons selectable={false} name="ios-chevron-back" size={24} color={kcColor("Icon")} onPress={() => ChangeSelectCommodity(StockCode)} />
               <Pressable style={[HL_Left_Center, { flex: 1 }]} onPress={() => ChangeSelectCommodity(StockCode)}>
                  <View style={[VL_Center_Left, { flex: 1, marginLeft: "10px" }]}>
                     <Text selectable={false} style={{ color: kcColor("Title"), fontSize: 16 }} children={StockCode} />
                     <Text selectable={false} style={{ color: kcColor("SubTitle"), fontSize: 14 }} children={StockName} />
                  </View>
               </Pressable>
               <Ionicons selectable={false} name="information-circle-sharp" size={28} color={kcColor("Blue")} onPress={() => ToCommodityInfoPage(StockCode)} />
            </div>
         );
      };

      return (
         <FixedSizeList height={m_.MaxListHeight} width={m_.TotalWidth} itemSize={EachItemHeigh} itemCount={m_.AllFilterItmes.length} overscanCount={30} itemData={m_.AllFilterItmes}>
            {IsSelect ? renderItemSelect : renderItem}
         </FixedSizeList>
      );
   };

   return (
      <View style={{ flexDirection: "column" }}>
         {/* // Title */}
         {m_.AllFilterItmes.length > 0 && (
            <Pressable style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", height: "50px", backgroundColor: kcColor("ListTitleBackground"), paddingLeft: "10px", paddingRight: "10px" }} onPress={(e) => SwitchShowList()}>
               {IsFavorite && <FontAwesome selectable={false} name={"star"} size={24} color={kcColor("Yellow")} style={{ marginRight: 10 }} />}
               <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", flex: 1 }}>
                  <Text selectable={false} style={{ color: kcColor("Title"), fontWeight: "400", fontSize: 16 }} children={IsFavorite ? GetText("Screen_Favorite") : kcModel_Enum.ExchangeToString(DataItem.Exchange)} />
                  <Text selectable={false} style={{ color: kcColor("Title"), fontWeight: "400", fontSize: 16, marginLeft: 10 }} children={`(${m_.AllFilterItmes.length})`} />
               </View>
               <Animated.View style={{ marginLeft: 10, marginRight: 10, transform: [{ rotate: m_.AnimatedIconRotate }] }}>
                  <MaterialIcons selectable={false} name="arrow-drop-down" size={30} color={kcColor("Icon")} />
               </Animated.View>
            </Pressable>
         )}

         {/* // Items */}
         <Animated.View style={{ maxHeight: m_.MaxListHeight, flexDirection: "row", height: m_.AnimatedItemsHeigh, overflow: "hidden", opacity: m_.AnimatedItemsOpacity }}>
            <RenderList />
         </Animated.View>
      </View>
   );
};
