import kcUnitModel from "./kcUnitModel";
import * as kcQuote from "../kcTransfer/kcQuote";
import * as kcTrade from "../kcTransfer/kcTrade";
import { JWTLoginState } from "../kcTransfer/JWTPayloadModel";
import {
  CommodityChangeType,
  HistoryDataType,
} from "../kcTransfer/InternalDefine";
import {
  kcTickModel,
  kcLastInfoModel,
  kcCommodityModel,
  kcAccountModel,
  kcHistoryOHLCModel,
  kcDepthModel,
} from "../kcModel";
import RegListHelper, { RegListUpdateParam } from "./RegListHelper";
import { Quote_Request_CommodityList } from "../kcTransfer/kcQuote";
import moment from "moment";

//   "-----------------------------kcData In-----------------------------"

export type UnitEventCallback = (_mdUnit: kcUnitModel) => void;
var OnUnit_Event: UnitEventCallback[] = [];

export var m_mdAccountLDF: kcAccountModel;

var dicCommodityList: Map<string, kcCommodityModel> = new Map<
  string,
  kcCommodityModel
>();
var dicUnit: Map<string, kcUnitModel> = new Map<string, kcUnitModel>();

var Init = () => {
  kcTrade.InitializeCallback(OnAccount);
  kcQuote.InitializeCallback(
    OnRecvTick,
    OnRecvDepth,
    OnRecvLastInfo,
    OnRecvCommodityList,
    OnRecvConnodityChange,
    OnHistory
  );
  RegListHelper.RegListUpdateEvent_Add(OnRegListUpdate);
};

/* --------------------------------------------------------------------------------- */
// kcQuote CallBack
let PrintOnTickTime = moment(0);
var OnRecvTick = (_szStockCode: string, _mdTick: kcTickModel) => {
  let Time = moment();
  if (Time.valueOf() - PrintOnTickTime.valueOf() > 1000) {
    PrintOnTickTime = Time;
  }
  let mdUnit = dicUnit.get(_szStockCode);
  if (!mdUnit) return;

  // Update ToUSD
  mdUnit.OnTick(_mdTick); // Update Unit

  // 發送Event
  _SendUnitEvent(mdUnit);
};
var OnRecvDepth = (_szStockCode: string, _mdDepth: kcDepthModel) => {
  let Time = moment();
  if (Time.valueOf() - PrintOnTickTime.valueOf() > 1000) {
    PrintOnTickTime = Time;
  }

  let mdUnit = dicUnit.get(_szStockCode);
  if (!mdUnit) return;

  // Update ToUSD
  mdUnit.OnDepth(_mdDepth); // Update Unit

  // 發送Event
  _SendUnitEvent(mdUnit);
};

var OnRecvLastInfo = (_mlLastInfos: kcLastInfoModel[]) => {
  _mlLastInfos.forEach((_mdLastInfo) => {
    // Update Unit
    let mdUnit = dicUnit.get(_mdLastInfo.StockCode);
    if (!mdUnit) {
      let mdComm = dicCommodityList.get(_mdLastInfo.StockCode);
      if (!mdComm) return;
      mdUnit = new kcUnitModel(mdComm.StockCode, mdComm, _mdLastInfo);
      dicUnit.set(_mdLastInfo.StockCode, mdUnit);
    }
    mdUnit.OnLastInfo(_mdLastInfo);

    // 發送Event
    _SendUnitEvent(mdUnit);
  });
};

var OnRecvCommodityList = (_mlCommodityList: kcCommodityModel[]): void => {
  let RegCommodity: string[] = [];
  _mlCommodityList.forEach((_mdCommodity) => {
    // 更新Commodity
    let mdCommodity = dicCommodityList.get(_mdCommodity.StockCode);
    if (mdCommodity) mdCommodity.Update(_mdCommodity);
    else dicCommodityList.set(_mdCommodity.StockCode, _mdCommodity);

    // 創造Unit
    let mdUnit = dicUnit.get(_mdCommodity.StockCode);
    if (!mdUnit) {
      mdUnit = new kcUnitModel(_mdCommodity.StockCode, _mdCommodity);
      dicUnit.set(_mdCommodity.StockCode, mdUnit);
    }
  });

  /* ------------------------------- */
  // Init需要註冊的商品

  // 1. 註冊列表裡面有的
  let RegStocks = RegListHelper.GetRegList();
  RegCommodity.push(...RegStocks);

  // 2. Set過濾重複項目
  RegCommodity = [...new Set(RegCommodity).values()];
  /* ------------------------------- */

  if (RegCommodity.length > 0) kcQuote.Register(RegCommodity);
};

var OnRecvConnodityChange: kcQuote.CommodityChangeCallback = (
  _mdCommodity: kcCommodityModel,
  _ChangeType: CommodityChangeType
) => {
  switch (_ChangeType) {
    case CommodityChangeType.Create: {
      let mdCommodity = dicCommodityList.get(_mdCommodity.StockCode);
      if (mdCommodity) mdCommodity.Update(_mdCommodity);
      else dicCommodityList.set(_mdCommodity.StockCode, _mdCommodity);
      break;
    }
    case CommodityChangeType.Modify: {
      let mdCommodity = dicCommodityList.get(_mdCommodity.StockCode);
      if (mdCommodity) mdCommodity.Update(_mdCommodity);
      else {
        dicCommodityList.set(_mdCommodity.StockCode, _mdCommodity);
      }
      break;
    }
    case CommodityChangeType.Delete: {
      dicCommodityList.delete(_mdCommodity.StockCode);
      break;
    }
  }
};

var OnHistory: kcQuote.HistoryCallback = (
  _StockCode: string,
  _HType: HistoryDataType,
  _DayNumber: number,
  _mlOHLC: kcHistoryOHLCModel[]
) => {
  let mdUnit = dicUnit.get(_StockCode);
  if (!mdUnit) return;

  mdUnit.OnHistory(_HType, _mlOHLC);
};

/* --------------------------------------------------------------------------------- */
// kcTrade CallBack
var OnAccount = (
  _Account: kcAccountModel,
  LoginState: JWTLoginState // 登入狀態 (User, admin)
) => {
  m_mdAccountLDF = _Account;

  // 讀取註冊列表
  RegListHelper.ChangeAccount(_Account.Account, (args) => {
    // RequestCommodity
    Quote_Request_CommodityList();
  });
};

/* --------------------------------------------------------------------------------- */
// 註冊列表Event
var OnRegListUpdate = (_Param: RegListUpdateParam) => {
  if (_Param.Added && _Param.Added.length > 0) Register(_Param.Added);
  if (_Param.Removed && _Param.Removed.length > 0) {
    UnRegister(_Param.Removed);
    // 反註冊時清除Tick資料
    for (let szStockCode of _Param.Removed) GetUnit(szStockCode)?.Clear();
  }
};

/* --------------------------------------------------------------------------------- */
// Quote Function
export var GetUnit = (_szStockCode: string): kcUnitModel | undefined => {
  if (!dicUnit.has(_szStockCode)) return undefined;
  return dicUnit.get(_szStockCode);
};
export var GetUnit_Async = async (
  _szStockCode: string
): Promise<kcUnitModel | undefined> => {
  let kcUnit = GetUnit(_szStockCode);
  if (kcUnit !== undefined) return kcUnit;

  if (
    dicCommodityList.size != 0 &&
    dicCommodityList.get(_szStockCode) === undefined
  )
    return undefined;

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(GetUnit_Async(_szStockCode));
    }, 50);
  });
};

/* --------------------------------------------------------------------------------- */
// Unit Event
export var UnitEvent_Add = (UnitEventCallback: UnitEventCallback) => {
  if (UnitEventCallback) OnUnit_Event.push(UnitEventCallback);
};
export var UnitEvent_Remove = (UnitEventCallback: UnitEventCallback) => {
  if (UnitEventCallback) {
    let nIdx = OnUnit_Event.indexOf(UnitEventCallback);
    if (nIdx >= 0) OnUnit_Event.splice(nIdx, 1);
  }
};
var _SendUnitEvent = (mdUnit: kcUnitModel): void => {
  for (let i = 0; i < OnUnit_Event.length; i++) OnUnit_Event[i](mdUnit);
};

// 註冊(訂閱)Tick
export var Register = (Stocks?: string | string[]) => {
  if (Stocks === undefined) {
    kcQuote.Register(Stocks);
    return;
  }

  let Units: kcUnitModel[] = []; // 所有註冊要求的商品, 有的話要先往下發一次
  let aRegList: string[] = [];
  if (typeof Stocks === "string") {
    // 可能要把商品列表沒有的過濾掉
    aRegList.push(Stocks);

    // 嘗試尋找已經存在的Unit
    let mdUnit = dicUnit.get(Stocks);
    if (mdUnit && mdUnit.LastInfo) Units.push(mdUnit);
  } else if (Array.isArray(Stocks)) {
    Stocks.forEach((szStock) => {
      // 可能要把商品列表沒有的過濾掉
      aRegList.push(szStock);

      // 嘗試尋找已經存在的Unit
      let mdUnit = dicUnit.get(szStock);
      if (mdUnit && mdUnit.LastInfo) Units.push(mdUnit);
    });
  }

  if (aRegList.length > 0) kcQuote.Register(aRegList); // 註冊

  // 先往下發一次註冊的Unit
  if (Units.length > 0) {
    Units.forEach((mdUnit) => _SendUnitEvent(mdUnit));
  }
};
// 反註冊(反訂閱)Tick
export var UnRegister = (Stocks?: string | string[]) => {
  if (Stocks === undefined) {
    kcQuote.UnRegister(Stocks);
    return;
  }

  let aRegList: string[] = [];
  if (typeof Stocks === "string") aRegList.push(Stocks);
  else if (Array.isArray(Stocks)) aRegList.push(...Stocks);

  if (aRegList.length > 0) kcQuote.UnRegister(aRegList);
};
// 註冊(訂閱)Tick, 先以註冊列表篩選過
export var Register_CheckRegList = (StockCode: string) => {
  if (!StockCode) {
    return;
  }
  if (RegListHelper.ContainsItem(StockCode)) return;
  Register(StockCode);
};
// 反註冊(反訂閱)Tick, 先以註冊列表篩選過
export var UnRegister_CheckRegList = (StockCode: string) => {
  if (!StockCode) {
    return;
  }
  if (RegListHelper.ContainsItem(StockCode)) return;
  UnRegister(StockCode);
};

export var GetCommodity = (_StockCode: string) => {
  return dicCommodityList.get(_StockCode);
};
export var GetCommodityList = () => {
  return [...dicCommodityList.values()];
};

export var AccountLDF = () => {
  return m_mdAccountLDF;
};

Init();
