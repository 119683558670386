const Encoding = require("text-encoding"); // npm install text-encoding --save
const UTF8Encoder = new Encoding.TextEncoder();
const UTF8Decoder = new Encoding.TextDecoder();

export default class StringConverter {
  static UTF8_GetBytes(_sz: string): Uint8Array {
    return UTF8Encoder.encode(_sz);
  }
  static UTF8_GetString(_Buffer: Uint8Array): string {
    return UTF8Decoder.decode(_Buffer);
  }
  static ToHex(_sz: string): number {
    if (_sz === "") return 0;
    if (_sz === "White" || _sz === "white") return 0xffffff;
    if (_sz === "Black" || _sz === "black") return 0;

    _sz = _sz.replace("#", "");
    if (_sz.length > 6) _sz = _sz.substring(0, 6);

    return parseInt(_sz, 16);
  }
}

export const ToHex = StringConverter.ToHex;
