import moment, { Moment } from "moment";
import { MomentExtensions } from "../kcExternal";
import { kcCommodityModel, kcHistoryOHLCModel, kcTickModel, kcTradeTimeModel, ModelJSExtensions } from "../kcModel";
import { TradeTimeState } from "../kcModel/kcModel_Enum";
import { HistoryDataType } from "../kcTransfer/InternalDefine";
import { HistoryParams } from "./kcTECPCollection";
import TradeTimeRuleModel, { TradeTimeResault } from "./TradeTimeRuleModel";

export class kcTECPExtensions {
   private static m_bRemoveCloseTime = true;
   private static DefaultCommoditySet: kcCommodityModel = new kcCommodityModel({
      TradeTime: [new kcTradeTimeModel({ State: TradeTimeState.Open, MinuteOffset: 0 }), new kcTradeTimeModel({ State: TradeTimeState.Close, MinuteOffset: 1439 })],
   }); // 預設 00:00 - 2359

   public static InitOHLCExData(_mlData: kcHistoryOHLCModel[], _mdCommodity: kcCommodityModel, _Params: HistoryParams): kcHistoryOHLCModel[] {
      switch (_Params.HistoryType) {
         case HistoryDataType.Tick: {
            return this.ToKLine_Tick(_mlData, _Params.HistoryBase, _mdCommodity);
         }
         case HistoryDataType.Minute: {
            switch (_Params.RecoverMode) {
               //case OHLCRecoverMode.DefaultRecover:
               case true:
                  return this.ToKLine_Minute_DefaultRecover(_mlData, _Params.HistoryBase, _mdCommodity, _Params.RecoverMode);

               //case OHLCRecoverMode.None:
               case false:
               default:
                  return this.ToKLine_Minute_Pure(_mlData, _Params.HistoryBase, _mdCommodity);
            }
         }
         case HistoryDataType.Day: {
            return this.ToKLine_Day(_mlData, _Params.HistoryBase, _mdCommodity, _Params.RecoverMode);
         }
         default:
            return [];
      }
   }
   public static UpdateOHLCExData(_mlSrc: kcHistoryOHLCModel[], _mlData: kcTickModel[], _mdCommodity: kcCommodityModel, _Params: HistoryParams): OHLCExUpdateResault {
      switch (_Params.HistoryType) {
         case HistoryDataType.Tick: {
            return this.UpdateOHLC_Tick(_mlSrc, _mlData, _Params.HistoryBase, _mdCommodity);
         }
         case HistoryDataType.Minute: {
            switch (_Params.RecoverMode) {
               //case OHLCRecoverMode.DefaultRecover:
               case true:
                  return this.UpdateOHLC_Minute_DefaultRecover(_mlSrc, _mlData, _Params.HistoryBase, _mdCommodity, _Params.RecoverMode);

               //case OHLCRecoverMode.None:
               case false:
               default:
                  return this.UpdateOHLC_Minute_Pure(_mlSrc, _mlData, _Params.HistoryBase, _mdCommodity);
            }
         }
         case HistoryDataType.Day: {
            return this.UpdateOHLC_Day(_mlSrc, _mlData, _Params.HistoryBase, _mdCommodity, _Params.RecoverMode);
         }
         default: {
            if (_mlSrc == null) _mlSrc = [];

            return new OHLCExUpdateResault();
         }
      }
   }

   /* --------------------------------------------------------------- */
   // KLine Tick
   private static ToKLine_Tick(_mlSrc: kcHistoryOHLCModel[], _nBase: number, _mdCommodity: kcCommodityModel): kcHistoryOHLCModel[] {
      let mlRet: kcHistoryOHLCModel[] = [];
      this.UpdateOHLC_Tick_Core(mlRet, _mlSrc, _nBase, _mdCommodity);
      return mlRet;
   }
   private static UpdateOHLC_Tick(_mlOHLCEx: kcHistoryOHLCModel[], _TickBuff: kcTickModel[], _nBase: number, _mdCommodity: kcCommodityModel): OHLCExUpdateResault {
      return this.UpdateOHLC_Tick_Core(_mlOHLCEx, _TickBuff, _nBase, _mdCommodity);
   }
   private static UpdateOHLC_Tick_Core(_mlOHLCEx: kcHistoryOHLCModel[], _mlData: kcTickModel[] | kcHistoryOHLCModel[], _nBase: number, _mdCommodity: kcCommodityModel): OHLCExUpdateResault {
      let mdResault = new OHLCExUpdateResault();

      // 檢查 Commodity的開收時間
      let mdCommodity = _mdCommodity;
      let bCommTimeOK = this.CheckCommodityTime(_mdCommodity);
      if (!bCommTimeOK) mdCommodity = this.DefaultCommoditySet;
      let mlTimeRule: TradeTimeRuleModel[] = this.GetTradeRules(mdCommodity);

      let nCount = 0;
      let OpenTime: Moment = moment(0);
      let CloseTime: Moment = moment(0);
      let mdRun = new kcHistoryOHLCModel({ Time: moment(0), EndTime: moment(0) });
      if (_mlOHLCEx.length > 0) {
         // 取得最後的資料沿用
         mdRun = _mlOHLCEx[_mlOHLCEx.length - 1];
         let TradeRes = this.GetTradeTime(mdRun.Time, mlTimeRule, false);
         OpenTime = TradeRes.OpenTime;
         CloseTime = TradeRes.CloseTime;
         nCount = mdRun.EndTickIndex + 1;
      }
      for (let mdData of _mlData) {
         if (this.IsEmptyData(mdData)) continue;

         let tNow = mdData.Time;
         if (tNow.isBefore(mdRun.Time)) {
            // 資料時間錯亂
            continue;
         }

         if (tNow.isAfter(CloseTime)) {
            // 換日流程
            nCount = 0; // 重置count計算
            // 更新開收盤時間, 並且判斷這筆資料是否可用(是否在交易時間內)
            let TradeRes = this.GetTradeTime(tNow, mlTimeRule, false);
            OpenTime = TradeRes.OpenTime;
            CloseTime = TradeRes.CloseTime;
            if (!TradeRes.IsInTradeTime) continue;
         }
         if (!this.IsInTradeTime(tNow, OpenTime, CloseTime))
            // 跳過壞掉的Data
            continue;

         // 換跟K棒
         if (nCount % _nBase == 0) {
            // // 起始結束時間用的Time
            // let RangeTime = mdData.Time;

            // // 判斷是否為重複時間的Tick
            // if (mdRun.Time.isSame( mdData.Time)/* || mdRun.EndTime == mdData.Time*/) // 多Tick可能出現的case
            // RangeTime = RangeTime.add(1,"ms");
            //     //RangeTime = mdRun.EndTime.AddTicks(1); // 用前一次的EndTime + 1做為StartTime延續

            mdRun = this.ToOHLCEx(mdData);
            _mlOHLCEx.push(mdRun);

            // 時間設定
            mdRun.Time = mdData.Time;
            // mdRun.StartTime = RangeTime;
            // mdRun.EndTime = RangeTime;

            // TickIndex
            mdRun.StartTickIndex = nCount;
            mdRun.EndTickIndex = nCount;

            // // 定義最小時間差
            // if (mdRun.EndTime <= mdRun.StartTime)
            //     mdRun.EndTime = mdRun.StartTime.AddTicks(m_lOHLCMinTimeDifTick - 1);
         } else {
            this.UpdateOHLCEx(mdRun, mdData);

            // // 時間設定
            // mdRun.EndTime = mdData.Time;

            // TickIndex
            mdRun.EndTickIndex = nCount;

            // // 定義最小時間差
            // if (mdRun.EndTime <= mdRun.StartTime)
            //     mdRun.EndTime = mdRun.StartTime.AddTicks(m_lOHLCMinTimeDifTick - 1);
         }

         nCount++;
         let nUpdateIdx = _mlOHLCEx.length - 1;
         mdResault.SetUpdateIndex(nUpdateIdx);
      }

      return mdResault;
   }

   /* --------------------------------------------------------------- */
   // KLine 分 Pure
   private static ToKLine_Minute_Pure(_mlSrc: kcHistoryOHLCModel[], _nBase: number, _mdCommodity: kcCommodityModel): kcHistoryOHLCModel[] {
      let mlRet: kcHistoryOHLCModel[] = [];
      this.UpdateOHLC_Minute_Pure_Core(mlRet, _mlSrc, _nBase, _mdCommodity);
      return mlRet;
   }
   private static UpdateOHLC_Minute_Pure(_mlOHLCEx: kcHistoryOHLCModel[], _TickBuff: kcTickModel[], _nBase: number, _mdCommodity: kcCommodityModel): OHLCExUpdateResault {
      return this.UpdateOHLC_Minute_Pure_Core(_mlOHLCEx, _TickBuff, _nBase, _mdCommodity);
   }
   private static UpdateOHLC_Minute_Pure_Core(_mlOHLCEx: kcHistoryOHLCModel[], _mlData: kcHistoryOHLCModel[] | kcTickModel[], _nBase: number, _mdCommodity: kcCommodityModel): OHLCExUpdateResault {
      if (_mlOHLCEx == null) _mlOHLCEx = [];
      let mdResault = new OHLCExUpdateResault();

      // 檢查 Commodity的開收時間
      let mdCommodity = _mdCommodity;
      let bCommTimeOK = this.CheckCommodityTime(_mdCommodity);
      if (!bCommTimeOK) mdCommodity = this.DefaultCommoditySet;
      let mlTimeRule = this.GetTradeRules(mdCommodity);

      let lTImeDifTicks = _nBase * MomentExtensions.TicksPerMinute;
      let OpenTime = moment(0);
      let CloseTime = moment(0);
      let nRunIdx = _mlOHLCEx.length - 1;

      for (let nBufIdx = 0; nBufIdx < _mlData.length; nBufIdx++) {
         let mdData = _mlData[nBufIdx];
         let tNow = mdData.Time;

         if (this.IsEmptyData(mdData)) continue;

         if (!this.IsInTradeTime(tNow, OpenTime, CloseTime)) {
            let TradeRes = this.GetTradeTime(tNow, mlTimeRule, false);
            OpenTime = TradeRes.OpenTime;
            CloseTime = TradeRes.CloseTime;
            if (!TradeRes.IsInTradeTime) continue;
         }

         let tOHLC = MomentExtensions.FloorToMinute_Basic(tNow, OpenTime, _nBase);
         if (this.m_bRemoveCloseTime && tOHLC.isSame(CloseTime)) {
            // 收盤時間特例處理
            tOHLC = tOHLC.add(-lTImeDifTicks, "ms");
            tOHLC = MomentExtensions.FloorToMinute_Basic(tOHLC, OpenTime, _nBase);
         }

         let FindRunningRes = this.FindRunningOHLCIndex(_mlOHLCEx, tOHLC, nRunIdx);
         nRunIdx = FindRunningRes.nRunningIndex;
         if (FindRunningRes.Resault) {
            // Update OHLC
            this.UpdateOHLCEx(_mlOHLCEx[nRunIdx], mdData);
         } else {
            // Insert OHLC
            let mdNew = this.ToOHLCEx(mdData);
            mdNew.Time = tOHLC;

            // mdNew.StartTime = tOHLC;
            // mdNew.EndTime = tOHLC.AddTicks(lTImeDifTicks - 1);
            _mlOHLCEx.splice(nRunIdx, 0, mdNew);
         }

         mdResault.SetUpdateIndex(nRunIdx);
      }

      return mdResault;
   }

   /* --------------------------------------------------------------- */
   // KLine 分 Default回補 (尚未實作應開盤無成交的回補)
   private static ToKLine_Minute_DefaultRecover(_mlSrc: kcHistoryOHLCModel[], _nBase: number, _mdCommodity: kcCommodityModel, _RecoverMode: boolean): kcHistoryOHLCModel[] {
      let mlRet: kcHistoryOHLCModel[] = [];
      this.UpdateOHLC_Minute_DefaultRecover_Core(mlRet, _mlSrc, _nBase, _mdCommodity, _RecoverMode);
      return mlRet;
   }
   private static UpdateOHLC_Minute_DefaultRecover(_mlOHLCEx: kcHistoryOHLCModel[], _TickBuff: kcTickModel[], _nBase: number, _mdCommodity: kcCommodityModel, _RecoverMode: boolean): OHLCExUpdateResault {
      return this.UpdateOHLC_Minute_DefaultRecover_Core(_mlOHLCEx, _TickBuff, _nBase, _mdCommodity, _RecoverMode);
   }
   private static UpdateOHLC_Minute_DefaultRecover_Core(_mlOHLCEx: kcHistoryOHLCModel[], _mlData: kcHistoryOHLCModel[] | kcTickModel[], _nBase: number, _mdCommodity: kcCommodityModel, _RecoverMode: boolean): OHLCExUpdateResault {
      if (_mlOHLCEx == null) _mlOHLCEx = [];
      let mdResault = new OHLCExUpdateResault();

      // 檢查 Commodity的開收時間
      let mdCommodity = _mdCommodity;
      let bCommTimeOK = this.CheckCommodityTime(_mdCommodity);
      if (!bCommTimeOK) mdCommodity = this.DefaultCommoditySet;
      let mlTimeRule = this.GetTradeRules(mdCommodity);

      let lTImeDifTicks = _nBase * MomentExtensions.TicksPerMinute;
      let OpenTime = moment(0);
      let CloseTime = moment(0);
      let nRunIdx = _mlOHLCEx.length - 1;

      for (let nBufIdx = 0; nBufIdx < _mlData.length; nBufIdx++) {
         let mdData = _mlData[nBufIdx];
         let tNow = mdData.Time;

         if (!this.IsInTradeTime(tNow, OpenTime, CloseTime)) {
            let TradeRes = this.GetTradeTime(tNow, mlTimeRule, false);
            OpenTime = TradeRes.OpenTime;
            CloseTime = TradeRes.CloseTime;
            if (!TradeRes.IsInTradeTime) continue;
         }

         let tOHLC = MomentExtensions.FloorToMinute_Basic(tNow, OpenTime, _nBase);
         if (this.m_bRemoveCloseTime && tOHLC.isSame(CloseTime)) {
            // 收盤時間特例處理
            tOHLC = tOHLC.add(-lTImeDifTicks, "ms");
            tOHLC = MomentExtensions.FloorToMinute_Basic(tOHLC, OpenTime, _nBase);
         }

         let CheckLimitTime = MomentExtensions.MaxValue;
         if (nBufIdx + 1 < _mlData.length) CheckLimitTime = _mlData[nBufIdx + 1].Time;
         let nEffectIdx_S: number; // Update影響到的 起始Index
         let nEffectIdx_E: number; // Update影響到的 結束Index 跟Insert有關係

         let FundRunningRes = this.FindRunningOHLCIndex(_mlOHLCEx, tOHLC, nRunIdx);
         nRunIdx = FundRunningRes.nRunningIndex;
         if (FundRunningRes.Resault) {
            // Update OHLC
            this.UpdateOHLCEx(_mlOHLCEx[nRunIdx], mdData);

            // 向後確認回補資料
            let nCheckChangeCount = this.CheckReCoverData(_mlOHLCEx, nRunIdx, CheckLimitTime);
            nEffectIdx_S = nRunIdx;
            nEffectIdx_E = nRunIdx + nCheckChangeCount;
         } else {
            // Insert OHLC
            let mdNew = this.ToOHLCEx(mdData);
            mdNew.Time = tOHLC;
            // mdNew.StartTime = tOHLC;
            // mdNew.EndTime = tOHLC.AddTicks(lTImeDifTicks - 1);
            _mlOHLCEx.splice(nRunIdx, 0, mdNew);

            let nCheckDataIndex = nRunIdx;
            // 前後回補流程
            if (bCommTimeOK) {
               let nBefReCoverCnt = this.MinuteReCoverRange(_mlOHLCEx, nRunIdx, lTImeDifTicks, mlTimeRule, this.m_bRemoveCloseTime, _RecoverMode);
               let nAftRCIdx = nRunIdx + nBefReCoverCnt + 1;
               let nAftReCoverCnt = this.MinuteReCoverRange(_mlOHLCEx, nAftRCIdx, lTImeDifTicks, mlTimeRule, this.m_bRemoveCloseTime, _RecoverMode);
               nCheckDataIndex = nAftRCIdx + nAftReCoverCnt;
            }

            // 向後確認回補資料
            this.CheckReCoverData(_mlOHLCEx, nCheckDataIndex, CheckLimitTime);

            nEffectIdx_S = nRunIdx;
            nEffectIdx_E = _mlOHLCEx.length - 1;
         }

         mdResault.SetUpdateIndex(nEffectIdx_S, nEffectIdx_E);
      }

      //// 向後回補到最後時間
      //if (bCommTimeOK)
      //{
      //    if (!TimeZoneHelper.TryGetTimeZone(mdCommodity.ExchangeCode, out TimeZoneInfo tz))
      //        tz = TimeZoneHelper.LocalTimeZone;
      //    DateTime TimeEnd = TimeZoneHelper.ToExcTime(_DataEndTime, tz);

      //    int nLastReCoverCnt = MinuteReCoverLast_ToDailyClose(_mlOHLCEx, TimeEnd, lTImeDifTicks, mlTimeRule, m_bRemoveCloseTime, _RecoverMode);
      //    if (nLastReCoverCnt > 0)
      //    {
      //        int nEffectIdx_S = _mlOHLCEx.Count - nLastReCoverCnt;
      //        int nEffectIdx_E = _mlOHLCEx.Count - 1;
      //        mdResault.SetUpdateIndex(nEffectIdx_S, nEffectIdx_E);
      //    }
      //}

      return mdResault;
   }

   /* --------------------------------------------------------------- */
   // KLine 日
   private static ToKLine_Day(_mlSrc: kcHistoryOHLCModel[], _nBase: number, _mdCommodity: kcCommodityModel, _RecoverMode: boolean): kcHistoryOHLCModel[] {
      let mlRet: kcHistoryOHLCModel[] = [];
      this.UpdateOHLC_Day_Core(mlRet, _mlSrc, _nBase, _mdCommodity, _RecoverMode, true);
      return mlRet;
   }
   private static UpdateOHLC_Day(_mlOHLCEx: kcHistoryOHLCModel[], _mlData: kcTickModel[], _nBase: number, _mdCommodity: kcCommodityModel, _RecoverMode: boolean): OHLCExUpdateResault {
      return this.UpdateOHLC_Day_Core(_mlOHLCEx, _mlData, _nBase, _mdCommodity, _RecoverMode, false);
   }
   private static UpdateOHLC_Day_Core(_mlOHLCEx: kcHistoryOHLCModel[], _mlData: kcHistoryOHLCModel[] | kcTickModel[], _nBase: number, _mdCommodity: kcCommodityModel, _RecoverMode: boolean, _bTrueDate: boolean): OHLCExUpdateResault {
      if (_mlOHLCEx == null) _mlOHLCEx = [];
      let mdResault = new OHLCExUpdateResault();

      //  let _bDayRecover = _RecoverMode == OHLCRecoverMode.DefaultRecover;
      let _bDayRecover = _RecoverMode == true;

      // 檢查 Commodity的開收時間
      let mdCommodity = _mdCommodity;
      let bCommTimeOK = this.CheckCommodityTime(_mdCommodity);
      if (!bCommTimeOK) mdCommodity = this.DefaultCommoditySet;

      let mlTimeRule = this.GetTradeRules(mdCommodity);

      // 日線未開盤回補, 只在"1日"日線使用
      let bRecoverOneDay = _bDayRecover && _nBase == 1 /*(int)DayKLineType.Day*/ && bCommTimeOK;

      // Floor Day Time Func
      let FloorDayFuncRes = ModelJSExtensions.GetFloorDayFunc(_nBase);
      let fncFloorDate = FloorDayFuncRes.fFloorDate;
      let nBase = FloorDayFuncRes.nFloorBase;

      let nRunIdx = _mlOHLCEx.length - 1;
      for (let nBufIdx = 0; nBufIdx < _mlData.length; nBufIdx++) {
         var mdData = _mlData[nBufIdx];
         let tNow = mdData.Time;
         if (!bRecoverOneDay && this.IsEmptyData(mdData))
            // 無資料且不回補的話跳過
            continue;

         //DateTime tDayOfTickTime = OpenTime.Date;
         let tBelongDate = _bTrueDate ? tNow : ModelJSExtensions.ToDayTime(tNow, _mdCommodity.TradeTime);
         let tOHLC = fncFloorDate.call(this, tBelongDate, nBase);

         let CheckLimitTime = MomentExtensions.MaxValue;
         if (nBufIdx + 1 < _mlData.length) CheckLimitTime = _mlData[nBufIdx + 1].Time;

         let FindRunningRes = this.FindRunningOHLCIndex(_mlOHLCEx, tOHLC, nRunIdx);
         nRunIdx = FindRunningRes.nRunningIndex;
         let bFindedOHLC = FindRunningRes.Resault;
         let nEffectIdx_S = nRunIdx; // Update影響到的 起始Index
         let nEffectIdx_E = nRunIdx; // Update影響到的 結束Index 跟Insert有關係

         if (bFindedOHLC) {
            // Update KLine
            this.UpdateOHLCEx(_mlOHLCEx[nRunIdx], mdData);

            if (bRecoverOneDay) {
               // 向後確認回補資料
               let nCheckChangeCount = this.CheckReCoverData(_mlOHLCEx, nRunIdx, CheckLimitTime);
               nEffectIdx_E = nRunIdx + nCheckChangeCount;
            }
         } // New KLine
         else {
            //  // 開始結束時間
            // let  DateToTradeTimeRes = ModelJSExtensions.DateToTradeTime(tOHLC, _nBase, mdCommodity);
            // let ts = DateToTradeTimeRes.StartTime;
            // let te = DateToTradeTimeRes.EndTime;

            // Insert OHLC
            let mdNew = this.ToOHLCEx(mdData);
            mdNew.Time = tOHLC;
            //  mdNew.StartTime = ts;
            //  mdNew.EndTime = te;
            _mlOHLCEx.splice(nRunIdx, 0, mdNew);

            if (bRecoverOneDay) {
               /* ------------------------------------------------ */
               //// 只向前回補
               //int nBefReCoverCnt = DayOHLCReCover(_mlOHLCEx, nRunIdx - 1, tOHLC, _mdCommodity);
               //int nCheckDataIndex = nRunIdx + nBefReCoverCnt;

               /* ------------------------------------------------ */
               //// 向前回補資料
               //int nBefReCoverCnt = DayOHLCReCover(_mlOHLCEx, nRunIdx - 1, tOHLC, _mdCommodity);
               //int nAftRCIdx = nRunIdx + nBefReCoverCnt+1;
               //// 向後回補資料
               //int nAftReCoverCnt = DayOHLCReCover(_mlOHLCEx, nAftRCIdx - 1, tOHLC, _mdCommodity);
               //int nCheckDataIndex = nAftRCIdx + nAftReCoverCnt;

               /* ------------------------------------------------ */
               // 不處裡回補
               let nCheckDataIndex = nRunIdx;

               // 向後確認回補資料
               let nCheckChangeCount = this.CheckReCoverData(_mlOHLCEx, nCheckDataIndex, CheckLimitTime);

               nEffectIdx_E = nCheckDataIndex + nCheckChangeCount;
            }
         }

         mdResault.SetUpdateIndex(nEffectIdx_S, nEffectIdx_E);
      }

      return mdResault;
   }

   /* --------------------------------------------------------------- */
   // 回補 輔助
   private static MinuteReCover(_mlOHLCEx: kcHistoryOHLCModel[], _nStartIndex: number, _ToTime: Moment, _lTImeDifTicks: number, _mlTimeRule: TradeTimeRuleModel[], _bRemoveCloseTime: boolean, _RecoverMode: boolean): number {
      //if (_RecoverMode == OHLCRecoverMode.None)
      if (_RecoverMode == false) return 0;

      let nReCoverCount = 0;
      let nStartIdx = _nStartIndex;
      if (nStartIdx < 0) return nReCoverCount;
      let nInsertIdxBase = nStartIdx + 1;

      let DailyRule = this.GetDailyTradeTimeRule(_mlTimeRule); // 整日的, 包含同日不同盤

      let lTickDif = _ToTime.valueOf() - _mlOHLCEx[nStartIdx].Time.valueOf();
      if (lTickDif > _lTImeDifTicks) {
         let TradeBaseDate = moment(0); // 交易時間基準日
         let RCOpenTime = moment(0);
         let RCCloseTime = moment(0);
         let RCBaseTime = _mlOHLCEx[nStartIdx].Time;
         let TimeRecover = RCBaseTime.add(_lTImeDifTicks, "ms");
         let StopTime = _ToTime;

         let SOpenTime, SCloseTime; // Input起始盤的開收盤時間
         let EOpenTime, ECloseTime; // Input結束盤的開收盤時間
         let mdTradeS = this.GetTradeTime(RCBaseTime, _mlTimeRule, false);
         SOpenTime = mdTradeS.OpenTime;
         SCloseTime = mdTradeS.CloseTime;
         let mdTradeE = this.GetTradeTime(StopTime, _mlTimeRule, false);
         EOpenTime = mdTradeE.OpenTime;
         ECloseTime = mdTradeE.CloseTime;

         while (true) {
            if (TimeRecover >= StopTime) break;

            if (!this.IsInTradeTime(TimeRecover, RCOpenTime, RCCloseTime)) {
               let mdTradeRC = this.GetTradeTime(TimeRecover, _mlTimeRule, false);
               RCOpenTime = mdTradeRC.OpenTime;
               RCCloseTime = mdTradeRC.CloseTime;
               let nRuleIndex = mdTradeRC.RuleIndex;
               if (nRuleIndex > -1) {
                  // 爛東西
                  let nOpenOffset = _mlTimeRule[nRuleIndex].OpenTimeOffset;
                  nOpenOffset += 1440 * -_mlTimeRule[nRuleIndex].DayOffset;
                  TradeBaseDate = RCOpenTime.clone().add(-nOpenOffset, "minutes");
               }
               if (RCOpenTime > RCBaseTime) {
                  TimeRecover = RCOpenTime;
                  if (TimeRecover >= StopTime) break;
               }
               // if (_RecoverMode == OHLCRecoverMode.DefaultRecover)
               if (_RecoverMode == true) {
                  /* ------------------------------------------------ */
                  //// 只回補有實際成交過的"交易日" 爛東西
                  //if (!IsInTradeTime(RCBaseTime, TradeBaseDate, DailyRule) &&
                  //    !IsInTradeTime(StopTime, TradeBaseDate, DailyRule))
                  //{
                  //    TimeRecover = RCCloseTime;
                  //    goto GoContinue;
                  //}

                  /* ------------------------------------------------ */
                  // 只回補有實際成交過的"盤" 爛東西
                  if (
                     !this.IsInTradeTime(TimeRecover, SOpenTime, SCloseTime) && // 不在起始盤的交易時段內
                     !this.IsInTradeTime(TimeRecover, EOpenTime, ECloseTime)
                  ) {
                     // 不在結束盤的交易時段內
                     TimeRecover = RCCloseTime;
                     //goto GoContinue;
                     TimeRecover = TimeRecover.add(_lTImeDifTicks, "ms");
                     continue;
                  }

                  /* ------------------------------------------------ */
                  // 回補包含未開盤的 周一~周五
                  //if (RCOpenTime.DayOfWeek == DayOfWeek.Sunday || RCOpenTime.DayOfWeek == DayOfWeek.Saturday)
                  //{
                  //    TimeRecover = RCCloseTime;
                  //    goto GoContinue;
                  //}
               }
            }

            if (_bRemoveCloseTime && TimeRecover == RCCloseTime) {
               //goto GoContinue;
               TimeRecover = TimeRecover.add(_lTImeDifTicks, "ms");
               continue;
            }

            let mdRecover = this.ToReCoverOHLC(_mlOHLCEx[nStartIdx], TimeRecover, TimeRecover, TimeRecover.clone().add(_lTImeDifTicks - 1, "ms"));
            _mlOHLCEx.splice(nInsertIdxBase + nReCoverCount, 0, mdRecover);
            nReCoverCount++;

            //  GoContinue:
            // TimeRecover = TimeRecover.add(_lTImeDifTicks,"ms");
         }
      }
      return nReCoverCount;
   }

   private static MinuteReCoverRange(_mlOHLCEx: kcHistoryOHLCModel[], _nRunIdx: number, _lTImeDifTicks: number, _mlTimeRule: TradeTimeRuleModel[], _bRemoveCloseTime: boolean, _RecoverMode: boolean): number {
      let nReCoverCount = 0;
      if (_nRunIdx >= _mlOHLCEx.length) return nReCoverCount;
      let nStartIndex = _nRunIdx - 1;
      let StopTime = _mlOHLCEx[_nRunIdx].Time;
      nReCoverCount = this.MinuteReCover(_mlOHLCEx, nStartIndex, StopTime, _lTImeDifTicks, _mlTimeRule, _bRemoveCloseTime, _RecoverMode);

      return nReCoverCount;
   }

   // private static int MinuteReCoverLast_ToDailyClose(List<kcOHLCExModel> _mlOHLCEx, DateTime _DataEndTime, long _lTImeDifTicks, List<TradeTimeRuleModel> _mlTimeRule, bool _bRemoveCloseTime, OHLCRecoverMode _RecoverMode)
   // {
   //     if (_mlOHLCEx.Count > 0)
   //     {
   //         int nRuleIdx = -1;
   //         DateTime OpenTime, CloseTime;
   //         GetTradeTime(_mlOHLCEx[_mlOHLCEx.Count - 1].Time, _mlTimeRule, out OpenTime, out CloseTime, out nRuleIdx, false);
   //         if (nRuleIdx > -1) // 爛東西
   //         {
   //             int nOpenOffset = _mlTimeRule[nRuleIdx].OpenTimeOffset;
   //             if (nOpenOffset < 0)
   //                 nOpenOffset += 1440;
   //             if (nOpenOffset > 1440)
   //                 nOpenOffset = nOpenOffset % 1440;
   //             DateTime TradeBaseDate = OpenTime.AddMinutes(-nOpenOffset);
   //             TradeTimeRuleModel DailyRule = this.GetDailyTradeTimeRule(_mlTimeRule); // 整日的, 包含同日不同盤
   //             DateTime LimitCloseTime = TradeBaseDate.AddMinutes(DailyRule.CloseTimeOffset);
   //             if (_DataEndTime > LimitCloseTime)
   //                 _DataEndTime = LimitCloseTime;
   //         }
   //     }
   //     return MinuteReCoverLast(_mlOHLCEx, _DataEndTime, _lTImeDifTicks, _mlTimeRule, _bRemoveCloseTime, _RecoverMode);
   // }

   // private static int MinuteReCoverLast(List<kcOHLCExModel> _mlOHLCEx, DateTime _ToTime, long _lTImeDifTicks, List<TradeTimeRuleModel> _mlTimeRule, bool _bRemoveCloseTime, OHLCRecoverMode _RecoverMode)
   // {
   //     return MinuteReCover(_mlOHLCEx, _mlOHLCEx.Count - 1, _ToTime, _lTImeDifTicks, _mlTimeRule, _bRemoveCloseTime, _RecoverMode);
   // }

   private static CheckReCoverData(_mlOHLCEx: kcHistoryOHLCModel[], _nIndex: number, _LimitTime: Moment): number {
      if (_nIndex >= _mlOHLCEx.length) return 0;

      let nCheckIdx = _nIndex + 1;
      let mdBase = _mlOHLCEx[_nIndex];
      while (true) {
         if (nCheckIdx >= _mlOHLCEx.length) break;
         let mdCheck = _mlOHLCEx[nCheckIdx];
         if (mdCheck.Time >= _LimitTime) break;
         if (!this.IsEmptyData(mdCheck)) break;
         if (mdCheck.ClosePrice == mdBase.ClosePrice) break;
         mdCheck.OpenPrice = mdBase.ClosePrice;
         mdCheck.HighPrice = mdBase.ClosePrice;
         mdCheck.LowPrice = mdBase.ClosePrice;
         mdCheck.ClosePrice = mdBase.ClosePrice;
         nCheckIdx++;
      }
      return nCheckIdx - _nIndex - 1;
   }

   private static FindRunningOHLCIndex(_mlOHLCEx: kcHistoryOHLCModel[], _FindTime: Moment, _nRunningIndex: number): { nRunningIndex: number; Resault: boolean } {
      let bFind = false;
      let Resault = { nRunningIndex: _nRunningIndex, Resault: bFind };

      if (_mlOHLCEx.length == 0) {
         _nRunningIndex = 0;
         return Resault;
      }

      if (_nRunningIndex == -1 || _nRunningIndex == _mlOHLCEx.length) _nRunningIndex = _mlOHLCEx.length - 1;

      let nPreFindIndex = _nRunningIndex;
      while (true) {
         let nCompare: number;
         if (_nRunningIndex <= -1) nCompare = -1;
         else if (_nRunningIndex >= _mlOHLCEx.length) nCompare = 1;
         else nCompare = _mlOHLCEx[_nRunningIndex].Time.valueOf() - _FindTime.valueOf();
         nCompare = Math.min(1, Math.max(-1, nCompare));

         if (nCompare == 0) {
            bFind = true;
            break;
         } else {
            let nNextFindIdx = _nRunningIndex - nCompare;
            if (nPreFindIndex == nNextFindIdx) {
               _nRunningIndex = _nRunningIndex > nPreFindIndex ? _nRunningIndex : nPreFindIndex;
               break;
            } else {
               nPreFindIndex = _nRunningIndex;
               _nRunningIndex = nNextFindIdx;
            }
         }
      }

      Resault.nRunningIndex = _nRunningIndex;
      Resault.Resault = bFind;
      return Resault;
   }

   /* --------------------------------------------------------------- */
   // kcOHLCExModel 輔助
   private static ToReCoverOHLC(_mdBase: kcHistoryOHLCModel, _Time: Moment, _StartTime: Moment, _EndTime: Moment): kcHistoryOHLCModel {
      let mdRet = new kcHistoryOHLCModel({
         Time: _Time,
         StartTime: _StartTime,
         EndTime: _EndTime,
         StartTickIndex: -1,
         EndTickIndex: -1,
         OpenPrice: _mdBase.ClosePrice,
         HighPrice: _mdBase.ClosePrice,
         LowPrice: _mdBase.ClosePrice,
         ClosePrice: _mdBase.ClosePrice,
         Vol: 0,
      });

      return mdRet;
   }
   private static ToOHLCEx(_Data: kcHistoryOHLCModel | kcTickModel): kcHistoryOHLCModel {
      let mdRet: kcHistoryOHLCModel;
      if (kcHistoryOHLCModel.IsHistoryOHLCModel(_Data)) {
         let _kcOHLC = _Data as kcHistoryOHLCModel;
         mdRet = new kcHistoryOHLCModel({
            OpenPrice: _kcOHLC.OpenPrice,
            HighPrice: _kcOHLC.HighPrice,
            LowPrice: _kcOHLC.LowPrice,
            ClosePrice: _kcOHLC.ClosePrice,
            Vol: _kcOHLC.Vol,
         });
      } else {
         let _kcTick = _Data as kcTickModel;
         let dPrice = _kcTick.ClosePrice;
         mdRet = new kcHistoryOHLCModel({
            OpenPrice: dPrice,
            HighPrice: dPrice,
            LowPrice: dPrice,
            ClosePrice: dPrice,
            Vol: _kcTick.Vol,
         });
      }

      return mdRet;
   }
   private static UpdateOHLCEx(_OHLCEx: kcHistoryOHLCModel, _Data: kcHistoryOHLCModel | kcTickModel): void {
      // 不考慮Time
      if (kcHistoryOHLCModel.IsHistoryOHLCModel(_Data)) return this.UpdateOHLCEx_OHLC(_OHLCEx, _Data as kcHistoryOHLCModel);
      else return this.UpdateOHLCEx_Tick(_OHLCEx, _Data as kcTickModel);
   }
   private static UpdateOHLCEx_OHLC(_OHLCEx: kcHistoryOHLCModel, _OHLC: kcHistoryOHLCModel): void {
      // 不考慮Time
      if (this.IsEmptyData(_OHLC)) return;
      let dHighPrice = _OHLC.HighPrice;
      let dLowPrice = _OHLC.LowPrice;
      if (this.IsEmptyData(_OHLCEx)) {
         _OHLCEx.OpenPrice = _OHLC.OpenPrice;
         _OHLCEx.HighPrice = dHighPrice;
         _OHLCEx.LowPrice = dLowPrice;
      } else {
         if (_OHLCEx.HighPrice < dHighPrice) _OHLCEx.HighPrice = dHighPrice;
         if (_OHLCEx.LowPrice > dLowPrice) _OHLCEx.LowPrice = dLowPrice;
      }
      _OHLCEx.ClosePrice = _OHLC.ClosePrice;
      _OHLCEx.Vol += _OHLC.Vol;
   }
   private static UpdateOHLCEx_Tick(_OHLCEx: kcHistoryOHLCModel, _kcTick: kcTickModel): void {
      // 不考慮Time
      if (this.IsEmptyData(_kcTick)) return;
      let dPrice = _kcTick.ClosePrice;
      if (this.IsEmptyData(_OHLCEx)) {
         _OHLCEx.OpenPrice = dPrice;
         _OHLCEx.HighPrice = dPrice;
         _OHLCEx.LowPrice = dPrice;
      } else {
         if (_OHLCEx.HighPrice < dPrice) _OHLCEx.HighPrice = dPrice;
         if (_OHLCEx.LowPrice > dPrice) _OHLCEx.LowPrice = dPrice;
      }
      _OHLCEx.ClosePrice = dPrice;
      _OHLCEx.Vol += _kcTick.Vol;
   }
   private static IsEmptyData(_md: kcHistoryOHLCModel | kcTickModel): boolean {
      return _md.Vol == 0;
   }

   /* --------------------------------------------------------------- */
   // TradeTime 輔助
   private static IsInTradeTime(_DateTime: Moment, _tOpen: Moment, _tClose: Moment): boolean {
      if (_DateTime.isSameOrAfter(_tOpen) && _DateTime.isSameOrBefore(_tClose)) return true;
      else return false;
   }
   private static CheckCommodityTime(_mdCommodity: kcCommodityModel): boolean {
      // 檢查Commodity的TradeTime資料是否可用
      if (_mdCommodity == null) return false;
      if (_mdCommodity.TradeTime == null || _mdCommodity.TradeTime.length < 2) return false;

      let sOpenOff = 0,
         sCloseOff = 0;
      for (let mdTime of _mdCommodity.TradeTime) {
         if (mdTime.State == TradeTimeState.Open) sOpenOff = mdTime.MinuteOffset;
         else if (mdTime.State == TradeTimeState.Close) sCloseOff = mdTime.MinuteOffset;
      }
      if (sOpenOff == 0 && sCloseOff == 0) return false;
      return true;
   }
   private static GetTradeRules(_Commodity: kcCommodityModel): TradeTimeRuleModel[] {
      let sOpen: number[] = [];
      let sClose: number[] = [];
      for (let md of _Commodity.TradeTime) {
         if (md.State == TradeTimeState.Open) sOpen.push(md.MinuteOffset);
         else if (md.State == TradeTimeState.Close) sClose.push(md.MinuteOffset);
      }
      return TradeTimeRuleModel.CreateExpandRules(sOpen, sClose, 1440);
   }
   private static GetTradeTime(_Time: Moment, _mlExpandRule: TradeTimeRuleModel[], _bFindBef: boolean = false): TradeTimeResault {
      let Resault: TradeTimeResault = new TradeTimeResault();
      let DateBase: Moment = MomentExtensions.FloorToDay(_Time);
      let nRetIndex: number | undefined = undefined;
      let bInner = false;
      for (let i = 0; i < _mlExpandRule.length; i++) {
         let nCompare = _mlExpandRule[i].RangeCompare_Time(DateBase, _Time);
         if (nCompare == 0) {
            nRetIndex = i;
            bInner = true;
            break;
         } else if (nCompare < 0) {
            if (_bFindBef) {
               nRetIndex = i - 1;
               break;
            } else {
               nRetIndex = i;
               break;
            }
         }
      }
      if (nRetIndex !== undefined && nRetIndex >= 0 && nRetIndex < _mlExpandRule.length) {
         let mdRule = _mlExpandRule[nRetIndex];
         Resault.OpenTime = DateBase.clone().add(mdRule.OpenTimeOffset, "minutes");
         Resault.CloseTime = DateBase.clone().add(mdRule.CloseTimeOffset, "minutes");
         Resault.RuleIndex = nRetIndex;
      } else {
         Resault.OpenTime = moment(0);
         Resault.CloseTime = moment(0);
         Resault.RuleIndex = -1;
      }
      Resault.IsInTradeTime = bInner;
      return Resault;
   }
   private static GetDailyTradeTimeRule(_mlExpandRule: TradeTimeRuleModel[]): TradeTimeRuleModel {
      let bHasValue = false;
      let nOpen = 0,
         nClose = 0;
      for (let mdRule of _mlExpandRule) {
         if (mdRule.IsExpand) continue;
         if (!bHasValue) {
            bHasValue = true;
            nOpen = mdRule.OpenTimeOffset;
         }
         nClose = mdRule.CloseTimeOffset;
      }

      let mdRet = new TradeTimeRuleModel();
      mdRet.IsExpand = false;
      mdRet.OpenTimeOffset = nOpen;
      mdRet.CloseTimeOffset = nClose;
      return mdRet;
   }
}

export class OHLCExUpdateResault {
   constructor() {
      this.StartIdx = -1;
      this.EndIdx = -1;
   }
   public StartIdx: number;
   public EndIdx: number;
   public get HasUpdate(): boolean {
      return this.StartIdx > -1 && this.EndIdx > -1;
   }

   public SetUpdateIndex(_nUpdateStartIndex: number, _nUpdateEndIndex?: number): void {
      // 不做額外判斷
      if (this.StartIdx == -1 || this.StartIdx > _nUpdateStartIndex) this.StartIdx = _nUpdateStartIndex;

      let nUpdateEndIndex = _nUpdateEndIndex ?? _nUpdateStartIndex;
      if (this.EndIdx == -1 || this.EndIdx < nUpdateEndIndex) this.EndIdx = nUpdateEndIndex;
   }
}
