import * as React from "react";
import { StyleSheet, ScrollView, View, Dimensions, ScaledSize, Pressable, SafeAreaView, Animated } from "react-native";
import { Text } from "../components/Themed";
import { kcData } from "../kcData";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { Button, SearchBar } from "react-native-elements";
import { RouteProp } from "@react-navigation/native";
import { StackHeaderLeftButtonProps, StackNavigationProp } from "@react-navigation/stack";
import { TabTECPParamList } from "../types";
import RegListHelper, { RegListUpdateParam } from "../kcData/RegListHelper";
import { kcCommodityModel, kcModel_Enum } from "../kcModel";
import { kcExchangeCode } from "../kcModel/kcModel_Enum";
import { Ionicons, FontAwesome } from "@expo/vector-icons";
import { kcColor } from "../constants/Colors";
import { MaterialIcons } from "@expo/vector-icons";
import { GetText } from "../Locales";
import { KcExcList } from "./KcExcList";

type CommodityItem = { Registed: boolean; Commodity: kcCommodityModel };
type ExcListType = { Exchange: kcExchangeCode; Stocks: CommodityItem[] };

type IState = {
   Update: boolean; // 只是用來SetState讓下面重畫, 沒別的用
   SearchText: string;
   expanded: boolean;
   Favorite?: ExcListType;
   DisplayList: ExcListType[];
   DimensionsWindow: ScaledSize;
};

type IProp = {
   route: RouteProp<TabTECPParamList, "TabTECPScreen_Select">;
   navigation: StackNavigationProp<TabTECPParamList, "TabTECPScreen_Select">;
};

class TabQuoteScreen_SelectCommodity extends React.Component<IProp, IState> {
   state: IState = {
      Update: true,
      SearchText: "",
      expanded: false,
      Favorite: undefined,
      DisplayList: [],
      DimensionsWindow: Dimensions.get("window"),
   };

   /* ------------------------------------------------------------- */
   // Member
   reg: string = "";
   ExchangeList: ExcListType[] = [];
   Favorite: ExcListType = { Exchange: kcExchangeCode.UnDef, Stocks: [] };

   /* ------------------------------------------------------------- */
   // HookFunc
   componentDidMount() {
      this.props.navigation.addListener("focus", this.OnComponent_Focused);
      this.props.navigation.addListener("blur", this.OnComponent_blur);
      Dimensions.addEventListener("change", this.onDimensionsChange);
      RegListHelper.RegListUpdateEvent_Add(this.OnRegListChanged);

      this.props.navigation.setOptions({
         //headerTitle: this.Header.bind(this, ""),
         //headerTitle: this.Render_HeaderTitle2,
         headerTitle: this.Render_HeaderTitle.bind(this, ""),
         headerLeft: this.Render_HeaderLeft, // this.Render_HeaderLeft.bind(this),
         headerTitleContainerStyle: {
            marginRight: 5,
            flex: 1,
         },
         // headerTitleContainerStyle: {
         //   flexDirection: "row",
         //   alignItems: "center",
         //   justifyContent: "center",
         //   flex: 1,
         //   borderWidth: 1,
         //   borderColor: "green",
         //   // margin: 0,
         //   // marginLeft: 0,
         //   height: 60,
         // },
      });

      this.InitExchangeList();
   }

   componentWillUnmount() {
      this.props.navigation.removeListener("focus", this.OnComponent_Focused);
      this.props.navigation.removeListener("blur", this.OnComponent_blur);
      Dimensions.removeEventListener("change", this.onDimensionsChange);
      RegListHelper.RegListUpdateEvent_Remove(this.OnRegListChanged);
      kcSetUnmount(this, true);
   }

   shouldComponentUpdate(nextProps: IProp, nextState: IState) {
      return true;
   }

   /* ------------------------------------------------------------- */

   // 整理成By各個交易所的List
   private InitExchangeList = () => {
      let CommodityList = kcData.GetCommodityList();
      if (!CommodityList || CommodityList.length === 0) {
         setTimeout(this.InitExchangeList, 500);
         return;
      }
      let ExcList: ExcListType[] = [];
      for (let i = 0; i < CommodityList.length; i++) {
         let mdCommodity = CommodityList[i];
         let ExcItem = ExcList.find((q) => q.Exchange === mdCommodity.ExchangeCode);
         if (!ExcItem) {
            ExcItem = { Exchange: mdCommodity.ExchangeCode, Stocks: [] };
            ExcList.push(ExcItem);
         }
         ExcItem.Stocks.push({ Commodity: mdCommodity, Registed: false });
      }
      ExcList.sort((a, b) => a.Exchange - b.Exchange);

      this.ExchangeList = ExcList;

      this.UpdateRegistedState(RegListHelper.GetRegList());

      kcSetState(this, {
         DisplayList: this.ExchangeList,
         Favorite: this.Favorite,
      });
   };

   private UpdateRegistedState = (_RegList: string[]) => {
      let RegSet = new Set(_RegList);
      let FavoriteItems: ExcListType["Stocks"] = [];
      for (let mdExc of this.ExchangeList) {
         for (let mdCommodity of mdExc.Stocks) {
            mdCommodity.Registed = RegSet.has(mdCommodity.Commodity.StockCode);
            if (mdCommodity.Registed) FavoriteItems.push(mdCommodity);
         }
      }

      // 更新自選
      this.Favorite.Stocks.length = 0;
      this.Favorite.Stocks.push(...FavoriteItems);
   };

   private updateSearch = (_Text: string) => {
      this.props.navigation.setOptions({
         headerTitle: this.Render_HeaderTitle.bind(this, _Text),
      });
      kcSetState(this, { SearchText: _Text });
   };

   /* ------------------------------------------------------------- */
   // On
   private OnComponent_Focused = () => {};

   private OnComponent_blur = () => {};

   private onDimensionsChange = ({ window, screen }: { window: ScaledSize; screen: ScaledSize }) => {
      this.setState({ DimensionsWindow: window });
   };

   private OnRegListChanged = (_Param: RegListUpdateParam) => {
      this.UpdateRegistedState(_Param.RegList);
      kcSetState(this, { Update: this.state.Update });
   };

   /* ------------------------------------------------------------- */
   //Render
   private Render_HeaderLeft: (props: StackHeaderLeftButtonProps) => React.ReactNode = (props) => {
      return (
         <Button
            // style={{ width: 48, height: 48 }}
            icon={<Ionicons name="ios-chevron-back" size={24} color={kcColor("Icon")} />}
            iconContainerStyle={{ alignSelf: "center" }}
            buttonStyle={styles.button}
            onPress={() => {
               // if (this.props.navigation.canGoBack()){ this.props.navigation.goBack();}
               // else{
               this.props.navigation.pop();
               //}
            }}
         />
      );
   };
   private Render_HeaderTitle = (_SearchText: string) => {
      return (
         <View
            style={{
               justifyContent: "center",
               flex: 1,
               backgroundColor: "#00000000",
            }}
         >
            <SearchBar
               key="StockSearchBar"
               platform="default"
               placeholder={GetText("Search")}
               onChangeText={this.updateSearch.bind(this)}
               value={_SearchText}
               textBreakStrategy="highQuality"
               showSoftInputOnFocus={true}
               //showLoading={true}
               containerStyle={[
                  styles.BackGroundColor,
                  {
                     borderTopWidth: 0,
                     borderBottomWidth: 0,
                     //backgroundColor: "green",
                  },
               ]}
               // rightIconContainerStyle={{
               //   width: 30,
               //   height: 30,
               //   backgroundColor: "green",
               // }}
               // inputStyle={{
               //   height: 30,
               //   //backgroundColor: "#00FFFF"
               //   borderTopWidth: 1,
               //   borderTopColor: "green",
               // }}
               inputContainerStyle={{
                  // height: 30,
                  borderRadius: 13,
                  backgroundColor: kcColor("ListTitleBackground"),
               }}
               // style={{
               //   height: 30,
               //   backgroundColor: "#ffff00",
               // }}
               // searchIcon={<></>}
            ></SearchBar>
         </View>
      );
   };

   render() {
      let { Favorite, DisplayList, SearchText } = this.state;
      const BottomViewHeight = 40;
      const EffectiveHeigh = this.state.DimensionsWindow.height - 60;

      return (
         <SafeAreaView
            style={{
               backgroundColor: kcColor("Background"),
               flexDirection: "column",
               height: EffectiveHeigh,
               paddingTop: 0,
               paddingBottom: 0,
            }}
         >
            <ScrollView
               style={{ backgroundColor: "#00000000", flex: 1 }}
               showsVerticalScrollIndicator={false} // 關閉垂直滾動條
            >
               {Favorite && <KcExcList key={`${kcExchangeCode[Favorite.Exchange]}`} DataItem={Favorite} SearchText={SearchText} ParentProp={this.props} IsSelect IsFavorite Expanded />}
               {DisplayList.map((_Item, _idx) => (
                  <KcExcList key={`${_idx}_${kcExchangeCode[_Item.Exchange]}`} DataItem={_Item} SearchText={SearchText} ParentProp={this.props} IsSelect />
               ))}

               <View style={{ backgroundColor: "#00000000", height: 60 }} />
            </ScrollView>
            <View style={{ backgroundColor: "#00000000", height: BottomViewHeight }} />
         </SafeAreaView>
      );
   }
}

const styles = StyleSheet.create({
   BackGroundColor: {
      backgroundColor: "#00000000",
   },

   button: {
      backgroundColor: "#00000000",
      borderColor: "red",
      borderWidth: 0,
      borderRadius: 15,
   },
});

export default TabQuoteScreen_SelectCommodity;
