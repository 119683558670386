export default class cTECPDef {
  //INT
  static AP_TOP_HEIGHT = 0; //最上面顯示今日股票資訊的空白
  //internal const int GRAY_LINE_HEIGH = 3;               //灰線高度
  static GRAY_LINE_HEIGH = 1; //灰線高度
  static KLINE_BUFF_AREA = 25; //KLINE上下留空白 要畫最高最低價需要較大
  static LEFT_FONT_AREA = 17; //LeftInfo寫成一行的上面留白高度
  static ELSE_TECP_BUFF_AREA = 3; //除了Kline上下留空白
  //static DATE_AREA_HEIGHT = 20; //中間顯示今日日期區間
  static FIXED_TOP_SPACE = 2; //上面留的固定空白 其中有三個是用來畫灰線
  static FIXED_LEFT_SPACE = 5; //暫時沒用
  static FIXED_RIGHT_SPACE = 82; //右邊固定留白區域 用來畫價錢格子資訊
  //static FIXED_RIGHT_SPACE = 72;          //右邊固定留白區域 用來畫價錢格子資訊
  static FIXED_FIRST_KLINE_SPACE = 0; //第一根K棒與右邊線留白區
  static DATA_NUM_DEFAULT = 80; //程式開啟時 DATA_NUM的設定 // 380
  static DATA_NUM_MIN = 2; //DataNum最少
  static DATA_NUM_MAX = 9999; //DataNum最多
}
