import SocketBase from "./SocketBase";
import Endpoint, { State } from "./Endpoint";
import Message from "./Message";
import StringASCIIHelper from "./StringASCIIHelper";

type delOnSubscriberMessage = (Topic: string, Messages: Uint8Array[]) => void;
export default class Subscriber extends SocketBase {
  constructor(_fonMessage?: delOnSubscriberMessage) {
    super();

    this.onMessage = _fonMessage;
  }
  /* ------------------------------------------- */
  // Member
  private subscriptions: Uint8Array[] = [];
  private isActive: boolean = false;
  private onMessage?: delOnSubscriberMessage;

  /* ------------------------------------------- */
  // Override function
  protected xattachEndpoint(_Endpoint: Endpoint) {
    this.endpoints.push(_Endpoint);

    for (var i = 0; i < this.subscriptions.length; i++) {
      var message = this.createSubscriptionMessage(this.subscriptions[i], true);

      _Endpoint.write(message);
    }

    if (!this.isActive) {
      this.isActive = true;

      if (this.sendReady != null) {
        this.sendReady(this);
      }
    }
  }

  protected xendpointTerminated(
    _Endpoint: Endpoint,
    _bFirstDeActivated: boolean
  ) {
    var index = this.endpoints.indexOf(_Endpoint);
    this.endpoints.splice(index, 1);
  }

  protected xhasOut() {
    return false;
  }

  protected xonMessage = (_Endpoint: Endpoint, _Message: Message): void => {
    if (_Message.getSize() === 0) return;
    if (this.onMessage) {
      let szTopic = _Message.popString();
      let Msgs: Uint8Array[] = [];
      while (_Message.getSize() > 0)
        Msgs.push(new Uint8Array(_Message.popBuffer()));
      this.onMessage(szTopic, Msgs);
    }
  };

  /* ------------------------------------------- */
  // 公開 function
  public subscribeAll = () => {
    this.subscribe("");
  };
  public subscribe = (subscription: Uint8Array | ArrayBuffer | string) => {
    let subItem: Uint8Array;
    if (subscription instanceof Uint8Array) {
      subItem = subscription;
    } else if (subscription instanceof ArrayBuffer) {
      subItem = new Uint8Array(subscription);
    } else {
      subItem = StringASCIIHelper.GetBytes(String(subscription));
    }
    // TODO: check if the subscription already exist
    this.subscriptions.push(subItem);

    var message = this.createSubscriptionMessage(subItem, true);

    for (var i = 0; i < this.endpoints.length; i++) {
      if (this.endpoints[i].state === State.Active)
        this.endpoints[i].write(message);
    }
  };

  public unsubscribe = (subscription: Uint8Array | ArrayBuffer | string) => {
    let subItem: Uint8Array;
    if (subscription instanceof Uint8Array) {
      // continue
      subItem = subscription;
    } else if (subscription instanceof ArrayBuffer) {
      subItem = new Uint8Array(subscription);
    } else {
      subItem = StringASCIIHelper.GetBytes(String(subscription));
    }

    for (var j = 0; j < this.subscriptions.length; j++) {
      if (this.subscriptions[j].length == subItem.length) {
        var equal = true;

        for (var k = 0; k < this.subscriptions[j].length; k++) {
          if (this.subscriptions[j][k] != subItem[k]) {
            equal = false;
            break;
          }
        }

        if (equal) {
          this.subscriptions.splice(j, 1);
          break;
        }
      }
    }

    var message = this.createSubscriptionMessage(subItem, false);

    for (var i = 0; i < this.endpoints.length; i++) {
      this.endpoints[i].write(message);
    }
  };

  /* ------------------------------------------- */
  // private function
  private createSubscriptionMessage = (
    subscription: Uint8Array,
    subscribe: boolean
  ) => {
    var frame = new Uint8Array(subscription.length + 1);
    frame[0] = subscribe ? 1 : 0;
    frame.set(subscription, 1);

    var message = new Message();
    message.addBuffer(frame);

    return message;
  };
}
