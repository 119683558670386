import { kcTECPCollection } from "..";
import { MathEx } from "../../kcExternal";
import { Point } from "../kcDrawHelper";
import { LinePath, PathPoint, SvgLinePath, TopInfo } from "../kcTECPCollection";
import { MaSetting } from "../TECPSetting";
import { XUnit } from "../XInfo";
import { YUnit } from "../YInfo";

export type MaValues = {
  XUnitValues: { Value: number; XUnit: XUnit }[];
  Max?: number;
  Min?: number;
};

export default class kcSmaData<T> {
  constructor(
    _Owner: kcTECPCollection,
    _OHLC: T[],
    _MaSetting: MaSetting,
    _fValue: (_md: T) => number
  ) {
    this.m_Owner = _Owner;
    this.m_DataSrc = _OHLC;
    this.m_Setting = _MaSetting;
    this.m_fGetValue = _fValue;
    this.m_Mas.length = _OHLC.length;
  }

  // Data
  private m_Owner: kcTECPCollection;
  private m_DataSrc: T[];
  private m_fGetValue: (_md: T) => number;
  private m_Mas: kcSmaDataItem[] = [];
  private m_Setting: MaSetting;

  public get Setting() {
    return this.m_Setting;
  }
  public get MaDayNumber() {
    return this.m_Setting.MaDayBase;
  }
  public get MaLineColor() {
    return this.m_Setting.LineColor;
  }
  public get MaLineWidth() {
    return this.m_Setting.LineWidth;
  }
  public get Ma() {
    return this.m_Mas;
  }

  // Core Function
  public Value(Index: number) {
    if (Index < 0 || Index >= this.m_Mas.length) return undefined;

    if (!this.m_Mas[Index]) this.m_Mas[Index] = new kcSmaDataItem();

    if (!this.m_Mas[Index].HasValue) {
      // setTimeout(() => {
      let dValue = this.CountValueCore(Index);
      if (dValue != undefined) this.m_Mas[Index].Value = dValue;
      // }, 500);
    }
    return this.m_Mas[Index].HasValue ? this.m_Mas[Index].Value : undefined;
  }
  public Init() {
    this.m_Mas.length = 0;
    this.m_Mas.length = this.m_DataSrc.length;
  }
  public Update(_StartIdx: number, _EndIdx: number) {
    if (this.m_Mas.length != this.m_DataSrc.length)
      this.m_Mas.length = this.m_DataSrc.length;

    for (let i = _StartIdx; i <= _EndIdx; i++) {
      if (this.m_Mas[i]) this.m_Mas[i].SetUpdate();
    }
  }
  private CountValueCore(_Index: number): number | undefined {
    let Total: number = 0;
    let DataNumber: number = 0;

    for (let i = 0; i < this.MaDayNumber; i++) {
      let DataIdx = _Index - i;
      if (DataIdx < 0 || DataIdx >= this.m_DataSrc.length) continue;

      Total += this.m_fGetValue(this.m_DataSrc[DataIdx]);
      DataNumber++;
    }

    if (DataNumber == 0) return undefined;
    return Total / DataNumber;
  }

  // Update Setting Function

  public UpdateSetting(_MaSetting: MaSetting) {
    let UpdateResault = this.m_Setting.SetSetting(_MaSetting);

    if (UpdateResault.DataChanged) {
      this.Init();
    }
  }

  // 輔助 Function
  public map<U>(
    callbackfn: (
      value: kcSmaDataItem,
      index: number,
      array: kcSmaDataItem[]
    ) => U
  ): U[] {
    let aRet: U[] = [];
    for (let i = 0; i < this.m_Mas.length; i++) {
      let Item = this.Value(i);
      aRet.push(callbackfn(this.m_Mas[i], i, this.m_Mas));
    }
    return aRet;
  }

  // Draw Function
  public GetValues(_XUnits: XUnit[]): MaValues | undefined {
    if (!this.m_Setting.Enable) return undefined;

    let aValues: MaValues["XUnitValues"] = [];
    let dMax: number | undefined = undefined;
    let dMin: number | undefined = undefined;

    for (let i = 0; i < _XUnits.length; i++) {
      let dValue = this.Value(_XUnits[i].nDataIndex);
      if (dValue) {
        if (dMax === undefined || dMax < dValue) dMax = dValue;
        if (dMin === undefined || dMin > dValue) dMin = dValue;
        aValues.push({ Value: dValue, XUnit: _XUnits[i] });
      }
    }

    if (aValues.length == 0) return undefined;
    return {
      XUnitValues: aValues,
      Max: dMax,
      Min: dMin,
    };
  }
  public GetLinePoints(
    _MaValues: MaValues | undefined,
    _YUnit: YUnit
  ): LinePath | undefined {
    if (
      !_MaValues ||
      !_MaValues.XUnitValues ||
      _MaValues.XUnitValues.length === 0
    )
      return undefined;

    if (!this.m_Setting.Enable) return undefined;

    let points: PathPoint[] = [];
    for (let i = 0; i < _MaValues.XUnitValues.length; i++) {
      let dValue = _MaValues.XUnitValues[i].Value;
      let XCoord = _MaValues.XUnitValues[i].XUnit.fXMidPos;
      let YCoord = _YUnit.ValueToCoord(dValue);

      points.push({ X: XCoord, Y: YCoord, StartPoint: i == 0 });
    }

    let Path: LinePath = {
      PathColor: this.MaLineColor,
      PathWidth: this.MaLineWidth,
      Points: points,
    };

    return Path;
  }

  public GetSVGPath(
    _MaValues: MaValues | undefined,
    _YUnit: YUnit
  ): SvgLinePath | undefined {
    if (
      !_MaValues ||
      !_MaValues.XUnitValues ||
      _MaValues.XUnitValues.length === 0
    )
      return undefined;

    if (!this.m_Setting.Enable) return undefined;

    let szPath = "";

    // for (let i = 0; i < _MaValues.XUnitValues.length; i++) {
    //   let dValue = _MaValues.XUnitValues[i].Value;
    //   let XCoord = _MaValues.XUnitValues[i].XUnit.fXMidPos;
    //   let YCoord = _YUnit.ValueToCoord(dValue);

    //   szPath += `${i == 0 ? "M" : "L"} ${XCoord} ${YCoord}`;
    // }
    let PreXCoord = 0;
    let PreYCoord = 0;
    for (let i = 0; i < _MaValues.XUnitValues.length; i++) {
      let nIdx = _MaValues.XUnitValues.length - 1 - i;
      let dValue = _MaValues.XUnitValues[nIdx].Value;
      let XCoord = _MaValues.XUnitValues[nIdx].XUnit.fXMidPos;
      let YCoord = _YUnit.ValueToCoord(dValue);

      if (i == 0) szPath += `M ${XCoord} ${YCoord}`;
      else {
        let XDiff = MathEx.kcRound(XCoord - PreXCoord, 2);
        let YDiff = MathEx.kcRound(YCoord - PreYCoord, 2);
        szPath += `l ${XDiff} ${YDiff}`;
      }

      PreXCoord = XCoord;
      PreYCoord = YCoord;
    }

    let Path: SvgLinePath = {
      PathColor: this.MaLineColor,
      PathWidth: this.MaLineWidth,
      SvgPath: szPath,
    };

    return Path;
  }
  public GetTopInfo() {
    if (!this.m_Setting.Enable) return undefined;
    let FloatNum = this.m_Owner.kcUnit?.Commodity?.FloatNum ?? 5;
    let dValue = this.Value(this.m_Mas.length - 1);
    let szValue = dValue ? MathEx.kcRound(dValue, FloatNum) : " ";
    let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤色 用KLine的平盤色

    // PreValue
    let dPreValue = this.Value(this.m_Mas.length - 2);
    if (dPreValue != undefined) {
      if (szValue < dPreValue)
        szValueColor = this.m_Owner.Setting.KLine.DownKColor;
      // 下跌 用KLine的下跌色
      else if (szValue > dPreValue)
        szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 用KLine的上漲色
    }

    let mdTopInfo: TopInfo = {
      Text: `MA${this.MaDayNumber}`,
      TextColor: this.MaLineColor,
      Value: `${szValue}`,
    };
    return mdTopInfo;
  }
  public AddTopInfo(_Collection: TopInfo[]) {
    let mdTop = this.GetTopInfo();
    if (mdTop && _Collection) _Collection.push(mdTop);
    return mdTop;
  }
  public GetLeftInfo(_nDataIndex: number) {
    if (_nDataIndex < 0 || _nDataIndex >= this.m_Mas.length) return undefined;
    if (!this.m_Setting.Enable) return undefined;

    let FloatNum = this.m_Owner.kcUnit?.Commodity?.FloatNum ?? 5;
    let dValue = this.Value(_nDataIndex);
    let szValue = dValue ? MathEx.kcRound(dValue, FloatNum) : " ";
    let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤色 用KLine的平盤色

    // PreValue
    let dPreValue = this.Value(_nDataIndex - 1);
    if (dPreValue != undefined) {
      if (szValue < dPreValue)
        szValueColor = this.m_Owner.Setting.KLine.DownKColor;
      // 下跌 用KLine的下跌色
      else if (szValue > dPreValue)
        szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 用KLine的上漲色
    }

    let mdTopInfo: TopInfo = {
      Text: `MA${this.MaDayNumber}`,
      TextColor: this.MaLineColor,
      Value: `${szValue}`,
      Color: szValueColor,
    };
    return mdTopInfo;
  }
  public AddLeftInfo(_nDataIndex: number, _Collection: TopInfo[]) {
    let mdLeft = this.GetLeftInfo(_nDataIndex);
    if (mdLeft && _Collection) _Collection.push(mdLeft);
    return mdLeft;
  }
}

class kcSmaDataItem {
  constructor() {}

  private m_HasValue: boolean = false;
  private m_Value: number = -1;

  public get HasValue() {
    return this.m_HasValue;
  }
  public get Value() {
    return this.m_Value;
  }
  public set Value(_Value: number) {
    this.m_Value = _Value;
    this.m_HasValue = true;
  }

  public SetUpdate() {
    this.m_HasValue = false;
  }
}
