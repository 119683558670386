import { kcTECPCollection } from "..";
import { MathEx } from "../../kcExternal";
import { kcHistoryOHLCModel } from "../../kcModel";
import { SvgLinePath, TopInfo } from "../kcTECPCollection";
import MACDSetting from "../TECPSetting/MACDSetting";
import { XUnit } from "../XInfo";
import { YUnit } from "../YInfo";

type MACDValues = {
  XUnitValues: { Value: kcMACDModel; XUnit: XUnit }[];
  Max?: number;
  Min?: number;
};

export default class kcMACDData {
  constructor(_Owner: kcTECPCollection, _Setting: MACDSetting) {
    this.m_Owner = _Owner;
    this.m_DataSrc = _Owner.mlOHLC;
    this.m_Setting = _Setting;
    this.m_Datas.length = this.m_DataSrc.length;
  }

  // Data
  private m_Owner: kcTECPCollection;
  private m_DataSrc: kcHistoryOHLCModel[];
  private m_Datas: kcMACDModel[] = [];
  private m_Setting: MACDSetting;

  public get Setting() {
    return this.m_Setting;
  }
  public get Datas() {
    return this.m_Datas;
  }

  // Core Function
  public Value(Index: number) {
    if (Index < 0 || Index >= this.m_Datas.length) return undefined;

    if (!this.m_Datas[Index]) this.m_Datas[Index] = new kcMACDModel();

    if (!this.m_Datas[Index].HasValue) {
      // setTimeout(() => {
      /* --------------------------------------- */
      // 因為是延續的資料, 向前檢查計算
      let PreIdx: number | undefined = undefined;
      for (let i = Index - 1; i >= 0; i--) {
        if (!this.m_Datas[Index] || !this.m_Datas[Index].HasValue) PreIdx = i;
        else break;
      }
      if (PreIdx != undefined) {
        for (let i = PreIdx; i < Index; i++) {
          if (!this.m_Datas[i]) this.m_Datas[i] = new kcMACDModel();
          let mdPreMACD = this.CountValueCore(i);
          if (mdPreMACD != undefined) this.m_Datas[i].SetValue(mdPreMACD);
        }
      }
      /* --------------------------------------- */

      let mdMACD = this.CountValueCore(Index);
      if (mdMACD != undefined) this.m_Datas[Index].SetValue(mdMACD);
      // }, 500);
    }
    return this.m_Datas[Index].HasValue ? this.m_Datas[Index] : undefined;
  }
  public Init() {
    this.m_Datas.length = 0;
    this.m_Datas.length = this.m_DataSrc.length;
  }
  public Update(_StartIdx: number, _EndIdx: number) {
    if (this.m_Datas.length != this.m_DataSrc.length)
      this.m_Datas.length = this.m_DataSrc.length;

    for (let i = _StartIdx; i <= this.m_DataSrc.length; i++) {
      if (this.m_Datas[i]) this.m_Datas[i].SetUpdate();
    }
  }
  private CountValueCore(_nIndex: number): kcMACDModel | undefined {
    let mdMACD = new kcMACDModel();
    let MACD_Base = this.m_Setting.MACD_Base;
    let EMA1_Base = this.m_Setting.EMA1_Base;
    let EMA2_Base = this.m_Setting.EMA2_Base;

    let Pre_MACD = 0;
    let Pre_EMA1 = 0;
    let Pre_EMA2 = 0;

    let mdPreMACD = this.Value(_nIndex - 1);
    if (mdPreMACD && mdPreMACD.MACD && mdPreMACD.EMA1 && mdPreMACD.EMA2) {
      Pre_MACD = mdPreMACD.MACD;
      Pre_EMA1 = mdPreMACD.EMA1;
      Pre_EMA2 = mdPreMACD.EMA2;
    }

    let dDI = 0;
    let mdNowOHLC = this.m_Owner.GetOHLCEx(_nIndex);
    if (mdNowOHLC)
      dDI =
        (mdNowOHLC.HighPrice + mdNowOHLC.LowPrice + 2 * mdNowOHLC.ClosePrice) /
        4;

    let Now_EMA1 = (Pre_EMA1 * (EMA1_Base - 1) + dDI * 2) / (EMA1_Base + 1);
    let Now_EMA2 = (Pre_EMA2 * (EMA2_Base - 1) + dDI * 2) / (EMA2_Base + 1);
    let DIF = Now_EMA1 - Now_EMA2;
    let Now_MACD = (Pre_MACD * (MACD_Base - 1) + DIF * 2) / (MACD_Base + 1);

    mdMACD.SetValue({
      MACD: Now_MACD,
      EMA1: Now_EMA1,
      EMA2: Now_EMA2,
      DIF: DIF,
      DIF_MACD: DIF - Now_MACD,
    });
    return mdMACD;
  }

  // Update Setting Function

  public UpdateSetting(_Setting: MACDSetting) {
    let UpdateResault = this.m_Setting.SetSetting(_Setting);

    if (UpdateResault.DataChanged) {
      this.Init();
    }
  }

  // 輔助 Function
  public map<U>(
    callbackfn: (value: kcMACDModel, index: number, array: kcMACDModel[]) => U
  ): U[] {
    let aRet: U[] = [];
    for (let i = 0; i < this.m_Datas.length; i++) {
      let Item = this.Value(i);
      aRet.push(callbackfn(this.m_Datas[i], i, this.m_Datas));
    }
    return aRet;
  }

  // Draw Function
  public GetValues(_XUnits: XUnit[]): MACDValues | undefined {
    if (!this.m_Setting.EnableAny) return undefined;

    let aValues: MACDValues["XUnitValues"] = [];
    let dMax: number = 0;

    for (let i = 0; i < _XUnits.length; i++) {
      let dValue = this.Value(_XUnits[i].nDataIndex);
      if (dValue && dValue.HasValue) {
        if (dValue.DIF_MACD) dMax = Math.max(dMax, Math.abs(dValue.DIF_MACD));

        if (this.m_Setting.Dif_Enable && dValue.DIF) {
          dMax = Math.max(dMax, Math.abs(dValue.DIF));
        }
        if (this.m_Setting.Dif_Enable && dValue.MACD) {
          dMax = Math.max(dMax, Math.abs(dValue.MACD));
        }
        aValues.push({ Value: dValue, XUnit: _XUnits[i] });
      }
    }

    if (aValues.length == 0) return undefined;

    return {
      XUnitValues: aValues,
      Max: dMax,
      Min: -dMax,
    };
  }
  public GetSVGPath(
    _Values: MACDValues | undefined,
    _YUnit: YUnit
  ): SvgLinePath[] | undefined {
    if (!_Values || !_Values.XUnitValues || _Values.XUnitValues.length === 0)
      return undefined;

    if (!this.m_Setting.EnableAny) return undefined;

    let szPath_DIF = "";
    let szPath_MACD = "";

    for (let i = 0; i < _Values.XUnitValues.length; i++) {
      let mdMACD = _Values.XUnitValues[i].Value;
      if (!mdMACD) continue;

      let XCoord = _Values.XUnitValues[i].XUnit.fXMidPos;

      if (this.m_Setting.Dif_Enable && mdMACD.DIF) {
        let YCoord = _YUnit.ValueToCoord(mdMACD.DIF);
        szPath_DIF += `${i == 0 ? "M" : "L"} ${XCoord} ${YCoord}`;
      }
      if (this.m_Setting.MACD_Enable && mdMACD.MACD) {
        let YCoord = _YUnit.ValueToCoord(mdMACD.MACD);
        szPath_MACD += `${i == 0 ? "M" : "L"} ${XCoord} ${YCoord}`;
      }
    }

    let aPaths: SvgLinePath[] = [];
    if (this.m_Setting.Dif_Enable) {
      aPaths.push({
        PathColor: this.m_Setting.Dif_LineColor,
        PathWidth: this.m_Setting.Dif_LineWidth,
        SvgPath: szPath_DIF,
      });
    }
    if (this.m_Setting.MACD_Enable) {
      aPaths.push({
        PathColor: this.m_Setting.MACD_LineColor,
        PathWidth: this.m_Setting.MACD_LineWidth,
        SvgPath: szPath_MACD,
      });
    }

    return aPaths;
  }
  public GetTopInfo() {
    if (!this.m_Setting.EnableAny) return undefined;

    let mdMACD = this.Value(this.m_Datas.length - 1);
    let mdPreMACD = this.Value(this.m_Datas.length - 2);
    if (!mdMACD) return undefined;

    let lTopInfos: TopInfo[] = [];
    let FloatNum = this.m_Owner.kcUnit?.Commodity?.FloatNum ?? 5;

    // DIF_MACD
    if (true) {
      let szDIF_MACD = mdMACD.DIF_MACD
        ? MathEx.kcRound(mdMACD.DIF_MACD, FloatNum)
        : " ";
      // let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      // if (mdMACD.DIF_MACD && mdPreMACD && mdPreMACD.DIF_MACD) {
      //   if (mdMACD.DIF_MACD < mdPreMACD.DIF_MACD)
      //     szValueColor = this.m_Owner.Setting.KLine.DownKColor;
      //   // 下跌 KLine下跌色
      //   else if (mdMACD.DIF_MACD > mdPreMACD.DIF_MACD)
      //     szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      // }
      let szValueColor = this.m_Setting.Dif_MACD_UpColor; // 預設 平盤 KLine平盤色
      if (mdMACD.DIF_MACD && mdMACD.DIF_MACD < 0)
        szValueColor = this.m_Setting.Dif_MACD_DownColor;

      lTopInfos.push({
        Text: `ODC`,
        Value: `${szDIF_MACD}`,
        Color: szValueColor,
      });
    }
    // MACD
    if (this.m_Setting.MACD_Enable) {
      let szMACD = mdMACD.MACD ? MathEx.kcRound(mdMACD.MACD, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdMACD.MACD && mdPreMACD && mdPreMACD.MACD) {
        if (mdMACD.MACD < mdPreMACD.MACD)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdMACD.MACD > mdPreMACD.MACD)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `MACD${this.m_Setting.MACD_Base}`,
        TextColor: this.m_Setting.MACD_LineColor,
        Value: `${szMACD}`,
        // Color: szValueColor,
      });
    }
    // DIF
    if (this.m_Setting.Dif_Enable) {
      let szDIF = mdMACD.DIF ? MathEx.kcRound(mdMACD.DIF, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdMACD.DIF && mdPreMACD && mdPreMACD.DIF) {
        if (mdMACD.DIF < mdPreMACD.DIF)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdMACD.DIF > mdPreMACD.DIF)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `DIF${this.m_Setting.EMA1_Base}-${this.m_Setting.EMA2_Base}`,
        TextColor: this.m_Setting.Dif_LineColor,
        Value: `${szDIF}`,
        // Color: szValueColor,
      });
    }

    return lTopInfos;
  }
  public AddTopInfo(_Collection: TopInfo[]) {
    let mdTop = this.GetTopInfo();
    if (mdTop && _Collection) _Collection.push(...mdTop);
    return mdTop;
  }
  public GetLeftInfo(_nDataIndex: number) {
    if (_nDataIndex < 0 || _nDataIndex >= this.m_Datas.length) return undefined;
    if (!this.m_Setting.EnableAny) return undefined;

    let mdMACD = this.Value(_nDataIndex);
    let mdPreMACD = this.Value(_nDataIndex - 1);
    if (!mdMACD) return undefined;

    let lTopInfos: TopInfo[] = [];
    // let FloatNum = this.m_Owner.kcUnit?.Commodity?.FloatNum ?? 5;
    let FloatNum = 4;

    // DIF_MACD
    if (true) {
      let szDIF_MACD = mdMACD.DIF_MACD
        ? MathEx.kcRound(mdMACD.DIF_MACD, FloatNum)
        : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdMACD.DIF_MACD && mdPreMACD && mdPreMACD.DIF_MACD) {
        if (mdMACD.DIF_MACD < mdPreMACD.DIF_MACD)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdMACD.DIF_MACD > mdPreMACD.DIF_MACD)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `ODC`,
        Value: `${szDIF_MACD}`,
        Color: szValueColor,
      });
    }
    // MACD
    if (this.m_Setting.MACD_Enable) {
      let szMACD = mdMACD.MACD ? MathEx.kcRound(mdMACD.MACD, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdMACD.MACD && mdPreMACD && mdPreMACD.MACD) {
        if (mdMACD.MACD < mdPreMACD.MACD)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdMACD.MACD > mdPreMACD.MACD)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `MACD${this.m_Setting.MACD_Base}`,
        TextColor: this.m_Setting.MACD_LineColor,
        Value: `${szMACD}`,
        Color: szValueColor,
      });
    }
    // DIF
    if (this.m_Setting.Dif_Enable) {
      let szDIF = mdMACD.DIF ? MathEx.kcRound(mdMACD.DIF, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdMACD.DIF && mdPreMACD && mdPreMACD.DIF) {
        if (mdMACD.DIF < mdPreMACD.DIF)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdMACD.DIF > mdPreMACD.DIF)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `DIF${this.m_Setting.EMA1_Base}-${this.m_Setting.EMA2_Base}`,
        TextColor: this.m_Setting.Dif_LineColor,
        Value: `${szDIF}`,
        Color: szValueColor,
      });
    }
    return lTopInfos;
  }
  public AddLeftInfo(_nDataIndex: number, _Collection: TopInfo[]) {
    let mdLeft = this.GetLeftInfo(_nDataIndex);
    if (mdLeft && _Collection) _Collection.push(...mdLeft);
    return mdLeft;
  }
}

class kcMACDModel {
  constructor() {}

  private m_HasValue: boolean = false;

  private m_MACD?: number;
  private m_EMA1?: number;
  private m_EMA2?: number;
  private m_DIF?: number;
  private m_DIF_MACD?: number;

  public get HasValue() {
    return this.m_HasValue;
  }
  public get MACD() {
    return this.m_MACD;
  }
  public get EMA1() {
    return this.m_EMA1;
  }
  public get EMA2() {
    return this.m_EMA2;
  }
  public get DIF() {
    return this.m_DIF;
  }
  public get DIF_MACD() {
    return this.m_DIF_MACD;
  }

  public SetValue(
    _mdValue:
      | kcMACDModel
      | {
          MACD?: number;
          EMA1?: number;
          EMA2?: number;
          DIF?: number;
          DIF_MACD?: number;
        }
  ) {
    if (_mdValue) {
      this.m_MACD = _mdValue.MACD;
      this.m_EMA1 = _mdValue.EMA1;
      this.m_EMA2 = _mdValue.EMA2;
      this.m_DIF = _mdValue.DIF;
      this.m_DIF_MACD = _mdValue.DIF_MACD;

      this.m_HasValue = true;
    }
  }

  public SetUpdate() {
    this.m_HasValue = false;
  }
}
