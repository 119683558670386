import { CommandType } from "./InternalDefine";
import { Message } from "../TSMQ";
import { StorageHelper, CompressionExtensions } from "../kcExternal";
import * as kcTransModel from "./kcTransModel";
import jwt_decode from "jwt-decode";
import JWTPayloadModel, { JWTLoginState } from "./JWTPayloadModel";
import { kcAccountModel } from "../kcModel";
import { GoLoginScreen } from "../navigation/RootNavigation";
import {
  Quote_SendRequest,
  SetAccountCallback,
  SetQuoteReadyCallback,
} from "./kcQuote";
import { delOnConnection, ConnectionState } from "../TSMQ/Dealer";
import { GetText } from "../Locales";

const Key_SessionToken = "SessionToken";

var SessionToken: string | undefined = undefined;
var Account: string = "";
var State: JWTLoginState = "None";

export enum LoginStatus {
  LoginSucceed,
  LoginFail,
  TraderReady,
}
export type AccountCallback = (
  AccountInfo: kcAccountModel,
  LoginState: JWTLoginState
) => void;

export type LoginStateCallback = (_state: LoginStatus, _szMsg: string) => void;

var OnAccountCallback: AccountCallback | undefined;
var OnLoginStateCallback: LoginStateCallback | undefined;

//   "-----------------------------kcTrade In-----------------------------"

export var InitializeCallback = (_OnAccountCallback?: AccountCallback) => {
  OnAccountCallback = _OnAccountCallback;
};
export var SetLoginStateCallback = (
  _OnLoginStateCallback?: LoginStateCallback
) => {
  OnLoginStateCallback = _OnLoginStateCallback;
};

var InitializeSocket = () => {
  SetQuoteReadyCallback(On_QuoteReady);
  SetAccountCallback(
    On_Dealer_Connection,
    On_Dealer_Receive_Login,
    On_Dealer_Receive_Account_GetAccount,
    Trade_Request_AccountInfo
  );

  StorageHelper.ReadData(Key_SessionToken).then((szToken) => {
    _TokenHandel(szToken);
    InitializeDealer();
  });
};

/* ------------------------------------------------------------------------- */
// Dealer
var InitializeDealer = () => {
  //Trade_Request_AccountInfo();
};
var On_Dealer_Connection: delOnConnection = (_State, _Address) => {
  // 連線失敗, 嘗試喚醒後端
  if (_State === ConnectionState.ConnectionFail) {
    // if (SendingAwake) return;
    // SendingAwake = true;
    // fetch(LdfApiAwakeURL).finally(() => {
    //   SendingAwake = false;
    // });
  } else if (_State === ConnectionState.Connected) {
    Trade_Request_AccountInfo();
  }
};

var On_Dealer_Receive_Login = (msg: Uint8Array[]) => {
  if (!msg || msg.length != 1) return;
  let szJson = CompressionExtensions.Zlib_UnZip_toString(msg[0]);
  let mdResault: kcTransModel.LoginResault = JSON.parse(szJson);

  if (!mdResault) return;

  let bState = mdResault.State === kcTransModel.ResaultState.Ok;
  let bToken = _TokenHandel(mdResault.Token); // 處理Token(更新狀態,存檔)
  let StateOK: boolean = bState && bToken;
  let nFailCode = mdResault.FailCode;

  if (StateOK) {
    if (OnLoginStateCallback)
      OnLoginStateCallback(
        LoginStatus.LoginSucceed,
        _GetLoginMessage(mdResault)
      );
    Trade_Request_AccountInfo();
  } else {
    if (OnLoginStateCallback)
      OnLoginStateCallback(LoginStatus.LoginFail, _GetLoginMessage(mdResault));
    else GoLoginScreen();
  }
};

var On_Dealer_Receive_Account_GetAccount = (msg: Uint8Array[]) => {
  if (!msg || msg.length != 1) return;
  let szJson = CompressionExtensions.Zlib_UnZip_toString(msg[0]);
  let mdResault: kcTransModel.GetAccountResault = JSON.parse(szJson);
  if (!mdResault) return;

  let PreAccount = Account;
  let bState = mdResault.State === kcTransModel.ResaultState.Ok;
  let bToken = _TokenHandel(mdResault.Token); // 處理Token(更新狀態,存檔)
  let StateOK: boolean = bState && bToken;

  if (StateOK) {
    if (OnAccountCallback) {
      let mdAccount = new kcAccountModel(mdResault.AccountInfo);
      OnAccountCallback(mdAccount, State);
    }

    // // 啟動Quote並RequestCommodity
    // InitQuote();
  } else {
    GoLoginScreen();
  }
};

var On_QuoteReady = () => {
  // 讓Login頁面跳轉
  if (OnLoginStateCallback) OnLoginStateCallback(LoginStatus.TraderReady, "");
};

// 登入
export var GetAccount = () => {
  return Account;
};
export var GetAccountState = () => {
  return State;
};
export var GetSessionToken = () => {
  return SessionToken;
};
export var TokenHandel = (_szJwtToken: string | null | undefined) => {
  return _TokenHandel(_szJwtToken);
};
export var LogOut = () => {
  StorageHelper.RemoveValue(Key_SessionToken);
  _TokenHandel(undefined);
  GoLoginScreen();
};
export var ClearStorage = () => {
  StorageHelper.ClearAll();
  _TokenHandel(undefined);
  GoLoginScreen();
};
export var Trade_Request_Login = (_szAccount: string, _szPW: string) => {
  // _CheckSendRequest() 會檢查sQDealer跟SessionToken, &&後面再帶一次只是為了好Call
  if (!_CheckSendRequest(false)) return;

  let CType: CommandType = CommandType.Login;
  let mdMsg: Message = new Message();

  let mdParma: kcTransModel.LoginParam = {
    Account: _szAccount,
    PW: _szPW,
    RequestKey: _GetRequestKey(),
  };
  let szJson = JSON.stringify(mdParma);
  let ZipBuffer = CompressionExtensions.Zlib_Zip_byString(szJson);

  mdMsg.addBuffer(_ToCommandTypeBuffer(CType));
  mdMsg.addBuffer(ZipBuffer);
  Quote_SendRequest(mdMsg);
};
export var Trade_Request_AccountInfo = () => {
  // _CheckSendRequest() 會檢查sQDealer跟SessionToken, &&後面再帶一次只是為了好Call
  if (!_CheckSendRequest() || !SessionToken) return;

  let CType: CommandType = CommandType.Account;
  let mdMsg: Message = new Message();

  let mdParma: kcTransModel.GetAccountParam = {
    Token: SessionToken,
    RequestKey: _GetRequestKey(),
  };

  let szJson = JSON.stringify(mdParma);
  let ZipBuffer = CompressionExtensions.Zlib_Zip_byString(szJson);

  mdMsg.addBuffer(_ToCommandTypeBuffer(CType));
  mdMsg.addBuffer(ZipBuffer);
  Quote_SendRequest(mdMsg);
};

// 檢查是否可以發送Request, 不能的話跳轉到Login頁面
var _CheckSendRequest = (_bCheckToken: boolean = true) => {
  let bTokenOK = _bCheckToken ? (SessionToken ? true : false) : true; // 不CheckToken的話就是true, CheckToken的話看SessionToken有沒有值
  let bCanSend = bTokenOK;

  if (!bCanSend) GoLoginScreen();
  return bCanSend;
};

var _ToCommandTypeBuffer = (_CType: CommandType): Uint8Array => {
  let Buf: Uint8Array = new Uint8Array(4);
  let dv = new DataView(Buf.buffer);
  dv.setUint32(0, _CType, true);
  return Buf;
};
var _TokenHandel = (_szJwtToken: string | undefined | null) => {
  let bRet: boolean = false;

  try {
    if (_szJwtToken) {
      SessionToken = _szJwtToken;
      StorageHelper.SaveData(Key_SessionToken, SessionToken);
      let JwtData: JWTPayloadModel = jwt_decode(_szJwtToken);
      if (JwtData.Account && JWTPayloadModel.Validate_State(JwtData.State)) {
        Account = JwtData.Account;
        State = JwtData.State;
        bRet = true;
      }
    }
  } catch {}

  if (!bRet) {
    SessionToken = undefined;
    Account = "";
    State = "None";
  }

  return bRet;
};

var RequestKey = 1000;
var _GetRequestKey = () => {
  return RequestKey++;
};

function _GetLoginMessage(_mdResault: kcTransModel.LoginResault) {
  let nFailCode = _mdResault.FailCode;
  switch (nFailCode) {
    case 0:
      return GetText("LoginMessage_OK");
    case 1:
      return GetText("LoginMessage_01");
    case 2:
      return GetText("LoginMessage_02");
    case 3:
      return GetText("LoginMessage_03");
    case 4:
      return GetText("LoginMessage_04");
    case 5:
      return GetText("LoginMessage_05");
    case 6:
      return GetText("LoginMessage_06");
    default:
      return _mdResault.Message;
  }
}

InitializeSocket();
