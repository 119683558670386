export default class SortedMap<TKey, TValue> {
  constructor(_fCompare?: (a: TKey, b: TKey) => number) {
    this.m_fCompare = _fCompare;
  }

  private m_fCompare?: (a: TKey, b: TKey) => number;
  private m_MapData: Map<TKey, TValue> = new Map<TKey, TValue>();

  get size(): number {
    return this.m_MapData.size;
  }
  get Count(): number {
    return this.m_MapData.size;
  }

  /* -------------------------------------------------- */
  // 原Map Function
  public clear(): void {
    this.m_MapData.clear();
  }
  public delete(key: TKey): boolean {
    return this.m_MapData.delete(key);
  }
  public forEach(
    callbackfn: (value: TValue, key: TKey, map: Map<TKey, TValue>) => void,
    thisArg?: any
  ): void {
    this.m_MapData.forEach(callbackfn);
  }
  public get(key: TKey): TValue | undefined {
    return this.m_MapData.get(key);
  }
  public has(key: TKey): boolean {
    return this.m_MapData.has(key);
  }
  public set(key: TKey, value: TValue): this {
    let bHasValue: boolean = this.m_MapData.has(key);
    this.m_MapData.set(key, value);

    if (!bHasValue) {
      if (this.m_fCompare) {
        let fCompare = this.m_fCompare;
        this.m_MapData = new Map<TKey, TValue>(
          [...this.m_MapData].sort((a, b) => fCompare(a[0], b[0]))
        );
      } else this.m_MapData = new Map<TKey, TValue>([...this.m_MapData].sort());
    }
    return this;
  }
  public entries(): IterableIterator<[TKey, TValue]> {
    return this.m_MapData.entries();
  }
  public keys(): IterableIterator<TKey> {
    return this.m_MapData.keys();
  }
  public values(): IterableIterator<TValue> {
    return this.m_MapData.values();
  }

  /* -------------------------------------------------- */
  // 擴充Function
  public get_WithAppend(key: TKey, _fDefault: () => TValue): TValue {
    let oValue: TValue | undefined = this.get(key);
    if (!oValue) {
      oValue = _fDefault();
      this.set(key, oValue);
    }
    return oValue;
  }
  public keysArray() {
    return [...this.keys()];
  }
  public valuesArray() {
    return [...this.values()];
  }
}
