import { DisplayTextModel } from "./_Model";

const zhTW: DisplayTextModel = {
  Cancel: "取消", // 取消
  Save: "儲存", // 儲存
  Search: "搜尋", // 搜尋
  Login: "登入", // 登入 log in
  ChangeLocale: "English", // 變更語言
  Screen_Login_Account: "帳號", // 帳號 Account
  Screen_Login_Password: "密碼", // 密碼 Password
  Screen_Login_ShowPassword: "顯示密碼", // 顯示密碼 show password
  Screen_Login_RememberPassword: "記住密碼", // 記住密碼 remember password

  Screen_Quote: "行情", // 報價 Quote
  Screen_Edit: "編輯", // 編輯 Edit
  Screen_Favorite: "自選商品", // 自選商品 favorite
  Screen_TECP: "圖表", // 圖表 Chart

  Quote_High: "最高", // 最高 High
  Quote_Low: "最低", // 最低 Low
  Quote_Bid: "買進", // 買進 Bid
  Quote_Ask: "賣出", // 賣出 Ask
  Quote_Vol: "單量", // 單量 Vol

  TECP_KLine: "K線圖", // K線圖 KLine
  TECP_KLine_Date: "日期", // 日期 Date
  TECP_KLine_Time: "時間", // 時間 Time
  TECP_KLine_Open: "開盤", // 開盤 Open
  TECP_KLine_High: "最高", // 最高 High
  TECP_KLine_Low: "最低", // 最低 Low
  TECP_KLine_Close: "收盤", // 收盤 Close
  TECP_KLineSub_FinTech_Fast: "快線", // 快線 Fast
  TECP_KLineSub_FinTech_Slow: "慢線", // 慢線 Slow
  TECP_KLineSub_FinTech_Scale: "升降刻度", // 升降刻度 Scale(Graduation)
  TECP_KLineSub_FinTech_Pressure: "壓力", // 壓力 Pressure
  TECP_KLineSub_FinTech_Support: "支撐", // 支撐 Support

  TECP_FinTech: "觔斗雲", // 觔斗雲 FinTech
  TECP_FinTech_Trend: "多空動向", // 多空動向 Trend

  Screen_Account: "帳號", // 帳號
  Screen_Account_Name: "姓名", // 姓名
  Screen_Account_ExpiryDate: "使用期限", // 使用期限 expiration date
  Screen_Account_Logout: "登出", // 登出 log out
  Screen_Account_ClearData: "清除本機資料", // 清除本機資料 clear local data

  Screen_Commodity: "商品規格", // 商品規格
  Screen_Commodity_Contract: "合約規格", // 合約規格 contract specification
  Screen_Commodity_Exchange: "交易所", // 交易所 exchange
  Screen_Commodity_Category: "類別", // 類別 Commodity Category
  Screen_Commodity_DecimalPlaces: "小數點位數", // 小數點位數 decimal places
  Screen_Commodity_TickSize: "最小跳動點數", // 最小跳動點數 TickSize
  Screen_Commodity_ValuePerPoint: "每點價值", // 每點價值 Pip value
  Screen_Commodity_TradeTime: "交易時段", // 交易時段 trading session

  Screen_Setting: "指標設定", // 指標設定 technical indicators setting
  Screen_Setting_ChartSetting: "圖表設定", // 圖表設定  Chart setting
  Screen_Setting_KLineColor: "K線顏色", // K線顏色 KLine color
  Screen_Setting_KLineColor_RedUp: "紅漲", // 紅漲 red up.
  Screen_Setting_KLineColor_GreenDown: "綠跌", // 綠跌 green down.
  Screen_Setting_KLineColor_GreenUp: "綠漲", // 綠漲 green up.
  Screen_Setting_KLineColor_RedDown: "紅跌", // 紅跌 red down.
  Screen_Setting_KLineColor_RUGD: "紅漲綠跌", // 紅漲綠跌 red up. green down.
  Screen_Setting_KLineColor_GURD: "綠漲紅跌", // 綠漲紅跌 green up. red down.
  Screen_Setting_KLineType: "圖表類型", // 圖表類型 chart type
  Screen_Setting_KLineType_CandlesTick: "蠟燭圖", // 蠟燭圖 Candlestick Chart
  Screen_Setting_KLineType_USLine: "美國線", // 美國線 Bar Chart
  Screen_Setting_KLineType_CloseLine: "折線", // 折線 Line Chart
  Screen_Setting_AuxiliaryLine: "輔助線", // 輔助線 Auxiliary line
  Screen_Setting_AuxiliaryLine_Daily: "換日線", // 換日線 daily line
  Screen_Setting_AuxiliaryLine_Price: "現價線", // 現價線 price line
  Screen_Setting_AuxiliaryLine_None: "不顯示輔助線", // 不顯示輔助線 no auxiliary line
  Screen_Setting_ShowSubChart: "顯示副圖", // 顯示副圖 Show sub-chart

  LoginMessage_OK: "登入成功", // 登入成功 Login suceed
  LoginMessage_01: "資料錯誤", // 資料錯誤 data error
  LoginMessage_02: "伺服器忙碌中, 請稍後再試", // 伺服器忙碌中請稍後再試 The server is busy, please try again later
  LoginMessage_03: "不存在的帳號或是錯誤的密碼", // 不存在的帳號或是錯誤的密碼 Wrong account or password
  LoginMessage_04: "此帳號尚未啟用", // 此帳號尚未啟用 This account has not been activated
  LoginMessage_05: "此帳號使用期限已過期", // 此帳號使用期限已過期 This account has expired
  LoginMessage_06: "錯誤", // 錯誤 Error
};

export default zhTW;
