import * as React from "react";
import { StyleSheet, Modal, ActivityIndicator } from "react-native";
import { View } from "../components/Themed";
import { Button } from "react-native-elements";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { ObjectExtensions } from "../kcExternal";
import { TapGestureHandler } from "react-native-gesture-handler";
import { HistoryDataType } from "../kcTransfer/InternalDefine";
import { IsBottomTabHieghtToLow } from "../navigation/BottomTabNavigator";
import { SafeAreaView } from "react-native-safe-area-context";

export class cHType {
  public sz: string = "M1";
  public HType: HistoryDataType = HistoryDataType.Minute;
  public HBase: number = 1;
}

const HTypes: cHType[] = [
  { sz: "M1", HType: HistoryDataType.Minute, HBase: 1 },
  { sz: "M5", HType: HistoryDataType.Minute, HBase: 5 },
  { sz: "M15", HType: HistoryDataType.Minute, HBase: 15 },
  { sz: "M30", HType: HistoryDataType.Minute, HBase: 30 },
  { sz: "H1", HType: HistoryDataType.Minute, HBase: 60 },
  { sz: "H4", HType: HistoryDataType.Minute, HBase: 240 },
  { sz: "D1", HType: HistoryDataType.Day, HBase: 1 },
  { sz: "W1", HType: HistoryDataType.Day, HBase: 5 },
  { sz: "MN", HType: HistoryDataType.Day, HBase: 20 },
];

export function FindcHType(_HType: HistoryDataType, _HBase: number): cHType {
  for (let Item of HTypes) {
    if (Item.HType === _HType && Item.HBase === _HBase) return Item;
  }

  let szDisplayText = "UnKnow";
  switch (_HType) {
    case HistoryDataType.Tick: {
      szDisplayText = `T${_HBase}`;
      break;
    }
    case HistoryDataType.Minute: {
      if (_HBase >= 60 && _HBase % 60 === 0) szDisplayText = `H${_HBase / 60}`;
      else szDisplayText = `M${_HBase}`;
      break;
    }
    case HistoryDataType.Day: {
      szDisplayText = `D${_HBase}`;
      break;
    }
  }

  return { sz: szDisplayText, HType: _HType, HBase: _HBase };
}

type IState = {
  Visable: boolean;
  loading: boolean;
  nFixedUpdateUI: number;
};

type IProp = {
  OnSelectHType?: (_cHype: cHType) => void;
  Location?: { X: number | string; Y: number | string };
};

export default class KC_HTypeModal extends React.Component<IProp, IState> {
  state: IState = {
    Visable: false,
    loading: false,
    nFixedUpdateUI: 0,
  };

  szDetail: string = "";
  bSuccess: boolean = false;

  constructor(props: IProp) {
    super(props);
  }

  componentDidMount() {
    kcSetUnmount(this, false);
  }
  componentWillUnmount() {
    kcSetUnmount(this, true);
  }
  shouldComponentUpdate(nextProps: IProp, nextState: IState) {
    if (!ObjectExtensions.ObjectsEqual(nextState, this.state)) return true;
    if (!ObjectExtensions.ObjectsEqual(nextProps, this.props)) return true;
    return false;
  }

  public Show = () => {
    kcSetState(this, {
      loading: false,
      Visable: true,
    });
  };
  public Close = () => {
    kcSetState(this, {
      Visable: false,
    });
  };
  private FixedUpdateUI = () => {
    kcSetState(this, { nFixedUpdateUI: this.state.nFixedUpdateUI + 1 });
  };

  private OnBtnClick = (_cHType: cHType) => {
    if (this.props.OnSelectHType) this.props.OnSelectHType(_cHType);
    kcSetState(this, { loading: false, Visable: false });
  };

  private OnTapHandlerStateChange = (event: any) => {
    setTimeout(() => {
      kcSetState(this, { loading: false, Visable: false });
    }, 100);
  };

  render() {
    if (this.state.Visable == false) return <></>;
    const BtnWidth = 45;
    const BtnHeight = 30;
    const BtnMargin = 5;
    const WarpPadding = 5;
    let IsToLow = IsBottomTabHieghtToLow();

    let Location: { X?: number | string; Y?: number | string } = {
      X: 5,
      Y: 60,
    };
    if (this.props.Location) Location = this.props.Location;
    else {
      if (IsToLow) Location.X = 12;
    }

    return (
      // Web 才有作用
      <TapGestureHandler onEnded={this.OnTapHandlerStateChange}>
        <Modal animationType="fade" transparent={true} visible={true}>
          <SafeAreaView
            style={{
              flex: 1,
              backgroundColor: "#000000BB",
              justifyContent: "flex-start", // 上下位置
              alignItems: "flex-start", // 左右位置
            }}
            // IOS APP 才有作用
            onTouchEnd={this.OnTapHandlerStateChange}
            onLayout={(e) => {
              this.FixedUpdateUI();
            }}
          >
            {this.state.loading && <ActivityIndicator size="large" />}
            {!this.state.loading && (
              <View
                style={{
                  flexDirection: IsToLow ? "row" : "column",
                  alignItems: "center",
                  backgroundColor: "#555555BB",
                  borderRadius: 10,
                  padding: WarpPadding,
                  left: Location.X,
                  top: Location.Y,
                }}
              >
                {HTypes.map((_cHType) => {
                  return (
                    <Button
                      buttonStyle={{
                        backgroundColor: "#666666",
                        width: BtnWidth,
                        height: BtnHeight,
                        margin: BtnMargin,
                      }}
                      titleStyle={{ fontSize: 14 }}
                      title={_cHType.sz}
                      onPress={() => {
                        this.OnBtnClick(_cHType);
                      }}
                    />
                  );
                })}
              </View>
            )}
          </SafeAreaView>
        </Modal>
      </TapGestureHandler>
    );
  }
}

const styles = StyleSheet.create({
  NumberBtnContainer: {
    //flex: 1,
    backgroundColor: "#00000000",
    justifyContent: "center",
  },
  NumberBtn: {
    //backgroundColor: "#505050",
    backgroundColor: "#00000000",
    height: 20,
    borderRadius: 0,
    borderWidth: 0,
    padding: 0,
    width: 50,
  },
  RowTitleStyles: {
    color: "#888888",
    fontSize: 16,
  },
  RowValueStyles: {
    color: "#000000",
    fontSize: 16,
    textAlign: "right",
  },
});
