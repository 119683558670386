import moment, { Moment } from "moment";
import { MomentExtensions } from "../kcExternal";
import kcHistoryOHLCModel from "./kcHistoryOHLCModel";
import { PriceInDepthType, PriceInDepthTypeArray } from "./kcModel_Enum";

export default class kcHistoryTickModel {
  constructor(props: object) {
    Object.assign(this, { ...props });

    this.Time = MomentExtensions.Parse(this.Time);
  }

  Time: Moment = moment(0);
  Price: number = 0;
  Vol: number = 0;
  AskPrice: number = 0;
  BidPrice: number = 0;
  PriceInDepth: PriceInDepthType = "Unknow";

  ToString() {
    let szRet = "";
    szRet += this.Time.format("YYYY/MM/DD HH:mm:ss.SSS") + ", ";
    szRet += this.Price + ", ";
    szRet += this.Vol + ", ";
    szRet += this.PriceInDepth + ", ";
    return szRet;
  }

  /* ---------------------------static Function--------------------------- */
  static FixedBufferSize: number = 37;
  static FromListBuffer(
    bytes: Uint8Array,
    _TimeZone: string
  ): kcHistoryTickModel[] {
    let mdRet: kcHistoryTickModel[] = [];

    try {
      let nDataCount = bytes.length / kcHistoryTickModel.FixedBufferSize;
      for (let i = 0; i < nDataCount; i++) {
        let md = kcHistoryTickModel.CreateFromBuffer(
          bytes,
          i * kcHistoryTickModel.FixedBufferSize,
          _TimeZone
        );
        if (md) mdRet.push(md);
      }
    } catch {}

    return mdRet;
  }

  static ToOHLCList(_mlTicks: kcHistoryTickModel[]): kcHistoryOHLCModel[] {
    let mdRet: kcHistoryOHLCModel[] = _mlTicks.map((mdTick) => {
      return new kcHistoryOHLCModel({
        Time: mdTick.Time,
        OpenPrice: mdTick.Price,
        HighPrice: mdTick.Price,
        LowPrice: mdTick.Price,
        ClosePrice: mdTick.Price,
        Vol: mdTick.Vol,
      });
    });
    try {
    } catch {}
    return mdRet;
  }

  static CreateFromBuffer(
    bytes: Uint8Array,
    offset: number,
    _TimeZone: string
  ): kcHistoryTickModel | undefined {
    if (offset + kcHistoryTickModel.FixedBufferSize > bytes.length)
      return undefined;

    let dv = new DataView(bytes.buffer, offset);

    let nIdx = 0;

    let dOADate: number = dv.getFloat64(nIdx, true);
    let tTime: Moment = MomentExtensions.FromOADate_WithTimeZone(
      dOADate,
      _TimeZone
    );
    nIdx += 8;

    let dPrice = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let nVol = dv.getUint32(nIdx, true);
    nIdx += 4;

    let dAskPrice = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let dBidPrice = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let nPriceInDepth = dv.getUint8(nIdx);
    nIdx += 1;
    let szPriceInDepth: PriceInDepthType = "Unknow";
    if (nPriceInDepth >= 0 && nPriceInDepth < PriceInDepthTypeArray.length)
      szPriceInDepth = PriceInDepthTypeArray[nIdx];

    let mdTick: kcHistoryTickModel = new kcHistoryTickModel({
      Time: tTime,
      Price: dPrice,
      Vol: nVol,
      AskPrice: dAskPrice,
      BidPrice: dBidPrice,
      PriceInDepth: szPriceInDepth,
    });

    return mdTick;
  }
}
