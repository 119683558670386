export default class StringASCIIHelper {
  static GetBytes = (str: string, buffer?: Uint8Array) => {
    if (!buffer) {
      buffer = new Uint8Array(str.length);
    }

    if (buffer) {
      for (var i = 0, strLen = str.length; i < strLen; i++) {
        var char = str.charCodeAt(i);

        if (char > 255) {
          // only ASCII are supported at the moment, we will put ? instead
          buffer[i] = "?".charCodeAt(i);
        } else {
          buffer[i] = char;
        }
      }
    }
    return buffer;
  };

  static GetString = (buffer: any) => {
    return String.fromCharCode.apply(null, buffer);
  };
}
