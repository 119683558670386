import { kcColor } from "../../constants/Colors";

const Default_KDBase: number = 9;
const Default_RSVWeight: number = 3;
const Default_KWeight: number = 3;

type KDDataSettingType = {
  KDBase?: number;
  RSVWeight?: number;
  KWeight?: number;
};
type KDDrawSettingType = {
  // RSV
  RSV_Enable?: boolean;
  RSV_LineColor?: string;
  RSV_LineWidth?: number;

  // K
  K_Enable?: boolean;
  K_LineColor?: string;
  K_LineWidth?: number;

  // D
  D_Enable?: boolean;
  D_LineColor?: string;
  D_LineWidth?: number;

  // J
  J_Enable?: boolean;
  J_LineColor?: string;
  J_LineWidth?: number;

  // 價位線 (Basic Horizonta Line)
  BHL_0_Enable?: boolean;
  BHL_0_Value?: number;
  BHL_0_LineColor?: string;
  BHL_0_LineWidth?: number;

  BHL_1_Enable?: boolean;
  BHL_1_Value?: number;
  BHL_1_LineColor?: string;
  BHL_1_LineWidth?: number;

  BHL_2_Enable?: boolean;
  BHL_2_Value?: number;
  BHL_2_LineColor?: string;
  BHL_2_LineWidth?: number;
};
export default class KDSetting {
  constructor(props: object) {
    Object.assign(this, { ...props });
  }

  KDBase: number = Default_KDBase;
  RSVWeight: number = Default_RSVWeight;
  KWeight: number = Default_KWeight;

  // RSV
  RSV_Enable: boolean = false;
  RSV_LineColor: string = "#AA0055FF";
  RSV_LineWidth: number = 1;

  // K
  K_Enable: boolean = true;
  K_LineColor: string = "#87CEEBFF";
  K_LineWidth: number = 1;

  // D
  D_Enable: boolean = true;
  D_LineColor: string = "#FFA500FF";
  D_LineWidth: number = 1;

  // J
  J_Enable: boolean = false;
  J_LineColor: string = "#008000FF";
  J_LineWidth: number = 1;

  // 價位線 (Basic Horizonta Line)
  BHL_0_Enable: boolean = true;
  BHL_0_Value: number = 75;
  BHL_0_LineColor: string = kcColor("Border");
  BHL_0_LineWidth: number = 1;

  BHL_1_Enable: boolean = true;
  BHL_1_Value: number = 50;
  BHL_1_LineColor: string = kcColor("Border");
  BHL_1_LineWidth: number = 1;

  BHL_2_Enable: boolean = true;
  BHL_2_Value: number = 25;
  BHL_2_LineColor: string = kcColor("Border");
  BHL_2_LineWidth: number = 1;

  public get EnableAny() {
    return this.RSV_Enable || this.K_Enable || this.D_Enable || this.J_Enable;
  }
  public get EnableAll() {
    return this.RSV_Enable && this.K_Enable && this.D_Enable && this.J_Enable;
  }

  public Clone(): KDSetting {
    return new KDSetting(this);
  }
  public SetGlobalSetting(_Setting: KDSetting) {
    Object.assign(this, _Setting);
  }

  public SetSetting(
    _Setting: (KDDataSettingType & KDDrawSettingType) | KDSetting
  ): {
    DataChanged: boolean;
    DrawChanged: boolean;
  } {
    let Reasult = { DataChanged: false, DrawChanged: false };

    if (_Setting) {
      // DataChanged
      if (_Setting.KDBase !== undefined && _Setting.KDBase !== this.KDBase) {
        this.KDBase = _Setting.KDBase;
        Reasult.DataChanged = true;
      }
      if (
        _Setting.RSVWeight !== undefined &&
        _Setting.RSVWeight !== this.RSVWeight
      ) {
        this.RSVWeight = _Setting.RSVWeight;
        Reasult.DataChanged = true;
      }
      if (_Setting.KWeight !== undefined && _Setting.KWeight !== this.KWeight) {
        this.KWeight = _Setting.KWeight;
        Reasult.DataChanged = true;
      }

      // DrawChanged
      if (
        _Setting.RSV_Enable !== undefined &&
        _Setting.RSV_Enable !== this.RSV_Enable
      ) {
        this.RSV_Enable = _Setting.RSV_Enable;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.RSV_LineColor !== undefined &&
        _Setting.RSV_LineColor !== this.RSV_LineColor
      ) {
        this.RSV_LineColor = _Setting.RSV_LineColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.RSV_LineWidth !== undefined &&
        _Setting.RSV_LineWidth !== this.RSV_LineWidth
      ) {
        this.RSV_LineWidth = _Setting.RSV_LineWidth;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.K_Enable !== undefined &&
        _Setting.K_Enable !== this.K_Enable
      ) {
        this.K_Enable = _Setting.K_Enable;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.K_LineColor !== undefined &&
        _Setting.K_LineColor !== this.K_LineColor
      ) {
        this.K_LineColor = _Setting.K_LineColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.K_LineWidth !== undefined &&
        _Setting.K_LineWidth !== this.K_LineWidth
      ) {
        this.K_LineWidth = _Setting.K_LineWidth;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.D_Enable !== undefined &&
        _Setting.D_Enable !== this.D_Enable
      ) {
        this.D_Enable = _Setting.D_Enable;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.D_LineColor !== undefined &&
        _Setting.D_LineColor !== this.D_LineColor
      ) {
        this.D_LineColor = _Setting.D_LineColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.D_LineWidth !== undefined &&
        _Setting.D_LineWidth !== this.D_LineWidth
      ) {
        this.D_LineWidth = _Setting.D_LineWidth;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.J_Enable !== undefined &&
        _Setting.J_Enable !== this.J_Enable
      ) {
        this.J_Enable = _Setting.J_Enable;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.J_LineColor !== undefined &&
        _Setting.J_LineColor !== this.J_LineColor
      ) {
        this.J_LineColor = _Setting.J_LineColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.J_LineWidth !== undefined &&
        _Setting.J_LineWidth !== this.J_LineWidth
      ) {
        this.J_LineWidth = _Setting.J_LineWidth;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.BHL_0_Enable !== undefined &&
        _Setting.BHL_0_Enable !== this.BHL_0_Enable
      ) {
        this.BHL_0_Enable = _Setting.BHL_0_Enable;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.BHL_0_Value !== undefined &&
        _Setting.BHL_0_Value !== this.BHL_0_Value
      ) {
        this.BHL_0_Value = _Setting.BHL_0_Value;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.BHL_0_LineColor !== undefined &&
        _Setting.BHL_0_LineColor !== this.BHL_0_LineColor
      ) {
        this.BHL_0_LineColor = _Setting.BHL_0_LineColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.BHL_0_LineWidth !== undefined &&
        _Setting.BHL_0_LineWidth !== this.BHL_0_LineWidth
      ) {
        this.BHL_0_LineWidth = _Setting.BHL_0_LineWidth;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.BHL_1_Enable !== undefined &&
        _Setting.BHL_1_Enable !== this.BHL_1_Enable
      ) {
        this.BHL_1_Enable = _Setting.BHL_1_Enable;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.BHL_1_Value !== undefined &&
        _Setting.BHL_1_Value !== this.BHL_1_Value
      ) {
        this.BHL_1_Value = _Setting.BHL_1_Value;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.BHL_1_LineColor !== undefined &&
        _Setting.BHL_1_LineColor !== this.BHL_1_LineColor
      ) {
        this.BHL_1_LineColor = _Setting.BHL_1_LineColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.BHL_1_LineWidth !== undefined &&
        _Setting.BHL_1_LineWidth !== this.BHL_1_LineWidth
      ) {
        this.BHL_1_LineWidth = _Setting.BHL_1_LineWidth;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.BHL_2_Enable !== undefined &&
        _Setting.BHL_2_Enable !== this.BHL_2_Enable
      ) {
        this.BHL_2_Enable = _Setting.BHL_2_Enable;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.BHL_2_Value !== undefined &&
        _Setting.BHL_2_Value !== this.BHL_2_Value
      ) {
        this.BHL_2_Value = _Setting.BHL_2_Value;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.BHL_2_LineColor !== undefined &&
        _Setting.BHL_2_LineColor !== this.BHL_2_LineColor
      ) {
        this.BHL_2_LineColor = _Setting.BHL_2_LineColor;
        Reasult.DrawChanged = true;
      }
      if (
        _Setting.BHL_2_LineWidth !== undefined &&
        _Setting.BHL_2_LineWidth !== this.BHL_2_LineWidth
      ) {
        this.BHL_2_LineWidth = _Setting.BHL_2_LineWidth;
        Reasult.DrawChanged = true;
      }
    }

    return Reasult;
  }

  public ToDefault() {
    this.KDBase = Default_KDBase;
    this.RSVWeight = Default_RSVWeight;
    this.KWeight = Default_KWeight;
  }
}
