import { kcTECPCollection } from ".";
import TECPSetting from "./TECPSetting";

export default class TECPSettingEditHelper {
  constructor(_kcTECP: kcTECPCollection) {
    this.m_kcTECP = _kcTECP;
    this.m_bSettingReady = this.m_kcTECP.SettingReady;
    this.m_Setting = this.m_kcTECP.Setting.Clone();
  }

  private m_bSettingReady: boolean;
  private m_kcTECP: kcTECPCollection;
  private m_Setting: TECPSetting;

  // 編輯用的Setting, Clone出來的
  public get SettingReady() {
    return this.m_bSettingReady;
  }
  public get Setting() {
    return this.m_Setting;
  }
  public ReSet() {
    this.m_Setting.SetSetting(this.m_kcTECP.Setting);
  }
  public Save() {
    this.m_kcTECP.UpdateSetting(this.m_Setting);
  }
  public async GetSettingAsync(_bReset: boolean): Promise<TECPSetting> {
    if (this.m_kcTECP.SettingReady) {
      if (!this.m_bSettingReady || _bReset) {
        this.ReSet();
        this.m_bSettingReady = true;
      }

      return this.m_Setting;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(this.GetSettingAsync(_bReset));
        }, 1);
      });
    }
  }

  /* ---------------------------------------------------- */
  // static
  public static SettingBuffer?: TECPSettingEditHelper;
  public static BeginEdit(_kcTECP: kcTECPCollection) {
    if (_kcTECP) this.SettingBuffer = new TECPSettingEditHelper(_kcTECP);
  }
  public static EndEdit(_bSave: boolean) {
    if (!this.SettingBuffer) return;

    if (_bSave) this.SettingBuffer.Save();

    //this.SettingBuffer = undefined;
  }
}
