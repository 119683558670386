import { CurrencyType, kcCommodityCategory, kcExchangeCode, kcIndustryCategory, kcMarketCategory } from "./kcModel_Enum";
import kcPriceScaleModel from "./kcPriceScaleModel";
import kcTradeTimeModel from "./kcTradeTimeModel";
import { CompressionExtensions, MathEx, MomentExtensions, StringConverter } from "../kcExternal";
import { Moment } from "moment";
import moment from "moment";
import { IsLocale } from "../Locales";

export default class kcCommodityModel {
   constructor(props: object) {
      Object.assign(this, { ...props });

      this.FloatNum = kcCommodityModel.ToFloatNumber(this.PriceScale);
      this.FixedTime();
      this.TimeZone = kcCommodityModel.GetTimeZone(this.ExchangeCode);
   }

   /* ---------------------------Member--------------------------- */
   StockCode: string = ""; // 商品代碼
   StockName: string = ""; // 商品名稱
   StockName_EN: string = ""; // 商品名稱 en
   ExchangeCode: kcExchangeCode = kcExchangeCode.UnDef; // 交易所代碼
   //MarketCategorye: kcMarketCategory = kcMarketCategory.UnDef; // 市場類別
   CommCategorye: kcCommodityCategory = kcCommodityCategory.Unknow; // 商品類別
   //IndustryCategorye: kcIndustryCategory = kcIndustryCategory.UnDef; // 產業類別
   //CreateDate: Moment = moment(0); // 上市日
   //Currency: CurrencyType = CurrencyType.UnKnow; // 貨幣
   //OutstandingStockVol: number = 0; // 流通在外張數  // ISIN & 公司基本資訊 ↑
   //SettlementDate: Moment = moment(0); // 結算日
   //ExpireDate: Moment = moment(0); // 最後交易日
   TradeTime: kcTradeTimeModel[] = [];
   PriceScale: kcPriceScaleModel[] = []; // 跳動點數
   PointPrice: number = 0; // 每點價值 (契約成數)

   // 為了方便額外加入
   FloatNum: number;
   TimeZone: string;

   private FixedTime() {
      // this.CreateDate = MomentExtensions.Parse(this.CreateDate);
      // this.SettlementDate = MomentExtensions.Parse(this.SettlementDate);
      // this.ExpireDate = MomentExtensions.Parse(this.ExpireDate);
   }

   ToString() {
      let szRet = "";
      szRet += this.StockCode + ", ";
      szRet += this.StockName + ", ";
      szRet += this.ExchangeCode + ", ";
      szRet += this.CommCategorye + ", ";
      //szRet += this.Currency + ", ";
      szRet += this.TradeTime + ", ";
      szRet += this.PriceScale;
      return szRet;
   }

   Update(_mdData: kcCommodityModel) {
      Object.assign(this, { ..._mdData });

      this.FloatNum = kcCommodityModel.ToFloatNumber(this.PriceScale);
      this.FixedTime();
   }
   LocaleStockName() {
      if (IsLocale("en")) return this.StockName_EN;
      else return this.StockName;
   }

   /* ---------------------------static Function--------------------------- */
   // 轉換成kcCommodityModel, 格式錯誤則為undefined
   static Create_FromJSon(ZipJSonBuffer: Uint8Array): kcCommodityModel | undefined {
      let JSonBuffer = CompressionExtensions.Zlib_UnZip(ZipJSonBuffer);
      let szJSon: string = StringConverter.UTF8_GetString(JSonBuffer);
      let Obj = JSON.parse(szJSon);

      let mdCommodity: kcCommodityModel | undefined = undefined;
      if (Obj) mdCommodity = new kcCommodityModel(Obj);

      return mdCommodity;
   }

   // 轉換成kcCommodityModel[], 格式錯誤則為空Array
   static CreateList_FromJSon(ZipJSonBuffer: Uint8Array): kcCommodityModel[] {
      let JSonBuffer = CompressionExtensions.Zlib_UnZip(ZipJSonBuffer);
      let szJSon: string = StringConverter.UTF8_GetString(JSonBuffer);
      let Obj = JSON.parse(szJSon);

      let mlCommoditys: kcCommodityModel[];
      if (Obj && Array.isArray(Obj)) {
         mlCommoditys = Obj.map((q) => new kcCommodityModel(q));
      } else mlCommoditys = [];
      return mlCommoditys;
   }
   static ToFloatNumber(_PriceScale: kcPriceScaleModel[]) {
      let PriceScale = 0.00001;
      let FloatNum = 5;

      if (_PriceScale.length != 0) {
         PriceScale = _PriceScale[0].TickPrice;
         FloatNum = MathEx.GetDecimalNumber(PriceScale);
      }

      return FloatNum;
   }
   static GetTimeZone(_ExcCode: kcExchangeCode) {
      switch (_ExcCode) {
         case kcExchangeCode.CBT:
         case kcExchangeCode.CME:
         case kcExchangeCode.CFE:
            return "US/Central";

         case kcExchangeCode.CMX:
         case kcExchangeCode.NYM:
         case kcExchangeCode.ICEUS:
            return "US/Eastern";

         case kcExchangeCode.SMX:
            return "Singapore";

         case kcExchangeCode.TAIFEX:
         case kcExchangeCode.TWSE:
            return "Asia/Taipei";

         case kcExchangeCode.ENX:
            return "Europe/Paris";

         case kcExchangeCode.ASX:
            return "Australia/Sydney";

         case kcExchangeCode.Eurex:
            return "Europe/Berlin";

         case kcExchangeCode.HKEx:
         case kcExchangeCode.SZSE:
            return "Asia/Hong_Kong";

         case kcExchangeCode.ICEEU:
         case kcExchangeCode.BalticEx:
            return "GMT";

         case kcExchangeCode.OSE:
         case kcExchangeCode.TCE:
            return "Asia/Tokyo";

         case kcExchangeCode.KRX:
            return "Asia/Seoul";

         case kcExchangeCode.FX:
         case kcExchangeCode.INDEX:
         default:
            return "Undef";
      }
   }
   static GetTradeTimeString(_mdCommodity: kcCommodityModel): string[] | undefined {
      if (_mdCommodity === undefined || _mdCommodity.TradeTime === undefined) return undefined;

      let nTeadeNumber = kcTradeTimeModel.GetTradeNumber(_mdCommodity.TradeTime);
      if (nTeadeNumber == 0) return [];

      let TradeSets: {
         mdOpen: kcTradeTimeModel;
         mdClose: kcTradeTimeModel;
      }[] = [];
      for (let i = 0; i < nTeadeNumber; i++) {
         let mdTrade = kcTradeTimeModel.GetTradeSet(_mdCommodity.TradeTime, i);
         if (mdTrade) {
            if (mdTrade.mdOpen.MinuteOffset < 0) {
               mdTrade.mdOpen.MinuteOffset += 1440;
               mdTrade.mdClose.MinuteOffset += 1440;
            }
            TradeSets.push(mdTrade);
         }
      }
      if (TradeSets.length > 1) TradeSets.sort((x, y) => x.mdOpen.MinuteOffset - y.mdOpen.MinuteOffset);

      let szRet: string[] = [];
      for (let i = 0; i < TradeSets.length; i++) {
         let sz = kcTradeTimeModel.ToDisplayString(TradeSets[i], _mdCommodity.TimeZone, true);
         if (sz) szRet.push(sz);
      }
      return szRet;
   }
}
